import React from "react";

/**
 * PopupBasicContent is a component for basic text content for a popup created by the component PopupR.
 *
 * @param [classes] {string}
 * @param text {string}
 * @param title {string}
 * @returns {JSX.Element}
 */
const PopupBasicContent = ({
	classes,
	text,
	title
}) => {
	return (
		<div className={`page generic ${classes}`}>
			<div className={"sprite"}/>
			<div className={"title font-title24"}>
				{title}
			</div>
			<div className={"desc font-normal"}>
				{text}
			</div>
		</div>
	);
};

export default PopupBasicContent;