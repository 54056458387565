import axios from "core/axios-sp";
import SavePopup from "views/survey/save-popup";
import {renameFolder as renameFolderInState} from "../../../surveysActions";

export const requestFolderRename = (folderId, newName) => {
	Surveypal.getLoader().show();

	axios.put("/app/home/folder", {
		id: folderId,
		name: newName
	}).then(response => {
		if (response.status === 200)
		{
			Surveypal.store.dispatch(renameFolderInState(folderId, newName));
			Surveypal.getLoader().hide();
		}
	});
};

export const renameFolder = (folder) => {
	new SavePopup(
		(newName) => requestFolderRename(folder.id, newName),
		"folder-rename",
		translator.get("rename-popup.renameTitle"),
		translator.get("rename-popup.renameFolderDesc"),
		translator.get("rename-popup.rename"),
		folder.name
	);
};