import {FILTER_VALUES} from "../components/Filters/Filters";
import AccessConf from "conf/survey-access";

/**
 * surveyHasNewResponses filters surveys based on whether they have new responses and whether the user wants to filter
 * surveys that have or haven't new responses.
 *
 * @param survey {object}
 * @param value {"ALL"|"YES"|"NO"}
 * @returns {boolean}
 */
export const surveyHasNewResponses = (survey, value) => {
	if (value === FILTER_VALUES.YES) return survey.newAnswerCount && survey.newAnswerCount > 0;
	if (value === FILTER_VALUES.NO) return !survey.newAnswerCount || survey.newAnswerCount === 0;

	return true;
};

/**
 * surveyIsShared filters surveys based on whether they are shared and whether the user wants to filter shared or
 * unshared surveys.
 *
 * @param survey {object}
 * @param value {"ALL"|"YES"|"NO"}
 * @returns {boolean}
 */
export const surveyIsShared = (survey, value) => {
	if (value === FILTER_VALUES.YES) return survey.shared && !AccessConf.isTeamShared(survey);
	if (value === FILTER_VALUES.NO) return !survey.shared;

	return true;
};

/**
 * surveyIsTemplate filters surveys based on whether they are used as templates and whether the user wants to filter
 * surveys that are or aren't used as templates.
 *
 * @param survey {object}
 * @param templates {object[]}
 * @param value {"ALL"|"YES"|"NO"}
 * @returns {boolean}
 */
export const surveyIsTemplate = (survey, templates, value) => {
	const isTemplate = templates.some(template => template.survey_id === survey.id);
	if (value === FILTER_VALUES.YES) return isTemplate;
	if (value === FILTER_VALUES.NO) return !isTemplate;

	return true;
};

/**
 * surveyHasAutomaticReports filters surveys based on whether they have automatic reports and whether the user wants
 * to filter surveys that have or haven't automatic reports.
 *
 * @param survey {object}
 * @param value {"ALL"|"YES"|"NO"}
 * @returns {boolean}
 */
const surveyHasAutomaticReports = (survey, value) => {
	if (value === FILTER_VALUES.YES) return !!survey.scheduledExport;
	if (value === FILTER_VALUES.NO) return !survey.scheduledExport;

	return true;
};

export default {
	surveyHasNewResponses,
	surveyIsShared,
	surveyIsTemplate,
	surveyHasAutomaticReports
};