import React from "react";
import CheckMark from "ui/Icons/CheckMark";
import "./Checkbox.scss";

/**
 * @callback onSelect
 */
/**
 * <Checkbox/> renders an individual checkbox.
 *
 * @param className {string}
 * @param onSelect {onSelect}
 * @param checked {boolean}
 * @param disabled {boolean}
 * @param label {string}
 * @returns {JSX.Element}
 */
const Checkbox = ({
	className,
	onSelect,
	checked,
	disabled,
	label
}) => {
	const select = !disabled ? onSelect : () => {};

	const classes = `checkbox-v2 ${className ? className : ""} ${disabled ? "disabled" : ""} ${checked ? "checked" : ""}`;

	return (
		<div className={classes} onClick={select}>
			<div className={"box"}>
				<CheckMark scale={0.17}/>
			</div>
			{
				!!label && label.trim() !== "" &&
				<div className={"label"}>{label}</div>
			}
		</div>
	);
};

export default Checkbox;