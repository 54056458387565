import axios from "../../../core/axios-sp";

export const SET_INITIAL_SUBSCRIPTIONS = "CRM.SUBSCRIPTIONS.SET_INITIAL_SUBSCRIPTIONS";
export const ADD_SUBSCRIPTION = "CRM.SUBSCRIPTIONS.ADD_SUBSCRIPTION";
export const SET_SUBSCRIPTION_TYPE = "CRM.SUBSCRIPTIONS.EDITED_SUBSCRIPTION";
export const SET_SUBSCRIPTIONS_SEARCH_PARAM = "CRM.SUBSCRIPTIONS.SET_SUBSCRIPTIONS_SEARCH_PARAM";
export const SET_NEW_SUBSCRIPTION_PARAM = "CRM.SUBSCRIPTIONS.SET_NEW_SUBSCRIPTION_PARAM";
export const PUSH_NOTIFICATION = "CMR.SUBSCRIPTIONS.SHOW_NOTIFICATION";
export const POP_NOTIFICATION = "CRM.SUBSCRIPTION.CLEAR_NOTIFICATION";
export const DELETE_LOCAL_SUBSCRIPTION = "CRM.SUBSCRIPTION.DELETE_LOCAL_SUBSCRIPTION";

export const UPDATE_ERROR = "Something went wrong while updating the subscription.";
export const CREATE_ERROR = "Something went wrong while creating a new subscription. Make sure you have all parameters defined.";
export const FETCH_ERROR = "Something went wrong while fetching subscriptions. Check the organization id.";
export const CREATE_MSG = "Subscription created.";
export const UPDATE_MSG = "Subscription updated.";
export const DELETE_MSG = "Subscription deleted.";
export const DELETE_ERROR = "Subscription couldn't be deleted.";

const NOTIFICATION_DELAY = 7000;

export const SUBSCRIPTION_TYPES = {
	ACTIVE: "ACTIVE",
	CANCELLED: "CANCELLED"
};


export const CHANNELS = {
	EMAIL: "email",
	SMS: "sms"
};

export const COMPONENTS = {
	OPEN: "OPEN",
	SELECTOR: "SELECTOR"
};

const PARAMS = {
	ORG_ID: {
		name: "orgId",
		value: "",
		component: COMPONENTS.OPEN,
		placeholder: "Select organization or write id",
		label: "Organization id"
	},
	CHANNEL: {
		name: "channel",
		value: -2,
		component: COMPONENTS.SELECTOR,
		label: "Channel",
		placeHolder: "Choose channel...",
		items: [[
			{value: CHANNELS.EMAIL, text: CHANNELS.EMAIL, fixed: true},
			{value: CHANNELS.SMS, text: CHANNELS.SMS, fixed: true}
		]]
	},
	IDENTIFIER: {
		name: "identifier",
		value: "",
		component: COMPONENTS.OPEN,
		label: "Identifier (email or sms)"
	},
	TYPE: {
		name: "type",
		value: -2,
		component: COMPONENTS.SELECTOR,
		label: "Subscription type",
		placeHolder: "Choose type...",
		items: [[
			{value: SUBSCRIPTION_TYPES.ACTIVE, text: SUBSCRIPTION_TYPES.ACTIVE, fixed: true},
			{value: SUBSCRIPTION_TYPES.CANCELLED, text: SUBSCRIPTION_TYPES.CANCELLED, fixed: true}
		]]
	}
};

export const SEARCH_PARAMS = [
	PARAMS.ORG_ID, PARAMS.IDENTIFIER, PARAMS.CHANNEL
];

export const NEW_SUB_PARAMS = [
	PARAMS.ORG_ID, PARAMS.IDENTIFIER, PARAMS.CHANNEL, PARAMS.TYPE
];

export function setInitialSubscriptions(subscriptions) {
	return {
		type: SET_INITIAL_SUBSCRIPTIONS,
		subscriptions
	};
}

export function addNewSubscription(subscription) {
	return {
		type: ADD_SUBSCRIPTION,
		subscription
	}
}

export function deleteLocalSubscription(id) {
	return {
		type: DELETE_LOCAL_SUBSCRIPTION,
		id
	}
}

export function setSubscriptionType(id, active)
{
	return {
		type: SET_SUBSCRIPTION_TYPE,
		id,
		active // is actually subscription.type, but "type" can't be used becaused is reserved for action.type
	}
}

export function setSubscriptionSearchParam(key, value) {
	return {
		type: SET_SUBSCRIPTIONS_SEARCH_PARAM,
		key,
		value
	}
}

export function setNewSubParam(key, value) {
	return {
		type: SET_NEW_SUBSCRIPTION_PARAM,
		key,
		value
	}
}

export function pushNotification(message, isError, id = Date.now()) {
	const notification = {
		message,
		isError
	};
	return {
		type: PUSH_NOTIFICATION,
		notification
	}
}

export function popNotification() {
	return {
		type: POP_NOTIFICATION
	}
}

const SUBSCRIPTION_PATH = "/app/crm/invitation/subscription";
const SUBSCRIPTIONS_PATH = "/app/crm/invitation/subscriptions";

export function fetchAndSetSubscriptions(searchParams = {}) {
	return dispatch => {
		console.log("Fetching subscriptions...");
		return axios.get(SUBSCRIPTIONS_PATH, {
			params: {
				json: searchParams
			}
		}).then(response => {
			console.log("Subscriptions fetched.");
			const sortedSubscriptions = response.data.sort((a,b) => a.identifier.localeCompare(b.identifier));
			dispatch(setInitialSubscriptions(sortedSubscriptions));
		}).catch(error => {
			dispatch(pushNotification(FETCH_ERROR, error));
			setTimeout(dispatch.bind(null, popNotification()), NOTIFICATION_DELAY);
			throw(error);
		});
	};
}

export function createSubscription(params) {
	return dispatch => {
		console.log("Creating subscription...");
		return axios.post(SUBSCRIPTION_PATH, params).then(response => {
			console.log("Subscription created.");
			const newSub = response.data;
			newSub.isNew = true;
			dispatch(addNewSubscription(newSub));
			dispatch(pushNotification(CREATE_MSG, false));
			setTimeout(dispatch.bind(null, popNotification()), NOTIFICATION_DELAY);
		}).catch(error => {
			dispatch(pushNotification(CREATE_ERROR, true));
			setTimeout(dispatch.bind(null, popNotification()), NOTIFICATION_DELAY);
			throw(error);
		});
	};
}

export function updateSubscription(orgId, id, type) {
	return dispatch => {
		console.log("Updating subscription...");
		return axios.put(SUBSCRIPTION_PATH, {
			orgId,
			id,
			type
		}).then(response => {
			console.log("Subscription updated.");
			dispatch(setSubscriptionType(id, type));
			dispatch(pushNotification(UPDATE_MSG, false));
			setTimeout(dispatch.bind(null, popNotification()), NOTIFICATION_DELAY);
		}).catch(error => {
			dispatch(pushNotification(UPDATE_ERROR, true));
			setTimeout(dispatch.bind(null, popNotification()), NOTIFICATION_DELAY);
			throw(error);
		});
	};
}

export function deleteSubscription(id) {
	return dispatch => {
		console.log("Deleting subscription...");
		return axios.delete(SUBSCRIPTION_PATH, {
			params: {
				json: {
					id
				}
			}
		}).then(response => {
			console.log("Subscription deleted.");
			dispatch(deleteLocalSubscription(id));
			dispatch(pushNotification(DELETE_MSG, false));
			setTimeout(dispatch.bind(null, popNotification()), NOTIFICATION_DELAY);
		}).catch(error => {
			dispatch(pushNotification(DELETE_ERROR, true));
			setTimeout(dispatch.bind(null, popNotification()), NOTIFICATION_DELAY);
			throw(error);
		});
	}
}