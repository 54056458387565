import axios from "core/axios-sp";

function listRequest(endpoint, limit, offset)
{
  return axios.get(endpoint, {
    params: {
      json: {
        limit,
        offset
      }
    }
  });
}

const LIST_LICENSES = "/app/crm/v2/model/license/list";
export function listLicenses(limit = 2000, offset = 0)
{
  return listRequest(LIST_LICENSES, limit, offset);
}
const LICENSE = "/app/crm/v2/model/license";
export function createLicense(name, description, status, visibility)
{
  return axios.put(LICENSE, {
    name,
    description,
    status,
    visibility
  });
}
export function updateLicense(id, name, description, status, visibility)
{
  return axios.post(LICENSE, {
    id,
    name,
    description,
    status,
    visibility
  });
}

const LICENSE_PRICES = "/app/crm/v2/model/license/price";
export function addLicensePrices(licenseId, prices)
{
  return axios.put(LICENSE_PRICES, {
    id: licenseId,
    prices
  });
}
export function updateLicensePrices(licenseId, prices)
{
  return axios.post(LICENSE_PRICES, {
    id: licenseId,
    prices
  });
}
export function removeLicensePrices(licenseId, priceIds)
{
  return axios.delete(LICENSE_PRICES, {
    data: {
      id: licenseId,
      priceIds
    }
  });
}

const UPDATE_LICENSE_SUPPORT = "/app/crm/v2/model/license/support";
export function updateSupportDetails(licenseId, support)
{
  return axios.post(UPDATE_LICENSE_SUPPORT, {
    id: licenseId,
    chat: support.chat,
    email: support.email,
    phone: support.phone
  });
}

const LICENSE_LIMITS = "/app/crm/v2/model/license/limit";
export function addLicenseLimits(licenseId, limits)
{
  return axios.put(LICENSE_LIMITS, {
    id: licenseId,
    limits
  });
}
export function updateLicenseLimits(licenseId, limits)
{
  return axios.post(LICENSE_LIMITS, {
    id: licenseId,
    limits
  });
}
export function removeLicenseLimits(licenseId, limitIds)
{
  return axios.delete(LICENSE_LIMITS, {
    data: {
      id: licenseId,
      limitIds
    }
  });
}

const LICENSE_QUOTAS = "/app/crm/v2/model/license/quota";
export function addLicenseQuotas(licenseId, quotas)
{
  return axios.put(LICENSE_QUOTAS, {
    id: licenseId,
    quotas
  });
}
export function updateLicenseQuotas(licenseId, quotas)
{
  return axios.post(LICENSE_QUOTAS, {
    id: licenseId,
    quotas
  });
}
export function removeLicenseQuotas(licenseId, quotaIds)
{
  return axios.delete(LICENSE_QUOTAS, {
    data: {
      id: licenseId,
      quotaIds
    }
  })
}

const QUOTA_PRICES = "/app/crm/v2/model/license/quota/price";
export function addQuotaPrices(licenseId, quotaId, prices)
{
  return axios.put(QUOTA_PRICES, {
    id: licenseId,
    quotaId,
    prices
  });
}
export function updateQuotaPrices(licenseId, quotaId, prices)
{
  return axios.post(QUOTA_PRICES, {
    id: licenseId,
    quotaId,
    prices
  });
}
export function removeQuotaPrices(licenseId, quotaId, priceIds)
{
  return axios.delete(QUOTA_PRICES, {
    data: {
      id: licenseId,
      quotaId,
      priceIds
    }
  });
}

const LICENSE_PACKAGE_SETS = "/app/crm/v2/model/license/packageset";
export function addLicensePackageSets(licenseId, packageSetIds)
{
  return axios.put(LICENSE_PACKAGE_SETS, {
    id: licenseId,
    packageSetIds
  });
}
export function removeLicensePackageSets(licenseId, packageSetIds)
{
  return axios.delete(LICENSE_PACKAGE_SETS, {
    data: {
      id: licenseId,
      packageSetIds
    }
  })
}


const LIST_PACKAGE_SETS = "/app/crm/v2/model/packageset/list";
export function listPackageSets(limit = 2000, offset = 0)
{
  return listRequest(LIST_PACKAGE_SETS, limit, offset);
}

const PACKAGE_SET = "/app/crm/v2/model/packageset";

export function createPackageSet(name, description, status)
{
  return axios.put(PACKAGE_SET, {
    name,
    description,
    status
  });
}

export function updatePackageSet(packageSetId, name, description, status)
{
  return axios.post(PACKAGE_SET, {
    id: packageSetId,
    name,
    description,
    status
  });
}

const PACKAGE_SET_PRICES = "/app/crm/v2/model/packageset/price";
export function addPackageSetPrices(packageSetId, prices)
{
  return axios.put(PACKAGE_SET_PRICES, {
    id: packageSetId,
    prices
  });
}
export function updatePackageSetPrices(packageSetId, prices)
{
  return axios.post(PACKAGE_SET_PRICES, {
    id: packageSetId,
    prices
  });
}
export function removePackageSetPrices(packageSetId, priceIds)
{
  return axios.delete(PACKAGE_SET_PRICES, {
    data: {
      id: packageSetId,
      priceIds
    }
  })
}

const PACKAGE_SET_PACKAGES = "/app/crm/v2/model/packageset/package";
export function addPackageSetPackages(packageSetId, packageIds)
{
  return axios.put(PACKAGE_SET_PACKAGES, {
    id: packageSetId,
    packageIds
  });
}
export function removePackageSetPackages(packageSetId, packageIds)
{
  return axios.delete(PACKAGE_SET_PACKAGES, {
    data: {
      id: packageSetId,
      packageIds
    }
  });
}

const LIST_PACKAGES = "/app/crm/v2/model/package/list";
export function listPackages(limit = 2000, offset = 0)
{
  return listRequest(LIST_PACKAGES, limit, offset);
}

const PACKAGE = "/app/crm/v2/model/package";
export function createPackage(name, description, status)
{
  return axios.put(PACKAGE, {
    name,
    description,
    status
  });
}
export function updatePackage(id, name, description, status)
{
  return axios.post(PACKAGE, {
    id,
    name,
    description,
    status
  });
}

const PACKAGE_PRICES = "/app/crm/v2/model/package/price";
export function addPackagePrices(packageId, prices)
{
  return axios.put(PACKAGE_PRICES, {
    id: packageId,
    prices
  });
}
export function updatePackagePrices(packageId, prices)
{
  return axios.post(PACKAGE_PRICES, {
    id: packageId,
    prices
  });
}
export function removePackagePrices(packageId, priceIds)
{
  return axios.delete(PACKAGE_PRICES, {
    data: {
      id: packageId,
      priceIds
    }
  });
}

const PACKAGE_FEATURES = "/app/crm/v2/model/package/feature";
export function addPackageFeatures(packageId, features)
{
  return axios.put(PACKAGE_FEATURES, {
    id: packageId,
    features
  });
}
export function removePackageFeatures(packageId, features)
{
  return axios.delete(PACKAGE_FEATURES, {
    data: {
      id: packageId,
      features
    }
  });
}

const PACKAGE_ASSETS = "/app/crm/v2/model/package/asset";
export function addPackageAssets(packageId, assetIds)
{
  return axios.put(PACKAGE_ASSETS, {
    id: packageId,
    assetIds
  });
}
export function removePackageAssets(packageId, assetIds)
{
  return axios.delete(PACKAGE_ASSETS, {
    data: {
      id: packageId,
      assetIds
    }
  })
}

const LIST_ASSETS = "/app/crm/v2/model/asset/list";
export function listAssets(limit = 2000, offset = 0)
{
  return axios.get(LIST_ASSETS, {
    params: {
      json: {
        limit,
        offset
      }
    }
  });
}

const ASSET = "/app/crm/v2/model/asset/survey/template";
export function createAsset(name, description, surveyId, language)
{
  return axios.put(ASSET, {
    name,
    description,
    language,
    surveyId
  });
}
export function updateAsset(assetId, name, description, surveyId, language)
{
  return axios.post(ASSET, {
    id: assetId,
    name,
    description,
    language,
    surveyId
  });
}