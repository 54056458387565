export default function splitLinkedText(text)
{
  const linkEnd = text.indexOf("}");
  const linkStart = text.indexOf("{");

  const beforeLink = text.slice(0, linkStart);
  const link = text.slice(linkStart + 1, linkEnd);
  const afterLink = text.slice(linkEnd + 1);

  return [beforeLink, link, afterLink];
}
