import axios from "axios";
import {
	toggleFolderDeletionPopup,
	fetchFolders,
	setFolders,
	fetchSurveys,
	setSurveys
} from "../../../surveysActions";
import {doArraysOverlap} from "utils/doArraysOverlap";
import {getPath} from "../../../utils/getPath";
import {
	createSurveyMovePromise,
	createSurveyRemovePromise
} from "./moveToFolder";

const finishDeletion = () => {
	Surveypal.getLoader().hide();
	Surveypal.store.dispatch(toggleFolderDeletionPopup(false));

	const lastRefresh = new Date();
	Surveypal.store.dispatch(setSurveys({
		fetching: true,
		fetched: false,
		surveys: [],
		lastRefresh
	}));
	Surveypal.store.dispatch(setFolders({
		fetching: true,
		fetched: false,
		folders: [],
		lastRefresh
	}));
	fetchSurveys();
	fetchFolders();
};

/**
 * createSurveyMovePromises first categorizes surveys by their immediate parent folders (the reduce function) and then
 * creates promises for removing the surveys within each folder (map function).
 *
 * @param surveyIds {number[]}
 * @returns {Promise<AxiosResponse<any>>[]}
 */
export const createSurveyRemovePromises = (surveyIds) => {
	const state = Surveypal.store.getState().surveys;
	const folders = state.fetchables.folders.folders;

	if (surveyIds.length === 0) return [];

	const surveysByFolder = surveyIds.reduce((carry, surveyId) => {
		const parentFolder = folders.find(folder => folder.surveys.indexOf(surveyId) !== -1);

		if (parentFolder.id in carry)
		{
			return {
				...carry,
				[parentFolder.id]: [...carry[parentFolder.id], surveyId]
			};
		}

		return {
			...carry,
			[parentFolder.id]: [surveyId]
		};
	}, {});

	return Object.keys(surveysByFolder).map(folderId => {
		return createSurveyRemovePromise(surveysByFolder[folderId], +folderId)[0];
	});
};

/**
 * createFolderDeletionPromise creates a promise for requesting the deletion of a specified folder.
 *
 * @param folderId {number}
 * @returns {Promise}
 */
export const createFolderDeletionPromise = (folderId) => {
	return axios.delete("/app/home/folder", {
		params: {
			json: {
				id: folderId
			}
		}
	});
};

/**
 * runDeletion executes the deletion of folders and their subfolders.
 *
 * Surveys inside any of these will be moved to the user's current folder.
 *
 * @param folderIds {number[]}
 */
export const runDeletion = (folderIds) => {
	Surveypal.getLoader().show();

	const state = Surveypal.store.getState().surveys;
	const folders = state.fetchables.folders.folders;
	const currentFolder = state.list.folder;

	const foldersInFolder = folders.filter(folder => doArraysOverlap(getPath(folder.id), folderIds));
	const surveysInFolder = foldersInFolder.reduce((carry, folder) => [...carry, ...folder.surveys], [])

	const surveyRemovePromises = createSurveyRemovePromises(surveysInFolder);
	const surveyMovePromise = createSurveyMovePromise(surveysInFolder, currentFolder);

	const folderDeletionPromises = foldersInFolder.map(folder => createFolderDeletionPromise(folder.id));
	const combinedPromises = [...surveyRemovePromises, ...surveyMovePromise, ...folderDeletionPromises];

	axios.all(combinedPromises).then(axios.spread((...responses) => {
		finishDeletion();
	})).catch(axios.spread((...responses) => {
		finishDeletion();
	}));

	Surveypal.store.dispatch(toggleFolderDeletionPopup(false));
};

/**
 * deleteFolders toggles the popup for confirming the deletion of folders.
 */
export const deleteFolder = () => {
	Surveypal.store.dispatch(toggleFolderDeletionPopup(true));
};