import React, {useEffect, useMemo} from "react";
import {connect} from "react-redux";
import {StepDivision} from "ui/Wizard/Wizard";
import Checkbox from "ui/Checkbox/Checkbox";
import RadioButton from "ui/RadioButton/RadioButton";
import Dropdown from "ui/Dropdown/Dropdown";
import LoadingIndicator from "ui/LoadingIndicator/LoadingIndicator";
import {SelectionList, SelectableItem} from "ui/SelectionList/SelectionList";
import {
	setName,
	setAllResponses,
	setSelectedFilters,
	setSelectedMergedData,
	setReportFormat,
	setPptxTheme,
	setExcel97to2003,
	setExcelUniqueIds,
	setCsvUniqueIds,
	setCsvCharset,
	setSpssUniqueIds,
	setSpssStringVariableSize,
	setSpssStaticStringVariableSize,
	setScheduleStepAvailable
} from "../../automaticReportingActions";
import "./FormatAndContent.scss";
import "../../automaticReportingTypes";

/**
 * <TypeAndFilters/> is the type and filters step of the automatic report creation wizard.
 *
 * @param filters {Filters}
 * @param pptxThemes {PptxThemes}
 * @param defaultPptxTheme {DefaultPptxTheme}
 * @param mergedData {MergedData}
 * @param existingReportNames {string[]}
 * @param settings {FormatAndContentSettings}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const FormatAndContent = ({
	filters,
	pptxThemes,
	defaultPptxTheme,
	mergedData,
	existingReportNames,
	settings,
	dispatch
}) => {
	const reportAlreadyExists = existingReportNames
		.some((name) => name.trim() === settings.reportName.trim());

	const reportFormatOptions = [[
		{
			text: translator.get("exports.excel"),
			value: "excel"
		},
		{
			text: translator.get("exports.powerpoint"),
			value: "pptx"
		},
		{
			text: translator.get("exports.word"),
			value: "word"
		},
		{
			text: translator.get("exports.csv"),
			value: "csv"
		},
		{
			text: translator.get("exports.spss"),
			value: "spss"
		},
		{
			text: translator.get("exports.xml"),
			value: "xml"
		}
	]];

	//Mark next step as available when everything is OK.
	const isNextStepAvailable =
		!filters.loading &&
		!pptxThemes.loading &&
		!defaultPptxTheme.loading &&
		!mergedData.loading &&
		settings.reportName.trim() !== "" &&
		!reportAlreadyExists &&
		settings.reportFormat !== undefined &&
		settings.reportFormat !== null &&
		(settings.allResponses || settings.filters.length > 0) &&
		settings.mergedData.length > 0;

	useEffect(() => {
		dispatch(setScheduleStepAvailable(isNextStepAvailable));
	}, [isNextStepAvailable])

	//Prepare powerpoint theme options
	const defaultThemeOption = useMemo(() => {
		if (defaultPptxTheme.loading) return [null];

		if (defaultPptxTheme.defaultPptxTheme === null) return [null];

		return [{
			text: `${defaultPptxTheme.defaultPptxTheme.name} (${translator.get("exports.defaultStyle")})`,
			value: defaultPptxTheme.defaultPptxTheme.id
		}];
	}, [defaultPptxTheme.loading]);

	const standardThemeOptions = [
		{text: translator.get("exports.whiteTheme"), value: "white"},
		{text: translator.get("exports.blueTheme"), value: "blue"}
	];

	const customThemeOptions = useMemo(() => {
		if (pptxThemes.loading) return [null];

		return pptxThemes.pptxThemes.map((theme) => ({
			text: theme.name,
			value: theme.id
		}));
	}, [pptxThemes.loading])

	//Create grouped theme dropdown options and remove empty groups.
	const themeOptions = [defaultThemeOption, standardThemeOptions, customThemeOptions]
		.filter((optionGroup) => optionGroup.every(option => option !== null) && optionGroup.length > 0);

	//When all themes have been loaded, select the default theme.
	//If there is no default theme, select the first stanard theme.
	useEffect(() => {
		if (!defaultPptxTheme.loading && !pptxThemes.loading)
		{
			if (defaultPptxTheme.defaultPptxTheme !== null)
			{
				dispatch(setPptxTheme(defaultPptxTheme.defaultPptxTheme.id));
				return;
			}

			dispatch(setPptxTheme(standardThemeOptions[0].value))
		}
	}, [defaultPptxTheme.loading, pptxThemes.loading]);

	//Prepare CSV charset options
	const csvCharsetOptions = [
		{text: translator.get("automaticReporting.charsetUtf8"), value: "UTF-8"},
		{text: translator.get("automaticReporting.charsetIso"), value: "ISO-8859-1"}
	];

	//Prepare SPSS string variable size options
	const spssOptions = [
		{text: translator.get("automaticReporting.spssDynamicVar"), value: "dynamic"},
		{text: translator.get("automaticReporting.spssVarSize"), value: "static"}
	];

	//Filter selection utils
	const allFiltersSelected = settings.filters.length === filters.filters.length && settings.allResponses;

	const isFilterSelected = (queriedFilterId) => settings.filters
		.some(selectedFilter => selectedFilter === queriedFilterId);

	const toggleFilter = (toggledFilterId) => {
		if (isFilterSelected(toggledFilterId))
		{
			dispatch(setSelectedFilters(settings.filters.filter(selectedFilterId => selectedFilterId !== toggledFilterId)));
			return;
		}
		dispatch(setSelectedFilters([...settings.filters, toggledFilterId]));
	};

	const selectAllFilters = () => {
		if (allFiltersSelected)
		{
			dispatch(setAllResponses(false));
			dispatch(setSelectedFilters([]));
			return;
		}

		dispatch(setAllResponses(true));
		dispatch(setSelectedFilters(filters.filters.map((filter) => filter.id)));
	};

	//Merged data selection utils
	const allMergedDataSelected = settings.mergedData.length === mergedData.mergedData.length;

	const isMergedDataSelected = (queriedDataId) => settings.mergedData
		.some(selectedDataId => selectedDataId === queriedDataId);

	const toggleMergedData = (toggledDataId) => {
		if (isMergedDataSelected(toggledDataId))
		{
			dispatch(setSelectedMergedData(settings.mergedData.filter(selectedDataId => selectedDataId !== toggledDataId)));
			return;
		}

		dispatch(setSelectedMergedData([...settings.mergedData, toggledDataId]))
	}

	const selectAllMergedData = () => {
		if (allMergedDataSelected)
		{
			dispatch(setSelectedMergedData([]));
			return;
		}

		dispatch(setSelectedMergedData(mergedData.mergedData.map((datum) => datum.id)));
	};

	return (
		<div className={"formatAndContentStep"}>
			<StepDivision
				className={"nameAndFormat"}
				title={translator.get("automaticReporting.defineNameAndFormat")}>
				<div className={"name"}>
					<label htmlFor={"reportName"}>{translator.get("automaticReporting.name")}</label>
					<input
						type={"text"}
						id={"reportName"}
						className={"text"}
						value={settings.reportName}
						onChange={(e) => dispatch(setName(e.target.value))}
					/>
				</div>
				<div className={"format"}>
					<label htmlFor={"reportFormat"}>{translator.get("automaticReporting.reportFormat")}</label>
					<Dropdown
						id={"reportFormat"}
						items={reportFormatOptions}
						selectedValue={settings.reportFormat}
						onChange={(newValue) => dispatch(setReportFormat(newValue))}
					/>
				</div>
				<div className={"formatSettings"}>
					{
						settings.reportFormat === "pptx" &&
						<div>
							<label>{translator.get("automaticReporting.pptxSettings")}</label>
							{
								pptxThemes.loading || defaultPptxTheme.loading
								?
								<div>
									<LoadingIndicator
										loading={pptxThemes.loading}
										text={translator.get("automaticReporting.fetchingPptxThemes")}
									/>
									<LoadingIndicator
										loading={defaultPptxTheme.loading}
										text={translator.get("automaticReporting.fetchingDefaultPptxTheme")}
									/>
								</div>
								:
								<Dropdown
									items={themeOptions}
									selectedValue={settings.pptx.theme}
									onChange={(newValue) => dispatch(setPptxTheme(newValue))}
								/>
							}
						</div>
					}
					{
						settings.reportFormat === "excel" &&
						<div>
							<label>{translator.get("automaticReporting.excelSettings")}</label>
							<Checkbox
								checked={settings.excel.excel97to2003}
								label={translator.get("exports.excel97to2003")}
								onSelect={() => dispatch(setExcel97to2003(!settings.excel.excel97to2003))}
							/>
							<Checkbox
								checked={settings.excel.useUniqueIds}
								label={translator.get("exports.uniqueid")}
								onSelect={() => dispatch(setExcelUniqueIds(!settings.excel.useUniqueIds))}
							/>
						</div>
					}
					{
						settings.reportFormat === "csv" &&
						<div>
							<label>{translator.get("automaticReporting.csvSettings")}</label>
							<Checkbox
								checked={settings.csv.useUniqueIds}
								label={translator.get("exports.uniqueid")}
								onSelect={() => dispatch(setCsvUniqueIds(!settings.csv.useUniqueIds))}
							/>
							<label>{translator.get("automaticReporting.charset")}</label>
							<RadioButton
								options={csvCharsetOptions}
								selected={settings.csv.charset}
								onChange={(newValue) => dispatch(setCsvCharset(newValue))}
							/>
						</div>
					}
					{
						settings.reportFormat === "spss" &&
						<div>
							<label>{translator.get("automaticReporting.spssSettings")}</label>
							<Checkbox
								checked={settings.spss.useUniqueIds}
								label={translator.get("exports.uniqueid")}
								onSelect={() => dispatch(setSpssUniqueIds(!settings.spss.useUniqueIds))}
							/>
							<RadioButton
								options={spssOptions}
								selected={settings.spss.stringVariableSize}
								onChange={(newValue) => dispatch(setSpssStringVariableSize(newValue))}
							/>
							<input
								type={"number"}
								className={"text"}
								onChange={(e) => dispatch(setSpssStaticStringVariableSize(e.target.value))}
								disabled={settings.spss.stringVariableSize !== "static"}
								value={settings.spss.staticStringVariableSize}
							/>
						</div>
					}
				</div>
			</StepDivision>
			<StepDivision
				className={"selectContent"}
				title={translator.get("automaticReporting.selectContent")}>
				<div className={"filters"}>
					<label htmlFor={"filtersSelection"}>
						{translator.get("automaticReporting.filters")}
					</label>
					<SelectionList
						className={"filtersSelection"}
						id={"filtersSelection"}>
						<SelectableItem
							className={"selectAll"}
							text={translator.get("filterLibrary.selectAll")}
							selected={false}
							onSelect={() => selectAllFilters()}
							checkable={true}
							checked={allFiltersSelected}
							onCheck={() => selectAllFilters()}
						/>
						<SelectableItem
							className={"allResponses"}
							text={translator.get("filterLibrary.all")}
							selected={false}
							onSelect={() => dispatch(setAllResponses(!settings.allResponses))}
							checkable={true}
							checked={settings.allResponses}
							onCheck={() => dispatch(setAllResponses(!settings.allResponses))}
						/>
						{
							filters.loading
							?
							<LoadingIndicator
								loading={filters.loading}
								text={translator.get("automaticReporting.fetchingFilters")}
							/>
							:
							filters.filters.map((filter) => (
								<SelectableItem
									key={`filter-${filter.id}`}
									className={"filterItem"}
									text={filter.name}
									selected={false}
									onSelect={() => toggleFilter(filter.id)}
									checkable={true}
									checked={isFilterSelected(filter.id)}
									onCheck={() => toggleFilter(filter.id)}
								/>
							))
						}
					</SelectionList>
				</div>
				<div className={"mergedData"}>
					<label htmlFor={"mergedDataSelection"}>
						{translator.get("automaticReporting.mergedData")}
					</label>
					<SelectionList
						className={"mergedDataSelection"}
						id={"mergedDataSelection"}>
						<SelectableItem
							className={"selectAll"}
							text={translator.get("filterLibrary.selectAll")}
							selected={false}
							onSelect={() => selectAllMergedData()}
							checkable={true}
							checked={allMergedDataSelected}
							onCheck={() => selectAllMergedData()}
						/>
						{
							mergedData.loading
							?
							<LoadingIndicator
								loading={mergedData.loading}
								text={translator.get("automaticReporting.fetchingMergedData")}
							/>
							:
							mergedData.mergedData.map((mergedDatum) => (
								<SelectableItem
									key={`filter-${mergedDatum.id}`}
									className={"filterItem"}
									text={mergedDatum.name}
									selected={false}
									onSelect={() => toggleMergedData(mergedDatum.id)}
									checkable={true}
									checked={isMergedDataSelected(mergedDatum.id)}
									onCheck={() => toggleMergedData(mergedDatum.id)}
								/>
							))
						}
					</SelectionList>
				</div>
			</StepDivision>
		</div>
	);
};

export default connect(state => ({
	filters: state.automaticReporting.createNewReport.fetchables.filters,
	pptxThemes: state.automaticReporting.createNewReport.fetchables.pptxThemes,
	defaultPptxTheme: state.automaticReporting.createNewReport.fetchables.defaultPptxTheme,
	mergedData: state.automaticReporting.createNewReport.fetchables.mergedData,
	existingReportNames: state.automaticReporting.existingReports.fetchables.existingReports.existingReports.map((report) => report.name),
	settings: state.automaticReporting.createNewReport.formatAndContent
}))(FormatAndContent);