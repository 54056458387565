import React, {useEffect} from "react";
import {connect} from "react-redux";
import {isFeatureEnabled} from "utils/organizationFeatures";
import {
	sendNewReport, setDestinationType, setFtpProtocol,
	setWizardStep
} from "../automaticReportingActions";
import {Step, Wizard, Footer} from "ui/Wizard/Wizard";
import ReportCreationSuccess from "./ReportCreationSuccess/ReportCreationSuccess";
import ReportCreationError from "./ReportCreationError/ReportCreationError";
import ButtonR from "ui/ButtonR";
import FormatAndContent from "./FormatAndContent/FormatAndContent";
import Schedule from "./Schedule/Schedule";
import Destination from "./Destination/Destination";
import "../automaticReportingTypes";
import "./CreateNewReport.scss";

/**
 * @callback onClose
 */
/**
 * <CreateNewReport/> renders a report creation wizard embedded into a popup.
 *
 * @param wizardStep {number}
 * @param onClose {onClose}
 * @param formatAndContent {FormatAndContentSettings}
 * @param schedule {Schedule}
 * @param destination {Destination}
 * @param finishAvailable {boolean}
 * @param reportCreatedSuccessfully {boolean}
 * @param reportCreationError {Error}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const CreateNewReport = ({
	wizardStep,
	onClose,
	formatAndContent,
	schedule,
	destination,
	finishAvailable,
	reportCreatedSuccessfully,
	reportCreationError,
	dispatch
}) => {
	//Select initial sharing method based on which organization enabled features.
	useEffect(() => {
		if (!isFeatureEnabled("SCHEDULED_EXPORTS") && isFeatureEnabled("SCHEDULED_EXPORTS_SFTP"))
		{
			dispatch(setDestinationType("SFTP"));
			dispatch(setFtpProtocol("SFTP"));
		}
	}, [])

	if (reportCreatedSuccessfully) return <ReportCreationSuccess/>;
	if (reportCreationError !== null) return <ReportCreationError/>;

	return (
		<Wizard
			className={"createNewReport"}
			embedded={true}
			finishAvailable={finishAvailable}
			step={wizardStep}
			onStepChange={(newStep) => dispatch(setWizardStep(newStep))}
			onClose={onClose}
			onFinish={() => sendNewReport(formatAndContent, schedule, destination, dispatch)}>
			<Step
				className={"create"}
				title={translator.get("automaticReporting.createNew")}
				available={true}>
				<FormatAndContent/>
			</Step>
			<Step
				className={"schedule"}
				title={translator.get("automaticReporting.schedule")}
				available={schedule.stepAvailable}>
				<Schedule/>
			</Step>
			<Step
				className={"destination"}
				title={translator.get("automaticReporting.destination")}
				available={destination.stepAvailable}>
				<Destination/>
			</Step>
			<Footer>
				<ButtonR
					isDefault={false}
					callback={() => onClose()}
					text={translator.get("common.close")}
				/>
			</Footer>
		</Wizard>
	);
};

export default connect(state => ({
	wizardStep: state.automaticReporting.createNewReport.wizardStep,
	formatAndContent: state.automaticReporting.createNewReport.formatAndContent,
	schedule: state.automaticReporting.createNewReport.schedule,
	destination: state.automaticReporting.createNewReport.destination,
	reportCreatedSuccessfully: state.automaticReporting.createNewReport.reportCreatedSuccessfully,
	reportCreationError: state.automaticReporting.createNewReport.reportCreationError,
	finishAvailable: state.automaticReporting.createNewReport.finishAvailable
}))(CreateNewReport);