import isNil from "lodash.isnil";
import { getMetaCount } from "./api";

// Class that takes care of fetching counts for meta keywords
export default class MetaCountFetcher {
  constructor()
  {
    this.cache = {};
  }

  clearCache()
  {
    this.cache = {};
  }

  fetch(surveyId, keyword)
  {
    const cacheKey = surveyId + "_" + keyword;
    if (!isNil(this.cache[cacheKey]))
    {
      return Promise.resolve(this.cache[cacheKey]);
    }
    else
    {
      return getMetaCount(surveyId, keyword).then(res => {
        const count = res.data.count;
        this.cache[cacheKey] = count;
        return count;
      });
    }
  }
};