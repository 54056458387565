define("ui/element", function(require)
{
	const Class = require("core/class");
	const PAGE_TYPE = require("conf/ai-events").PAGE_TYPE;

	return Class.extend(
	{
		init: function(template)
		{
			// Safety checks
			if(!template || template === "")
			{
				console.warn("No template given!");
			}

			// Create dom element
			var dom = null;
			if(typeof template == "string")
			{
				dom = jQuery(template);
			}
			else
			{
				dom = template;
			}

			// Getter for DOM
			this.find = function(selector)
			{
				if(selector)
				{
					return dom.find(selector);
				}
				return dom;
			};

			// Translate the template, but only when the translation engine is available
			if(typeof Surveypal !== "undefined" && Surveypal.getTranslator)
			{
				Surveypal.getTranslator().translate(dom);
			}

			if (Surveypal.applicationInsights && this._aiViewName)
			{
				Surveypal.applicationInsights.trackPageView({
					name: this._aiViewName,
					pageType: this._aiPageType ? this._aiPageType : PAGE_TYPE.ELEMENT
				});
			}
		},

		// Will append the element to target
		append: function(target)
		{
			this.find().appendTo(target);
		},

		// Will prepend the element to target
		prepend: function(target)
		{
			this.find().prependTo(target);
		},

		attach: function(target, position)
		{
			switch(position)
			{
			case "before":
				this.find().insertBefore(target);
				break;
			case "after":
				this.find().insertAfter(target);
				break;
			case "top":
				this.find().prependTo(target);
				break;
			case "bottom":
			default:
				this.find().appendTo(target);
				break;
			}
		},

		detach: function()
		{
			this.find().detach();
		},

		deinit: function()
		{
			this.detach();
		},

		// Should be overwritten. Implementing methods should delegate fit() to children.
		fit: function()
		{
			// Update element dom a bit force repaint (affects IE most)
			try
			{
				this.find().css("zoom", 1);
				// document.body.style.zoom = 1;
			}
			catch(ex)
			{
				//Output error instead of throwing error, because this routine has been written so that it relies on
				//not crashing on an error.
				console.log("!!! The following error is nondisruptive but indicative of something wrong.");
				console.error(ex);
				console.log("!!! Non-disruptive error ends here.");
			}
		},

		cmdKeyEvent: function(e)
		{

		}
	});
});