define("utils/local-storage", function(require)
{
	var Storage = require("utils/storage");
	return Storage.extend(
		{
			init: function()
			{
				this.supreme(localStorage);
			}
		});
});