define("conf/base-datamap", function()
{
	return function(surveyName, analysisThreshold) {
		return {
			name: surveyName,
			id: "base",
			analysisThreshold: {
				surveyId: "base",
				surveyName: surveyName,
				threshold: analysisThreshold
			}
		};
	};
});