//Under the hood
import clone from "lodash.clonedeep";

//Common things
import {
	DISPLAYS,
	SEARCH_FOR_OPTIONS,
	SEARCH_MODES
} from "./common";
import {
	CHANGE_QUERY,
	CLEAR_PASSWORD_POLICY_INFORMATION,
	CLEAR_TEAM_INFORMATION,
	DELIVER_PASSWORD_POLICY_INFORMATION,
	DELIVER_TEAM_INFORMATION,
	REFRESH_GROUP,
	STORE_GROUP_DATA,
	SWITCH_DISPLAY,
	SWITCH_GROUP,
	TOGGLE_SIDE_BAR,
	UPDATE_SEARCH_RESULTS
} from "./organizationsActions";

export const defaultState = {
	//In group view:
	// - groupId is the group that is being viewed.
	// - organizationId is the organization initially opened
	// - userId is the user on which the user clicked on in the search results table. This mainly signals that the group
	//   view should open the organization user list initially.
	groupId: null,
	organizationId: null,
	userId: null,
	groupData: {},
	groupRenderTime: null,

	//query is what the user is currently typing into the search term box
	query: "",

	//searched tells OrganizationGroupsTable whether to display a "No Matches" if there are no matches, or not..
	searched: false,

	//filter is what the user finally searched for; this is used to filter out users in user listing in the groups table.
	filter: "",

	//teams is the team data fetched for "Add new users", "Edit teams" and "Edit user" panels
	teamData: [],
	teamInformationReady: false,

	//passwordPolicy is the organization password policy data fetched for "Add new users" and "Edit user" panels.
	passwordPolicy: {},
	passwordPolicyInformationReady: false,

	sidebarHidden: false,
	searchMode: SEARCH_MODES.BASIC,
	searchFor: SEARCH_FOR_OPTIONS.NONE,
	searchResults: [],
	display: DISPLAYS.TABLE
};

export default function reducer(state = defaultState, action)
{
	const nextState = clone(state);

	switch(action.type)
	{
		case CHANGE_QUERY:
			nextState[action.item] = action.newValue;
			return nextState;
		case CLEAR_PASSWORD_POLICY_INFORMATION:
			nextState.passwordPolicy = defaultState.passwordPolicy;
			nextState.passwordPolicyInformationReady = defaultState.passwordPolicyInformationReady;
			return nextState;
		case CLEAR_TEAM_INFORMATION:
			nextState.teamData = defaultState.teamData;
			nextState.teamInformationReady = defaultState.teamInformationReady;
			return nextState;
		case DELIVER_PASSWORD_POLICY_INFORMATION:
			nextState.passwordPolicy = action.passwordPolicy;
			nextState.passwordPolicyInformationReady = action.passwordPolicyInformationReady;
			return nextState;
		case DELIVER_TEAM_INFORMATION:
			nextState.teamData = action.teamData;
			nextState.teamInformationReady = action.teamInformationReady;
			return nextState;
		case REFRESH_GROUP:
			nextState.groupRenderTime = action.groupRenderTime;
			return nextState;
		case STORE_GROUP_DATA:
			nextState.groupData = action.groupData;
			return nextState;
		case SWITCH_DISPLAY:
			nextState.groupId = action.groupId;
			nextState.organizationId = action.organizationId;
			nextState.userId = action.userId;
			nextState.display = action.newDisplay;
			return nextState;
		case SWITCH_GROUP:
			nextState.groupId = action.groupId;
			nextState.organizationId = action.organizationId;
			nextState.userId = action.userId;
			return nextState;
		case TOGGLE_SIDE_BAR:
			nextState.sidebarHidden = !state.sidebarHidden;
			return nextState;
		case UPDATE_SEARCH_RESULTS:
			nextState.searchResults = action.searchResults;
			nextState.filter = action.filter;
			nextState.searched = true;
			return nextState;
		default:
			return nextState;
	}
};