import React from "react";
import {connect} from "react-redux";
import ContextMenu from "ui/ContextMenu/ContextMenu";
import {newFolder} from "../../Toolbar/functions/newFolder";
import {enterFolder} from "../../Toolbar/functions/enterFolder";
import {renameFolder} from "../../Toolbar/functions/renameFolder";
import {deleteFolder} from "../../Toolbar/functions/deleteFolder";

/**
 * @callback OnClose
 */
/**
 * ContextMenu appears when the user clicks on a survey in the survey listing using the second mouse
 * button. ContextMenu should contain the same items as the toolbar.
 *
 * Note: When modifying items in the context menu, remember to make the same modifications in the toolbar.
 *
 * @param onClose {OnClose}
 * @param style {object}
 * @param folders {object[]}
 * @param selectedFolderIds {number[]}
 * @returns {JSX.Element}
 */
const ContextMenuSurvey = ({
	onClose,
	style,
	folders,
	selectedFolderIds
}) => {
	const firstSelectedFolder = folders.find(folder => folder.id === selectedFolderIds[0]);
	const selectedFolders = folders.find(folder => selectedFolderIds.indexOf(folder.id) !== -1);

	const items = [
		[
			{
				text: translator.get("newFolder.newFolder"),
				disabled: selectedFolderIds.length === 0,
				onClick: newFolder
			}
		],
		[
			{
				text: translator.get("home.enterFolder"),
				disabled: selectedFolderIds.length === 0,
				onClick: () => enterFolder(firstSelectedFolder.id)

			},
			{
				text: translator.get("home.rename"),
				disabled: selectedFolderIds.length !== 1,
				onClick: () => renameFolder(firstSelectedFolder),
			}
		],
		[
			{
				text: translator.get("home.delete"),
				disabled: selectedFolderIds.length === 0,
				onClick: () => deleteFolder()
			}
		]
	];

	return (
		<ContextMenu
			items={items}
			onClose={onClose}
			style={style}
		/>
	);
};

export default connect(state => ({
	folders: state.surveys.fetchables.folders.folders,
	selectedFolderIds: state.surveys.list.selectedFolderIds
}))(ContextMenuSurvey);