import React from "react";
import PopupR from "ui/PopupR";
import PopupBasicContent from "ui/PopupBasicContent";

/**
 * @callback onReturn
 */
/**
 *
 * @param error {string}
 * @param onReturn {onReturn}
 * @constructor
 */
const ErrorPopup = ({
	error,
	onReturn
}) => {
	return (
		<PopupR
			cancelText={translator.get("common.return")}
			hideSubmit={true}
			cancelCallback={onReturn}
			title={translator.get(`register-error-popup.title_${error}`)}>
			<PopupBasicContent
				title={translator.get(`register-error-popup.title_${error}`)}
				text={translator.get(`register-error-popup.desc_${error}`)}
			/>
		</PopupR>
	)
};

export default ErrorPopup;