define("monitors/expire-monitor", function (require)
{
	var Class = require("core/class");
	var ExpiredPopup = require("views/login/expired");

	return Class.extend(
		{
			init: function ()
			{
				this.checkExpired();
			},

			checkExpired: function ()
			{
				if (Surveypal.getUser && Surveypal.getUser() && Surveypal.getUser().hasExpired() && !this.hasNotification() && !Surveypal.getLoader().isVisible()) // View must exist
				{
					this.notification = new ExpiredPopup(this.unsetNotification.bind(this));
				}
				else
				{
					setTimeout(this.checkExpired.bind(this), 500);
				}
			},

			unsetNotification: function()
			{
				this.notification = null;
				setTimeout(this.checkExpired.bind(this), 1000);
			},

			hasNotification: function()
			{
				return this.notification;
			}
		});
});