import React, {useMemo} from "react";
import Dropdown from "ui/Dropdown/Dropdown";
import "./TimeSelection.scss";

/**
 * @typedef Time
 * @type object
 * @property hours {number}
 * @property minutes {number}
 */
/**
 * @callback onChangeHours
 * @param newHours {number}
 */
/**
 * @callback onChangeMinutes
 * @param newMinutes {number}
 */
/**
 * TimeSelection renders a block with time selection dropdowns.
 *
 * @param value {Time}
 * @param onChangeHours {onChangeHours}
 * @param onChangeMinutes {onChangeMinutes}
 * @returns {JSX.Element}
 */
const TimeSelection = ({
	value,
	onChangeHours,
	onChangeMinutes
}) => {
	const hoursOptions = useMemo(() => [Array(24).fill(0).map((_, index) => ({
		text: `${index}`,
		value: index
	}))], []);

	const minutesOptions = useMemo(() => [Array(60).fill(0).map((_, index) => ({
		text: `${index.toString().padStart(2, "0")}`,
		value: index
	}))], []);

	return (
		<div className={"timeSelection"}>
			<div className={"hoursPicker"}>
				<Dropdown
					items={hoursOptions}
					selectedValue={value.hours}
					onChange={(newValue) => onChangeHours(newValue)}
				/>
			</div>
			<div className={"minutesPicker"}>
				<Dropdown
					items={minutesOptions}
					selectedValue={value.minutes}
					onChange={(newValue) => onChangeMinutes(newValue)}
				/>
			</div>
		</div>
	);
};

export default TimeSelection;