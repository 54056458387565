define("notifications/version-notification", function(require)
{
	var NotificationBar = require("ui/notification-bar");
	var RoundButton = require("ui/round-button");

	return NotificationBar.extend(
		{
			init: function ()
			{
				this.supreme();

				var button = new RoundButton(translator.get("version-notification.button"), this.cmdRefresh.bind(this));
				button.makeDefault();
				button.attach(this.find());

				this.text(translator.get("version-notification.label"));
			},

			cmdRefresh: function ()
			{
				Surveypal.reload(true, true);
			}
		});
});