import React from "react";
import {AUTOMATIC_REPORTS_DESTINATIONS} from "../../AutomaticReporting";
import RemoveReport from "./RemoveReport/RemoveReport";
import Schedule from "./Schedule/Schedule";
import DateAndTime from "ui/DateAndTime/DateAndTime";
import "./ReportInfo.scss";
import {connect} from "react-redux";
import {removeReport} from "../../automaticReportingActions";
import "../../automaticReportingTypes";

/**
 * <ReportInfo/> renders report information.
 *
 * @param existingReports {ExistingReports}
 * @param reportId {undefined|number}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const ReportInfo = ({existingReports, reportId, dispatch}) => {
	const report = existingReports.existingReports.find((report) => report.id === reportId);

	const endDate = new Date(report.schedule.endDate.split("+")[0]);
	const nextDate = new Date(report.schedule.nextExecution.split("+")[0]);

	return (
		<div className={"reportInfo"}>
			<div className={"reportInfoHeader"}>
				<div className={"title font-title24"}>{report.name}</div>
				<div className={"removeButton"}>
					<RemoveReport
						reportName={report.name}
						onClick={() => removeReport(reportId, dispatch)}
					/>
				</div>
			</div>
			<table>
				<tbody>
					<tr>
						<th>{translator.get("automaticReporting.schedule")}</th>
						<td><Schedule expression={report.schedule.cronExpression}/></td>
					</tr>
					<tr>
						<th>{translator.get("automaticReporting.startDate")}</th>
						<td><DateAndTime date={report.schedule.date}/></td>
					</tr>
					<tr>
						<th>{translator.get("automaticReporting.endDate")}</th>
						<td><DateAndTime date={report.schedule.endDate}/></td>
					</tr>
				</tbody>
				<tbody>
					{
						nextDate < endDate &&
						<tr>
							<th>{translator.get("automaticReporting.nextExecution")}</th>
							<td><DateAndTime date={report.schedule.nextExecution}/></td>
						</tr>
					}
					<tr>
						<th>{translator.get("automaticReporting.previousExecution")}</th>
						<td><DateAndTime date={report.schedule.previousExecution}/></td>
					</tr>
				</tbody>
				{
					report.destinations[0]["@class"] === AUTOMATIC_REPORTS_DESTINATIONS.EMAIL
					?
					<tbody>
						<tr>
							<th>{translator.get("automaticReporting.sharedVia")}</th>
							<td>{translator.get("automaticReporting.emailDestination")}</td>
						</tr>
						<tr>
							<th>{translator.get("automaticReporting.emailTo")}</th>
							<td>
								{report.destinations.map(destination => destination.to).join(" ")}
							</td>
						</tr>
						<tr>
							<th>{translator.get("automaticReporting.emailSubject")}</th>
							<td>{report.destinations[0].subject}</td>
						</tr>
					</tbody>
					:
					<tbody>
						<tr>
							<th>{translator.get("automaticReporting.destinationType")}</th>
							<td>{translator.get("automaticReporting.ftpDestination")}</td>
						</tr>
						<tr>
							<th>{translator.get("automaticReporting.ftpHost")}</th>
							<td>{report.destinations[0].host}</td>
						</tr>
						<tr>
							<th>{translator.get("automaticReporting.ftpPort")}</th>
							<td>{report.destinations[0].port}</td>
						</tr>
						<tr>
							<th>{translator.get("automaticReporting.ftpProtocol")}</th>
							<td>{report.destinations[0].protocol}</td>
						</tr>
						<tr>
							<th>{translator.get("automaticReporting.ftpUsername")}</th>
							<td>{report.destinations[0].username}</td>
						</tr>
					</tbody>
				}
			</table>
		</div>
	);
};

export default connect(state => ({
	existingReports: state.automaticReporting.existingReports.fetchables.existingReports
}))(ReportInfo);