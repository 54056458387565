import ELEMENT_TYPE from "conf/element-types";
import UNITS from "./maxAgeUnits";
import isNil from "lodash.isnil";
import clone from "lodash.clonedeep";
import moment from "moment";
import {getMaxCount} from "./ruleActions";


export const ANONYMIZATION_TARGET = {
	META: "META",
	QUESTION: "QUESTION"
};

export function validateRuleParams(count, unit)
{
	switch (unit)
	{
		case UNITS.MINUTES:
		case UNITS.HOURS:
		case UNITS.YEARS:
		case UNITS.MONTHS:
		case UNITS.WEEKS:
		case UNITS.DAYS:
			return !isNaN(count) && count >= getMinCountForRule(unit);
		default:
			return false;
	}
}

export function validateRuleParamsMax(count, unit)
{
	switch(unit)
	{
		case UNITS.MINUTES:
		case UNITS.HOURS:
		case UNITS.YEARS:
		case UNITS.MONTHS:
		case UNITS.WEEKS:
		case UNITS.DAYS:
			return !isNaN(count) && count <= getMaxCountForRule(unit);
		default:
			return false;
	}
}

export function getMinCountForRule(unit)
{
	switch (unit)
	{
		case UNITS.YEARS:
		case UNITS.MONTHS:
		case UNITS.HOURS:
			return 1;
		case UNITS.WEEKS:
			return 2;
		case UNITS.DAYS:
			return 14;
		case UNITS.MINUTES:
			return 15;
		default:
			return 0;
	}
}

export function getMaxCountForRule(unit)
{
	switch(unit)
	{
		case UNITS.YEARS:
			return 10;
		case UNITS.MONTHS:
			return 120;
		case UNITS.HOURS:
			return 85440;
		case UNITS.WEEKS:
			return 520;
		case UNITS.DAYS:
			return 3560;
		case UNITS.MINUTES:
			return 5126400;
		default:
			return 0;
	}
}

export function collectTargetsForIds(targetIds, allTargets)
{
	if (isNil(targetIds) || isNil(allTargets))
	{
		return [];
	}
	else
	{
		return targetIds.reduce((collector, targetKey) =>
		{
			const targetObject = allTargets.find(target => target.value === targetKey);
			if (targetObject)
			{
				const targetObjectWithKey = clone(targetObject);
				// Attribute "key" is required when comparing current rules with existing rules
				targetObjectWithKey.key = targetObject.value;
				collector.push(targetObjectWithKey);
			}
			return collector;
		}, []);
	}
}

export function addRuleTimestamps(responses, rule, timestampKey)
{
	if (!isNil(rule) &&
	    !isNil(rule.maxAgeValue) &&
	    !isNaN(parseInt(rule.maxAgeValue)) &&
	    !isNil(rule.maxAgeUnit))
	{
		return clone(responses).map(response => {
			if (isNil(response.started) && response.visibleState !== translator.get("manage.stateBounced") &&
				response.visibleState !== translator.get("manage.stateNoAnswer"))
			{
				response[timestampKey] = null;
				return response;
			}
			else
			{
				let modifiedMoment = 0;

				if(response.visibleState === translator.get("manage.stateBounced"))
				{
					let currentDate = new Date();
					modifiedMoment = moment(currentDate);
				}
				else
				{
					if(!isNil(response.created))
					{
						modifiedMoment = moment(new Date(response.created));
					}
					else
					{
						modifiedMoment = moment(new Date(response.started));
					}
				}

				const safeMaxAgeValue = parseInt(rule.maxAgeValue);
				let resultDate;

				switch (rule.maxAgeUnit)
				{
					// Moment api https://momentjs.com/docs/#/manipulating/add/
					// moment.add(...) adds days, weeks, months or years
					// moment(...).valueOf returns unix millisecond timestamp
					case UNITS.MINUTES:
						resultDate = modifiedMoment.add(safeMaxAgeValue, "minutes").valueOf();
						break;
					case UNITS.HOURS:
						resultDate = modifiedMoment.add(safeMaxAgeValue, "hours").valueOf();
						break;
					case UNITS.WEEKS:
						resultDate = modifiedMoment.add(safeMaxAgeValue, "weeks").valueOf();
						break;
					case UNITS.MONTHS:
						resultDate = modifiedMoment.add(safeMaxAgeValue, "months").valueOf();
						break;
					case UNITS.YEARS:
						resultDate = modifiedMoment.add(safeMaxAgeValue, "years").valueOf();
						break;
					case UNITS.DAYS:
					default:
						resultDate = modifiedMoment.add(safeMaxAgeValue, "days").valueOf();
						break;
				}
				// The earliest possible date is startDateMs of the rule
				let initialRuleDate = rule.startDateMs > resultDate ?
				                         rule.startDateMs :
				                         resultDate;

				let today = new Date();
				today = today.getTime();
				// Date at the "Deletion" or "Anonymization" column can not be smaller than current date
				response[timestampKey] = initialRuleDate < today ? today : initialRuleDate;

				return response;
			}
		});
	}
	else
	{
		return clone(responses).map(res => {
			res[timestampKey] = null;
			return res;
		});
	}
}

// Return simple question texts, ids and page indices
export function getAnonymizableElements(elements, pages, order)
{
	const orderedPages = order.reduce((coll, pageId) => {
		if (pages[pageId])
		{
			coll.push(pages[pageId]);
		}
		return coll;
	}, []);
	return elements.reduce((collector, element) => {
		const texts = element.question ?
		              element.question :
		              element.text;
		const elementToAdd = {
			id: element.id,
			allTexts: texts,
			page: orderedPages.findIndex(page => Object.keys(page.elements).some(someElementId => someElementId === element.id)) + 1,
			questionType: element.type,
			type: ANONYMIZATION_TARGET.QUESTION
		};
		switch(element.type)
		{
			case ELEMENT_TYPE.OPEN:
				collector.push(elementToAdd);
				break;

			case ELEMENT_TYPE.SINGLE_SELECTION:
			case ELEMENT_TYPE.MULTI_SELECTION:
				const hasOpenOptions = element.options.some(option => option.open === true);
				if (hasOpenOptions)
				{
					collector.push(elementToAdd);
				}
				break;

			case ELEMENT_TYPE.TABLE:
			case ELEMENT_TYPE.OLD_TABLE:
				const hasOpenBlocks = element.blocks.some(block => block.type === "table");
				if (hasOpenBlocks)
				{
					collector.push(elementToAdd);
				}
				break;

			case ELEMENT_TYPE.NORMAL_UPLOAD:
			case ELEMENT_TYPE.IMAGE_UPLOAD:
			case ELEMENT_TYPE.VIDEO_UPLOAD:
			case ELEMENT_TYPE.AUDIO_UPLOAD:
				collector.push(elementToAdd);
				break;
		}
		return collector;
	}, []);
}

