import React from "react";

/**
 * PopupRichContent is the framework for popup content that needs React elements.
 *
 * @params children {JSX.Element[]}
 * @params className {string}
 * @params title {string}
 */
const PopupRichContent = ({
	children,
	className,
	title
}) => {
	return (
		<div className={`page generic ${className}`}>
			<div className={"sprite"}/>
			<div className={"title font-title24"}>{title}</div>
			<div className={"desc font-normal"}>{children}</div>
		</div>
	);
}

export default PopupRichContent;