define("common/failure-popup", function (require)
{
	var Popup = require("common/notification-popup");
	return Popup.extend(
		{
			init: function (text, callback, title) {
				this.supreme(title ? title : translator.get("failure-popup.title"), text, callback);
				this.find(".desc").html(text);
				this.find().addClass("failure");
			}
		});
});