import React from "react";
import Checkbox from "ui/Checkbox/Checkbox";
import "./SelectableItem.scss";

/**
 * @callback onSelect
 */
/**
 * @callback onCheck
 */
/**
 * <SelectableItem/> renders a selectable item inside <SelectionList/>
 *
 * @param className {string}
 * @param text {string}
 * @param selected {boolean}
 * @param onSelect {onSelect}
 * @param checkable {boolean}
 * @param checked {boolean}
 * @param onCheck {onCheck}
 * @param sticky {boolean}
 * @returns {JSX.Element}
 */
const SelectableItem = ({
	className,
	text,
	selected,
	onSelect,
	checkable,
	checked,
	onCheck,
	sticky
}) => {
	return (
		<div
			className={`SelectableItem ${className ? className : ""} ${selected ? "selected" : ""} ${sticky ? "sticky" : ""}`}>
			{
				checkable &&
				<Checkbox
					className={"SelectableItemCheckbox"}
					label={""}
					checked={checked}
					onSelect={onCheck}
				/>
			}
			<div
				className={"Clickable"}
				onClick={onSelect}>
				{text}
			</div>
		</div>
	);
};

export default SelectableItem;