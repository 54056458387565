import * as api from "./api";
import clone from "lodash.clonedeep";
import difference from "lodash.difference";
import differenceBy from "lodash.differenceby";
import isNil from "lodash.isnil";
import promiseChain from "utils/promiseChain";

export const ASSET_CREATED = "CRM.LICENSES.ASSET_CREATED";
export const ASSET_UPDATED = "CRM.LICENSES.ASSET_UPDATED";
export const ASSETS_LOADED = "CRM.LICENSES.ASSETS_LOADED";
export const LICENSES_LOADED = "CRM.LICENSES.LICENSES_LOADED";
export const LICENSE_CREATED = "CRM.LICENSES.LICENSE_CREATED";
export const LICENSE_UPDATED = "CRM.LICENSES.LICENSE_UPDATED";
export const PACKAGE_CREATED = "CRM.LICENSES.PACKAGE_CREATED";
export const PACKAGE_UPDATED = "CRM.LICENSES.PACKAGE_UPDATED";
export const PACKAGE_SET_CREATED = "CRM.LICENSES.PACKAGE_SET_CREATED";
export const PACKAGE_SET_UPDATED = "CRM.LICENSE.PACKAGE_SET_UPDATED";
export const PACKAGE_SETS_LOADED = "CRM.LICENSES.PACKAGE_SETS_LOADED";
export const PACKAGES_LOADED = "CRM.LICENSES.PACKAGES_LOADED";
export const SET_PERSISTING = "CRM.LICENSES.SET_PERSISTING";

export function licensesLoaded(licenses)
{
  return {
    type: LICENSES_LOADED,
    licenses
  };
}

export function packageSetsLoaded(packageSets)
{
  return {
    type: PACKAGE_SETS_LOADED,
    packageSets
  };
}

export function packagesLoaded(packages)
{
  return {
    type: PACKAGES_LOADED,
    packages
  };
}

export function assetsLoaded(assets)
{
  return {
    type: ASSETS_LOADED,
    assets
  };
}

export function setPersisting(persisting)
{
  return {
    type: SET_PERSISTING,
    persisting
  };
}
export function assetCreated(asset)
{
  return {
    type: ASSET_CREATED,
    asset
  };
}

export function assetUpdated(asset)
{
  return {
    type: ASSET_UPDATED,
    asset
  };
}
export function licenseCreated(license)
{
  return {
    type: LICENSE_CREATED,
    license
  };
}

export function licenseUpdated(license)
{
  return {
    type: LICENSE_UPDATED,
    license
  };
}

export function packageSetCreated(packageSet)
{
  return {
    type: PACKAGE_SET_CREATED,
    packageSet
  };
}

export function packageSetUpdated(packageSet)
{
  return {
    type: PACKAGE_SET_UPDATED,
    packageSet
  };
}

export function packageCreated(pkg)
{
  return {
    type: PACKAGE_CREATED,
    pkg
  };
}

export function packageUpdated(pkg)
{
  return {
    type: PACKAGE_UPDATED,
    pkg
  };
}

function nameComparator(a, b)
{
  return a.name.localeCompare(b.name);
}

export function loadLicenses()
{
  return (dispatch) =>
  {
    return api.listLicenses().then((res) =>
    {
      const sorted = Array.isArray(res.data) ?
        res.data.sort(nameComparator) :
        [];
      dispatch(licensesLoaded(sorted));
    });
  };
}

export function loadPackageSets()
{
  return (dispatch) =>
  {
    return api.listPackageSets().then((res) =>
    {
      const sorted = Array.isArray(res.data) ?
        res.data.sort(nameComparator) :
        [];
      dispatch(packageSetsLoaded(sorted));
    })
  };
}

export function loadPackages()
{
  return (dispatch) =>
  {
    return api.listPackages().then((res) =>
    {
      const sorted = Array.isArray(res.data) ?
        res.data.sort(nameComparator) :
        [];
      dispatch(packagesLoaded(sorted));
    });
  };
}

export function loadAssets()
{
  return (dispatch) =>
  {
    return api.listAssets().then((res) =>
    {
      const sorted = Array.isArray(res.data) ?
        res.data.sort(nameComparator) :
        [];
      dispatch(assetsLoaded(sorted));
    });
  };
}

// Trim local keys from objects
function prepForRequest(original)
{
  const reqObj = clone(original);
  if (reqObj.fresh)
  {
    delete reqObj.fresh;
    delete reqObj.id;
  }
  if (reqObj.prices)
  {
    reqObj.prices = reqObj.prices.map(prepForRequest);
  }
  return reqObj;
}

function stripPrices(original)
{
  const reqObj = clone(original);
  delete reqObj.prices;
  return reqObj;
}

async function ifNotEmpty(list, defaultValue, doRequest)
{
  if (list.length > 0)
  {
    const res = await doRequest();
    return res.data;
  }
  else
  {
    return defaultValue;
  }
}

async function ifNotDefault(value, defaultValue, doRequest)
{
  if (!isNil(value) && value !== -1)
  {
    const res = await doRequest();
    return res.data;
  }
  else
  {
    return defaultValue;
  }
}

function getId(item)
{
  return item.id;
}

export function createLicense(name, description, status, visibility, prices, packageSetIds, support, limits, quotas)
{
  return async (dispatch) => {
    dispatch(setPersisting(true));
    try
    {
      const licenseRes = await api.createLicense(name, description, status, visibility);
      const licenseId = licenseRes.data.id;
      const supportRes = await api.updateSupportDetails(licenseId, support);
      let license = supportRes.data;

      // Related objects: prices, package sets, limits and quotas
      license = await ifNotEmpty(prices, license, api.addLicensePrices.bind(null, licenseId, prices.map(prepForRequest)));
      license = await ifNotEmpty(packageSetIds, license, api.addLicensePackageSets.bind(null, licenseId, packageSetIds));
      license = await ifNotEmpty(limits, license, api.addLicenseLimits.bind(null, licenseId, limits.map(prepForRequest)));
      license = await ifNotEmpty(quotas, license, api.addLicenseQuotas.bind(null, licenseId, quotas.map(prepForRequest)));

      dispatch(licenseCreated(license));
      return licenseId;
    }
    catch (e)
    {
      dispatch(setPersisting(false));
      console.error("Something went wrong while creating a new license:", e);
      throw e;
    }
  };
}

export function updateLicense(oldLicense, name, description, status, visibility, prices, packageSetIds, support, limits, quotas)
{
  return async (dispatch) => {
    dispatch(setPersisting(true));
    try
    {
      // Update license and support, no need to await here
      const licenseId = oldLicense.id;
      await api.updateLicense(licenseId, name, description, status, visibility);
      const supportRes = await api.updateSupportDetails(licenseId, support);
      let license = supportRes.data;

      // Add new prices, remove old ones
      const removedPriceIds = difference(oldLicense.prices.map(getId), prices.map(getId));
      const updatedPrices = prices.filter(p => !p.fresh);
      const newPrices = prices.filter(p => p.fresh);
      license = await ifNotEmpty(removedPriceIds, license, api.removeLicensePrices.bind(null, licenseId, removedPriceIds));
      license = await ifNotEmpty(updatedPrices, license, api.updateLicensePrices.bind(null, licenseId, updatedPrices));
      license = await ifNotEmpty(newPrices, license, api.addLicensePrices.bind(null, licenseId, newPrices.map(prepForRequest)));

      // Same for package sets, limits and quotas
      const removedPackageSets = difference(oldLicense.packageSetIds, packageSetIds);
      const addedPackageSets = difference(packageSetIds, oldLicense.packageSetIds);
      license = await ifNotEmpty(removedPackageSets, license, api.removeLicensePackageSets.bind(null, licenseId, removedPackageSets));
      license = await ifNotEmpty(addedPackageSets, license, api.addLicensePackageSets.bind(null, licenseId, addedPackageSets));

      const removedLimits = differenceBy(oldLicense.limits, limits, getId);
      const newLimits = differenceBy(limits, oldLicense.limits, getId);
      const updatedLimits = differenceBy(limits, newLimits, getId);
      license = await ifNotEmpty(removedLimits, license, api.removeLicenseLimits.bind(null, licenseId, removedLimits.map(getId)));
      license = await ifNotEmpty(updatedLimits, license, api.updateLicenseLimits.bind(null, licenseId, updatedLimits));
      license = await ifNotEmpty(newLimits, license, api.addLicenseLimits.bind(null, licenseId, newLimits.map(prepForRequest)));

      const removedQuotas = differenceBy(oldLicense.quotas, quotas, getId);
      const newQuotas = differenceBy(quotas, oldLicense.quotas, getId);
      const updatedQuotas = differenceBy(quotas, newQuotas, getId);
      license = await ifNotEmpty(removedQuotas, license, api.removeLicenseQuotas.bind(null, licenseId, removedQuotas.map(getId)));
      license = await ifNotEmpty(updatedQuotas, license, api.updateLicenseQuotas.bind(null, licenseId, updatedQuotas.map(stripPrices)));
      license = await ifNotEmpty(newQuotas, license, api.addLicenseQuotas.bind(null, licenseId, newQuotas.map(prepForRequest)));

      license = await persistQuotaPrices(license, oldLicense.quotas, updatedQuotas);

      dispatch(licenseUpdated(license));
      return licenseId;
    }
    catch (e)
    {
      dispatch(setPersisting(false));
      console.error("Something went wrong while updating a license:", e);
      throw e;
    }
  };
}

async function persistQuotaPrices(license, persistedQuotas, updatedQuotas)
{
  return promiseChain(updatedQuotas, license, async (updatedLicense, quota, _i) => {
    const oldPrices = persistedQuotas.find(q => q.id === quota.id).prices;
    const removedPrices = differenceBy(oldPrices, quota.prices, getId);
    const newPrices = differenceBy(quota.prices, oldPrices, getId);
    const updatedPrices = differenceBy(quota.prices, newPrices, getId);

    let newUpdatedLicense = await ifNotEmpty(removedPrices, updatedLicense, api.removeQuotaPrices.bind(null, license.id, quota.id, removedPrices.map(getId)));
    newUpdatedLicense = await ifNotEmpty(updatedPrices, newUpdatedLicense, api.updateQuotaPrices.bind(null, license.id, quota.id, updatedPrices.map(prepForRequest)));
    newUpdatedLicense = await ifNotEmpty(newPrices, newUpdatedLicense, api.addQuotaPrices.bind(null, license.id, quota.id, newPrices.map(prepForRequest)));
    return newUpdatedLicense;
  });
}

export function createPackageSet(name, description, status, prices, packageIds)
{
  return async (dispatch) =>
  {
    dispatch(setPersisting(true));
    try
    {
      const packageSetRes = await api.createPackageSet(name, description, status);
      const id = packageSetRes.data.id;
      let packageSet = packageSetRes.data;

      packageSet = await ifNotEmpty(prices, packageSet, api.addPackageSetPrices.bind(null, id, prices.map(prepForRequest)));
      packageSet = await ifNotEmpty(packageIds, packageSet, api.addPackageSetPackages.bind(null, id, packageIds));

      dispatch(packageSetCreated(packageSet));
      return id;
    }
    catch (e)
    {
      dispatch(setPersisting(false));
      console.error("Something went wrong while creating a package set:", e);
      throw e;
    }
  };
}

export function updatePackageSet(oldPackageSet, name, description, status, prices, packageIds)
{
  return async (dispatch) =>
  {
    dispatch(setPersisting(true));
    try
    {
      const packageSetId = oldPackageSet.id;
      const packageSetRes = await api.updatePackageSet(oldPackageSet.id, name, description, status);
      let packageSet = packageSetRes.data;

      const removedPriceIds = difference(oldPackageSet.prices.map(getId), prices.map(getId));
      const updatedPrices = prices.filter(p => !p.fresh);
      const newPrices = prices.filter(p => p.fresh);
      packageSet = await ifNotEmpty(removedPriceIds, packageSet, api.removePackageSetPrices.bind(null, packageSetId, removedPriceIds));
      packageSet = await ifNotEmpty(updatedPrices, packageSet, api.updatePackageSetPrices.bind(null, packageSetId, updatedPrices));
      packageSet = await ifNotEmpty(newPrices, packageSet, api.addPackageSetPrices.bind(null, packageSetId, newPrices.map(prepForRequest)));

      const removedPackageIds = difference(oldPackageSet.packageIds, packageIds);
      const addedPackageIds = difference(packageIds, oldPackageSet.packageIds);
      packageSet = await ifNotEmpty(removedPackageIds, packageSet, api.removePackageSetPackages.bind(null, packageSetId, removedPackageIds));
      packageSet = await ifNotEmpty(addedPackageIds, packageSet, api.addPackageSetPackages.bind(null, packageSetId, addedPackageIds));

      dispatch(packageSetUpdated(packageSet));
      return packageSetId;
    }
    catch (e)
    {
      dispatch(setPersisting(false));
      console.error("Something went wrong while updating a package set:", e);
      throw e;
    }
  }
}

export function createPackage(name, description, status, prices, assetId, feature)
{
  return async (dispatch) =>
  {
    dispatch(setPersisting(true));
    try
    {
      const packageRes = await api.createPackage(name, description, status);
      const id = packageRes.data.id;
      let pkg = packageRes.data;

      pkg = await ifNotEmpty(prices, pkg, api.addPackagePrices.bind(null, id, prices.map(prepForRequest)));
      pkg = await ifNotDefault(feature, pkg, api.addPackageFeatures.bind(null, id, [feature]));
      pkg = await ifNotDefault(assetId, pkg, api.addPackageAssets.bind(null, id, [assetId]));

      dispatch(packageCreated(pkg));
      return id;
    }
    catch (e)
    {
      dispatch(setPersisting(false));
      console.error("Something went wrong while creating a package set:", e);
      throw e;
    }
  };
}

export function updatePackage(oldPackage, name, description, status, prices, assetId, feature)
{
  return async (dispatch) =>
  {
    dispatch(setPersisting(true));
    try
    {
      const id = oldPackage.id;
      let packageRes = await api.updatePackage(id, name, description, status);
      let pkg = packageRes.data;

      const removedPriceIds = difference(oldPackage.prices.map(getId), prices.map(getId));
      const updatedPrices = prices.filter(p => !p.fresh);
      const newPrices = prices.filter(p => p.fresh);
      pkg = await ifNotEmpty(removedPriceIds, pkg, api.removePackagePrices.bind(null, id, removedPriceIds));
      pkg = await ifNotEmpty(updatedPrices, pkg, api.updatePackagePrices.bind(null, id, updatedPrices));
      pkg = await ifNotEmpty(newPrices, pkg, api.addPackagePrices.bind(null, id, newPrices.map(prepForRequest)));

      if (oldPackage.feature !== feature)
      {
        packageRes = await api.removePackageFeatures(id, [oldPackage.feature]);
        pkg = packageRes.data;
        pkg = await ifNotDefault(feature, pkg, api.addPackageFeatures.bind(null, id, [feature]));
      }
      if (oldPackage.assetId !== assetId)
      {
        packageRes = await api.removePackageAssets(id, [oldPackage.assetId]);
        pkg = packageRes.data;
        pkg = await ifNotDefault(assetId, pkg, api.addPackageAssets.bind(null, id, [assetId]));
      }

      dispatch(packageUpdated(pkg));
      return id;
    }
    catch (e)
    {
      dispatch(setPersisting(false));
      console.error("Something went wrong while updating a package", e);
      throw e;
    }
  };
}

export function createAsset(name, description, surveyId, language)
{
  return async (dispatch) => {
    dispatch(setPersisting(true));
    try
    {
      const res = await api.createAsset(name, description, surveyId, language);
      dispatch(assetCreated(res.data));
      return res.data.id;
    }
    catch (e)
    {
      dispatch(setPersisting(false));
      console.error("Something went wrong while creating an asset", e)
      throw e;
    }
  };
}

export function updateAsset(oldAsset, name, description, surveyId, language)
{
  return async (dispatch) => {
    dispatch(setPersisting(true));
    try
    {
      const assetRes = await api.updateAsset(oldAsset.id, name, description, surveyId, language);
      dispatch(assetUpdated(assetRes.data));
      return assetRes.data.id;
    }
    catch (e)
    {
      dispatch(setPersisting(false));
      console.error("Something went wrong while updating an asset", e)
      throw e;
    }
  };
}
