define("notifications/language-notification", function(require)
{
	var NotificationBar = require("ui/notification-bar");
	var RoundButton = require("ui/round-button");

	return NotificationBar.extend(
		{
			init: function(language)
			{
				this.supreme();

				var button = new RoundButton(Locale.get(language, "language-changed-notification", "button"), this.cmdRefresh.bind(this));
				button.makeDefault();
				button.attach(this.find());

				// Make sure the locale is loaded
				this.text(Locale.get(language, "language-changed-notification", "label"));
			},

			cmdRefresh: function()
			{
				Surveypal.reload(true);
			}
		});
});