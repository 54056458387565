define("utils/currency-formatter", [], function()
{
	var fn = function(amount, currency)
	{
		if(currency == "USD")
		{
			return "$" + amount;
		}
	};
	return fn;
});