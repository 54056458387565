import axios from "core/axios-sp";
import clone from "lodash.clonedeep";
import isNil from "lodash.isnil";

// Timeline results can be cached because they change slowly and recently fetched timelines are valid in context of GDPR.
// This class takes care of fetching timeline results and caching them for each survey.
export default class TimelineFetcher {
	constructor()
	{
		this.cache = {};
	}

	fetch(surveyId)
	{
		// Use cache
		if (!isNil(this.cache[surveyId]))
		{
			return Promise.resolve(clone(this.cache[surveyId]));
		}
		// Fetch new results from back-end
		else
		{
			return TimelineFetcher.fetchTimeline(surveyId).then(timelineEntries => {
				this.cache[surveyId] = timelineEntries;
				return clone(timelineEntries);
			});
		}
	}

	// Fetch survey timeline in an array that has survey timeline in shape:
	// [{date: 100}, {date: 200} ...]
	static fetchTimeline(surveyId)
	{
		return axios.get("/app/team/personaldata/survey/timeline", {
			params: {
				json: {
					surveyId
				}
			}
		}).then(response => {
			if (response.status === 200)
			{
				return response.data;
			}
			// "No content" status
			else if (response.status === 204)
			{
				return [];
			}
			else
			{
				return Promise.reject("Unexpected status from GET /app/team/personaldata/survey/timeline !");
			}
		});
	}
};