define("utils/gravatar", function(require)
{
	var Environment = require("utils/environment");
	return function(md5, size)
	{
		var url = "https://www.gravatar.com/avatar/" + md5 + "?s=" + size;
		if(!Environment.isLocalhost())
		{
			url = url + "&d=" + encodeURIComponent(Environment.getDomain() + "/graphics/avatar.png"); // Set default image which is on our servers.
		}
		return url;
	};
});