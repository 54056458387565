import React from "react";
import PopupR from "ui/PopupR";
import PopupRichContent from "ui/PopupRichContent";
import {connect} from "react-redux";

/**
 * <MassDeletionProgress/> shows a popup displaying the progress of deleting several reports.
 *
 * @param successfulDeletions {string[]}
 * @param failedDeletions {string[]}
 * @param reportsToDelete {string[]}
 * @returns {JSX.Element}
 */
const MassDeletionProgress = ({
	successfulDeletions,
	failedDeletions,
	reportsToDelete
}) => {
	const current = successfulDeletions.length + failedDeletions.length + 1;

	return (
		<PopupR
			title={translator.get("automaticReporting.automaticReporting")}
			hideButtons={true}>
			<PopupRichContent
				title={translator.get("automaticReporting.massDeleteProgressTitle")}>
				<p>
					{
						translator.get("automaticReporting.massDeleteProgressText")
							.replace("${progress}", current)
							.replace("${target}", reportsToDelete.length)
					}
				</p>
			</PopupRichContent>
		</PopupR>
	);
};

export default connect(state => ({
	successfulDeletions: state.automaticReporting.existingReports.massDelete.successfulDeletions,
	failedDeletions: state.automaticReporting.existingReports.massDelete.failedDeletions,
	reportsToDelete: state.automaticReporting.existingReports.massDelete.reportsToDelete
}))(MassDeletionProgress);