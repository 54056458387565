define("data/localetext", function(require)
{
	var Class = require("core/class");

	return Class.extend(
	{
		init: function(o)
		{
			this.data = o;
		},

		has: function(lang)
		{
			return !!(this.get(lang));
		},

		keys: function()
		{
			return Object.keys(this.data);
		},

		get: function(lang)
		{
			var data = this.data;
			if(data === undefined)
			{
				console.warn("Undefined locale text");
				return "???";
			}
			if(typeof data == "string")
			{
				return data;
			}
			return data[lang];
		},

		// Returns plain text version, cut to certain length if requested
		text: function(lang, length)
		{
			if (typeof this.data === 'string')
			{
				return this.data;
			}
			if(lang === "first")
			{
				var current = Surveypal.getSurvey().getLanguages().getCurrent();
				var hasCurrent = Object.keys(this.data).some(function(key)
				{
					return key == current;
				});
				if(hasCurrent)
				{
					lang = current;
				}
				else
				{
					lang = Object.keys(this.data)[0];
				}
			}
			var str = this.get(lang);
			if(typeof str === "string") {
				var text = str.stripHTML();
				if(length && text.length > length)
				{
					text = text.substr(0, length) + "...";
				}
				return text;
			}
			return "";
		},

		set: function(lang, text)
		{
			this.data[lang] = text;
		},

		getRaw: function()
		{
			return this.data;
		},

		remove: function(lang)
		{
			delete this.data[lang];
		}
	});
});