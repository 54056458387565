import axios from "../../../core/axios-sp";

export const SET_SEARCH_TERM = "CRM.UTILITIES.SET_SURVEY_TERM";
export const SET_SURVEY_INFO = "CRM.UTILITIES.SET_SURVEY_INFO";
export const SET_SURVEY_OWNER_INFO = "CRM.UTILITIES.SET_SURVEY_OWNER_INFO";
export const SHOW_NOTIFICATION = "CRM.UTILITIES.SHOW_NOTIFICATION";
export const SET_SEARCH_STATUS = "CRM.UTILITIES.SET_SEARCH_STATUS";
export const FINISH_SEARCH = "CRM.UTILITIES.FINISH_SEARCH";
export const CHECK_SURVEY_ID_WARNING = "Seems like there's something wrong with the provided survey id.";
export const GENERAL_WARNING = "Something went wrong while fetching the survey data. Check the survey id.";
export const NOT_SEARCHED_YET = 0;
export const FETCHING_SURVEY_AND_OWNER_INFO = 1;
export const FETCHING_OWNER_INFO = 2;
export const SEARCH_FINISHED = 3;
export const ERROR_404 = 404;

export function setSearchTermAction(searchTerm) {
	return {
		type: SET_SEARCH_TERM,
		searchTerm
	};
}

export function showNotificationAction(notification) {
	return {
		type: SHOW_NOTIFICATION,
		notification
	};
}

export function setSurveyInfoAction(survey) {
	return {
		type: SET_SURVEY_INFO,
		survey
	};
}

export function setSurveyOwnerInfoAction(surveyOwner) {
	return {
		type: SET_SURVEY_OWNER_INFO,
		surveyOwner
	};
}

export function setSearchStatus(searchStatus)
{
	return {
		type: SET_SEARCH_STATUS,
		searchStatus
	}
}

export function doSurveySearch(searchTerm)
{
	return dispatch => {
		dispatch(setSearchStatus(FETCHING_SURVEY_AND_OWNER_INFO));

		return new Promise((resolve, reject) => {
			axios.get("/app/crm/lookup/survey", {
				params: {
					json: {
						searchTerm: searchTerm
					}
				}
			}).then(surveyInfoResponse => {
				dispatch(setSearchStatus(FETCHING_OWNER_INFO));
				const surveyId = surveyInfoResponse.data.id;

				const surveyOwnerInfoRequest = axios.get("/app/crm/lookup/survey/owner", {
					params: {
						json: {
							id: surveyId
						}
					}
				}).then(ownerInfoResponse => {
					resolve(
						dispatch(setSearchStatus(SEARCH_FINISHED)),
						dispatch(setSurveyInfoAction(surveyInfoResponse.data)),
						dispatch(setSurveyOwnerInfoAction(ownerInfoResponse.data))
					);
				}).catch(error => {
					reject(setSearchStatus(error.response.status));
				});
			}).catch(error => {
				reject(setSearchStatus(error.response.status));
			});
		}).catch(error => {
			return dispatch(error);
		});
	}
}