define("notifications/upcoming-maintenance-notification", function(require)
{
	var NotificationBar = require("ui/notification-bar");
	var RoundButton = require("ui/round-button");

	return NotificationBar.extend(
		{
			init: function (maintenance, callback)
			{
				this.supreme();

				var button = new RoundButton(translator.get("upcoming-maintenance-notification.button"), callback);
				button.makeDefault();
				button.attach(this.find());

				var startDate = Date.parse(maintenance.scheduled_for);
				var endDate = Date.parse(maintenance.scheduled_until);

				var link = '<a class="" href="%1" target="_blank">%2</a>';
				link = link.replace("%1", maintenance.shortlink);
				link = link.replace("%2", translator.get("upcoming-maintenance-notification.readmore"));

				var text = translator.get("upcoming-maintenance-notification.text");
				text = text.replace("%1", translator.timestamp("Date.fullDate", startDate));
				text = text.replace("%2", translator.timestamp("Date.fullDate", endDate));
				text = text + link;

				this.text(text);
			}
		});
});