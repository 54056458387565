define("monitors/answer-limit-monitor", function (require)
{
	var PollMonitor = require("monitors/poll-monitor");
	var Request = require("core/request");
	var LocalStorage = require("utils/local-storage");
	var FreeLimitNotification = require("notifications/free-limit-notification");
	var PremiumLimitNotification = require("notifications/premium-limit-notification");
	var AnswerLimitPopup = require("common/answer-limit-popup");
	var UpgradePopup = require("common/upgrade-popup");

	return PollMonitor.extend(
		{
			init: function ()
			{
				this.supreme(5 * 60 * 1000); //poll once in 5min

				// Reset some values
				this.answerAmount = 0;

				// Start checking loop
				this.check();

				// Start interval for polling
				this.start();
			},

			canPoll: function()
			{
				var licenseType = Surveypal.getUser().getLicenseType();
				return this.supreme() && (licenseType == "free" || licenseType == "premium");
			},

			clear: function ()
			{
				if (this.notification)
				{
					this.notification.deinit();
					this.notification = null;
				}
				this.answerAmount = 0;
			},

			poll: function ()
			{
				if (!this.canPoll())
				{
					return;
				}

				// For some users just schedule the next check
				var user = Surveypal.getUser();
				var visitor = user.isVisitor();
				if (!user.hasSession() || visitor)
				{
					// If no session, do nothing
					return;
				}

				// Abort previous requests
				if (this.request && this.request.isActive())
				{
					this.request.abort();
				}

				// Define a callback
				var callback = function (status, json)
				{
					if(status == 200 && this.answerAmount != json.answerAmount)
					{
						Surveypal.getIntercom().updateAnswerAmount(json.answerAmount);
						this.answerAmount = json.answerAmount;
					}
				}.bind(this);

				this.request = new Request("/app/monthly");
				this.request.setCallback(callback);
				this.request.get();
			},

			check: function ()
			{
				clearTimeout(this.checkTimer);

				// Check notifications
				if(Surveypal && Surveypal.getUser() && !this.answerLimitPopup)
				{
					var licenseType = Surveypal.getUser().getLicenseType();

					if (licenseType == "free" && this.answerAmount >= 50)
					{
						this.addFreeNotification();
					}
					else if (licenseType == "premium" && this.answerAmount > 1000)
					{
						this.addPremiumNotification();
					}
					else
					{
						if(this.notification)
						{
							this.notification.deinit();
						}
					}
				}

				// Check every 1sec if notifications should be shown
				this.checkTimer = setTimeout(this.check.bind(this), 1000);
			},

			isLimitReached: function()
			{
				var license = Surveypal.getUser().getLicenseType();
				return (license == "free" && this.answerAmount > 100) || (license == "premium" && this.answerAmount > 1000);
			},

			addFreeNotification: function()
			{
				if (!this.notification)
				{
					this.notification = new FreeLimitNotification(this.upgrade.bind(this));
				}
				this.notification.setText(this.answerAmount);

				var limit = 100;

				var storage = new LocalStorage();
				var ms_last = storage.get("answerLimitPopup");
				var ms_minute = 1000 * 60;
				var now = new Date();
				var ms_now = now.getTime();
				var view = Surveypal.getViewManager().getSubviewName();
				var viewMatch = (view == "manage" || view === "respondent" || view === "result"); // We are in a correct view
				var timeMatch = (ms_last === null || ms_now > ms_last + ms_minute * 30); // 30mins passed since last shown (or first time)
				if (this.answerAmount > limit && (viewMatch || timeMatch))
				{
					storage.set("answerLimitPopup", ms_now);

					// Display the popup if 100 answers it has not been shown in 30min
					var close_callback = function ()
					{
						this.answerLimitPopup = null;
					}.bind(this);
					this.answerLimitPopup = new AnswerLimitPopup(this.answerAmount, close_callback, this.upgrade.bind(this), Surveypal.reload.bind(Surveypal));
				}
			},

			addPremiumNotification: function()
			{
				if (!this.notification)
				{
					this.notification = new PremiumLimitNotification();
				}
				this.notification.setText(this.answerAmount);
			},

			upgrade: function ()
			{
				new UpgradePopup(false, Surveypal.getLoader(), Surveypal.getUser(), Surveypal.getIntercom(), Surveypal.reload.bind(Surveypal));
			}
		});
});