import React from "react";
import "./InlineNote.scss";

/**
 * InlineNote creates an inline text element with notification styling.
 *
 * @param className {string}
 * @param icon {boolean}
 * @param children {JSX.Element}
 * @returns {JSX.Element}
 */
const InlineNote = ({className, icon, children}) => {
	return (
		<span className={`InlineNote ${icon ? "NoteIcon" : ""} ${className ? className : ""}`}>
			{children}
		</span>
	);
};

export default InlineNote;