import { ALL_GROUP_OPERATORS } from "./ruleActions";
import { isElementRule } from "common/rules/rule-utils";
import { CONDITION_ELEMENT_TYPE } from "./conditionActions";
import { TYPES } from "common/rules/rule-conf";
import isNil from "lodash.isnil";
import equals from "lodash.isequal";
import assign from "lodash.assign";
import clone from "lodash.clonedeep";

export function getAddableElements(elementItems, conditions, condition)
{
	// Collect element ids for all elements already in conditions
	const allConditionalElements = conditions.filter(c => !c.deleted).reduce((collector, condition) => {
		return collector.concat(condition.elements.filter(el => !el.deleted));
	}, []);
	// Collect element ids from element rules for current condition
	const ruleElementIds = ALL_GROUP_OPERATORS.reduce((collector, groupOperator) => {
		const elementRules = condition.rules.filter(r => !r.deleted && r.groupOperator === groupOperator).filter(rule => isElementRule(elementItems, rule));
		const elementsInRules = elementRules.map(rule => rule.key);
		return collector.concat(elementsInRules);
	}, []);
	return elementItems.filter(candidate => {
		// To be added,
		// 1) element can't be in other conditions,
		// 2) must appear after element rules in current condition
		return allConditionalElements.every(elem => elem.targetId !== candidate.targetId) &&
		       ruleElementIds.every(elemId => isBefore(elementItems, elemId, candidate.targetId));
	});
}

export function isBefore(elementItems, elementId, compareElementId)
{
	const indexA = elementItems.findIndex(elem => elem.targetId === elementId);
	const indexB = elementItems.findIndex(elem => elem.targetId === compareElementId);
	return indexA !== -1 && indexB !== -1 ?
	       (indexB - indexA) > 0 :
	       false;
}

// Split element items into two-dimensional array where each nested array represents a page
// Example: [[page1, elem1, elem2], [page2, elem3, elem4], ...]
export function splitToPages(elementItems)
{
	return splitToPagesRec([], [], elementItems);
}

function splitToPagesRec(blockCollector, collector, items)
{
	// Stop recursion when all items have been processed
	if (items.length === 0)
	{
		blockCollector.push(collector);
		return blockCollector;
	}
	else
	{
		if (items[0].type === CONDITION_ELEMENT_TYPE.PAGE)
		{
			// First item is a special case, it doesn't finish the ongoing block
			if (blockCollector.length === 0 && collector.length === 0)
			{
				collector.push(items[0]);
				return splitToPagesRec(blockCollector, collector, items.slice(1));
			}
			// Finish previous block and start a new block
			else
			{
				blockCollector.push(collector);
				const newCollector = [];
				newCollector.push(items[0]);
				return splitToPagesRec(blockCollector, newCollector, items.slice(1));
			}
		}
		// Put item to current block
		else
		{
			collector.push(items[0]);
			return splitToPagesRec(blockCollector, collector, items.slice(1));
		}
	}
}

export function isConditionalElement(elemId, conditions)
{
	return conditions &&
	       conditions.some(cond => {
		       return cond.elements &&
		              cond.elements.some(elem => elem.targetId === elemId)
	       });
}

// Return all conditions that have rules in which the element specified by elemId appears
export function getRelatedConditions(elemId, conditions)
{
	return conditions.reduce((collector, cond) => {
		if (cond.rules && cond.rules.some(rule => rule.key.indexOf(elemId) !== -1))
		{
			return collector.concat(clone(cond));
		}
		else
		{
			return collector;
		}
	}, []);
}

export function isValidRule(elementItems, rule)
{
	switch (rule.type)
	{
		case TYPES.UNDEFINED:
		case TYPES.GENERAL:
		case TYPES.META:
			return !rule.deleted &&
			       !isNil(rule.op) &&
			       !isNil(rule.key);
		case TYPES.ANSWER:
			return !rule.deleted &&
			       !isNil(rule.op) &&
			       !isNil(rule.key) &&
			       elementItems.some(elItem => rule.key.indexOf(elItem.targetId) === 0);
		default:
			return false;
	}
}

export function isValidElement(elementItems, element)
{
	return !element.deleted &&
	       elementItems.some(elItem => elItem.targetId === element.targetId);
}

export function isValidCondition(elementItems, condition)
{
	const hasValidRules = condition.rules.some(isValidRule.bind(null, elementItems));
	const hasValidElements = condition.elements.some(isValidElement.bind(null, elementItems));
	return !condition.deleted && hasValidRules && hasValidElements;
}

export function conditionNeedsUpdate(elementItems, persistedConditions, condition)
{
	const original = persistedConditions.find(pCond => pCond.id === condition.id);
	const newValidatedCondition = assign(clone(condition), {
		rules: condition.rules.filter(isValidRule.bind(null, elementItems)),
		elements: condition.elements.filter(isValidElement.bind(null, elementItems))
	});
	return condition.fresh || !(equals(newValidatedCondition, original));
}

export function isDeletableCondition(elementItems, condition)
{
	const hasNoValidRules = !condition.rules.some(isValidRule.bind(null, elementItems));
	const hasNoValidElements = !condition.elements.some(isValidElement.bind(null, elementItems));
	return (!condition.fresh && condition.deleted) ||
	       (!condition.fresh && (hasNoValidRules || hasNoValidElements));
}