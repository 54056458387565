import React, {useState, useMemo, useRef} from "react";
import DropdownMenu from "./DropdownMenu/DropdownMenu";
import "./Dropdown.scss";

/**
 * @callback onChange
 * @param newValue {T}
 */
/**
 * Dropdown react component.
 *
 * @param id {string}
 * @param items {ItemGroup[]}
 * @param selectedValue {T}
 * @param disabled {boolean}
 * @param className {string}
 * @param filterable {boolean}
 * @param prefix {string}
 * @param onChange {onChange}
 * @returns {JSX.Element}
 */
const Dropdown = ({
	id,
	items,
	selectedValue,
	disabled,
	className,
	filterable,
	prefix,
	onChange
}) => {
	const [open, setOpen] = useState(false);
	const [filterInputStyle, setFilterInputStyle] = useState({})
	const [filterString, setFilterString] = useState("");
	const [menuStyle, setMenuStyle] = useState({});
	const dropdownReference = useRef();
	const filterInputReference = useRef();

	const selectedItem = useMemo(() => {
		if (selectedValue === undefined) return items[0][0];

		const itemGroup = items.find((itemGroup) => itemGroup.some((item) => item.value === selectedValue));

		if (itemGroup === undefined) return items[0][0];

		return itemGroup.find((item) => item.value === selectedValue);
	}, [selectedValue]);

	const toggle = () => {
		//If the dropdown is disabled, don't do anything when clicking on the dropdown.
		if (disabled) return;

		//If the dropdown is open, close it.
		if (open)
		{
			setFilterInputStyle({});
			setFilterString("");
			setMenuStyle({});
			setOpen(false);
			return;
		}

		const referencePosition = dropdownReference.current.getBoundingClientRect();

		setFilterInputStyle({
			position: "fixed",
			top: `${referencePosition.y}px`,
			left: `${referencePosition.x}px`,
			height: `${referencePosition.height}px`,
			width: `${referencePosition.width}px`
		});
		setMenuStyle({
			position: "fixed",
			top: `${referencePosition.y + referencePosition.height + 2}px`,
			left: `${referencePosition.x - 1}px`,
			minWidth: `${referencePosition.width + 100}px`,
		})
		setOpen(true);
	};

	return (
		<div
			key={"dropdown"}
			ref={dropdownReference}
			className={`dropdown-v2 ${className ? className : ""}`}
			id={id}
			onClick={toggle}>
			<div
				key={"dropdownTop"}
				className={`top ${open ? "open" : ""}`}>
				<div
					key={"dropdownTopLabel"}
					className={"label"}
					contentEditable={open && filterable}>
					{prefix}{selectedItem.text}
				</div>
				<div
					key={"dropdownTopArrow"}
					className={"arrow sprite"}>
					{null}
				</div>
			</div>
			{
				open &&
				<div
					className={"dropdown-v2-backdrop"}
					onClick={toggle}>
					<div style={menuStyle}>
						{
							filterable &&
							<input
								autoFocus={true}
								type={"text"}
								className={"dropdown-v2-filter"}
								style={filterInputStyle}
								value={filterString}
								onChange={(e) => setFilterString(e.target.value)}
								onClick={(e) => {e.preventDefault();e.stopPropagation();}}
								placeholder={translator.get("common.search")}
							/>
						}
						<DropdownMenu
							narrow={"width" in menuStyle && parseInt(menuStyle.width.replace("px", "")) < 102}
							items={items}
							selectedValue={selectedValue}
							onClick={onChange}
							filter={filterString}
						/>
					</div>
				</div>
			}
		</div>
	);
};

export default Dropdown;