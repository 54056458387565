import React, {useState, useRef} from "react";
import "./SplitButton.scss";
import SplitButtonMenu from "./SplitButtonMenu/SplitButtonMenu";

/**
 * @typedef SplitButtonItem
 * @type {object}
 * @property text {string}
 * @property onClick {function}
 * @property disabled {boolean}
 */
/**
 * @param className {string}
 * @param items {SplitButtonItem[][]}
 * @param disabled {boolean}
 * @param isRound {boolean}
 * @returns {JSX.Element}
 */
const SplitButton = ({
	className,
	items,
	disabled,
	isRound
}) => {
	const [open, setOpen] = useState(false);
	const [menuStyle, setMenuStyle] = useState({});
	const menuReference = useRef();

	const toggle = () => {
		//If the split button is disabled as a whole, don't do anything.
		if (disabled) return;

		//If the menu is open, close it.
		if (open)
		{
			setMenuStyle({});
			setOpen(false);
			return;
		}

		const referencePosition = menuReference.current.getBoundingClientRect();
		setMenuStyle({
			position: "fixed",
			top: `${referencePosition.y + referencePosition.height + 1}px`,
			left: `${referencePosition.x}px`,
			width: `${referencePosition.width}px`
		});
		setOpen(true);
	};

	const onClickDefault = !disabled ? items[0][0].onClick : () => {};

	return (
		<div
			ref={menuReference}
			className={`SplitButton ${isRound ? "round" : "square"} ${className ? className : ""}`}>
			<div
				className={"DefaultAction"}
				onClick={onClickDefault}>
				{items[0][0].text}
			</div>
			<div
				className={"MenuArrow"}
				onClick={toggle}>
				<div className={"sprite"}>{null}</div>
			</div>
			{
				open &&
				<div
					className={"SplitButtonBackdrop"}
					onClick={toggle}>
					<div style={menuStyle}>
						<SplitButtonMenu
							items={items}
						/>
					</div>
				</div>
			}
		</div>
	);
};

export default SplitButton;