import OwnershipPopup from "common/share/ownership-popup";
import OwnershipTransferToSelfPopup from "common/share/ownership-transfer-to-self-popup";
import OwnershipTransferFailPopup from "common/share/ownership-transfer-fail-popup";
import {
	fetchSurveys,
	setSelectedSurveys
} from "../../../surveysActions";
import axios from "axios";
import EVENTS from "conf/ai-events";

/**
 * createSurveyTransferPromise creates an axios promise for transferring the specified survey.
 *
 * @param surveyId {number}
 * @param username {string}
 * @param selfShareAccess {number|null}
 * @returns {Promise}
 */
export const createSurveyTransferPromise = (surveyId, username, selfShareAccess) => {
	const params = {
		surveyId,
		username,
		...(selfShareAccess !== null ? {keepAccess: selfShareAccess} : {})
	};

	const postData = encodeURI(`json=${JSON.stringify(params)}`);

	return axios.post("/app/home/survey/ownership", postData);
};

/**
 * requestSurveyTransfer sends survey transfer request to backend, then refetches new list of
 * surveys.
 *
 * @param surveyIds {number[]}
 * @param username {string}
 * @param selfShareAccess {string} What access right the user wants to retain to themselves
 */
export const requestSurveyTransfer = (surveyIds, username, selfShareAccess) => {
	//Cannot transfer to self
	if (username === Surveypal.getUser().getUsername())
	{
		new OwnershipTransferToSelfPopup(() => showSurveyTransferPopup(surveyIds, username));
		return;
	}

	Surveypal.getLoader().show();

	axios.all(surveyIds.map((surveyId) => createSurveyTransferPromise(surveyId, username, selfShareAccess)))
		.then(axios.spread((...responses) => {
			if (responses.every(response => response.status === 200))
			{
				Surveypal.applicationInsights.trackEvent(EVENTS.SURVEY_OWNERSHIP_MOVED);
				Surveypal.store.dispatch(setSelectedSurveys([]));
				fetchSurveys();
			}
		})).catch(response => {
			Surveypal.getLoader().hide();
			new OwnershipTransferFailPopup(() => showSurveyTransferPopup(surveyIds, username));
		});
};

/**
 * transferSurvey displays the popup for the user to enter to whom they wish to transfer
 * the ownership of the survey to whom.
 *
 * @param surveyIds {number[]}
 * @param defaultUsername {string}
 */
export const transferSurvey = (surveyIds, defaultUsername) => {
	new OwnershipPopup((username, selfShareAccess) => requestSurveyTransfer(surveyIds, username, selfShareAccess), defaultUsername);
};