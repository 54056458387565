import * as api from "./rulesApi";
import UNITS from "./maxAgeUnits";
import differenceBy from "lodash.differenceby";
import moment from "moment";
import clone from "lodash.clonedeep";
import isNil from "lodash.isnil";
import { fetchAndSetInitialResponses, DEFAULT_SEARCH, DEFAULT_SORT_KEY } from "../responses/responseActions";
import { ANONYMIZATION_TARGET } from "./ruleUtils";
export const ANON_TASK_STATE_LOADED = "SURVEYPAL.MAANGE.RULES.ANON_TASK_STATE_LOADED";
export const DEL_TASK_STATE_LOADED = "SURVEYPAL.MANAGE.RULES.DEL_TASK_STATE_LOADED";
export const TOTAL_RESPONSE_COUNT = "SURVEYPAL.MANAGE.RULES.TOTAL_RESPONSE_COUNT";
export const LOG_LOADED = "SURVEYPAL.MANAGE.RULES.LOG_LOADED";
export const RULES_LOADED = "SURVEYPAL.MANAGE.RULES.RULES_LOADED";
export const ALL_ANONYMIZATION_TARGETS_LOADED = "SURVEYPAL.MANAGE.RULES.ALL_ANONYMIZATION_TARGETS_LOADED";
export const TOGGLE_ANONYMIZATION = "SURVEYPAL.MANAGE.RULES.TOGGLE_ANONYMIZATION";
export const TOGGLE_DELETION = "SURVEYPAL.MANAGE.RULES.TOGGLE_DELETION";
export const TOGGLE_RULE_LOADER = "SURVEYPAl.MANAGE.RULES.TOGGLE_RULE_LOADER";
export const TOGGLE_RULE_UPDATED = "SURVEYPAl.MANAGE.RULES.TOGGLE_RULE_UPDATED";
export const SELECT_ANONYMIZATION_TARGET = "SURVEYPAL.MANAGE.RULES.SELECT_ANONYMIZATION_TARGET";
export const SET_DEL_MAX_AGE_VALUE = "SURVEYPAL.MANAGE.RULES.SET_DEL_MAX_AGE_VALUE";
export const SET_ANON_MAX_AGE_VALUE = "SURVEYPAL.MANAGE.RULES.SET_ANON_MAX_AGE_VALUE";
export const SET_ANON_MAX_AGE_UNIT = "SURVEYPAL.MANAGE.RULES.SET_ANON_MAX_AGE_UNIT";
export const SET_DEL_MAX_AGE_UNIT = "SURVEYPAL.MANAGE.RULES.SET_DEL_MAX_AGE_UNIT";

export const DATA_RULE_TYPE = {
	ANONYMIZATION: "ANONYMIZATION",
	DELETION: "DELETION"
};

export const TASK_STATE = {
	LOADING: "LOADING", // Special state for front-end that indicates that task status is still being fetched
	IDLE: "IDLE",
	RUNNING: "RUNNING",
	FINISHED: "FINISHED"
};

export const LOG_TYPE = {
	USER: "USER",
	WORKER: "WORKER"
};

export function toggleDeletion(enable)
{
	return {
		type: TOGGLE_DELETION,
		enable
	}
}

export function setDeletionMaxAgeUnit(unit)
{
	return {
		type: SET_DEL_MAX_AGE_UNIT,
		unit
	};
}

export function setAnonymizationMaxAgeUnit(unit)
{
	return {
		type: SET_ANON_MAX_AGE_UNIT,
		unit
	};
}


export function setAnonymizationMaxAgeValue(value)
{
	return {
		type: SET_ANON_MAX_AGE_VALUE,
		value
	};
}

export function toggleAnonymization(enable)
{
	return {
		type: TOGGLE_ANONYMIZATION,
		enable
	};
}

export function setDeletionMaxAgeValue(value)
{
	return {
		type: SET_DEL_MAX_AGE_VALUE,
		value
	}
}

export function toggleLoading(loading)
{
	return {
		type: TOGGLE_RULE_LOADER,
		loading
	}
}

export function toggleRuleUpdated(ruleUpdated)
{
	return {
		type: TOGGLE_RULE_UPDATED,
		ruleUpdated
	}
}

export function allAnonymizationTargetsLoaded(allAnonymizationTargets)
{
	return {
		type: ALL_ANONYMIZATION_TARGETS_LOADED,
		allAnonymizationTargets
	}
}

export function selectAnonymizationTarget(anonymizationTarget, select)
{
	return {
		type: SELECT_ANONYMIZATION_TARGET,
		anonymizationTarget,
		select
	};
}

export function setTotalResponseCount(totalCount)
{
	return {
		type: TOTAL_RESPONSE_COUNT,
		totalCount
	}
}

export function rulesLoaded(anonymizationRule = null, anonymizationTargets = [], deletionRule = null)
{
	return {
		type: RULES_LOADED,
		anonymizationRule,
		anonymizationTargets,
		deletionRule
	}
}

export function logLoaded(logEntries)
{
	return {
		type: LOG_LOADED,
		logEntries
	};
}

export function deletionTaskStateLoaded(state, currentCount, expectedCount, deleteAllStarted)
{
	return {
		type: DEL_TASK_STATE_LOADED,
		state,
		currentCount,
		expectedCount,
		deleteAllStarted
	}
}

export function anonymizationTaskStateLoaded(state, currentCount, expectedCount, anonymizeAllStarted)
{
	return {
		type: ANON_TASK_STATE_LOADED,
		state,
		currentCount,
		expectedCount,
		anonymizeAllStarted
	};
}


export function validateDeletionParams(count, unit)
{
	switch(unit)
	{
		case UNITS.MINUTES:
		case UNITS.HOURS:
		case UNITS.YEARS:
		case UNITS.MONTHS:
		case UNITS.WEEKS:
		case UNITS.DAYS:
			return !isNaN(count) && count >= getMinCount(unit);
		default:
			return false;
	}
}

export function validateDeletionParamsMax(count, unit)
{
	switch(unit)
	{
		case UNITS.MINUTES:
		case UNITS.HOURS:
		case UNITS.YEARS:
		case UNITS.MONTHS:
		case UNITS.WEEKS:
		case UNITS.DAYS:
			return !isNaN(count) && count <= getMaxCount(unit);
		default:
			return false;
	}
}

export function loadAllRuleData(surveyId, anonymizableQuestionItems, metaTextPrefix)
{
	return dispatch => {
		dispatch(toggleLoading(true));

		const rulesPromise = api.getRules(surveyId);
		const metaPromise = api.getMetaKeys(surveyId);

		return Promise.all([rulesPromise, metaPromise]).then(responsesArray => {
			const rulesResponse = responsesArray[0];
			const anonymizationRule = rulesResponse.data.find(rule => rule.dataRuleType === DATA_RULE_TYPE.ANONYMIZATION);
			const anonymizationTargets = anonymizationRule ?
			                             anonymizationRule.targets :
														       [];
			if (anonymizationRule)
			{
				delete anonymizationRule.targets;
			}
			const deletionRule = rulesResponse.data.find(rule => rule.dataRuleType === DATA_RULE_TYPE.DELETION);

			// Build anonymization targets for meta and combine them with questions
			const metaKeys = responsesArray[1].data;
			const metaTargetItems = metaKeys
				.filter(key => key !== "language" && key !== "email")
				.sort((a, b) => a.localeCompare(b))
				.map(key => {
					return {
						id: key,
						value: key,
						type: ANONYMIZATION_TARGET.META,
						text: metaTextPrefix + key,
						fixed: true
					};
				});

			metaTargetItems.push({ id: "email", value: "email", fixed: true, text: translator.get("profile-app-myinfo.email"), type: "META" });
			const allAnomymizationTargets = metaTargetItems.concat(anonymizableQuestionItems);

			dispatch(allAnonymizationTargetsLoaded(allAnomymizationTargets));
			dispatch(rulesLoaded(anonymizationRule, anonymizationTargets, deletionRule));
			dispatch(getTotalResponseCount(surveyId));
			return 200;
		}).catch(e => {
			dispatch(toggleLoading(false));
			throw e;
		});
	}
}

export function getMinCount(unit)
{
	switch(unit)
	{
		case UNITS.YEARS:
		case UNITS.MONTHS:
		case UNITS.HOURS:
			return 1;
		case UNITS.WEEKS:
			return 2;
		case UNITS.DAYS:
			return 14;
		case UNITS.MINUTES:
			return 15;
		default:
			return 0;
	}
}

export function getMaxCount(unit)
{
	switch(unit)
	{
		case UNITS.YEARS:
			return 10;
		case UNITS.MONTHS:
			return 120;
		case UNITS.HOURS:
			return 85440;
		case UNITS.WEEKS:
			return 520;
		case UNITS.DAYS:
			return 3560;
		case UNITS.MINUTES:
			return 5126400;
		default:
			return 0;
	}
}

export function getExistingRules(surveyId)
{
	return dispatch => {
		dispatch(toggleLoading(true));
		return api.getRules(surveyId).then(response => {
			const anonymizationRule = response.data.find(rule => rule.dataRuleType === DATA_RULE_TYPE.ANONYMIZATION);
			const deletionRule = response.data.find(rule => rule.dataRuleType === DATA_RULE_TYPE.DELETION);
			const anonymizationTargets = anonymizationRule ?
			                             anonymizationRule.targets :
				                           [];
			dispatch(rulesLoaded(anonymizationRule, anonymizationTargets, deletionRule));
			return response.status;
		}).catch(e => {
			dispatch(toggleLoading(false));
			throw e;
		});
	}
}

export function persistRules(surveyId, deletionRule, anonymizationRule, anonymizationTargets, oldAnonymizationTargets)
{
	return dispatch =>
	{
		dispatch(toggleLoading(true));
		const deletionRuleProm = persistDeletionRule(surveyId, deletionRule);
		const anonymizationRuleProm = persistAnonymizationRule(surveyId, anonymizationRule);

		// Removing "LANGUAGE" tag from targets
		const anonymTargets = anonymizationTargets;
		const updatedAnonymizationTargets = anonymTargets.filter(target => (target.id !== "LANGUAGE"));

		return Promise.all([deletionRuleProm, anonymizationRuleProm]).then(newRules => {
			const deletionRule = newRules[0];
			const anonymizationRule = newRules[1];
			// Only persist anonymization targets if anonymization rule exists. Otherwise they can be simply reset.
			if (anonymizationRule)
			{
				return persistTargets(surveyId, updatedAnonymizationTargets, oldAnonymizationTargets).then(newTargets => {
					dispatch(rulesLoaded(anonymizationRule, newTargets, deletionRule));
					dispatch(toggleLoading(false));
					return true;
				});
			}
			else
			{
				dispatch(rulesLoaded(anonymizationRule, [], deletionRule));
				dispatch(toggleLoading(false));
				return true;
			}
		}).catch(e => {
			console.error("Something went wrong when persisting response rules: " + e);
			dispatch(toggleLoading(false));
			return false;
		});
	};
}

function persistTargets(surveyId, newTargets, oldTargets)
{
	const isNotEmailOrLanguage = target => target.key !== "language";
	const parseKey = o => o.key;
	const targetsToDelete = differenceBy(oldTargets, newTargets, parseKey).filter(isNotEmailOrLanguage);
	const targetsToCreate = differenceBy(newTargets, oldTargets, parseKey).filter(isNotEmailOrLanguage);
	const targetsToRemain = differenceBy(oldTargets, targetsToDelete, parseKey).filter(isNotEmailOrLanguage);
	const targetDeletePromise = Promise.all(targetsToDelete.map(t => api.deleteAnonymizationTarget(surveyId, t.id, "RULE")));

	return targetDeletePromise.then(() => {
		return Promise.all(targetsToCreate.map(t => api.createAnonymizationTarget(surveyId, t.type, t.key, "RULE")));
	}).then(newTargetResponses => {
		return targetsToRemain.concat(newTargetResponses.map(res => res.data));
	});
}

function persistDeletionRule(surveyId, rule)
{
	if (rule.enabled && isNil(rule.id))
	{
		return api.createDeletionRule(surveyId, rule.maxAgeUnit, rule.maxAgeValue).then(res => res.data);
	}
	else if (rule.enabled && !isNil(rule.id))
	{
		return api.updateDeletionRule(surveyId, rule.maxAgeUnit, rule.maxAgeValue).then(res => res.data);
	}
	else if (!rule.enabled && !isNil(rule.id))
	{
		return api.deleteDeletionRule(surveyId).then(_res => null);
	}
	// Nothing to delete or create
	else
	{
		return Promise.resolve(null);
	}
}

function persistAnonymizationRule(surveyId, rule)
{
	if (rule.enabled && isNil(rule.id))
	{
		return api.createAnonymizationRule(surveyId, rule.maxAgeUnit, rule.maxAgeValue).then(res => res.data);
	}
	else if (rule.enabled)
	{
		return api.updateAnonymizationRule(surveyId, rule.maxAgeUnit, rule.maxAgeValue).then(res => res.data);
	}
	else if (!isNil(rule.id))
	{
		return api.deleteAnonymizationRule(surveyId).then(_res => null);
	}
	else
	{
		return Promise.resolve(null);
	}
}

export function loadLog(surveyId)
{
	return dispatch => {
		return api.getLogs(surveyId).then(response => {
			dispatch(logLoaded(response.data));
		});
	};
}

const STATE_POLL_INTERVAL = 5000;

export function getDeletionJobStatus(surveyId)
{
	return dispatch => {
		return api.getDeletionStatus(surveyId).then(response => {
			if(response.data.dataRemovalType === "DELETION")
			{
				// Refresh deletion job status until it's finished
				if (response.data.state !== TASK_STATE.FINISHED)
				{
					dispatch(deletionTaskStateLoaded(response.data.state, response.data.currentCount, response.data.expectedCount, true));
					setTimeout(() => {
						dispatch(getDeletionJobStatus(surveyId));
					}, STATE_POLL_INTERVAL);
				}
				else
				{
					dispatch(deletionTaskStateLoaded(response.data.state, response.data.currentCount, response.data.expectedCount, false));
					return dispatch(fetchAndSetInitialResponses(DEFAULT_SEARCH, surveyId, DEFAULT_SORT_KEY));
				}
			}
			else
			{
				if (response.data.state !== TASK_STATE.FINISHED)
				{
					//dispatch(deletionTaskStateLoaded(null, null, null, false));
					setTimeout(() => {
						dispatch(getDeletionJobStatus(surveyId));
					}, STATE_POLL_INTERVAL);
				}
			}
		}).catch(handleTaskStatusError.bind(null, dispatch, deletionTaskStateLoaded(null, null, null, false)));
	}
}

/* Lauri: getAnonymizationJobState and anonymizeAllResponses look similar to getDeletionJobStatus and deleteAllResponses.
 * Why such repetition? A generic higher order function for both deletion and anonymization looked hard to debug and
 * it made assumptions about the function interfaces it used. There's no way to verify the functions before
 * runtime, so I decided it's much safer to do similar but separate functions for both actions.
 */

export function getAnonymizationJobState(surveyId)
{
	return dispatch => {
		dispatch(getTotalResponseCount(surveyId));
		return api.getAnonymizationStatus(surveyId).then(response => {
			if(response.data.dataRemovalType === "ANONYMIZATION")
			{
				if (response.data.state !== TASK_STATE.FINISHED)
				{
					dispatch(anonymizationTaskStateLoaded(response.data.state, response.data.currentCount, response.data.expectedCount, true));
					setTimeout(() => {
						dispatch(getAnonymizationJobState(surveyId));
					}, STATE_POLL_INTERVAL);
				}
				else
				{
					dispatch(anonymizationTaskStateLoaded(response.data.state, response.data.currentCount, response.data.expectedCount, false));
					return dispatch(fetchAndSetInitialResponses(DEFAULT_SEARCH, surveyId, DEFAULT_SORT_KEY));
				}
			}
			else
			{
				if (response.data.state !== TASK_STATE.FINISHED)
				{
					setTimeout(() => {
						dispatch(getAnonymizationJobState(surveyId));
					}, STATE_POLL_INTERVAL);
				}
			}

		}).catch(handleTaskStatusError.bind(null, dispatch, anonymizationTaskStateLoaded(null, null, null, false)))
	}
}

export function deleteAllResponses(surveyId)
{
	return dispatch => {
		// Send action withs null values first to ensure button is disabled immediately
		//dispatch(deletionTaskStateLoaded(TASK_STATE.RUNNING, null, null, false));
		return api.deleteAll(surveyId).then(response => {
			dispatch(deletionTaskStateLoaded(response.data.state, response.data.currentCount, response.data.expectedCount, true));
			if (response.data.state !== TASK_STATE.FINISHED)
			{
				setTimeout(() => {
					dispatch(getDeletionJobStatus(surveyId));
				}, STATE_POLL_INTERVAL);
			}
			else
			{
				dispatch(deletionTaskStateLoaded(null, null, null, false));
				return dispatch(fetchAndSetInitialResponses(DEFAULT_SEARCH, surveyId, DEFAULT_SORT_KEY));
			}
		}).catch(handleTaskStatusError.bind(null, dispatch, deletionTaskStateLoaded(null, null, null, false)));
	};
}

export function cancelDeleteAllResponses(surveyId)
{
	return dispatch => {
		return api.cancelDeleteAll(surveyId).then(response => {
			dispatch(deletionTaskStateLoaded(TASK_STATE.FINISHED, null, null, false));
		}).catch(handleTaskStatusError.bind(null, dispatch, deletionTaskStateLoaded(null, null, null, false)));
	};
}

export function cancelAnonymizeAllResponses(surveyId)
{
	return dispatch => {
		return api.cancelAnonymizeAll(surveyId).then(response => {
			dispatch(anonymizationTaskStateLoaded(TASK_STATE.FINISHED, null, null, false));
		}).catch(handleTaskStatusError.bind(null, dispatch, anonymizationTaskStateLoaded(null, null, null, false)));
	};
}

export function getTotalResponseCount(surveyId)
{
	return dispatch => {
		return api.getResponseCount(surveyId).then(response => {
			dispatch(setTotalResponseCount(response.data.count));
		}).catch(handleTaskStatusError.bind(null, dispatch, anonymizationTaskStateLoaded(null, null, null, false)));
	};
}

export function anonymizeAllResponses(surveyId, targets)
{
	return dispatch => {
		dispatch(anonymizationTaskStateLoaded(TASK_STATE.RUNNING, null, null, true));
		return api.anonymizeAll(surveyId, targets).then(response => {
			dispatch(anonymizationTaskStateLoaded(response.data.state, response.data.currentCount, response.data.expectedCount, true));
			if (response.data.state !== TASK_STATE.FINISHED)
			{
				setTimeout(() => {
					dispatch(getAnonymizationJobState(surveyId));
				}, STATE_POLL_INTERVAL);
			}
			else
			{
				dispatch(anonymizationTaskStateLoaded(response.data.state, response.data.currentCount, response.data.expectedCount, false));
				return dispatch(fetchAndSetInitialResponses(DEFAULT_SEARCH, surveyId, DEFAULT_SORT_KEY));
			}
		}).catch(handleTaskStatusError.bind(null, dispatch, anonymizationTaskStateLoaded(null, null, null, false)));
	};
}

export function handleTaskStatusError(dispatch, expectedErrorAction, error)
{
	// Task doesn't exist or there's nothing left to handle in the task
	if (error.response && (error.response.status === 204 || error.response.status === 404 || error.response.status === 412))
	{
    console.log("GET error: Task doesn't exist or there's nothing left to handle in the task");
		dispatch(expectedErrorAction);
	}
	// Otherwise it's an undexpected error
	else
	{
		console.error("Something went wrong when getting task state!");
		throw error;
	}
}