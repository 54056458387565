import React from "react";
import ButtonR from "ui/ButtonR";

/**
 * @callback onClick
 */
/**
 * <NextButton/> creates a button that takes the user to the next step.
 *
 * @param onClick {onClick}
 * @param disabled {boolean}
 * @returns {JSX.Element}
 */
const NextButton = ({onClick, disabled}) => {
	return (
		<ButtonR
			text={translator.get("wizard.next")}
			callback={onClick}
			isDisabled={disabled}
			isDefault={true}
			singleUse={false}
		/>
	);
};

export default NextButton;