import axios from "core/axios-sp";
import SavePopup from "views/survey/save-popup";
import {
	setFolders,
	fetchFolders
} from "../../../surveysActions";
import "../../../surveysTypes";

/**
 * finishFolderCreation sets the new folders in the state and hides the loader.
 */
export const finishFolderCreation = () => {
	Surveypal.getLoader().hide();
	Surveypal.store.dispatch(setFolders({
		fetching: true,
		fetched: false,
		folders: [],
		lastRefresh: new Date()
	}));
	fetchFolders();
};

/**
 * requestFolderCreation creates a backend request to create a new folder and then another to move
 * it to the user's current folder (unless it's root (0)).
 *
 * @param name {string}
 */
export const requestFolderCreation = (name) => {
	Surveypal.getLoader().show();

	axios.post("/app/home/folder", {
		name: name
	}).then(creationResponse => {
		const state = Surveypal.store.getState().surveys;

		//If the user is in the root folder, move straight to finishing.
		if (state.list.folder === 0)
		{
			finishFolderCreation();
			return;
		}

		//If the user is in some other folder than root, move the new folder to that folder, then finish.
		axios.put("/app/home/folder/move", {
			id: creationResponse.data.id,
			parentFolderId: state.list.folder
		}).then(moveResponse => {
			finishFolderCreation();
		});
	});
};

/**
 * newFolder displays a popup for the user to create and name a new folder.
 */
export const newFolder = () => {
	new SavePopup(
		(name) => requestFolderCreation(name),
		"newFolder",
		translator.get("newFolder.newFolder"),
		translator.get("newFolder.newFolderText"),
		translator.get("common.create"),
		""
	);
};