import {parseTags} from "../../utils/parseTags";

/**
 * getAllTags collects all tags from non-deleted surveys in lowercase and in alphabetical order.
 *
 * @param surveys {object[]}
 * @returns {string[]}
 */
export const getAllTags = (surveys) => {
	const sorter = (_a, _b) => {
		//Remove double quotes to make sorting prettier
		const a = _a.toLowerCase().replace(/\"/gi, "");
		const b = _b.toLowerCase().replace(/\"/gi, "");

		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	// Return all tags from every non-deleted survey, lowercase and sorted
	return surveys.filter(survey => !survey.deleted)
		.reduce((carry, survey) => {
			const tags = parseTags(survey);
			const tagsToAdd = tags
				.map(tag => tag.toLowerCase())
				.filter(tag => tag.length > 1 && carry.indexOf(tag) === -1);

			if (tagsToAdd.length === 0) return carry;

			return [
				...carry,
				...tagsToAdd
			];
		}, []).sort(sorter);
};