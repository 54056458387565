//React
import React from "react";

//Utilities
import Keycodes from "conf/keycodesCode";

//Style
import "./Search.scss";

/**
 * @callback OnChange
 * @param newValue {string}
 */
/**
 * @callback OnSearch
 * @param value {string}
 */
/**
 * <Search/> renders a search text input and triggers search when pressing enter.
 *
 * @param className {string}
 * @param value {string}
 * @param onChange {OnChange}
 * @param onSearch {OnSearch}
 * @returns {JSX.Element}
 */
const Search = ({className, value, onChange, onSearch}) => {
	const onKeyUp = (key) => {
		if (key === Keycodes.ENTER)
		{
			onSearch(value);
		}
	};

	const classes = `Search transition ${className ? className : ""} ${value === "" ? "empty" : ""}`;

	return (
		<div className={classes}>
			<div className={"sprite search icon"}>{null}</div>
			<input
				value={value}
				type={"text"}
				maxLength={256}
				onChange={e => onChange(e.target.value)}
				onKeyUp={e => onKeyUp(e.key)}
			/>
			<div className={"sprite clear icon"}>{null}</div>
		</div>
	);
}

export default Search;