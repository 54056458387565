import React from "react";
import PopupR from "ui/PopupR";
import PopupRichContent from "ui/PopupRichContent";
import {
	setReportsToDelete,
	setConfirmMassDeletion,
	setExecutingMassDeletion,
	setMassDeletionEnded,
	clearMassDeletionProgress
} from "../../automaticReportingActions";
import {connect} from "react-redux";
import "../../automaticReportingTypes";

/**
 * <MassDeletionEnded/> shows a popup informing the user that deletion is over.
 *
 * @param reportsToDelete {string[]}
 * @param successfulDeletions {string[]}
 * @param failedDeletions {string[]}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const MassDeletionEnded = ({
	reportsToDelete,
	successfulDeletions,
	failedDeletions,
	dispatch
}) => {
	const backToExistingReports = () => {
		dispatch(setReportsToDelete([]));
		dispatch(setConfirmMassDeletion(false));
		dispatch(setExecutingMassDeletion(false));
		dispatch(setMassDeletionEnded(false));
		dispatch(clearMassDeletionProgress());
	};

	const success = successfulDeletions.length + failedDeletions.length === reportsToDelete.length;

	return (
		<PopupR
			classes={success ? "success" : ""}
			title={translator.get("automaticReporting.automaticReporting")}
			hideCancel={true}
			submitText={translator.get("common.ok")}
			submitCallback={backToExistingReports}>
			<PopupRichContent
				title={translator.get("automaticReporting.massDeleteEndedTitle")}>
				{
					success
					?
					<p>{translator.get("automaticReporting.massDeleteFullSuccess")}</p>
					:
					<div>
						<p>{translator.get("automaticReporting.massDeleteSomeSuccess1")}</p>
						<p>{successfulDeletions.join(", ")}</p>
						<p>{translator.get("automaticReporting.massDeleteSomeSuccess2")}</p>
						<p>{failedDeletions.join(", ")}</p>
						<p>{translator.get("automaticReporting.massDeleteSomeSuccess3")}</p>
					</div>
				}
			</PopupRichContent>
		</PopupR>
	);
};

export default connect(state => ({
	reportsToDelete: state.automaticReporting.existingReports.massDelete.reportsToDelete,
	successfulDeletions: state.automaticReporting.existingReports.massDelete.successfulDeletions,
	failedDeletions: state.automaticReporting.existingReports.massDelete.failedDeletions
}))(MassDeletionEnded);