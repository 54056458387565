import React from "react";
import LifeSaverR from "ui/LifeSaverR";
import "../../../../automaticReportingTypes";

/**
 * This component renders a lifesaver to tell the user why their Cron expression is invalid.
 *
 * @param validity {CronValidityWithoutSeconds}
 * @returns {JSX.Element}
 */
const CronAnalysisLifeSaver = ({validity}) => {
	const content = (
		<div>
			<p>{translator.get("cronValidator.errorInColumns")}</p>
			<ul>
				{
					!validity.minutes &&
					<li>{translator.get("cronValidator.errorInMinutes").replace("%n", 1)}</li>
				}
				{
					!validity.hours &&
					<li>{translator.get("cronValidator.errorInHours").replace("%n", 2)}</li>
				}
				{
					!validity.daysOfMonth &&
					<li>{translator.get("cronValidator.errorInDaysOfMonth").replace("%n", 3)}</li>
				}
				{
					!validity.months &&
					<li>{translator.get("cronValidator.errorInMonths").replace("%n", 4)}</li>
				}
				{
					!validity.daysOfWeek &&
					<li>{translator.get("cronValidator.errorInDaysOfWeek").replace("%n", 5)}</li>
				}
				{
					!validity.years &&
					<li>{translator.get("cronValidator.errorInYears").replace("%n", 6)}</li>
				}
			</ul>
		</div>
	);

	return (
		<LifeSaverR
			place={"right"}
			text={content}
		/>
	);
};

export default CronAnalysisLifeSaver;