import React from "react";
import LifeSaver from "ui/LifeSaverR";
import WeekDays from "conf/weekdays";
import {SCHEDULING_MODES} from "../../../CreateNewReport/Schedule/Schedule";
import * as Roles from "conf/roles";

/**
 * This component displays a given Quartz cron expression in a human-readable format.
 *
 * This is not a complete translator, instead this only focuses on the options available
 * when creating a new automatic report.
 *
 * @param expression {string}
 * @returns {JSX.Element}
 */
const Schedule = ({expression}) => {
	const schedulingMode = (() => {
		if (/^[0-9]{1,2} [0-9]{1,2} \? \* [a-zA-Z]{3} \*$/.test(expression)) return SCHEDULING_MODES.WEEKLY;
		if (/^[0-9]{1,2} [0-9]{1,2} (L\-)?[0-9]{1,2} \* \? \*$/.test(expression)) return SCHEDULING_MODES.MONTHLY;
		return SCHEDULING_MODES.ADVANCED;
	})();

	const showCron = Roles.isAtLeast(Surveypal.getUser().getRole(), "support");

	if (schedulingMode === SCHEDULING_MODES.WEEKLY)
	{
		const [minutes, hours, dayOfMonth, month, dayOfWeek, year] = expression.split(" ");

		const isFirstDayMonday = Surveypal.getUser().getFirstDayOfWeek() === WeekDays.MONDAY;
		const weekdayWords = translator.get("Date.days");
		const weekdaysFromCron = dayOfWeek
			.split(",")
			.map(weekdayName => ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].findIndex(weekday => weekday === weekdayName));

		const weekdaysCorrectOrder = (() => {
			if (isFirstDayMonday && weekdaysFromCron.length > 1 && weekdaysFromCron[0] === 0)
				return [...weekdaysFromCron.splice(1, weekdaysFromCron.length), weekdaysFromCron[0]];

			return weekdaysFromCron;
		})();

		const humanReadableWeekdays = weekdaysCorrectOrder.map(weekday => weekdayWords[weekday]).join(", ");

		const time = translator.get("highchart-datetime-labels.minute").replace("%H", hours).replace("%M", minutes.padStart(2, "0"));

		return (
			<>
				{
					translator.get("automaticReporting.humanReadableWeekly")
						.replace("${time}", time)
						.replace("${weekdays}", humanReadableWeekdays)
				}
				{
					showCron &&
					<LifeSaver
						text={expression}
						place={"bottom"}
					/>
				}
			</>
		);
	}

	if (schedulingMode === SCHEDULING_MODES.MONTHLY)
	{
		const [minutes, hours, dayOfMonth, month, dayOfWeek, year] = expression.split(" ");

		const time = translator.get("highchart-datetime-labels.minute").replace("%H", hours).replace("%M", minutes.padStart(2, "0"));

		return (
			<>
				{
					translator.get("automaticReporting.humanReadableMonthly")
						.replace("${time}", time)
						.replace("${day}", dayOfMonth)
				}
				{
					showCron &&
					<LifeSaver
						text={expression}
						place={"bottom"}
					/>
				}
			</>
		);
	}

	if (schedulingMode === SCHEDULING_MODES.ADVANCED) return <>{expression}</>;
};

export default Schedule;