export const SELECT_ORGANIZATION = "CHANGE_ORG_ACTIONS.SELECT_ORGANIZATION";
export const SHOW_WARNING = "CHANGE_ORG_ACTIONS.SHOW_WARNING";

export function selectOrgAction(organization)
{
	return {
		type: SELECT_ORGANIZATION,
		organization
	};
}

export function showWarningAction(warning)
{
	return {
		type: SHOW_WARNING,
		warning: warning
	};
}