import {ACTIONS} from "./automaticReportingActions";
import {TABS} from "./AutomaticReporting";
import {SCHEDULING_MODES} from "./CreateNewReport/Schedule/Schedule";

const initialState = {
	tab: TABS.EXISTING_REPORTS,
	existingReports: {
		fetchables: {
			existingReports: {
				finished: false,
				existingReports: []
			},
			count: {
				loading: true,
				count: undefined,
			}
		},
		selectedReport: undefined,
		massDelete: {
			reportsToDelete: [],
			confirmMassDeletion: false,
			executingMassDeletion: false,
			successfulDeletions: [],
			failedDeletions: [],
			massDeletionEnded: false
		}
	},
	createNewReport: {
		fetchables: {
			filters: {
				loading: true,
				filters: []
			},
			pptxThemes: {
				loading: true,
				pptxThemes: []
			},
			defaultPptxTheme: {
				loading: true,
				defaultPptxTheme: {}
			},
			mergedData: {
				loading: true,
				mergedData: []
			}
		},
		wizardStep: 0,
		finishAvailable: false,
		reportCreatedSuccessfully: false,
		reportCreationError: null,
		formatAndContent: {
			reportName: "",
			allResponses: true,
			filters: [],
			mergedData: ["base"],
			reportFormat: "pptx",
			pptx: {
				theme: undefined
			},
			excel: {
				excel97to2003: false,
				useUniqueIds: false
			},
			csv: {
				useUniqueIds: false,
				charset: "ISO-8859-1"
			},
			spss: {
				useUniqueIds: false,
				stringVariableSize: "dynamic",
				staticStringVariableSize: 32767
			}
		},
		schedule: {
			stepAvailable: false,
			schedulingMode: SCHEDULING_MODES.WEEKLY,
			weekly: {
				weekday: 1
			},
			monthly: {
				dayOfMonth: 1
			},
			advanced: {
				cronExpression: "",
				cronExpressionValidity: {
					withSeconds: {
						valid: false,
						seconds: false,
						minutes: false,
						hours: false,
						daysOfMonth: false,
						months: false,
						daysOfWeek: false,
						years: false,
					},
					withoutSeconds: {
						valid: false,
						minutes: false,
						hours: false,
						daysOfMonths: false,
						months: false,
						daysOfWeek: false,
						years: false
					}
				}
			},
			start: {
				date: new Date(),
				hours: new Date().getHours(),
				minutes: new Date().getMinutes(),
			},
			end: {
				date: (() => { const date = new Date(); date.setDate(date.getDate() + 1); return date;})(),
				hours: new Date().getHours(),
				minutes: new Date().getMinutes(),
			},
			firing: {
				hours: new Date().getHours(),
				minutes: new Date().getMinutes()
			},
			perpetual: true
		},
		destination: {
			stepAvailable: false,
			destinationType: "email",
			email: {
				to: "",
				subject: null,
			},
			ftp: {
				protocol: "SFTP",
				host: "",
				port: 22,
				username: "",
				password: "",
				privateKey: "",
				path: "",
				nameTemplate: ""
			}
		}
	}
};

const automaticReportingReducer = (previousState = initialState, action) => {
	switch (action.type)
	{
		/**
		 * Actions for looking at existing reports.
		 */
		case ACTIONS.SET_REPORT_COUNT:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					fetchables: {
						...previousState.existingReports.fetchables,
						count: action.payload
					}
				}
			};
		case ACTIONS.ADD_EXISTING_REPORTS:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					fetchables: {
						...previousState.existingReports.fetchables,
						existingReports: {
							...previousState.existingReports.fetchables.existingReports,
							existingReports: [
								...previousState.existingReports.fetchables.existingReports.existingReports,
								...action.payload
							]
						}
					}
				}
			};
		case ACTIONS.REMOVE_EXISTING_REPORT:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					fetchables: {
						...previousState.existingReports.fetchables,
						existingReports: {
							...previousState.existingReports.fetchables.existingReports,
							existingReports: previousState.existingReports.fetchables.existingReports.existingReports.filter((report) => report.id !== action.payload)
						}
					}
				}
			};
		case ACTIONS.MARK_EXISTING_REPORTS_FINISHED:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					fetchables: {
						...previousState.existingReports.fetchables,
						existingReports: {
							...previousState.existingReports.fetchables.existingReports,
							finished: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_SELECTED_REPORT:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					selectedReport: action.payload
				}
			}

		/**
		 * Actions for mass deletion.
		 */
		case ACTIONS.SET_REPORTS_TO_DELETE:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					massDelete: {
						...previousState.existingReports.massDelete,
						reportsToDelete: action.payload
					}
				}
			};
		case ACTIONS.SET_CONFIRM_MASS_DELETION:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					massDelete: {
						...previousState.existingReports.massDelete,
						confirmMassDeletion: action.payload
					}
				}
			};
		case ACTIONS.SET_EXECUTING_MASS_DELETION:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					massDelete: {
						...previousState.existingReports.massDelete,
						executingMassDeletion: action.payload
					}
				}
			};
		case ACTIONS.ADD_SUCCESSFUL_DELETION:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					massDelete: {
						...previousState.existingReports.massDelete,
						successfulDeletions: [...previousState.existingReports.massDelete.successfulDeletions, action.payload]
					}
				}
			};
		case ACTIONS.ADD_FAILED_DELETION:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					massDelete: {
						...previousState.existingReports.massDelete,
						failedDeletions: [...previousState.existingReports.massDelete.failedDeletions, action.payload]
					}
				}
			};
		case ACTIONS.SET_MASS_DELETION_ENDED:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					massDelete: {
						...previousState.existingReports.massDelete,
						massDeletionEnded: action.payload
					}
				}
			};
		case ACTIONS.CLEAR_MASS_DELETION_PROGRESS:
			return {
				...previousState,
				existingReports: {
					...previousState.existingReports,
					massDelete: {
						...previousState.existingReports.massDelete,
						successfulDeletions: [],
						failedDeletions: []
					}
				}
			};

		/**
		 * Actions for creating a new report.
		 */
		case ACTIONS.SET_WIZARD_STEP:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					wizardStep: action.payload
				}
			};
		case ACTIONS.SET_SCHEDULE_STEP_AVAILABLE:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						stepAvailable: action.payload
					}
				}
			};
		case ACTIONS.SET_DESTINATION_STEP_AVAILABLE:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						stepAvailable: action.payload
					}
				}
			};
		case ACTIONS.SET_FINISH_AVAILABLE:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					finishAvailable: action.payload
				}
			};
		case ACTIONS.SET_NAME:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						reportName: action.payload
					}
				}
			};
		case ACTIONS.SET_ALL_RESPONSES:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						allResponses: action.payload
					}
				}
			};
		case ACTIONS.SET_SELECTED_FILTERS:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						filters: action.payload
					}
				}
			};
		case ACTIONS.SET_SELECTED_MERGED_DATA:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						mergedData: action.payload
					}
				}
			};
		case ACTIONS.SET_REPORT_FORMAT:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						reportFormat: action.payload
					}
				}
			};
		case ACTIONS.SET_PPTX_THEME:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						pptx: {
							theme: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_EXCEL_97_TO_2003:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						excel: {
							...previousState.createNewReport.formatAndContent.excel,
							excel97to2003: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_EXCEL_UNIQUE_IDS:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						excel: {
							...previousState.createNewReport.formatAndContent.excel,
							useUniqueIds: action.payload
						}
					}
				}

			};
		case ACTIONS.SET_CSV_UNIQUE_IDS:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						csv: {
							...previousState.createNewReport.formatAndContent.csv,
							useUniqueIds: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_CSV_CHARSET:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						csv: {
							...previousState.createNewReport.formatAndContent.csv,
							charset: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_SPSS_UNIQUE_IDS:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						spss: {
							...previousState.createNewReport.formatAndContent.spss,
							useUniqueIds: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_SPSS_STRING_VARIABLE_SIZE:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						spss: {
							...previousState.createNewReport.formatAndContent.spss,
							stringVariableSize: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_SPSS_STATIC_STRING_VARIABLE_SIZE:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					formatAndContent: {
						...previousState.createNewReport.formatAndContent,
						spss: {
							...previousState.createNewReport.formatAndContent.spss,
							staticStringVariableSize: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_SCHEDULING_MODE:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						schedulingMode: action.payload
					}
				}
			};
		case ACTIONS.SET_WEEKDAY:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						weekly: {
							...previousState.createNewReport.schedule.weekly,
							weekday: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_DAY_OF_MONTH:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						monthly: {
							...previousState.createNewReport.schedule.monthly,
							dayOfMonth: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_CRON_EXPRESSION:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						advanced: {
							...previousState.createNewReport.schedule.advanced,
							cronExpression: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_CRON_EXPRESSION_VALIDITY:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						advanced: {
							...previousState.createNewReport.schedule.advanced,
							cronExpressionValidity: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FIRING_HOURS:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						firing: {
							...previousState.createNewReport.schedule.firing,
							hours: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FIRING_MINUTES:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						firing: {
							...previousState.createNewReport.schedule.firing,
							minutes: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_START_DATE:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						start: {
							...previousState.createNewReport.schedule.start,
							date: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_START_HOURS:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						start: {
							...previousState.createNewReport.schedule.start,
							hours: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_START_MINUTES:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						start: {
							...previousState.createNewReport.schedule.start,
							minutes: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_END_DATE:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						end: {
							...previousState.createNewReport.schedule.end,
							date: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_END_HOURS:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						end: {
							...previousState.createNewReport.schedule.end,
							hours: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_END_MINUTES:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						end: {
							...previousState.createNewReport.schedule.end,
							minutes: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_PERPETUAL:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					schedule: {
						...previousState.createNewReport.schedule,
						perpetual: action.payload
					}
				}
			};
		case ACTIONS.SET_DESTINATION_TYPE:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						destinationType: action.payload
					}
				}
			};
		case ACTIONS.SET_EMAIL_TO:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						email: {
							...previousState.createNewReport.destination.email,
							to: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_EMAIL_SUBJECT:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						email: {
							...previousState.createNewReport.destination.email,
							subject: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FTP_PROTOCOL:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						ftp: {
							...previousState.createNewReport.destination.ftp,
							protocol: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FTP_HOST:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						ftp: {
							...previousState.createNewReport.destination.ftp,
							host: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FTP_PORT:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						ftp: {
							...previousState.createNewReport.destination.ftp,
							port: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FTP_USERNAME:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						ftp: {
							...previousState.createNewReport.destination.ftp,
							username: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FTP_PASSWORD:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						ftp: {
							...previousState.createNewReport.destination.ftp,
							password: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FTP_PRIVATE_KEY:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						ftp: {
							...previousState.createNewReport.destination.ftp,
							privateKey: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FTP_PATH:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						ftp: {
							...previousState.createNewReport.destination.ftp,
							path: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FTP_NAME_TEMPLATE:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					destination: {
						...previousState.createNewReport.destination,
						ftp: {
							...previousState.createNewReport.destination.ftp,
							nameTemplate: action.payload
						}
					}
				}
			};
		case ACTIONS.SET_FETCHED_FILTERS:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					fetchables: {
						...previousState.createNewReport.fetchables,
						filters: action.payload
					}
				}
			};
		case ACTIONS.SET_FETCHED_PPTX_THEMES:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					fetchables: {
						...previousState.createNewReport.fetchables,
						pptxThemes: action.payload
					}
				}
			};
		case ACTIONS.SET_FETCHED_DEFAULT_PPTX_THEME:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					fetchables: {
						...previousState.createNewReport.fetchables,
						defaultPptxTheme: action.payload
					}
				}
			};
		case ACTIONS.SET_FETCHED_MERGED_DATA:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					fetchables: {
						...previousState.createNewReport.fetchables,
						mergedData: action.payload
					}
				}
			};
		case ACTIONS.SET_REPORT_CREATED_SUCCESSFULLY:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					reportCreatedSuccessfully: action.payload,
					reportCreationError: null
				}
			};
		case ACTIONS.SET_REPORT_CREATION_ERROR:
			return {
				...previousState,
				createNewReport: {
					...previousState.createNewReport,
					reportCreatedSuccessfully: false,
					reportCreationError: action.payload
				}
			};

		/**
		 * General actions.
		 */
		case ACTIONS.CLEAR_AUTOMATIC_REPORTING_STATE:
			return initialState;
		case ACTIONS.CLEAR_NEW_REPORT:
			return {
				...previousState,
				createNewReport: {
					...initialState.createNewReport,
					fetchables: previousState.createNewReport.fetchables
				}
			};
		case ACTIONS.SET_TAB:
			return {
				...previousState,
				tab: action.payload
			};
		default:
			return previousState;
	}
};

export default automaticReportingReducer;