import axios from "axios";
import {
	setSurveys,
	setFolders,
	toggleMoveToFolderPopup,
	fetchSurveys,
	fetchFolders
} from "../../../surveysActions";

/**
 * finishMove refetches the new state of surveys and folders from backend.
 */
const finishMove = () => {
	Surveypal.getLoader().hide();
	Surveypal.store.dispatch(toggleMoveToFolderPopup(false));

	const lastRefresh = new Date();
	Surveypal.store.dispatch(setSurveys({
		fetching: true,
		fetched: false,
		surveys: [],
		lastRefresh
	}));
	Surveypal.store.dispatch(setFolders({
		fetching: true,
		fetched: false,
		folders: [],
		lastRefresh
	}));
	fetchSurveys();
	fetchFolders();
};

/**
 * createFolderMovePromise returns an axios promise for moving a folder.
 *
 * @param folderId {number} Id of the folder to be moved
 * @param targetFolderId {number}
 * @returns {Promise<AxiosResponse<any>>}
 */
const createFolderMovePromise = (folderId, targetFolderId) => {
	if (targetFolderId === 0)
	{
		return axios.put("/app/home/folder/move", {
			id: folderId
		});
	}

	return axios.put("/app/home/folder/move", {
		id: folderId,
		parentFolderId: targetFolderId
	});
};

/**
 * createSurveyMovePromise creates a promise for adding selected surveys into a folder.
 *
 * The survey move will not be requested if no surveys are selected, or if the target folder is root (id 0). This is
 * because the way to move a survey to root is not to move ite there, but to remove it from folders.
 *
 * @param selectedSurveyIds {number[]}
 * @param targetFolderId {number}
 * @returns {Promise<AxiosResponse<any>>[]}
 */
export const createSurveyMovePromise = (selectedSurveyIds, targetFolderId) => {
	if (selectedSurveyIds.length === 0 || targetFolderId === 0) return [];

	return [axios.put("/app/home/folder/survey", {
		id: targetFolderId,
		surveys: selectedSurveyIds
	})];
};

/**
 * createSurveyRemovePromise creates a promise for removing selected surveys from a folder.
 *
 * The promise is not created if the source folder is the root (id 0), as surveys are not removed from the root.
 *
 * @param selectedSurveyIds {number[]}
 * @param currentFolderId {number}
 * @returns {Promise<AxiosResponse<any>>[]}
 */
export const createSurveyRemovePromise = (selectedSurveyIds, currentFolderId) => {
	if (selectedSurveyIds.length === 0 || currentFolderId === 0) return [];

	return [axios.delete("/app/home/folder/survey", {
		params: {
			json: {
				id: currentFolderId,
				surveys: selectedSurveyIds
			}
		}
	})];
};

/**
 * runMove gathers the surveys and folders to move, and then moves them.
 *
 * @param targetFolderId {number}
 */
export const runMove = (targetFolderId) => {
	Surveypal.getLoader().show();
	const state = Surveypal.store.getState().surveys;
	const selectedSurveyIds = state.list.selectedSurveyIds;
	const selectedFolderIds = state.list.selectedFolderIds;
	const currentFolderId = state.list.folder;

	const surveyMovePromise = createSurveyMovePromise(selectedSurveyIds, targetFolderId);
	const surveyRemovePromise = createSurveyRemovePromise(selectedSurveyIds, currentFolderId);
	const folderMovePromises = selectedFolderIds.map(folderId => createFolderMovePromise(folderId, targetFolderId));
	const combinedPromises = [...surveyMovePromise, ...surveyRemovePromise, ...folderMovePromises];

	axios.all(combinedPromises).then(axios.spread((...responses) => {
		finishMove();
	})).catch(axios.spread((...responses) => {
		finishMove();
	}));
};

/**
 * moveToFolder toggles the folder selection popup.
 */
export const moveToFolder = () => {
	Surveypal.store.dispatch(toggleMoveToFolderPopup(true));
};