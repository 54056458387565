import React from "react";
import WeekDays from "conf/weekdays";
import {DatePicker, DayOfWeek, initializeIcons, registerIcons} from "@fluentui/react";
import "ui/fluentui-datepicker.scss";
import "./DateSelection.scss"

/**
 * @callback onChangeDate
 * @param newDate {Date}
 */
/**
 * DateSelection renders a block with a datepicker.
 *
 * @param value {Date}
 * @param onChangeDate {onChangeDate}
 * @returns {JSX.Element}
 */
const DateSelection = ({
	value,
	onChangeDate
}) => {
	initializeIcons();
	registerIcons({
		icons: {
			"CalendarIcon": (
				<svg viewBox="0 0 24 24">
					<g fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.91">
						<path d="M1.48 3.37h21.04v4.78H1.48zM1.48 8.15h21.04V22.5H1.48zM6.26.5v4.78M12 .5v4.78M17.74.5v4.78"></path>
					</g>
				</svg>
			)
		}
	});

	const firstDayOfWeek = Surveypal.getUser().getFirstDayOfWeek() === WeekDays.SUNDAY ? DayOfWeek.Sunday : DayOfWeek.Monday;
	const dateStrings = {
		months: translator.get("Date.months"),
		shortMonths: translator.get("Date.monthsShort"),
		days: translator.get("Date.days"),
		shortDays: translator.get("Date.daysShort"),
		goToToday: translator.get("datepicker.today"),
		prevMonthAriaLabel: translator.get("datepicker.labelMonthPrev"),
		nextMonthAriaLabel: translator.get("datepicker.labelMonthNext"),
		prevYearAriaLabel: translator.get("datepicker.labelYearPrev"),
		nextYearAriaLabel: translator.get("datepicker.labelYearNext"),
		closeButtonAriaLabel: translator.get("datepicker.close")
	};
	const formatDate = (date) => translator.get("Date.fluentUIDateFormat")
		.replace("%d", date.getDate())
		.replace("%m", date.getMonth() + 1)
		.replace("%Y", date.getFullYear());

	return (
		<div className={"dateSelection"}>
			<DatePicker
				className={"date-picker"}
				value={value}
				allowTextInput={false}
				strings={dateStrings}
				firstDayOfWeek={firstDayOfWeek}
				textField={{iconProps: {iconName: "CalendarIcon"}}}
				calendarProps={{className: "date-picker-calendar"}}
				onSelectDate={onChangeDate}
				formatDate={formatDate}
			/>
		</div>
	);
};

export default DateSelection;