import React from "react";
import CheckMark from "ui/Icons/CheckMark";
import "./Stepper.scss";

/**
 * @typedef Step
 * @type {object}
 * @property className {string}
 * @property content {JSX.Element[]}
 * @property available {boolean}
 * @property title {string}
 */
/**
 * <Stepper/> creates a UI element that displays all the steps of the wizard and shows user's progress.
 *
 * @param currentStep {number}
 * @param embedded {boolean}
 * @param steps {Step[]}
 * @returns {JSX.Element}
 */
const Stepper = ({
	currentStep,
	embedded,
	steps
}) => {
	return (
		<div className={`stepper ${embedded ? "embedded" : "standAlone"}`}>
			{
				steps.map((step, index) => {
					const stepDone = index < currentStep;
					const onThisStep = index === currentStep;
					const stepClassName = `step title ${stepDone && !onThisStep ? "done" : "not-done"} ${onThisStep ? "current-step" : ""}`;

					return (
						<div className={stepClassName} key={index}>
							<div className={"icon"}>
								{
									stepDone
									?
									<CheckMark scale={0.17}/>
									:
									<div>{index+1}</div>
								}
							</div>
							<div className={"step-title"}>
								{step.title}
							</div>
						</div>
					);
				}
			)}
		</div>
	);
};

export default Stepper;