define("conf/plans", function (require)
{
	var Class = require("core/class");

	var _plans = {
		"free":       {"features": {"export": true, "styleeditor": true, "automation": true, "expertphonesupport": false, "livechatsupport": true, "priorityemailsupport": false, "imports": true, "showpoweredbysurveypal": true, "sharing": true, "salesforce": true, "api": true, "centralizedbilling": false, "specialquestionelement": true, "administration": false, "emailsupport": true}, "prices": {"month": 0, "year": 0, "currency": "USD"}},
		"premium":    {"features": {"export": true, "styleeditor": true, "automation": true, "expertphonesupport": false, "livechatsupport": true, "priorityemailsupport": false, "imports": true, "showpoweredbysurveypal": true, "sharing": true, "salesforce": true, "api": true, "centralizedbilling": false, "specialquestionelement": true, "administration": false, "emailsupport": true}, "prices": {"month": 60, "year": 480, "currency": "USD"}},
		"enterprise": {"features": {"export": true, "styleeditor": true, "automation": true, "expertphonesupport": true, "livechatsupport": true, "priorityemailsupport": true, "imports": true, "showpoweredbysurveypal": true, "sharing": true, "salesforce": true, "api": true, "centralizedbilling": true, "specialquestionelement": true, "administration": true, "emailsupport": true}, "prices": {"month": "0", "year": "0", "currency": "USD"}}};

	return Class.extend(
	{
		get: function(id)
		{
			var plan = _plans[id] || _plans["free"];
			return plan;
		},

		format: function(id, branded)
		{
			var str = branded ? "Surveypal " : "";
			str += translator.get("plans." + id);
			return str;
		},

		period: function(period)
		{
			if(period == "month")
			{
				return translator.get("plans.monthly");
			}
			return translator.get("plans.yearly");
		},

		getPrice: function(id, period)
		{
			var plan = this.get(id);
			return plan.prices[period];
		},

		getCurrency: function(id)
		{
			var plan = this.get(id);
			return plan.prices.currency;
		},

		getFeatures: function(id)
		{
			var plan = this.get(id);
			return Object.clone(plan.features);
		},

		list: function()
		{
			return Object.keys(_plans);
		}
	});
});

