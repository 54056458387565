define("conf/keycodesCode", function(require)
{
	return {
		"ENTER": "Enter",
		"ESC": "Escape",
		"DELETE": "Delete",
		"BACKSPACE": "Backspace",
		"TAB": 9,
		"F1": 112,
		"UP": "ArrowUp",
		"DOWN": "ArrowDown",
		"SPACE": "Space",
		"LEFT": "ArrowLeft",
		"RIGHT": "ArrowRight",
		"ZERO": "Digit0",
		"NINE": "Digit9",
		"NUMPAD_ZERO": "Numpad0",
		"NUMPAD_NINE": "Numpad9",
		"DOT": "Period",
	};
});