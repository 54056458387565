/**
 * Returns a human-readable date in the correct locale date format.
 *
 * This component is to reduce the need to implement date formatting logic again and again everywhere.
 *
 * @param date {string|number}
 * @returns {string}
 */
const DateAndTime = ({date}) => {
	if (date === undefined || (typeof date === "string" && date.trim() === "")) return "";

	const dateObject = new Date(
		typeof date === "number"
		?
		date
		:
		date.split("+")[0]
	);

	const minutes = dateObject.getMinutes().toString().padStart(2, "0");
	const hours12 = (() => {
		const hours = dateObject.getHours() % 12;

		if (hours === 0) return 12;

		return hours;
	})();
	const hours24 = dateObject.getHours()
	const halfOfDay = hours24 >= 12 ? translator.get("Date.PM") : translator.get("Date.AM");
	const day = dateObject.getDate();
	const month = dateObject.getMonth() + 1;
	const paddedMonth = month.toString().padStart(2, "0");
	const year = dateObject.getFullYear();

	return translator.get("Date.fullDate")
		.replace("MM", minutes)
		.replace("h", hours12)
		.replace("H", hours24)
		.replace("TT", halfOfDay)
		.replace("d", day)
		.replace("mm", paddedMonth)
		.replace("m", month)
		.replace("yyyy", year);
};

export default DateAndTime;