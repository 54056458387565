import clone from "lodash.clonedeep";
import { VISIBILITY_LEVELS, REASON_OBJS, FEATURES } from "conf/visibility-constants";
import ROLES from "conf/roles";

// Default rule for all features - requires that the feature is licensed
const LICENSE_REQUIRED = {
	priority: 2,
	reason: REASON_OBJS.LICENSE.key,
	result: VISIBILITY_LEVELS.DISABLED,
	text: REASON_OBJS.LICENSE.text
};
const DEFAULT_RULES = [LICENSE_REQUIRED];

// Same as license required rule but with a custom DS related text
const LICENSE_REQUIRED_DS_TEXT = {
	priority: 2,
	reason: REASON_OBJS.LICENSE.key,
	result: VISIBILITY_LEVELS.DISABLED,
	text: "visibility-rules.oldForms"
};

// License rule that fully hides the target
const LICENSE_REQUIRED_HIDDEN = {
	priority: 2,
	reason: REASON_OBJS.LICENSE.key,
	result: VISIBILITY_LEVELS.HIDDEN,
	text: REASON_OBJS.LICENSE.text
};

// Rule that invalidates inaccessible features
const INACCESSIBLE = {
	priority: 1,
	reason: REASON_OBJS.INACCESSIBILITY.key,
	result: VISIBILITY_LEVELS.DISABLED,
	text: REASON_OBJS.INACCESSIBILITY.text
};

// Rule that checks if user's role is at least Sales
const AT_LEAST_SALES = {
	priority: 1,
	reason: REASON_OBJS.ROLE.key,
	result: VISIBILITY_LEVELS.HIDDEN,
	text: REASON_OBJS.ROLE.text,
	value: ROLES.Sales
};

// Test rule for the link functionality
const LINK_TEST_RULE = {
	link: "https://www.surveypal.com",
	priority: 2,
	reason: REASON_OBJS.LICENSE.key,
	result: VISIBILITY_LEVELS.DISABLED,
	text: "This here is a test for {{the link}} functionality."
};

// Note: Rule priorities affect which rules are displayed as the reason for disabling a feature.
// Higher number is higher priority. Priority only affects the human-readable reason text, not the way rules affect
// the decision whether a feature will be hidden, disabled or usable.
export const CUSTOM_RULES = {
	// Build
	ANSWER_PATHS: [LICENSE_REQUIRED_DS_TEXT],
	DATE_PICKER: [LICENSE_REQUIRED_DS_TEXT],
	ENFORCE_ACCESSIBILITY: [LICENSE_REQUIRED_DS_TEXT],
	/*RANKORDER_ELEMENT: [LICENSE_REQUIRED_HIDDEN],*/

	// CURRENT LOCAL FEATURES - Will be added as features in C-1316
	SLIDER_HANDLE: [LICENSE_REQUIRED_HIDDEN],

	// Feature for unit tests
	UNIT_TEST_FEATURE: [LICENSE_REQUIRED_DS_TEXT, AT_LEAST_SALES, LINK_TEST_RULE],
	INACCESSIBLE_UNIT_TEST_FEATURE: [LICENSE_REQUIRED, INACCESSIBLE]
};

export const INSIDER_ACCESS_MAP = {
	/*RANKORDER_ELEMENT: "F-462specialcode7581"*/
}

// Get an object containing all the rules.
// If feature has no custom rules defined, DEFAULT_RULES is used.
function getAllVisibilityRules()
{
	const featureKeys = Object.keys(FEATURES);
	return featureKeys.reduce((ruleObj, featureKey) => {
		ruleObj[featureKey] = CUSTOM_RULES[featureKey] ?
			clone(CUSTOM_RULES[featureKey]) :
			clone(DEFAULT_RULES);
		return ruleObj;
	}, {});
}

export const ALL_VISIBILITY_RULES = getAllVisibilityRules();