import React from "react";
import SplitButtonItem from "./SplitButtonItem/SplitButtonItem";
import "./SplitButtonMenu.scss";

/**
 * SplitButtonMenu is the menu for the Split Button.
 *
 * @param items {SplitButtonItem[][]}
 * @returns {JSX.Element}
 */
const SplitButtonMenu = ({items}) => {
	return (
		<div className={"SplitButtonMenu"}>
			{
				items.map((itemGroup) => {
					const key = itemGroup.reduce((carry, item) => `${carry}${item.text}`, "SplitButtonItemGroup");

					return (
						<div
							key={key}
							className={"SplitButtonItemGroup"}>
							{
								itemGroup.map((item) => (
									<SplitButtonItem
										key={`SplitButton-Item-${item.text}`}
										disabled={item.disabled}
										onClick={item.onClick}>
										{item.text}
									</SplitButtonItem>
								))
							}
						</div>
					);
				})
			}
		</div>
	);
};

export default SplitButtonMenu;