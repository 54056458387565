define("utils/tab", function(require)
{
	var Class = require("core/class");

	return Class.extend(
	{
		_instance: null,

		init: function(path)
		{
			this.open(path);
		},

		open: function(path)
		{
			var url = path + (path.indexOf("#") === -1 ? "#" : "&");
			this._instance = window.open(url, "_blank"); // uuid to guarantee unique frame name
		}
	});
});