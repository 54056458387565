import React from "react";
import {connect} from "react-redux";
import "./SortIndicator.scss";
import "../../../surveysTypes";

/**
 * SortIndicator shows an up/down arrow or no arrow at all depending on which column is sorted.
 *
 * @param sort {Sort}
 * @param column {string}
 * @returns {JSX.Element}
 */
const SortIndicator = ({
	sort,
	column
}) => {
	if (sort.column !== column)
	{
		//return <span className={"SortIndicator"}>&nbsp;</span>;
		return <></>;
	}

	if (sort.direction === -1)
	{
		return <span className={"SortIndicator"}>▼</span>;
	}

	return <span className={"SortIndicator"}>▲</span>;
};

export default connect(state => ({
	sort: state.surveys.list.sort
}))(SortIndicator);