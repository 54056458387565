// Required parameter shape can be seen in ui/Parameters/Parameters
const PARAM_TYPE = require("ui/Parameters/paramType").default;
const clone = require("lodash.clonedeep");
const getLanguageItems = require("utils/languageItems").default;

export const TEMPLATE_TYPE = {
	NO_TEMPLATE: "NO_TEMPLATE",
	ORGANIZATION: "ORGANIZATION",
	GLOBAL: "GLOBAL"
};

const TYPE_OPTION_NO_TEMPLATE = {
	value: TEMPLATE_TYPE.NO_TEMPLATE,
	text: "template-popup.stateHidden",
	fixed: false
};
const TYPE_OPTION_ORGANIZATION = {
	value: TEMPLATE_TYPE.ORGANIZATION,
	text: "template-popup.stateRestricted",
	fixed: false
};
const TYPE_OPTION_GLOBAL = {
	value: TEMPLATE_TYPE.GLOBAL,
	text: "template-popup.statePublic",
	fixed: false
};

export const TYPE_PARAMS = [
	{
		key: "type",
		type: PARAM_TYPE.SELECTION,
		label: "template-popup.stateLabel",
		items: [[TYPE_OPTION_NO_TEMPLATE, TYPE_OPTION_ORGANIZATION, TYPE_OPTION_GLOBAL]]
	}
];
export const TYPE_PARAMS_WITHOUT_GLOBAL = [
	{
		key: "type",
		type: PARAM_TYPE.SELECTION,
		label: "template-popup.stateLabel",
		items: [[TYPE_OPTION_NO_TEMPLATE, TYPE_OPTION_ORGANIZATION]]
	}
];
export const TYPE_PARAMS_WITHOUT_NO_TEMPLATE = [
	{
		key: "type",
		type: PARAM_TYPE.SELECTION,
		label: "template-popup.stateLabel",
		items: [[TYPE_OPTION_ORGANIZATION, TYPE_OPTION_GLOBAL]]
	}
];
export const TEMPLATE_PARAMS = [
	{
		key: "title",
		type: PARAM_TYPE.STRING,
		label: "template-popup.nameLabel",
		validate: notEmpty,
		tooltip: "template-popup.templTitleTooltip"
	},
	{
		key: "description",
		type: PARAM_TYPE.BIG_STRING,
		label: "template-popup.descriptionLabel",
		tooltip: "template-popup.templDescriptionTooltip"
	},
	{
		key: "language",
		type: PARAM_TYPE.SELECTION,
		items: [], // Items must be populated dynamically based on UI language
		label: "template-popup.localeLabel",
		filterable: true
	}
];

export function getTemplateParamsWithLanguages(userLocale)
{
	const clonedParams = clone(TEMPLATE_PARAMS);
	const localeParam = clonedParams.find(p => p.key === "language");
	localeParam.items = [getLanguageItems(userLocale)];
	return clonedParams;
}

function notEmpty(str)
{
	return str && str.length >= 1 ?
		{valid: true} :
		{valid: false, error: "profile-app-sso.emptyError"}
}