import isNil from "lodash.isnil";
import buildObjectKey from "../buildObjectKey";
import { getMeta } from "./api";

export const ITEM_KEYS = {
  ID: "id",
  SOURCE: "source",
  IDENTIFIER: "identifier",
  EMAIL: "email",
  LANGUAGE: "language",
  META: "meta"
};

export const RESERVED_META_KEYS = [ITEM_KEYS.EMAIL, ITEM_KEYS.LANGUAGE];

// Class that takes care of fetching batches of meta data and caching them for reuse.
export default class MetaFetcher {
  constructor()
  {
    this.cache = {};
  }

  clearCache()
  {
    this.cache = {};
  }

  static buildCacheKey(surveyId, limit, offset, keyword, orderBy, sort, metaKey)
  {
    const paramObj = {
      surveyId,
      limit,
      offset,
      keyword,
      orderBy,
      sort,
    };
    if (metaKey)
    {
      paramObj["metaKey"] = metaKey;
    }
    return buildObjectKey(paramObj);
  }

  hasCached(surveyId, limit, offset, keyword, orderBy, sort, metaKey)
  {
    const cacheKey = MetaFetcher.buildCacheKey(surveyId, limit, offset, keyword, orderBy, sort, metaKey);
    return !isNil(this.cache[cacheKey]);
  }

  fetch(surveyId, limit, offset, keyword, orderBy, sort, metaKey = null)
  {
    const cacheKey = MetaFetcher.buildCacheKey(surveyId, limit, offset, keyword, orderBy, sort, metaKey);
    if (!isNil(this.cache[cacheKey]))
    {
      return Promise.resolve(this.cache[cacheKey]);
    }
    else
    {
      return getMeta(surveyId, limit, offset, keyword, orderBy, sort, metaKey).then(res => {
        const metaItems = res.data.map(buildLocalMetaItem);
        this.cache[cacheKey] = metaItems;
        return metaItems;
      });
    }
  }
};

// Exported for testability
export function buildLocalMetaItem(recipient)
{
  const identifier = recipient.meta && recipient.meta[ITEM_KEYS.EMAIL] !== undefined ?
    recipient.meta[ITEM_KEYS.EMAIL] :
    "";
  const baseItem = {
    [ITEM_KEYS.ID]: recipient.answerId,
    [ITEM_KEYS.SOURCE]: recipient.answerSource ? recipient.answerSource :
      (recipient.answerSourceJson ? recipient.answerSourceJson : {channel: ""}),
    [ITEM_KEYS.IDENTIFIER]: identifier,
    [ITEM_KEYS.EMAIL]: identifier,
    [ITEM_KEYS.META]: {}
  };
  if (typeof recipient.meta === "object")
  {
    Object.keys(recipient.meta).forEach(key => {
      if (RESERVED_META_KEYS.every(rKey => rKey !== key))
      {
        baseItem[ITEM_KEYS.META][key] = recipient.meta[key];
      }
    });
  }
  return baseItem;
}