import PARAM_TYPE from "ui/Parameters/paramType";

export const CONFIG_TYPE = {
	OPENID_CONNECT: "oidc"
};

export const PARAM_KEY = {
	CLASS: "class",
	NAME: "name",
	TENANT_ID: "tenantId",
	TYPE: "type",
	SUBDOMAIN: "subdomain",
	AUTHORITY: "authority",
	AUTHENTICATION_ENDPOINT: "authEndpoint",
	CLIENT_ID: "clientId",
	CLIENT_SECRET: "clientSecret",
	ENABLED: "enabled",
	TOKEN_ENDPOINT: "tokenEndpoint",
	USER_INFO_ENDPOINT: "userInfoEndpoint"
};

export const PASSWORD_PARAM_KEY = {
	ID: "id",
	ENABLED: "enabled",
	MINIMUM_LENGTH: "minPasswordLength",
	ORGANIZATION_ID: "organizationId",
	LOWERCASE_LETTERS: "requireLowercaseLetters",
	UPPERCASE_LETTERS: "requireUppercaseLetters",
	NUMBERS: "requireNumbers",
	SPECIAL_CHARACTERS: "requireSpecialCharacters",
	BAN_COMMON_PASSWORDS: "banCommonPasswords"
}

export const NAME_CONSISTENCE = {
	AZURE_AD: "profile-app-sso.azureAd",
	OKTA: "profile-app-sso.oktaConf",
	CUSTOM: "profile-app-sso.customConf",
	AAD_TENANT: "profile-app-sso.azureAdTenantRegistration",
	PASSWORD: "profile-app-sso.password"
};

export const CONFIGURATION_CLASSES = {
	AZURE_AD: "AZURE_AD",
	OKTA: "OKTA",
	CUSTOM: "CUSTOM",
	AAD_TENANT: "AAD_TENANT",
	OIDC: "OIDC",
	PASSWORD: "PASSWORD"
};

function notEmpty(str)
{
	return str && str.length >= 1 ?
		     {valid: true} :
		     {valid: false, error: "profile-app-sso.emptyError"}
}

function isBoolean(variable)
{
	return typeof variable === PARAM_TYPE.BOOLEAN ?
		{valid: true} :
		{valid: false, error: "profile-app-sso.mustBeBoolean"};
}

function isNumber(variable)
{
	return typeof variable === PARAM_TYPE.NUMBER ?
		{valid: true} :
		{valid: false, error: "profile-app-sso.mustBeNumber"};
}

function validateTenantId(tenantId)
{
	let alpha = /^[a-z0-9\-]+$/i;

	if(tenantId.length < 256)
	{
		if(tenantId.match(alpha))
		{
			return {valid: true}
		}
	}
	return {valid: false, error: "profile-app-sso.tenant-id-wrong"}
}

export const PARAMS = {
	[CONFIG_TYPE.OPENID_CONNECT]: [
		{
			key: PARAM_KEY.ENABLED,
			type: PARAM_TYPE.BOOLEAN,
			value: true,
			label: "profile-app-sso.paramEnabled"
		},
		{
			key: PARAM_KEY.NAME,
			type: PARAM_TYPE.STRING,
			value: "",
			label: "profile-app-sso.paramName",
			tooltip: "profile-app-sso.paramNameTooltip",
			validate: notEmpty
		},
		{
			key: PARAM_KEY.TENANT_ID,
			type: PARAM_TYPE.STRING,
			value: "",
			label: "profile-app-sso.paramTenantId",
			tooltip: "profile-app-sso.paramTenantIdTooltip",
			// Values of authority and authentication endpoint can depend on this parameter's value
			children: [PARAM_KEY.AUTHORITY, PARAM_KEY.AUTHENTICATION_ENDPOINT],
			validate: notEmpty
		},
		{
			key: PARAM_KEY.AUTHORITY,
			type: PARAM_TYPE.AUTO_STRING,
			value: "",
			label: "profile-app-sso.paramAuthority",
			validate: notEmpty,
			generateVal: conf => conf[PARAM_KEY.TENANT_ID] && conf[PARAM_KEY.TENANT_ID].length > 0 ?
			                     `https://login.microsoftonline.com/${conf[PARAM_KEY.TENANT_ID]}/` :  "",
		},
		{
			key: PARAM_KEY.AUTHENTICATION_ENDPOINT,
			type: PARAM_TYPE.AUTO_STRING,
			value: "",
			label: "profile-app-sso.paramAuthEndpoint",
			validate: notEmpty,
			generateVal: conf => conf[PARAM_KEY.TENANT_ID] && conf[PARAM_KEY.TENANT_ID].length > 0 ?
			                     `https://login.microsoftonline.com/${conf[PARAM_KEY.TENANT_ID]}/oauth2/authorize` : "",
		},
		{
			key: PARAM_KEY.CLIENT_ID,
			type: PARAM_TYPE.STRING,
			value: "",
			label: "profile-app-sso.paramClientId",
			tooltip: "profile-app-sso.paramClientIdTooltip",
			validate: notEmpty
		},
		{
			key: PARAM_KEY.CLIENT_SECRET,
			type: PARAM_TYPE.STRING,
			value: "",
			label: "profile-app-sso.paramClientSecret",
			tooltip: "profile-app-sso.paramClientSecretTooltip",
			validate: notEmpty,
			hideIfEmpty: true
		}
	]
};

export const COMMON_PARAMS = [
	{
		key: PARAM_KEY.NAME,
		type: PARAM_TYPE.STRING,
		value: "",
		label: "profile-app-sso.paramName",
		tooltip: "profile-app-sso.paramNameTooltip",
		validate: notEmpty
	},
	{
		key: PARAM_KEY.CLIENT_ID,
		type: PARAM_TYPE.STRING,
		value: "",
		label: "profile-app-sso.paramClientId",
		tooltip: "single-sign-on.client-id-tooltip",
		validate: notEmpty
	},
	{
		key: PARAM_KEY.CLIENT_SECRET,
		type: PARAM_TYPE.STRING,
		value: "",
		label: "profile-app-sso.paramClientSecret",
		tooltip: "profile-app-sso.paramClientSecretTooltip",
		validate: notEmpty,
		hideIfEmpty: true
	}
];

export const TENANT_ID = [{
	key: PARAM_KEY.TENANT_ID,
	type: PARAM_TYPE.STRING,
	value: "",
	label: "profile-app-sso.paramTenantId",
	tooltip: "profile-app-sso.paramTenantIdTooltip",
	children: [PARAM_KEY.TOKEN_ENDPOINT, PARAM_KEY.USER_INFO_ENDPOINT, PARAM_KEY.AUTHENTICATION_ENDPOINT],
	validate: validateTenantId
}];

export const SUBDOMAIN = [{
	key: PARAM_KEY.SUBDOMAIN,
	type: PARAM_TYPE.STRING,
	value: "",
	label: "single-sign-on.subdomain",
	tooltip: "single-sign-on.subdomain-tooltip",
	children: [PARAM_KEY.TOKEN_ENDPOINT, PARAM_KEY.USER_INFO_ENDPOINT, PARAM_KEY.AUTHENTICATION_ENDPOINT],
	validate: notEmpty
}];

export const PASSWORD_CONFIGURATION_PARAMS = {
	[CONFIG_TYPE.OPENID_CONNECT]: [
		{
			key: PASSWORD_PARAM_KEY.ID,
			type: PARAM_TYPE.NUMBER,
			value: null,
			label: "profile-app-sso.passwordConfigurationId",
			validate: isNumber
		},
		{
			key: PASSWORD_PARAM_KEY.ENABLED,
			type: PARAM_TYPE.BOOLEAN,
			value: null,
			label: "profile-app-sso.passwordEnabled",
			validate: isBoolean
		},
		{
			key: PASSWORD_PARAM_KEY.MINIMUM_LENGTH,
			type: PARAM_TYPE.NUMBER,
			value: null,
			label: "profile-app-sso.passwordSettingMinimumLength",
			validate: isNumber
		},
		{
			key: PASSWORD_PARAM_KEY.ORGANIZATION_ID,
			type: PARAM_TYPE.NUMBER,
			value: null,
			label: "profile-app-sso.organizationId",
			validate: isNumber
		},
		{
			key: PASSWORD_PARAM_KEY.LOWERCASE_LETTERS,
			type: PARAM_TYPE.BOOLEAN,
			value: null,
			label: "profile-app-sso.passwordSettingLowercase",
			validate: isBoolean
		},
		{
			key: PASSWORD_PARAM_KEY.UPPERCASE_LETTERS,
			type: PARAM_TYPE.BOOLEAN,
			value: null,
			label: "profile-app-sso.passwordSettingUppercase",
			validate: isBoolean
		},
		{
			key: PASSWORD_PARAM_KEY.NUMBERS,
			type: PARAM_TYPE.BOOLEAN,
			value: null,
			label: "profile-app-sso.passwordSettingNumbers",
			validate: isBoolean
		},
		{
			key: PASSWORD_PARAM_KEY.SPECIAL_CHARACTERS,
			type: PARAM_TYPE.BOOLEAN,
			value: null,
			label: "profile-app-sso.passwordSettingSpecialCharacters",
			validate: isBoolean
		}
	]
}

export const AZURE_PARAMS = {
	[CONFIG_TYPE.OPENID_CONNECT]: [
		COMMON_PARAMS[0],
		TENANT_ID[0],
		COMMON_PARAMS[1],
		COMMON_PARAMS[2],
		{
			key: PARAM_KEY.AUTHENTICATION_ENDPOINT,
			type: PARAM_TYPE.AUTO_STRING,
			value: "",
			label: "profile-app-sso.paramAuthEndpoint",
			validate: notEmpty,
			generateVal: conf => conf[PARAM_KEY.TENANT_ID] && conf[PARAM_KEY.TENANT_ID].length > 0 ?
			                     `https://login.microsoftonline.com/${conf[PARAM_KEY.TENANT_ID]}/oauth2/authorize` : "",
		},
		{
			key: PARAM_KEY.TOKEN_ENDPOINT,
			type: PARAM_TYPE.AUTO_STRING,
			value: "",
			label: "single-sign-on.token-endpoint",
			validate: notEmpty,
			generateVal: conf => conf[PARAM_KEY.TENANT_ID] && conf[PARAM_KEY.TENANT_ID].length > 0 ?
			                     `https://login.microsoftonline.com/${conf[PARAM_KEY.TENANT_ID]}/oauth2/token` : "",
		},
		{
			key: PARAM_KEY.USER_INFO_ENDPOINT,
			type: PARAM_TYPE.AUTO_STRING,
			value: "",
			label: "single-sign-on.user-info-endpoint",
			validate: notEmpty,
			generateVal: conf => conf[PARAM_KEY.TENANT_ID] && conf[PARAM_KEY.TENANT_ID].length > 0 ?
			                     `https://login.microsoftonline.com/${conf[PARAM_KEY.TENANT_ID]}/openid/userinfo` : "",
		}

	]
};

export const AAD_TENANT_PARAMS = {
	[CONFIG_TYPE.OPENID_CONNECT]: [
		COMMON_PARAMS[0],
		{
			key: PARAM_KEY.TENANT_ID,
			type: PARAM_TYPE.STRING,
			value: "",
			label: "profile-app-sso.paramTenantId",
			tooltip: "profile-app-sso.paramTenantIdTooltip",
			validate: notEmpty
		}
	]
};

export const OKTA_PARAMS = {
	[CONFIG_TYPE.OPENID_CONNECT]: [
		COMMON_PARAMS[0],
		SUBDOMAIN[0],
		COMMON_PARAMS[1],
		COMMON_PARAMS[2],
		{
			key: PARAM_KEY.AUTHENTICATION_ENDPOINT,
			type: PARAM_TYPE.AUTO_STRING,
			value: "",
			label: "profile-app-sso.paramAuthEndpoint",
			validate: notEmpty,
			generateVal: conf => conf[PARAM_KEY.SUBDOMAIN] && conf[PARAM_KEY.SUBDOMAIN].length > 0 ?
			                     `https://${conf[PARAM_KEY.SUBDOMAIN]}.okta.com/oauth2/v1/authorize` : "",
		},
		{
			key: PARAM_KEY.TOKEN_ENDPOINT,
			type: PARAM_TYPE.AUTO_STRING,
			value: "",
			label: "single-sign-on.token-endpoint",
			validate: notEmpty,
			generateVal: conf => conf[PARAM_KEY.SUBDOMAIN] && conf[PARAM_KEY.SUBDOMAIN].length > 0 ?
			                     `https://${conf[PARAM_KEY.SUBDOMAIN]}.okta.com/oauth2/v1/token` : "",
		},
		{
			key: PARAM_KEY.USER_INFO_ENDPOINT,
			type: PARAM_TYPE.AUTO_STRING,
			value: "",
			label: "single-sign-on.user-info-endpoint",
			validate: notEmpty,
			generateVal: conf => conf[PARAM_KEY.SUBDOMAIN] && conf[PARAM_KEY.SUBDOMAIN].length > 0 ?
			                     `https://${conf[PARAM_KEY.SUBDOMAIN]}.okta.com/oauth2/v1/userinfo` : "",
		}

	]
};

export const CUSTOM_PARAMS = {
	[CONFIG_TYPE.OPENID_CONNECT]: [
		COMMON_PARAMS[0],
		COMMON_PARAMS[1],
		COMMON_PARAMS[2],
		{
			key: PARAM_KEY.AUTHENTICATION_ENDPOINT,
			type: PARAM_TYPE.STRING,
			value: "",
			label: "profile-app-sso.paramAuthEndpoint",
			validate: notEmpty
		},
		{
			key: PARAM_KEY.TOKEN_ENDPOINT,
			type: PARAM_TYPE.STRING,
			value: "",
			label: "single-sign-on.token-endpoint",
			validate: notEmpty
		},
		{
			key: PARAM_KEY.USER_INFO_ENDPOINT,
			type: PARAM_TYPE.STRING,
			value: "",
			label: "single-sign-on.user-info-endpoint",
			validate: notEmpty
		}

	]
};

export const AUTO_VALUES = {
	[CONFIG_TYPE.OPENID_CONNECT]: [
		{
			key: "loginUrl",
			label: "profile-app-sso.loginUrlLabel",
			generateVal: (conf, environment) => conf[PARAM_KEY.CLIENT_ID] && conf[PARAM_KEY.CLIENT_ID].length > 0 ?
			                                    `${environment.getFullToolUrl()}/oidc/auth/${conf[PARAM_KEY.CLIENT_ID]}` : "",
		},
		{
			key: "redirectUrl",
			label: "profile-app-sso.redirectUrlLabel",
			generateVal: (conf, environment) => conf[PARAM_KEY.CLIENT_ID] && conf[PARAM_KEY.CLIENT_ID].length > 0 ?
			                                    `${environment.getFullToolUrl()}/oidc/auth/${conf[PARAM_KEY.CLIENT_ID]}` : "",
		}
	]
};