import {EMAIL_PROVIDERS, SMS_PROVIDERS} from "conf/email-sms-providers";
import capitalize from "utils/capitalize-string";
import ROLES from "conf/roles";
import LOCALES from "conf/locales";
import axios from "core/axios-sp";

export const DISPLAYS = {
	TABLE: "TABLE",
	ORGANIZATION_GROUP: "ORGANIZATION_GROUP"
};

export const LEVELS = {
	GROUP: 0,
	ORGANIZATION: 1,
	USERS: 2
};

export const ORGANIZATION_TYPES = {
	CUSTOMER: "Customer",
	DEMO: "Demonstration",
	DONATION: "Donation",
	PARTNER: "Partner",
	INTERNAL_TESTING: "Internal testing",
	OTHER: "Other",
	SURVEYPAL: "Surveypal"
}

//todo: When the license packages are actually defined, replace this with the actual packages.
export const PACKAGES = {
	Enterprise: "enterprise",
	Community: "community",
	Personal: "personal"
}

export const SEARCH_FOR_OPTIONS = {
	NONE: "NONE",
	ORGANIZATION_GROUP: "ORGANIZATION_GROUP",
	ORGANIZATION: "ORGANIZATION",
	TEAM: "TEAM",
	USER: "USER"
};

export const SEARCH_MODES = {
	BASIC: "BASIC",
	CUSTOMER_ID: "CUSTOMER_ID",
	ORGANIZATION_GROUP_ID: "ORGANIZATION_GROUP_ID",
	ORGANIZATION_ID: "ORGANIZATION_ID",
	TEAM_ID: "TEAM_ID",
	USER_ID: "USER_ID"
};

/**
 * This function prepares an array of license expiration day dropdown options. The callback is the state change function
 * of the component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initDayOptions(callback)
{
	const days = [[]];
	for (let i = 1; i <= 31; i++)
	{
		days[0].push({ value: i, text: i, callback: callback.bind(this, i), fixed: true});
	}
	return days;
}

/**
 * This function prepares an array of email provider dropdown options. The callback function is the state change function
 * of the component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initEmailProviderOptions(callback)
{
	const emailProviders = [[]];

	for (let provider = 0; provider < EMAIL_PROVIDERS.length; provider++)
	{
		emailProviders[0].push({
			value: EMAIL_PROVIDERS[provider],
			text: EMAIL_PROVIDERS[provider],
			fixed: true,
			callback: callback.bind(this, EMAIL_PROVIDERS[provider])
		});
	}

	return emailProviders;
}

/**
 * This function prepares an array of locale dropdown options. The callback is the state change function of the
 * component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initLocaleOptions(callback)
{
	const localeDropdownOptions = [[]];

	for (let locale = 0; locale < LOCALES.order.length; locale++)
	{
		localeDropdownOptions[0].include({
			value: LOCALES.order[locale],
			text: LOCALES.map[LOCALES.order[locale]].name,
			fixed: true,
			callback: callback.bind(this, LOCALES.order[locale])
		});
	}

	return localeDropdownOptions;
}

/**
 * This function prepares an array of license expiration months dropdown options. The callback is the state change
 * function of the component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initMonthOptions(callback)
{
	const months = [[]];
	for (let i = 1; i <= 12; i++)
	{
		months[0].push({ value: i, text: i, callback: callback.bind(this, i), fixed: true});
	}
	return months;
}

/**
 * This function prepares an array of license package dropdown options. The callback is the state change function of the
 * component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initPackageOptions(callback)
{
	return [Object.keys(PACKAGES).map((item, index) => {
		return {
			value: PACKAGES[item],
			text: item,
			fixed: true,
			callback: callback.bind(this, PACKAGES[item])
		}
	})];
}

/**
 * This function prepares an array of user role dropdown options. The callback function is the state change function of
 * the component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initRoleOptions(currentRole, callback)
{
	const options = [[
		{ value: ROLES.User, text: "User", fixed: true, callback: callback.bind(this, ROLES.User)},
		{ value: ROLES.Admin, text: "Admin", fixed: true, callback: callback.bind(this, ROLES.Admin)}
	]];

	if (currentRole !== ROLES.User && currentRole !== ROLES.Admin && currentRole !== null)
	{
		options[0].push({
			value: currentRole, text: capitalize(currentRole), fixed: true, callback: callback.bind(this, currentRole)
		});
	}

	return options;
}

/**
 * This function prepares an array of license seller dropdown options. The callback is the state change function of the
 * component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initSellerOptions(callback)
{
	return new Promise((resolve, reject) => {
		let items = [];

		axios.get("/app/crm/sellers").then(response => {
			if (response.status === 200)
			{
				items[0] = response.data.map(seller => {
					return {
						value: seller.id,
						text: seller.firstname + " " + seller.lastname,
						callback: callback.bind(this, seller.id),
						fixed: true
					};
				});

				items[0].push({
					value: -1,
					text: "No seller selected",
					callback: callback.bind(this, -1),
					fixed: true
				});

				resolve(items);
			}
			else
			{
				reject([]);
			}
		});
	});
}

/**
 * This function prepares an array of short URL domani dropdown options. The callback is the state change function of
 * the component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */

export function initShortUrlDomainOptions(callback)
{
	const prefix = () => {
		switch (Surveypal.getUser().getEnvironmentParameter())
		{
			case "localhost":
			case "test":
				return "t.";
			case "staging":
				return "s.";
			default:
				return "";
		}
	}

	const domains = [
		prefix() + "palautteesi.fi",
		prefix() + "respond.fi",
		prefix() + "yourfeedback.fi",
		prefix() + "srvp.al"
	]

	return [domains.map(domain => {
		return {
			value: domain, text: domain, fixed: true, callback: callback.bind(this, domain)
		}
	})];
}

/**
 * This function prepares an array of SMS provider dropdown options. The callback function is the state change function
 * of the component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initSmsProviderOptions(callback)
{
	const smsProviders = [[]];

	for (let provider = 0; provider < SMS_PROVIDERS.length; provider++)
	{
		smsProviders[0].push({
			value: SMS_PROVIDERS[provider].value,
			text: SMS_PROVIDERS[provider].text,
			fixed: true,
			callback: callback.bind(this, SMS_PROVIDERS[provider].value)
		});
	}

	return smsProviders;
}

/**
 * This function prepares an array of survey access dropdown options. The callback is the state change function of the
 * component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initSurveyAccessOptions(callback)
{
	return [[
		{ value: "NONE", text: "None", fixed: true, callback: callback.bind(this, "NONE")},
		{ value: "READ", text: "Read", fixed: true, callback: callback.bind(this, "READ")},
		{ value: "WRITE", text: "Write", fixed: true, callback: callback.bind(this, "WRITE")}
	]];
}

/**
 * This function prepares an array of organization/group type dropdown options. The callback function is the state
 * change function of the component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initTypeOptions(callback)
{
	const typeOptions = [[]];

	Object.keys(ORGANIZATION_TYPES).map((type, index) => {
		typeOptions[0].push({
			value: type,
			text: ORGANIZATION_TYPES[type],
			callback: callback.bind(this, type),
			fixed: true
		});
	});

	return typeOptions;
}

/**
 * This function prepares an array of license expiration year dropdown options. The callback is the state change
 * function of the component where the dropdown is used.
 *
 * @param callback
 * @returns {[[]]}
 */
export function initYearOptions(callback)
{
	const date = new Date();
	const years = [[]];
	for (let i = date.getFullYear(); i <= (date.getFullYear() + 10); i++)
	{
		years[0].push({ value: i, text: i, callback: callback.bind(this, i), fixed: true});
	}
	years[0].push({ value: 3000, text: 3000, callback: callback.bind(this, 3000), fixed: true});

	return years;
}

/**
 * This function determines whether the user is allowed to see the buttons for setting up or cancelling a deletion task
 * for organizations. Organization deletion is limited to super admins in production, and support+ everywhere else.
 *
 * @returns {boolean}
 */
export function isOrganizationDeletionButtonVisible()
{
	const environment = Surveypal.getUser().getEnvironmentParameter();
	const userRole = Surveypal.getUser().getRole();
	return (environment === "production" && userRole === ROLES.Super_admin) ||
	       (environment !== "production" && ROLES.isAtLeast(userRole, ROLES.Support));
}

/**
 * This function converts the selected expiration year, month and day to a format expected by the server when saving a
 * license.
 *
 * @param year
 * @param month
 * @param day
 * @returns {number}
 */
export function parseExpiration(year, month, day)
{
	return Date.UTC(year, month-1, day);
}