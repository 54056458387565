import React, {useState, useEffect, useRef} from "react";
import "./ContextMenu.scss";

/**
 * @callback OnClose
 */
/**
 * @typedef Item
 * @property [disabled] {boolean}
 * @property onClick {function}
 * @property text {string}
 */
/**
 * @typedef Group
 * @type {Item[]}
 */
/**
 * @typedef Items
 * @type {Group[]}
 */
/**
 * ContextMenu renders a context menu.
 *
 * @param items {Items}
 * @param onClose {OnClose}
 * @param style {{x: number, y: number}}
 * @returns {JSX.Element}
 */
const ContextMenu = ({
	items,
	onClose,
	style
}) => {
	const [finalStyle, setFinalStyle] = useState({
		...style,
		visibility: "hidden"
	});
	const menuReference = useRef();

	const clickItem = (groupIndex, itemIndex) => {
		if (items[groupIndex][itemIndex].disabled) return;

		items[groupIndex][itemIndex].onClick();
	};

	useEffect(() => {
		const menuRectangle = menuReference.current.getBoundingClientRect();
		const windowWidth = window.getWidth();
		const windowHeight = window.getHeight();
		setFinalStyle({
			visibility: "visible",
			left: style.left + menuRectangle.width < windowWidth ? style.left : windowWidth - menuRectangle.width + "px",
			top: style.top + menuRectangle.height < windowHeight ? style.top : windowHeight - menuRectangle.height + "px"
		});
	}, []);

	return (
		<div
			className={"ContextMenuWrapper"}
			onClick={onClose}>
			<div
				style={finalStyle}
				className={"ContextMenu"}
				ref={menuReference}>
				{
					items.map((group, groupIndex) => (
						<div
							className={"ContextMenuGroup"}
							key={`cm-group-${groupIndex}`}>
							{
								group.map((item, itemIndex) => (
									<div
										className={"ContextMenuItem"}
										key={`cm-group-${groupIndex}-item-${itemIndex}`}
										onClick={() => clickItem(groupIndex, itemIndex)}>
										{item.text}
									</div>
								))
							}
						</div>
					))
				}
			</div>
		</div>
	)
};

export default ContextMenu;