define("ui/email-input-field", function(require)
{
	var Environment = require("utils/environment");
	var InputField = require("ui/input-field");
	var Request = require("core/request");
	var UserListPopup = require("ui/user-list-popup");
	var UsersNotFoundPopup = require("common/share/users-not-found-popup");
	var RegExp = require("utils/regexp");

	var userList = [];
	return InputField.extend(
	{
		init: function()
		{
			this.supreme("input-field.emailPlaceholder");
			this.setExcludeList([Surveypal.getUser().getUsername()]);
			this.initSuggest();
		},

		initSuggest: function()
		{
			var callback = function(status, json)
			{
				if(status == 200)
				{
					var suggestList = [];
					userList = [];
					for(var i = 0; i < json.length; i++ )
					{
						// Sanity check
						if(!json[i].username)
						{
							continue;
						}

						// Ignore if excluded
						var username = json[i].username.toLowerCase();
						if(this.exclude(username))
						{
							continue;
						}

						// Add
						suggestList.push(username);      // Accept into suggest list
						userList.push(json[i]); // Accept into original emailArray list (to be used in user-list-popup).
					}

					// Add shortcuts for customer service
					suggestList.push(Environment.getHelpdeskEmail("fi_FI"));
					suggestList.push(Environment.getHelpdeskEmail());
					userList.push({deleted: false, id: 0, role: "support", firstname: "Asiakaspalvelu", lastname: "Customer service", username: Environment.getHelpdeskEmail("fi_FI"), email:Environment.getHelpdeskEmail("fi_FI")});
					userList.push({deleted: false, id: 0, role: "support", firstname: "Asiakaspalvelu", lastname: "Customer service", username: Environment.getHelpdeskEmail(), email: Environment.getHelpdeskEmail()});

					this.setSuggestList(suggestList);
					this.addSelectButton();
				}
			}.bind(this);

			Surveypal.getUser().getUserlist(callback, true);
		},

		addSelectButton: function()
		{
			var link = jQuery('<span class="link"></span>');
			link.text(translator.get("input-field.addSelected"));
			link.on("click", this.cmdAddSelected.bind(this));
			link.appendTo(this.find(".actions"));
		},

		cmdAddSelected: function()
		{
			var callback = function(selected, all)
			{
				this.find(".add").remove();
				for(var x = 0; x < all.length; x++)
				{
					if(this.getValues().includes(all[x].username))
					{
						this.remove(all[x].username);
					}
				}
				for(var i = 0; i < selected.length; i++ )
				{
					this.value(selected[i].username.toLowerCase());
				}
				this.placeholder();
			}.bind(this);

			new UserListPopup(userList, callback, this.getValues());
		},

		validate: function(dom, str)
		{
			var trimmedEmail = str;
			trimmedEmail = trimmedEmail.trim();
			trimmedEmail = trimmedEmail.replace(/,/g, "");

			if(this.exclude(trimmedEmail))
			{
				dom.addClass("invalid");
				dom.attr("title", translator.get("input-field.invalidEmailOwn"));
				return;
			}

			if(!str.test(RegExp.EMAIL))
			{
				dom.addClass("invalid");
				dom.attr("title", translator.get("input-field.invalidEmail"));
				return;
			}
		},

		hasInvalidEmails: function()
		{
			if (this.find(".invalid").length > 0) // If there is some invalid ones in the list
			{
				var invalidEmails = [];
				var invalidDOMs = this.find(".invalid");
				for(var i = 0; i < invalidDOMs.length; ++i)
				{
					invalidEmails.push(jQuery(invalidDOMs[i]).text());
				}
				new UsersNotFoundPopup(invalidEmails);
				return true;
			}
			return false;
		}
	});
});