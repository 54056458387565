import cloneDeep from "lodash.clonedeep";
import { SET_SUBSCRIPTIONS_SEARCH_PARAM, PUSH_NOTIFICATION, POP_NOTIFICATION, SET_NEW_SUBSCRIPTION_PARAM,
ADD_SUBSCRIPTION, DELETE_LOCAL_SUBSCRIPTION, SET_INITIAL_SUBSCRIPTIONS, SET_SUBSCRIPTION_TYPE } from "./subscriptionsActions";

export const defaultState = {
	newSubParams: {},
	notifications: [],
	searchParams: {},
	subscriptions: []
};

export default function crmSubscriptionsReducer(state = defaultState, originalAction)
{
	const action = cloneDeep(originalAction);
	const nextState = cloneDeep(state);
	switch(action.type)
	{
		case ADD_SUBSCRIPTION:
			nextState.subscriptions.unshift(action.subscription);
			return nextState;

		case DELETE_LOCAL_SUBSCRIPTION:
			const index = nextState.subscriptions.findIndex(sub => sub.id === action.id);
			if (index !== -1)
			{
				nextState.subscriptions.splice(index, 1);
			}
			return nextState;

		case SET_NEW_SUBSCRIPTION_PARAM:
			nextState.newSubParams[action.key] = action.value;
			return nextState;

		case SET_INITIAL_SUBSCRIPTIONS:
			nextState.subscriptions = action.subscriptions;
			return nextState;

		case SET_SUBSCRIPTIONS_SEARCH_PARAM:
			nextState.searchParams[action.key] = action.value;
			return nextState;

		case SET_SUBSCRIPTION_TYPE:
			const sub = nextState.subscriptions.find(sub => sub.id === action.id);
			if (sub)
			{
				sub.isNew = true;
				sub.type = action.active;
			}
			return nextState;

		case PUSH_NOTIFICATION:
		{
			// Create predictable unique ids
			const newId = nextState.notifications.reduce((accumulator, notification) => {
				return accumulator + notification.id;
			}, 1);
			const notification = action.notification;
			notification.id = newId;
			nextState.notifications.push(notification);
			return nextState;
		}


		case POP_NOTIFICATION:
			nextState.notifications.shift();
			return nextState;

		default:
			return nextState;
	}
}