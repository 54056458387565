import {getDifferenceBetweenHelsinkiAndAccountTimezone, getDifferenceBetweenLocalAndAccountTimezone} from "../utils/dateUtils";
import moment from "moment-timezone";

define("core/translator", function(require)
{
	var Class = require("core/class");
	var moment = require("moment-timezone");

	return Class.extend(
		{
			init: function (getUserCallback)
			{
				this._getUserCallback = getUserCallback;
			},

			// This one will parse through HTML dom and translate things
			translate: function(dom)
			{
				var items = jQuery(dom).find(".locale");
				for(var i = 0; i < items.length; i++)
				{
					var item = jQuery(items[i]);
					item.removeClass("locale");
					if(item.attr("_text"))
					{
						var text = this.get(item.attr("_text"));
						var tagName = item.prop("tagName").toLowerCase();
						if(tagName == "input" || tagName == "textarea")
						{
							item.val(text);
						}
						else
						{
							item.text(text);
						}
					}
					else if(item.attr("_tooltip"))
					{
						item.attr("title", this.get(item.attr("_tooltip")));
					}
					else
					{
					}
				}
			},

			highcharts: function()
			{
				var language = this._getUserCallback().getLocale();
				Highcharts.setOptions({lang: Locale.get(language, "highchart-lang")});
				Highcharts.setOptions({global: { useUTC: false }});
			},

			// Translates text
			get:               function(key)
			{
				// Split key
				var p = key.split(".");
				var group = p[0];
				var identifier = p[1];

				// Get language
				var language = this._getUserCallback().getPreferredLocale();

				// Return translation
				return Locale.get(language, group, identifier);
			},

			// The format must be in locale placeholder syntax, for example: "Date.fullDate"
			timestamp:         function(format, timestamp, useAccountTimezone)
			{
				if(!timestamp)
				{
					return "";
				}

				// Create a date object
				var date = new Date();
				var fixedTimestamp;

				if (useAccountTimezone)
				{
					 var differenceBetweenLocalAndAccountTimezone = getDifferenceBetweenLocalAndAccountTimezone();
					 var differenceBetweenAccountAndHelsinkiTimezone = getDifferenceBetweenHelsinkiAndAccountTimezone();
					 var localAndAccountDifferenceInMilliseconds = differenceBetweenLocalAndAccountTimezone * 60000;
					 var accountAndHelsinkiDifferenceInMillisecnds = differenceBetweenAccountAndHelsinkiTimezone * 60000;
					 fixedTimestamp = timestamp - (localAndAccountDifferenceInMilliseconds - accountAndHelsinkiDifferenceInMillisecnds);
				}
				else
				{
					// Fix for P-4121, get "base-time" of Helsinki for comparing it against machines date
					var basetime = moment.tz('Europe/Helsinki').format('LLL');
					var toCompare = new Date(basetime);
					// Calculate the difference between machine date and base-date
					var difference = (date - toCompare).toFixed(0);
					// Fix the timestamp with difference
					fixedTimestamp = timestamp - difference;
				}

				date.setTime(fixedTimestamp);
				var f = this.get(format);
				var str = date.format(f);
				return str;
			}
		});
});