define("notifications/browser-notification", function(require)
{
	var NotificationBar = require("ui/notification-bar");
	var RoundButton = require("ui/round-button");
	var Locales = require("conf/locales");

	return NotificationBar.extend(
		{
			init: function (browserLabel)
			{
				this.supreme();

				var link = '<a href="%1" target="_blank">%2</a>';
				var url = Locales.get(Surveypal.getUser().getLocale(), "supportedbrowsers");
				link = link.replace("%1", url);
				link = link.replace("%2", translator.get("browser-notification.link"));

				var label = translator.get("browser-notification.label");
				label = label.replace("%1", browserLabel);
				label = label.replace("%2", link);
				this.text(label);

				var fn = function()
				{
					this.deinit();
				}.bind(this);

				var button = new RoundButton(translator.get("common.close"), fn);
				button.attach(this.find());
			}
		});
});