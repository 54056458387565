import React from "react";
import {connect} from "react-redux";
import {openSurvey} from "../../Toolbar/functions/openSurvey";
import {enterFolder} from "../../Toolbar/functions/enterFolder";
import {
	formatStatus,
	formatOwner,
	formatModified
} from "./utils/utils";
import LoadingIndicator from "ui/LoadingIndicator/LoadingIndicator";
import {COLUMNS} from "../List";
import {FILE_TYPES} from "../List";
import {
	setSelectedSurveys,
	setSelectedFolders
} from "../../../surveysActions";
import "../../../surveysTypes";
import "./Item.scss";

/**
 * @callback OnContextMenu
 * @param x {number}
 * @param y {number}
 */
/**
 * @callback OnSelect
 * @param e {object}
 */
/**
 * FileItem displays a file (survey or folder) in the file listing.
 *
 * @param fileId {number}
 * @param type {string}
 * @param columns {Columns}
 * @param surveys {object[]}
 * @param folders {object[]}
 * @param selectedSurveyIds {number[]}
 * @param selectedFolderIds {number[]}
 * @param onContextMenu {OnContextMenu}
 * @param onSelect {OnSelect}
 * @param dispatch {function}
 * @return {JSX.Element}
 */
const Item = ({
	fileId,
	type,
	columns,
	selectedSurveyIds,
	selectedFolderIds,
	surveys,
	folders,
	onContextMenu,
	onSelect,
	dispatch
}) => {
	const file = (
		type === FILE_TYPES.SURVEY
		?
		surveys.find(survey => survey.id === fileId)
		:
		folders.find(folder => folder.id === fileId)
	);

	const selected = selectedSurveyIds.indexOf(fileId) !== -1 || selectedFolderIds.indexOf(fileId) !== -1;
	const hasNewResponses = type === FILE_TYPES.SURVEY && file.newAnswerCount && file.newAnswerCount > 0;

	const openContextMenu = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const {x, y} = Event.parseCoordinates(e);
		//dispatch(setSelected(file.id));
		if (type === FILE_TYPES.SURVEY)
		{
			dispatch(setSelectedSurveys([file.id]));
			dispatch(setSelectedFolders([]));
		}
		else
		{
			dispatch(setSelectedSurveys([]));
			dispatch(setSelectedFolders([file.id]));
		}
		onContextMenu(x, y);
	};

	return (
		<tr
			onClick={(e) => onSelect(e)}
			onContextMenu={openContextMenu}
			onDoubleClick={() => type === FILE_TYPES.SURVEY ? openSurvey(file) : enterFolder(file.id)}
			className={`SurveyListItem ${hasNewResponses ? "newResponses" : ""} ${selected ? "selected" : ""}`}>
			<td className={`FileIcon`}>
				<span className={`sprite ${type}`}></span>
			</td>
			<td className={"FileName"}>{file.name}</td>
			{
				columns[COLUMNS.SURVEY_ID] && <td>{type === FILE_TYPES.SURVEY ? file.id : ""}</td>
			}
			{
				columns[COLUMNS.OWNER] && <td>{type === FILE_TYPES.SURVEY ? formatOwner(file) : ""}</td>
			}
			{
				columns[COLUMNS.LAST_MODIFIED] && <td>{type === FILE_TYPES.SURVEY ? formatModified(file) : ""}</td>
			}
			{
				columns[COLUMNS.RESPONSES] && <td>{type === FILE_TYPES.SURVEY ? file.totalAnswerCount : ""}</td>
			}
			{
				columns[COLUMNS.NEW_RESPONSES] && <td>{type === FILE_TYPES.SURVEY ? (hasNewResponses ? file.newAnswerCount : 0) : ""}</td>
			}
			{
				columns[COLUMNS.SURVEY_STATUS] && !file.deleting && <td>{type === FILE_TYPES.SURVEY ? formatStatus(file) : ""}</td>
			}
			{
				columns[COLUMNS.SURVEY_STATUS] && file.deleting &&
				<td className={"DeletingStatus"}>
					{
						type === FILE_TYPES.SURVEY &&
						<LoadingIndicator
							className={"deleting"}
							loading={true}
							text={translator.get("common.deleting")}
						/>
					}
				</td>
			}
		</tr>
	);
};

export default connect(state => ({
	surveys: state.surveys.fetchables.surveys.surveys,
	folders: state.surveys.fetchables.folders.folders,
	columns: state.surveys.list.columns,
	selectedSurveyIds: state.surveys.list.selectedSurveyIds,
	selectedFolderIds: state.surveys.list.selectedFolderIds
}))(Item)