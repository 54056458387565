import { combineDynamicRuleTemplates } from "common/rules/rule-utils";
import { RULES, RULE_COMPONENTS } from "common/rules/rule-conf";
import { buildRuleValueForSelector } from "common/rules/rule-value-generator";

export const IMPORTED_STATUS_CODES = {
	OK: "success",
	NO_FILTERS: "no_filters",
	FAILED: "failed"
};

export function getDummyId()
{
	return "dummy-id-" + Date.now();
}

export function validateCopiedFiltersOrAutomations(filtersOrAutomations, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues, initialImport = false)
{
	const allApplicableRuleTemplates = combineDynamicRuleTemplates(applicableRules, elementItems, metaItems);

	filtersOrAutomations.forEach(filterOrAutomationItem => {
		if (filterOrAutomationItem.isImported)
		{
			const filterOrAutomation = filterOrAutomationItem.filter ? filterOrAutomationItem.filter : filterOrAutomationItem;
			filterOrAutomation.groups.forEach(group => {
				group.rules.forEach(rule => {
					rule.corruptedItems = rule.corruptedItems ? rule.corruptedItems : {};

					// Validate rule type
					let appliedRuleTemplate = allApplicableRuleTemplates.find(ruleTemplate => getUniqueRuleKey(ruleTemplate) === getUniqueRuleKey(rule));
					if (!appliedRuleTemplate && initialImport)
					{
						rule.corruptedItems.type = true;
						appliedRuleTemplate = RULES.UNDEFINED;
					}
					else if (appliedRuleTemplate && !initialImport)
					{
						rule.corruptedItems.type = false;
					}

					if (!appliedRuleTemplate)
					{
						appliedRuleTemplate = RULES.UNDEFINED;
					}

					// Validate rule operator
					let appliedOp = appliedRuleTemplate.operators.find(someOp => (someOp.value === rule.op || someOp.newTemporaryValue === rule.op));

					if (!appliedOp && initialImport)
					{
						rule.corruptedItems.op = true;
						appliedOp = RULE_COMPONENTS.NULL;
					}
					else if (appliedOp && !initialImport)
					{
						rule.corruptedItems.op = false;
					}

					if (!appliedOp)
					{
						appliedOp = RULE_COMPONENTS.NULL;
					}

					// Validate rule value
					if (appliedOp && appliedOp.component && appliedOp.component === RULE_COMPONENTS.SELECTOR)
					{
						const selectorItemsAndValue = buildRuleValueForSelector(rule, useSliderIndexValues, languageItems, sourceItems);
						const matchingValue = selectorItemsAndValue.items.findIndex(item => item.value == selectorItemsAndValue.selectedValue);
						if (matchingValue === -1 && initialImport)
						{
							rule.corruptedItems.value = true;
						}
						else if (matchingValue !== -1 && !initialImport)
						{
							rule.corruptedItems.value = false;
						}
					}
				});
			});

			filterOrAutomation.corrupted = shouldFilterOrAutomationBeCorrupted(filterOrAutomation.groups);
		}
	});

	return filtersOrAutomations;
}

function shouldFilterOrAutomationBeCorrupted(groups)
{
	let corrupted = false;
	groups.forEach(group => {
		group.rules.forEach(rule => {
			const ruleItems = rule.corruptedItems;
			if (ruleItems.type || ruleItems.op || ruleItems.value)
			{
				corrupted = true;
			}
		});
	});

	return corrupted;
}

function getUniqueRuleKey(rule)
{
	return rule.key + "-" + (rule.type ? rule.type : "null");
}