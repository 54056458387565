import React, {useState} from "react";
import ReportInfo from "./ReportInfo/ReportInfo";
import {SelectionList, SelectableItem} from "ui/SelectionList/SelectionList";
import Search from "ui/Search/Search";
import LoadingIndicator from "ui/LoadingIndicator/LoadingIndicator";
import SplitButton from "ui/SplitButton/SplitButton";
import "./ExistingReports.scss";
import {connect} from "react-redux";
import {
	setSelectedReport,
	setReportsToDelete,
	setConfirmMassDeletion,
	executeMassDeletion
} from "../automaticReportingActions";
import MassDeleteConfirmationPopup from "./MassDeleteConfirmationPopup/MassDeleteConfirmationPopup";
import MassDeletionProgress from "./MassDeletionProgress/MassDeletionProgress";
import MassDeletionEnded from "./MassDeletionEnded/MassDeletionEnded";
import "../automaticReportingTypes";
import ButtonR from "ui/ButtonR";

export const REPORTS_PER_PAGE = 25;

/**
 * <ExistingReports/> renders a tab showing:
 *  - "Loading" while fetching existing reports.
 *  - "No existing scheduled reports discovered." if there are no existing reports.
 *  - List and information about reports if they exist.
 *
 * @param existingReportsCount {ExistingReportsCount}
 * @param existingReports {ExistingReports}
 * @param selectedReport {number}
 * @param reportsToDelete {Report[]}
 * @param confirmMassDeletion {boolean}
 * @param executingMassDeletion {boolean}
 * @param massDeletionEnded {boolean}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const ExistingReports = ({
	selectedReport,
	existingReportsCount,
	existingReports,
	reportsToDelete,
	confirmMassDeletion,
	executingMassDeletion,
	massDeletionEnded,
	dispatch
}) => {
	const [filter, setFilter] = useState("");

	const startDeletion = () => {
		dispatch(setConfirmMassDeletion(false));
		executeMassDeletion(reportsToDelete, dispatch);
	};

	//If there are no existing reports, just return a text saying that.
	if (existingReports.finished && existingReports.existingReports.length === 0)
	{
		return (
			<div className={"noExistingReports"}>
				<p>{translator.get("automaticReporting.noExistingReportsFound")}</p>
			</div>
		);
	}

	//If the user has clicked to delete several reports at once, display confirmation popup first.
	if (confirmMassDeletion)
	{
		return (
			<MassDeleteConfirmationPopup
				onCancel={() => dispatch(setConfirmMassDeletion(false))}
				onConfirm={startDeletion}
			/>
		);
	}

	//If we're in the process of deleting several reports, display progress popup.
	if (executingMassDeletion) return <MassDeletionProgress/>;

	//If several reports were deleted at once and the deletion has ended, display conclusion popup.
	if (massDeletionEnded) return <MassDeletionEnded/>;

	const hasScheduleEnded = (report) =>
		!!report.schedule.endDate &&
		new Date(report.schedule.endDate.split("+")[0]) < new Date();

	const isReportChecked = (reportId) => reportsToDelete.some(checkedReport => checkedReport.id === reportId);

	const toggleReportCheck = (report) => {
		if (isReportChecked(report.id))
		{
			dispatch(setReportsToDelete(reportsToDelete.filter(checkedReport => checkedReport.id !== report.id)));
			return;
		}

		dispatch(setReportsToDelete([...reportsToDelete, report]));
	};

	const checkAll = () => {
		dispatch(setReportsToDelete(existingReports.existingReports.map(report => report)));
	};

	const checkEnded = () => {
		dispatch(setReportsToDelete(existingReports.existingReports
			.filter(report => !!report.schedule.endDate && hasScheduleEnded(report))
			.map(report => report))
		);
	};

	const selectedReportExists =
		selectedReport !== undefined &&
		existingReports.existingReports.some((report) => report.id === selectedReport);

	const massSelectActions = [[
		{
			text: translator.get("filterLibrary.selectAll"),
			onClick: checkAll
		},
		{
			text: translator.get("filterLibrary.unselectAll"),
			onClick: () => dispatch(setReportsToDelete([]))
		},
		{
			text: translator.get("automaticReporting.massDeleteSelectEnded"),
			onClick: checkEnded
		}
	]];

	const reportsLeftToFetch =
		existingReportsCount.loading
		?
		-1
		:
		existingReportsCount.count - existingReports.existingReports.length;

	return (
		<div className={"existingReports"}>
			<div className={"list"}>
				<Search
					value={filter}
					onChange={newValue => setFilter(newValue)}
					onSearch={() => {}}
				/>
				<SelectionList
					className={"existingReportsSelection"}>
					{
						existingReports.existingReports
							.filter(report => report.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
							.map(report => (
								<SelectableItem
									key={`selectableItem-${report.id}`}
									className={`reportItem ${hasScheduleEnded(report) ? "ended" : ""}`}
									text={`${report.name} ${hasScheduleEnded(report) ? translator.get("automaticReporting.reportEnded") : ""}`}
									selected={selectedReportExists && selectedReport === report.id}
									onSelect={() => dispatch(setSelectedReport(report.id))}
									checkable={true}
									checked={isReportChecked(report.id)}
									onCheck={() => toggleReportCheck(report)}
								/>
							))
					}
					{
						!existingReports.finished && existingReports.existingReports.length > 0 &&
						<LoadingIndicator
							className={"fetchingMoreReports"}
							loading={true}
							text={translator.get("automaticReporting.fetchingMoreReports").replace("${n}", reportsLeftToFetch)}
						/>
					}
				</SelectionList>
				{
					existingReports.existingReports.length > 0 &&
					<div className={"massEffect"}>
						<SplitButton
							className={"massEffectMenu"}
							items={massSelectActions}
							isRound={true}
						/>
						{
							reportsToDelete.length > 0 &&
							<ButtonR
								className={"massEffectButton"}
								text={translator.get("automaticReporting.massDeleteExecute")}
								callback={() => dispatch(setConfirmMassDeletion(true))}
								isRound={true}
								isDangerous={true}
							/>
						}
					</div>
				}
			</div>
			<div className={"reportInfoPanel"}>
				{
					existingReports.existingReports.length === 0 && !existingReports.finished &&
					<LoadingIndicator
						loading={true}
						text={translator.get("automaticReporting.fetchingExistingReports")}
					/>
				}
				{
					selectedReportExists &&
					<ReportInfo
						reportId={selectedReport}
					/>
				}
			</div>
		</div>
	);
};

export default connect(state => ({
	existingReportsCount: state.automaticReporting.existingReports.fetchables.count,
	existingReports: state.automaticReporting.existingReports.fetchables.existingReports,
	selectedReport: state.automaticReporting.existingReports.selectedReport,
	reportsToDelete: state.automaticReporting.existingReports.massDelete.reportsToDelete,
	confirmMassDeletion: state.automaticReporting.existingReports.massDelete.confirmMassDeletion,
	executingMassDeletion: state.automaticReporting.existingReports.massDelete.executingMassDeletion,
	massDeletionEnded: state.automaticReporting.existingReports.massDelete.massDeletionEnded
}))(ExistingReports);