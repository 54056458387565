import axios from "core/axios-sp";
import axiosServiceRequest from "core/axios-sp-servicereq";
import assign from "lodash.assign";

const ANONYMIZE_ALL_RESPONSES = "/app/survey/data/anonymize";
const ANONYMIZATION_ENDPOINT = "/app/survey/data/rules/anonymization";
const ANONYMIZATION_TARGET_ENDPOINT = "/app/survey/data/anonymizationtarget";
const DELETE_ALL_RESPONSES = "/app/survey/data/delete";
const DELETION_ENDPOINT = "/app/survey/data/rules/deletion";
const LOGS_ENDPOINT = "/app/survey/data/retention/logs";
const RULES_ENDPOINT = "/app/survey/data/rules";
const RESPONSES_COUNT_ENDPOINT = "/app/survey/data/count";

export function getRules(surveyId)
{
	return axios.get(RULES_ENDPOINT, {
		params: {
			json: {
				surveyId
			}
		}
	});
}

export function getMetaKeys(surveyId)
{
	const queries = [{_req: "getMetaKeys", id: surveyId}];
	return axiosServiceRequest.post("/app/j", queries).then(response => {
		// Mutate the response so that the response data is directly the meta key array
		return assign(response, {
			data: response.data[0].keys
		});
	});
}

export function createDeletionRule(surveyId, maxAgeUnit, maxAgeValue)
{
	return axios.put(DELETION_ENDPOINT, {
		surveyId,
		maxAgeUnit,
		maxAgeValue
	})
}

export function updateDeletionRule(surveyId, maxAgeUnit, maxAgeValue)
{
	return axios.post(DELETION_ENDPOINT, {
		surveyId,
		maxAgeUnit,
		maxAgeValue
	});
}

export function deleteDeletionRule(surveyId)
{
	return axios.delete(DELETION_ENDPOINT, {
		data: {
			surveyId
		}
	})
}

export function createAnonymizationRule(surveyId, maxAgeUnit, maxAgeValue)
{
	return axios.put(ANONYMIZATION_ENDPOINT, {
		surveyId,
		maxAgeUnit,
		maxAgeValue
	})
}

export function updateAnonymizationRule(surveyId, maxAgeUnit, maxAgeValue)
{
	return axios.post(ANONYMIZATION_ENDPOINT, {
		surveyId,
		maxAgeUnit,
		maxAgeValue
	});
}

export function deleteAnonymizationRule(surveyId)
{
	return axios.delete(ANONYMIZATION_ENDPOINT, {
		data: {
			surveyId
		}
	})
}

export function getLogs(surveyId)
{
	return axios.get(LOGS_ENDPOINT, {
		params: {
			json: {
				surveyId
			}
		}
	});
}

export function createAnonymizationTarget(surveyId, type, key, entityType)
{
	return axios.put(ANONYMIZATION_TARGET_ENDPOINT, {
		surveyId,
		entityType,
		type,
		key
	})
}

export function deleteAnonymizationTarget(surveyId, targetId, entityType)
{
	return axios.delete(ANONYMIZATION_TARGET_ENDPOINT, {
		data: {
			surveyId,
			targetId,
			entityType
		}
	});
}

export function deleteAll(surveyId)
{
	return axios.put(DELETE_ALL_RESPONSES, {
		surveyId
	});
}

export function getDeletionStatus(surveyId)
{
	return axios.get(DELETE_ALL_RESPONSES, {
		params: {
			json: {
				surveyId
			}
		}
	});
}

export function anonymizeAll(surveyId, targets)
{
	return axios.put(ANONYMIZE_ALL_RESPONSES, {
		surveyId,
		targets
	});
}

export function getAnonymizationStatus(surveyId)
{
	return axios.get(ANONYMIZE_ALL_RESPONSES, {
		params: {
			json: {
				surveyId
			}
		}
	});
}

export function cancelDeleteAll(surveyId)
{
	return axios.delete(DELETE_ALL_RESPONSES, {
		params: {
			json: {
				surveyId
			}
		}
	});
}

export function cancelAnonymizeAll(surveyId)
{
	return axios.delete(ANONYMIZE_ALL_RESPONSES, {
		params: {
			json: {
				surveyId
			}
		}
	});
}

export function getResponseCount(surveyId)
{
	return axios.get(RESPONSES_COUNT_ENDPOINT, {
		params: {
			json: {
				surveyId
			}
		}
	});
}