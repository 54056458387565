define("core/service-request", function(require)
{
	var Request = require("core/request");
	var UUID = require("utils/uuid");

	// This class wraps functionality of the old service queue requests, to be removed when service queue is not used anymore.
	return Request.extend(
	{
		init: function()
		{
			this.supreme("/app/j", false);
		},

		setData: function(settings)
		{
			// Parse parameters by method (only GET and POST with service queue)
			var json = this.getJSON();
			for(var i = 0; i < json.length; i++ )
			{
				json[i]._id = UUID();
			}
			settings.data = json ? "d=" + encodeURIComponent(JSON.stringify(json)) : null;
		}
	});
});