//React
import React, {useState} from "react";

//Components
import Search from "ui/Search/Search";
import RibbonButtonR, {RibbonIcon} from "./RibbonButtonR/RibbonButtonR";

//Style
import "./PopupRibbonR.scss";

/**
 * @callback onSearch
 * @param value {string}
 */
/**
 * <PopupRibbon/> renders a row of buttons on top of a popup. These buttons can be used for functions or tabs.
 *
 * @param className {string}
 * @param showSearch {boolean}
 * @param onSearch {onSearch}
 * @param children {JSX.Element[]}
 * @returns {JSX.Element}
 */
const PopupRibbonR = ({className, showSearch, onSearch, children}) => {
	const [searchTerm, setSearchTerm] = useState("");

	const classes = `PopupRibbonR ${className ? className : ""}`;

	return (
		<div className={classes}>
			<div className={"buttonSection"}>
				{children}
				{
					showSearch &&
					<div className={"searchSection"}>
						<Search
							value={searchTerm}
							onChange={setSearchTerm}
							onSearch={onSearch}
						/>
					</div>
				}
			</div>
		</div>
	);
};

export {PopupRibbonR, RibbonButtonR, RibbonIcon};