const SOURCE = {
  BUTTON: "BUTTON",
  CLICK: "CLICK",
  REQUEST: "REQUEST",
  VIEW: "VIEW"
};

export default {
  // Anonymization set up in manage view
  ANONYMIZATION_SET_UP: {
    name: "Response anonymization set up"
  },
  // Answer paths/conditions are used
  ANSWER_PATHS_CREATED: {
    name: "Answer paths created"
  },
  // Api key creation request is sent
  API_KEY_CREATED: {
    name: "API key created"
  },
  // Automations are created in the automation popup
  AUTOMATIONS_CREATED: {
    name: "Automation created"
  },
  // Comment is addded in the preview
  COMMENT_ADDED: {
    name: "Comment added"
  },
  // OAuth popup is opened to be connected to an external service
  CONNECT_TO: {
    name: "Connected external service"
  },
  // Create survey from scratch or using template
  CREATE_SURVEY: {
    name: "Create survey"
  },
  CREATE_SURVEY_TEMP: {
    name: "Create survey using a template"
  },
  // Custom style is enabled in style view (enabled by default)
  CUSTOM_STYLE_ENABLED: {
    name: "Custom style enabled"
  },
  // Deletion is set up in manage view
  DELETION_SET_UP: {
    name: "Response deletion set up"
  },
  // Device style is enabled in style view
  DEVICE_STYLE_ENABLED: {
    name: "Device style enabled"
  },
  // Element is added to survey
  ELEMENT_ADDED: {
    name: "Element added to survey"
  },
  // Launched survey has element
  ELEMENT_LAUNCHED: {
    name: "Element launched in a survey"
  },
  // Email recipients are anonymized
  EMAIL_RECIPIENTS_ANONYMIZED: {
    name: "Email recipients anonymized"
  },
  // Email html editor is used
  EMAIL_HTML_EDITOR_USED: {
    name: "Email html editor used"
  },
  // Email raw code editor used
  EMAIL_CODE_VIEW_USED: {
    name: "Email code view used"
  },
  // Email invitations are actually sent
  EMAIL_INVITATIONS_SENT: {
    name: "Email invitations sent"
  },
  // Email NPS question is added
  EMAIL_QUESTION_ADDED: {
    name: "Email NPS question added"
  },
  // Recipients are imported from and Excel file
  EXCEL_RECIPIENTS_IMPORTED: {
    name: "Recipients imported from excel"
  },
  // Filters created in analyze view
  FILTERS_CREATED: {
    name: "Filters created"
  },
  // Integration is created to an external service
  INTEGRATION_CREATED: {
    name: "Integration created to external service"
  },
  // Image is uploaded in image popup
  IMAGE_UPLOADED: {
    name: "Image uploaded"
  },
  // Language added to survey
  LANGUAGE_ADDED: {
    name: "Language added to survey"
  },
  // Logic is saved
  LOGIC_ADDED: {
    name: "Logic added to survey"
  },
  // Merges create in analyze view
  MERGES_CREATED: {
    name: "Merges created"
  },
  // Password policy change in profile
  PASSWORD_POLICY_CHANGED: {
    name: "Password policy changed"
  },
  PDF_PRINTED: {
    name: "Survey PDF printed"
  },
  // Personal data search button is clicked
  PERSONAL_DATA_SEARCH: {
    name: "Personal data search used"
  },
  // Survey preview image is set
  PREVIEW_IMAGE_SET: {
    name: "Survey preview image is set"
  },
  // Report created in analyze view
  REPORT_CREATED: {
    name: "Report created"
  },
  // Result share used in analyze view
  RESULT_SHARE: {
    name: "Result share created"
  },
  // Result time range selected in analyze view
  RESULT_TIME_RANGE: {
    name: "Result time range selected"
  },
  // Code snippet is added to survey
  SNIPPET_ADDED: {
    name: "Code snippet added"
  },
  // Social media button clicked
  SOME_BUTTON_CLICKED: {
    name: "Social media button clicked"
  },
  // SSO configuration created
  SSO_CONF_CREATED: {
    name: "SSO configuration created"
  },
  // Survey closed from home view
  SURVEY_CLOSED: {
    name: "Survey closed"
  },
  // Survey deleted in home view
  SURVEY_DELETED: {
    name: "Survey deleted"
  },
  // Survey duplicated in home view
  SURVEY_DUPLICATED: {
    name: "Survey duplicated"
  },
  // Survey keyword added in home view
  SURVEY_KEYWORD_ADD: {
    name: "Survey keyword added"
  },
  // Survey launched from home view
  SURVEY_LAUNCHED: {
    name: "Survey launched"
  },
  // Survey list mouse right button clicked
  SURVEY_LIST_CLICK: {
    name: "Survey list right click"
  },
  // Survey opened from home view
  SURVEY_OPENED: {
    name: "Survey opened"
  },
  // Survey ownership moved in home view
  SURVEY_OWNERSHIP_MOVED: {
    name: "Survey ownership changed"
  },
  // Public share disabled in home view
  SURVEY_PUBLIC_SHARE_DISABLED: {
    name: "Survey public share disabled"
  },
  // Public share enabled in home view
  SURVEY_PUBLIC_SHARE_ENABLED: {
    name: "Survey public share enabled"
  },
  // Survey renamed in home view
  SURVEY_RENAMED: {
    name: "Survey renamed"
  },
  // Survey shared from home view
  SURVEY_SHARED: {
    name: "Survey shared"
  },
  // Team renamed in team view
  TEAM_RENAMED: {
    name: "Team renamed"
  },
  // Theme saved in style view
  THEME_SAVED: {
    name: "Theme saved"
  },
  // User added in team view
  USER_ADDED: {
    name: "User added"
  },
  // User edited in team view
  USER_EDITED: {
    name: "User edited"
  }
}

export const PAGE_TYPE = {
  ELEMENT: "element",
  POPUP: "popup"
};