import * as ChangeOrgActions from "./crmChangeOrgActions";
import cloneDeep from "lodash.clonedeep";

export const defaultState = {
	changeOrg: {
		selectedOrganization: null,
		warning: null
	}
};

export default function(state = defaultState, action)
{
	// Clone to avoid manipulation of original values
	const nextState = cloneDeep(state);
	switch (action.type)
	{
		case ChangeOrgActions.SELECT_ORGANIZATION:
			nextState.changeOrg.selectedOrganization = action.organization;
			nextState.changeOrg.warning = null;
			return nextState;

		case ChangeOrgActions.SHOW_WARNING:
			nextState.changeOrg.warning = action.warning;
			return nextState;

		default:
			return nextState;
	}
};
