define("utils/intercom", function(require)
{
	var Class = require("core/class");
	var Environment = require("utils/environment");
	var Topics = require("conf/topics");

	return Class.extend({
		running: false,
		storage: {},
		platformVersions: {
			DYNAMIC: "Dynamic surveys",
			STATIC: "Static surveys"
		},

		init: function()
		{
			Surveypal.events.subscribe(Topics.INTERCOM.TRY.topic, this.tryIntercom.bind(this));
			Surveypal.events.subscribe(Topics.INTERCOM.BOOT.topic, this.boot.bind(this));
			Surveypal.events.subscribe(Topics.INTERCOM.SHUTDOWN.topic, this.shutdown.bind(this));
		},

		reset: function()
		{
			this.storage = {};
		},

		getBootObject: function()
		{
			// Basic user info
			var bootObject = this.getUserObject();
			bootObject.app_id = Environment.getIntercomIdentifier();
			bootObject.company = this.getCompanyObject();
			return bootObject;
		},

		isRunning: function()
		{
			return this.running;
		},

		boot: function()
		{
			if(typeof Intercom != "undefined" && !this.isRunning())
			{
				this.tryIntercom('boot', this.getBootObject());
				this.running = true;
			}
		},

		shutdown: function()
		{
			if(typeof Intercom != "undefined" && this.isRunning())
			{
				this.tryIntercom('shutdown');
				this.running = false;
				this.reset();
			}
		},

		event: function(name)
		{
			if(typeof Intercom != "undefined" && this.isRunning())
			{
				this.tryIntercom('trackEvent', name);
			}
		},

		getUserObject: function()
		{
			const user = Surveypal.getUser();
			const organization = user.getOrganization();

			var userObject = {
				email: user.getUsername(),
				created_at: Math.floor(user.getCreated()/1000),
				name: user.getFullname(),
				user_id: user.getId(),
				language_override: user.getLocale().split("_")[0],
				role: user.getRole(),
				platform_version: (user.isSPAFormEnabled() ? this.platformVersions.DYNAMIC : this.platformVersions.STATIC),
				"organization_real_name": organization.name,
				"organization_id": organization.id,
				"license_type": user.getLicense().type
			};

			return userObject;
		},

		getCompanyObject: function()
		{
			try
			{
				var user = Surveypal.getUser();

				var account = user.getAccount();
				var organization = user.getOrganization();
				var pack = account.package;
				var companyObject = {
					creditcard: pack.creditcard,
					status: pack.status,
					amount: pack.amount,
					monthly_spend: (pack.subscription == "month" ? pack.amount : pack.amount / 12),
					currency: pack.currency,
					subscription: pack.subscription,
					id: organization.id,
					plan: user.getLicense().type,
					name: organization.name,
					source: user.getLicense().source
				};

				// Override amount and monthly spend if enterprise
				if(companyObject.plan == "enterprise")
				{
					companyObject.amount = 0;
					companyObject.monthly_spend = 0;
				}
				return companyObject;
			}
			catch(ex)
			{
				//Output error instead of throwing error, because this routine has been written so that it relies on
				//not crashing on an error.
				console.log("!!! The following error is nondisruptive but indicative of something wrong.");
				console.error(ex);
				console.log("!!! Non-disruptive error ends here.");

				return {};
			}
		},

		// Can be used to send changes in the user, for example after locale change
		updateUser: function()
		{
			if(typeof Intercom != "undefined" && this.isRunning())
			{
				var userObject = this.getUserObject();
				this.tryIntercom('update', userObject);
			}
		},

		// Can be used to send changes in the company, for example after plan change
		updateCompany: function()
		{
			if(typeof Intercom != "undefined" && this.isRunning())
			{
				var companyObject = this.getCompanyObject();
				this.tryIntercom('update', { "company": companyObject });
			}
		},

		// Update answer amount to intercom
		updateAnswerAmount: function(amount)
		{
			if(typeof Intercom != "undefined" && this.isRunning())
			{
				this.tryIntercom('update', { "company": { "answer-amount": amount }});
			}
		},

		tryIntercom: function()
		{
			try
			{
				Intercom.apply(null, arguments);
			}
			catch (ex)
			{
				console.error("Intercom.io fail");
				//Output error instead of throwing error, because this routine has been written so that it relies on
				//not crashing on an error.
				console.log("!!! The following error is nondisruptive but indicative of something wrong.");
				console.error(ex);
				console.log("!!! Non-disruptive error ends here.");
			}
		}
	});
});