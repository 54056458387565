import React, {useState, useRef, useEffect} from "react";
import SelectableItem from "./SelectableItem/SelectableItem";
import "./SelectionList.scss";

/**
 *  <SelectionList/> renders a scrollable list with items.
 *
 * @param className {string}
 * @param id {string}
 * @param children {JSX.Element[]}
 * @returns {JSX.Element|JSX.Element[]}
 */
const SelectionList = ({
	className,
	id,
	children
}) => {
	const [stickiesHeight, setStickiesHeight] = useState(0);
	const stickiesRef = useRef();

	const stickies = React.Children.toArray(children)
		.filter(child => child.props.sticky)

	const nonstickies = React.Children.toArray(children)
		.filter(child => !child.props.sticky)

	useEffect(() => {
		if (stickiesRef.current)
		{
			setStickiesHeight(stickiesRef.current.getBoundingClientRect().height);
		}
	}, []);

	return (
		<div
			className={`SelectionList ${className ? className : ""}`}
			id={id}>
			{
				stickies.length > 0 &&
				<div
					className={"stickies"}
					ref={stickiesRef}>
					{stickies}
				</div>
			}
			<div
				className={"nonstickies"}
				style={{
					marginTop: `${stickiesHeight}px`
				}}>
				{nonstickies}
			</div>
		</div>
	);
};

export {SelectionList, SelectableItem};