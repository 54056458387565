import React from "react";
import PopupR from "ui/PopupR";
import PopupRichContent from "ui/PopupRichContent";
import {connect} from "react-redux";
import "../../automaticReportingTypes";

/**
 * @callback onConfirm
 */
/**
 * @callback onCancel
 */
/**
 * <MassDeleteConfirmationPopup/> asks the user to confirm the deletion of several scheduled reports.
 *
 * @param reportsToDelete {Report[]}
 * @param onConfirm {onConfirm}
 * @param onCancel {onCancel}
 * @returns {JSX.Element}
 */
const MassDeleteConfirmationPopup = ({
	reportsToDelete,
	onConfirm,
	onCancel
}) => {
	return (
		<PopupR
			title={translator.get("automaticReporting.automaticReporting")}
			cancelCallback={onCancel}
			cancelText={translator.get("common.no")}
			submitCallback={onConfirm}
			submitText={translator.get("common.yes")}>
			<PopupRichContent
				title={translator.get("automaticReporting.massDeleteConfirmTitle")}>
				<p>{translator.get("automaticReporting.massDeleteConfirmText")}</p>
				<p>
					{
						reportsToDelete.map(report => report.name).join(", ")
					}
				</p>
			</PopupRichContent>
		</PopupR>
	);
};

export default connect(state => ({
	reportsToDelete: state.automaticReporting.existingReports.massDelete.reportsToDelete
}))(MassDeleteConfirmationPopup);