import {removeSurveyDeletionTask} from "../../../surveysActions";
import axios from "core/axios-sp";

/**
 * cancelDeletion cancels the deletion task associated with the selected survey.
 *
 * @param surveyId {number}
 */
export const cancelDeletion = (surveyId) => {
	axios.delete("/app/survey/deletion/task", {
		params: {
			json: {
				surveyId
			}
		}
	}).then((response) => {
		if (response.status === 200)
		{
			Surveypal.store.dispatch(removeSurveyDeletionTask(surveyId));
		}
	});
};