import {
	setSelectedSurveys,
	setSelectedFolders
} from "../../../surveysActions";
import {FILE_TYPES} from "../List";

/**
 * toggleSelection takes the user selection from the list to state.
 *
 * @param e {object}
 * @param clickedIndex {number} The clicked item's index in the current list of displayed items
 * @param type {"SURVEY"|"FOLDER"}
 * @param displayedItems {object[]} The full list of currently displayed items
 * @param selectedSurveyIds {number[]} List of IDs of the currently selected surveys
 * @param selectedFolderIds {number[]} List of IDs of the currently selected folder
 */
export const toggleSelection = (e, clickedIndex, type, displayedItems, selectedSurveyIds, selectedFolderIds) => {
	const itemId = displayedItems[clickedIndex].id;

	const itemAlreadySelected = (
		(type === FILE_TYPES.FOLDER && selectedFolderIds.indexOf(itemId) !== -1)
		||
		selectedSurveyIds.indexOf(itemId) !== -1
	);

	if (!e.shiftKey && !e.ctrlKey)
	{
		if (itemAlreadySelected && selectedFolderIds.length + selectedSurveyIds.length === 1)
		{
			Surveypal.store.dispatch(setSelectedSurveys([]));
			Surveypal.store.dispatch(setSelectedFolders([]));
			return;
		}

		if (type === FILE_TYPES.SURVEY)
		{
			Surveypal.store.dispatch(setSelectedSurveys([itemId]));
			Surveypal.store.dispatch(setSelectedFolders([]));
			return;
		}

		Surveypal.store.dispatch(setSelectedSurveys([]));
		Surveypal.store.dispatch(setSelectedFolders([itemId]));
		return;
	}

	if (e.ctrlKey)
	{
		if (itemAlreadySelected)
		{
			if (type === FILE_TYPES.SURVEY)
			{
				const newSelection = selectedSurveyIds.filter(id => id !== itemId);
				Surveypal.store.dispatch(setSelectedSurveys(newSelection));
				return;
			}

			const newSelection = selectedFolderIds.filter(id => id !== itemId);
			Surveypal.store.dispatch(setSelectedFolders(newSelection));
			return;
		}

		if (type === FILE_TYPES.SURVEY)
		{
			const newSelection = [...selectedSurveyIds, itemId];
			Surveypal.store.dispatch(setSelectedSurveys(newSelection));
			return;
		}

		const newSelection = [...selectedFolderIds, itemId];
		Surveypal.store.dispatch(setSelectedFolders(newSelection));
		return;
	}

	if (e.shiftKey)
	{
		if (selectedSurveyIds.length === 0 && selectedFolderIds.length === 0)
		{
			if (type === FILE_TYPES.SURVEY)
			{
				Surveypal.store.dispatch(setSelectedSurveys([itemId]));
				Surveypal.store.dispatch(setSelectedFolders([]));
				return;
			}

			Surveypal.store.dispatch(setSelectedSurveys([]));
			Surveypal.store.dispatch(setSelectedFolders([itemId]));
			return;
		}

		const firstSelectedIndex = displayedItems.findIndex(item => selectedSurveyIds.indexOf(item.id) !== -1 || selectedFolderIds.indexOf(item.id) !== -1);
		const startRangeFrom = Math.min(firstSelectedIndex, clickedIndex);
		const rangeLength = Math.abs(firstSelectedIndex - clickedIndex) + 1;
		const newSelectionIndices = Array(rangeLength).fill(0).map((_, i) => startRangeFrom + i);
		const {newSurveySelection, newFolderSelection} = newSelectionIndices.reduce((carry, newSelectionIndex) => {
			const currentType = displayedItems[newSelectionIndex]._fileType;
			if (currentType === FILE_TYPES.SURVEY)
			{
				return {
					...carry,
					newSurveySelection: [...carry.newSurveySelection, displayedItems[newSelectionIndex].id]
				};
			}

			return {
				...carry,
				newFolderSelection: [...carry.newFolderSelection, displayedItems[newSelectionIndex].id]
			};
		}, {newSurveySelection: [], newFolderSelection: []});
		Surveypal.store.dispatch(setSelectedSurveys(newSurveySelection));
		Surveypal.store.dispatch(setSelectedFolders(newFolderSelection));
	}
};