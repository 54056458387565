define("core/view-manager", function(require)
{
	var Class = require("core/class");
	var Hash = require("utils/hash");

	return Class.extend(
		{
			init: function(view_container, view_index, views, parent)
			{
				this._view_container = view_container;                           // Container for a subview
				this._view_index = view_index;                                   // The index of the view hash to be used
				this._views = views;                                             // All valid views for this manager
				this._parent = parent;                                           // The instance that owns this view manager
				if(!this._views)
				{
					this._views = {};
				}
				this._views.public = [].concat(this._views.public);                       // Append rights for roles
				this._views.visitor = this._views.public.concat(this._views.visitor);
				this._views.user = this._views.visitor.concat(this._views.user);
				this._views.admin = this._views.user.concat(this._views.admin);
				this._views.sales = this._views.admin.concat(this._views.sales);
				this._views.support = this._views.sales.concat(this._views.support);
				this._views.super_admin = this._views.support.concat(this._views.super_admin);
				this._view = null;                                               // Current view reference
				this._view_old = null;                                           // Old view reference
			},

			getContainer: function ()
			{
				if(this.getViewIndex() === 0 && !!Surveypal)
				{
					var height = jQuery(window).height();
					height = height - Surveypal.notificationCount() * 40;
					this._view_container.height(height);
				}
				return this._view_container;
			},

			getViewIndex: function()
			{
				return this._view_index;
			},

			getViews: function (role)
			{
				return this._views[role];
			},

			getView: function ()
			{
				return this._view;
			},

			setView: function(view)
			{
				this.setOldView(this._view);
				this._view = view;
			},

			setOldView: function(view)
			{
				this._view_old = view;
			},

			unsetOldView: function()
			{
				if (this._view_old)
				{
					this._view_old.stop();
					this._view_old.detach();
				}
			},

			getParent: function()
			{
				return this._parent;
			},

			createView: function (ViewClass) // View class is a class definition of the requested class
			{
				if(this.getView() instanceof ViewClass && !this.getView().isLogin() && !this.getView().isSetPassword())
				{
					//delegate the hashchange to possible subviews
					this.getView().hashChange();
				}
				else
				{
					// Create a new instance
					new ViewClass(this);
				}
			},

			getViewHash: function()
			{
				var hash = new Hash();
				return hash.get("view");
			},

			getViewPath: function()
			{
				var hash_view = this.getViewHash();
				var path = '';
				if(hash_view)
				{
					var sections = hash_view.split('/');
					for(var i = 0; i < this.getViewIndex(); i++)
					{
						path += sections[i];
						if(i < this.getViewIndex() - 1)
						{
							path += '/';
						}
					}
				}
				return path;
			},

			getViewName: function()
			{
				return this.getViewHashSection(this.getViewIndex());
			},

			getSubviewName: function()
			{
				return this.getViewHashSection(this.getViewIndex() + 1);
			},

			getViewHashSection: function(index)
			{
				var hash_view = this.getViewHash();
				var section = null;
				if(hash_view)
				{
					var sections = hash_view.split('/');
					section = sections[index];
				}
				return section;
			},

			swap: function(view)
			{
				view.start();
				this.unsetOldView();
				view.attach(this.getContainer());
				view.fit();

				// Delegate if a subview
				if(!this.getSubviewName() || this.getView().isLogin() || this.getView().isSetPassword())
				{
					this.getParent().viewReady();
				}
			}
		}
	);
});