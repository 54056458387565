import {
	moveSurveyToFolder,
	undeleteSurvey
} from "../../../surveysActions";
import axios from "core/axios-sp";

/**
 * restoreSurvey marks the selected surveys as no longer deleted.
 *
 * @param restoredSurveys {object[]}
 */
export const restoreSurvey = (restoredSurveys) => {
	const folderIds = Surveypal.store.getState().surveys.fetchables.folders.folders.map(folder => folder.id);

	restoredSurveys.forEach((survey) => {
		const params = {
			id: survey.id
		};

		const postData = encodeURI("json=" + JSON.stringify(params));

		axios.post("/app/home/survey/undelete", postData).then((response) => {
			if (response.status === 200)
			{
				//If the original folder of the survey no longer exists, set its folder to root (0).
				if (folderIds.indexOf(survey.parentFolderId) === -1)
				{
					Surveypal.store.dispatch(moveSurveyToFolder(survey.id, 0));
				}

				Surveypal.store.dispatch(undeleteSurvey(survey.id));
			}
		});
	});
};