import { createNewRuleFromTemplate } from "common/rules/rule-utils";
import clone from "lodash.clonedeep";
import assign from "lodash.assign";
import { IMPORTED_STATUS_CODES } from "./libraries/filterOrAutomationImportingUtils";
const RuleConf = require("common/rules/rule-conf");

export const CREATE_FILTER = "SURVEY.RESULT.FILTERS.CREATE_FILTER";
export const COPY_FILTER = "SURVEY.RESULT.FILTERS.COPY_FILTER";
export const DELETE_FILTER = "SURVEY.RESULT.FILTERS.DELETE_FILTER";
export const SELECT_FILTER = "SURVEY.RESULT.FILTERS.SELECT_FILTER";
export const INITIAL_DATA_LOADING = "SURVEY.RESULT.FILTERS.INITIAL_DATA_LOADING";
export const INITIAL_DATA_LOADED = "SURVEY.RESULT.FILTERS.INITIAL_DATA_LOADED";
export const SURVEYS_WITH_FILTERS_LOADING = "SURVEY.RESULT.FILTERS.SURVEYS_WITH_FILTERS_LOADING";
export const SURVEYS_WITH_FILTERS_LOADED = "SURVEY.RESULT.FILTERS.SURVEYS_WITH_FILTERS_LOADED";
export const HIDE_SURVEY_SELECTION_POPUP = "SURVEY.RESULT.FILTERS.HIDE_SURVEY_SELECTION_POPUP";
export const LOAD_FILTERS_FROM_SURVEY_LOADING = "SURVEY.RESULT.FILTERS.LOAD_FILTERS_FROM_SURVEY_LOADING";
export const LOAD_FILTERS_FROM_SURVEY_LOADED = "SURVEY.RESULT.FILTERS.LOAD_FILTERS_FROM_SURVEY_LOADED";
export const COPY_LOADED_FILTERS_TO_SURVEY = "SURVEY.RESULT.FILTERS.COPY_LOADED_FILTERS_TO_SURVEY";
export const HIDE_FILTER_SELECTION_POPUP = "SURVEY.RESULT.FILTERS.HIDE_FILTER_SELECTION_POPUP";
export const HIDE_FILTERS_IMPORTED_POPUP = "SURVEY.RESULT.FILTERS.HIDE_FILTERS_IMPORTED_POPUP";
export const EDIT_FILTER = "SURVEY.RESULT.FILTERS.EDIT_FILTER";
export const RENAME_FILTER = "SURVEY.RESULT.FILTERS.RENAME_FILTER";
export const CHANGE_ROOT_OPERATOR = "SURVEY.RESULT.FILTERS.CHANGE_ROOT_OPERATOR";
export const CHANGE_GROUP_OPERATOR = "SURVEY.RESULT.FILTERS.CHANGE_GROUP_OPERATOR";
export const CHANGE_RULE_TYPE = "SURVEY.RESULT.FILTERS.CHANGE_RULE_TYPE";
export const CHANGE_RULE_OPERATOR = "SURVEY.RESULT.FILTERS.CHANGE_RULE_OPERATOR";
export const CHANGE_RULE_VALUE = "SURVEY.RESULT.FILTERS.CHANGE_RULE_VALUE";
export const DELETE_RULE = "SURVEY.RESULT.FILTERS.DELETE_RULE";
export const DUPLICATE_RULE = "SURVEY.RESULT.FILTERS.DUPLICATE_RULE";
export const CREATE_NEW_RULE = "SURVEY.RESULT.FILTERS.CREATE_NEW_RULE";
export const ADD_NEW_CONDITION_GROUP = "SURVEY.RESULT.FILTERS.ADD_NEW_CONDITION_GROUP";
export const REMOVE_CONDITION_GROUP = "SURVEY.RESULT.FILTERS.REMOVE_CONDITION_GROUP";

export function loadingInitialData()
{
	return {
		type: INITIAL_DATA_LOADING
	}
}

export function initialDataLoaded(filters, sourceItems, metaItems, elementItems, languageItems)
{
	return {
		type: INITIAL_DATA_LOADED,
		filters,
		sourceItems,
		metaItems,
		elementItems,
		languageItems
	}
}

export function surveysWithFiltersLoading()
{
	return {
		type: SURVEYS_WITH_FILTERS_LOADING
	}
}

export function surveysWithFiltersLoaded(surveys)
{
	return {
		type: SURVEYS_WITH_FILTERS_LOADED,
		surveys: surveys
	}
}

export function hideSurveySelectionPopup()
{
	return {
		type: HIDE_SURVEY_SELECTION_POPUP,
		showSurveySelectionPopup: false
	}
}

export function loadFiltersFromSurveyLoading()
{
	return {
		type: LOAD_FILTERS_FROM_SURVEY_LOADING
	}
}

export function loadFiltersFromSurveyLoaded(status, filters)
{
	return {
		type: LOAD_FILTERS_FROM_SURVEY_LOADED,
		filtersFetchedStatus: status,
		loadedFilters: filters,
		showFiltersImportedPopup: status !== IMPORTED_STATUS_CODES.OK
	}
}

export function copyLoadedFiltersToSurvey(existingFilters, selectedLoadedFilters, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	const concatenatedFilters = existingFilters.concat(selectedLoadedFilters);
	return {
		type: COPY_LOADED_FILTERS_TO_SURVEY,
		filters: concatenatedFilters,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	}
}

export function hideFiltersImportedPopup()
{
	return {
		type: HIDE_FILTERS_IMPORTED_POPUP,
		showFiltersImportedPopup: false
	}
}

export function hideFilterSelectionPopup()
{
	return {
		type: HIDE_FILTER_SELECTION_POPUP
	}
}

export function getNewDummyId(id)
{
	return "new-filter-" + id;
}

function getNewDummyGroup(rules)
{
	return [{
		match: RuleConf.ROOT_OPERATORS.FILTERS_ALL.value,
		rules: rules
	}];
}

function prepareCopiedFilter(filter)
{
	filter.groups = handleFilterGroups(filter.groups);

	return filter;
}

export function handleFilterGroups(groups)
{
	groups.forEach(group => {
		delete group.id;
		group.rules.forEach(rule => {
			delete rule.id;
		});
	});

	return groups;
}

export function createNewDummyFilter(id, orderValue)
{
	const ruleTemplate = RuleConf.FILTER_RULES[0];
	const rules = [createNewRuleFromTemplate(ruleTemplate)];
	return {
		match: RuleConf.ROOT_OPERATORS.FILTERS_ALL.value,
		name: translator.get("filters.newFilter"),
		id: getNewDummyId(id),
		groups: getNewDummyGroup(rules),
		isNew: true,
		selected: true,
		order: orderValue
	};
}

// Other simple actions
export function createFilterAction(id = Date.now(), orderValue = undefined)
{
	return {
		type: CREATE_FILTER,
		filter: createNewDummyFilter(id, orderValue)
	};
}

export function deleteFilterAction(filterId)
{
	return {
		type: DELETE_FILTER,
		filterId: filterId
	};
}

export function selectFilterAction(filterId)
{
	return {
		type: SELECT_FILTER,
		filterId: filterId
	};
}

export function copyFilterAction(originFilter, id = Date.now())
{
	const newFilter = assign(clone(originFilter), {
		id: getNewDummyId(id),
		isNew: true
	});
	return {
		type: COPY_FILTER,
		newFilter: prepareCopiedFilter(newFilter),
		originalFilter: originFilter
	};
}

export function editFilterAction(filterId)
{
	return {
		type: EDIT_FILTER,
		filterId: filterId
	};
}

export function renameFilterAction(filterId, value)
{
	return {
		type: RENAME_FILTER,
		filterName: value,
		filterId: filterId
	};
}

export function changeRootOperatorAction(filterId, rootOperatorValue)
{
	return {
		type: CHANGE_ROOT_OPERATOR,
		filterId: filterId,
		rootOperatorValue: rootOperatorValue
	};
}

export function changeGroupOperatorAction(filterId, groupIndex, operatorValue)
{
	return {
		type: CHANGE_GROUP_OPERATOR,
		filterId: filterId,
		groupIndex: groupIndex,
		operatorValue: operatorValue
	};
}

export function changeRuleTypeAction(filterId, groupIndex, ruleIndex, ruleKey, operator, value, ruleType, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	return {
		type: CHANGE_RULE_TYPE,
		filterId: filterId,
		groupIndex: groupIndex,
		ruleIndex: ruleIndex,
		ruleKey: ruleKey,
		ruleType: ruleType,
		operator: operator,
		value: value,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	};
}

export function changeRuleOperatorAction(filterId, groupIndex, ruleIndex, operator, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	return {
		type: CHANGE_RULE_OPERATOR,
		filterId: filterId,
		groupIndex: groupIndex,
		ruleIndex: ruleIndex,
		operator: operator,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	};
}

export function changeRuleValueAction(filterId, groupIndex, ruleIndex, value, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	return {
		type: CHANGE_RULE_VALUE,
		filterId: filterId,
		groupIndex: groupIndex,
		ruleIndex: ruleIndex,
		value: value,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	};
}

export function deleteRuleAction(filterId, groupIndex, ruleIndex, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	return {
		type: DELETE_RULE,
		filterId: filterId,
		groupIndex: groupIndex,
		ruleIndex: ruleIndex,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	};
}

export function duplicateRuleAction(filterId, groupIndex, ruleIndex)
{
	return {
		type: DUPLICATE_RULE,
		filterId: filterId,
		groupIndex: groupIndex,
		ruleIndex: ruleIndex
	};
}

export function createRuleAction(filterId, groupIndex, newRule)
{
	return {
		type: CREATE_NEW_RULE,
		filterId: filterId,
		groupIndex: groupIndex,
		newRule: newRule
	};
}

export function addNewConditionGroup(filterId)
{
	const ruleTemplate = RuleConf.FILTER_RULES[0];
	const rules = [createNewRuleFromTemplate(ruleTemplate)];
	const newGroup = {
		match: "ALL",
		rules: rules
	};

	return {
		type: ADD_NEW_CONDITION_GROUP,
		filterId: filterId,
		newGroup: newGroup
	}
}

export function removeConditionGroup(filterId, groupIndex, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	return {
		type: REMOVE_CONDITION_GROUP,
		filterId: filterId,
		groupIndex: groupIndex,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	}
}