export const SUBVIEW_PATHS = {
	ALL_SURVEYS: "all",
	DELETED_SURVEYS: "deleted",
	OVERVIEW: "overview",
	SHARED_SURVEYS: "shared",
	KEYWORDS: "tags",
	KEYWORD: "tag",
	TEAM_SURVEYS: "team",
	YOUR_SURVEYS: "own",
	ZERO_SURVEYS: "zero"
};

export const SURVEYS_SUBVIEWS = {
	public: [],
	visitor: [],
	user: [
		{
			route: SUBVIEW_PATHS.YOUR_SURVEYS,
			view: null
		},
		{
			route: SUBVIEW_PATHS.SHARED_SURVEYS,
			view: null
		},
		{
			route: SUBVIEW_PATHS.ALL_SURVEYS,
			view: null
		},
		{
			route: SUBVIEW_PATHS.KEYWORDS,
			view: null
		},
		{
			route: SUBVIEW_PATHS.KEYWORD,
			view: null
		},
		{
			route: SUBVIEW_PATHS.DELETED_SURVEYS,
			view: null
		}
	],
	admin: [],
	sales: [],
	support: [],
	super_admin: []
};
