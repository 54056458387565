import React, {useEffect} from "react";
import {connect} from "react-redux";
import {isFeatureEnabled} from "utils/organizationFeatures";
import {StepDivision} from "ui/Wizard/Wizard";
import {
	setEmailTo,
	setEmailSubject
} from "../../../automaticReportingActions";

/**
 * Email settings for the Destination step.
 *
 * @param reportName {string}
 * @param to {string}
 * @param subject {string|null}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const EmailSettings = ({
	reportName,
	to,
	subject,
	dispatch
}) => {
	if (!isFeatureEnabled("SCHEDULED_EXPORTS"))
	{
		return (
			<StepDivision
				className={"settings"}
				title={translator.get("automaticReporting.shareViaDisabledTitle")}>
				<p>{translator.get("automaticReporting.shareViaDisabledText")}</p>
			</StepDivision>
		);
	}

	//For user convenience, assume that the user wants the email subject to tbe the same as the report name.
	useEffect(() => {
		if (subject === null)
		{
			dispatch(setEmailSubject(reportName));
		}
	});

	return (
		<StepDivision
			className={"settings"}
			title={translator.get("automaticReporting.emailDetails")}>
			<div>
				<label htmlFor={"emailTo"}>{translator.get("automaticReporting.emailTo")}</label>
				<input
					type={"text"}
					id={"emailTo"}
					className={"text"}
					value={to}
					onChange={(e) => dispatch(setEmailTo(e.target.value))}
				/>
			</div>
			<div>
				<label htmlFor={"emailSubject"}>{translator.get("automaticReporting.emailSubject")}</label>
				<input
					type={"text"}
					id={"emailSubject"}
					className={"text"}
					value={subject === null ? reportName : subject}
					onChange={(e) => dispatch(setEmailSubject(e.target.value))}
				/>
			</div>
		</StepDivision>
	)
};

export default connect(state => ({
	reportName: state.automaticReporting.createNewReport.formatAndContent.reportName,
	to: state.automaticReporting.createNewReport.destination.email.to,
	subject: state.automaticReporting.createNewReport.destination.email.subject
}))(EmailSettings);