import React from "react";
import PopupR from "ui/PopupR";
import PopupRichContent from "ui/PopupRichContent";

/**
 * @callback onConfirm
 */
/**
 * @callback onCancel
 */
/**
 * <ConfirmationPopup/> asks the user to confirm the deletion of a scheduled report.
 *
 * @param reportName {string}
 * @param onConfirm {onConfirm}
 * @param onCancel {onCancel}
 * @returns {JSX.Element}
 */
const ConfirmationPopup = ({reportName, onConfirm, onCancel}) => {
	return (
		<PopupR
			title={translator.get("automaticReporting.automaticReporting")}
			cancelCallback={onCancel}
			cancelText={translator.get("common.no")}
			submitCallback={onConfirm}
			submitText={translator.get("common.yes")}>
			<PopupRichContent
				title={translator.get("automaticReporting.confirmDeletion")}>
				<p>{translator.get("automaticReporting.confirmDeletionText")}</p>
				<p><strong>{reportName}</strong></p>
			</PopupRichContent>
		</PopupR>
	);
};

export default ConfirmationPopup;