import RegExp from "utils/regexp";

/**
 * parseTags parses tags from a survey object.
 *
 * @param survey {object}
 * @returns {string[]}
 */
export const parseTags = (survey) => {
	if (!survey.tags || (typeof survey.tags === "string" && survey.tags.trim() === "")) return [];

	const tagsString = survey.tags.trim();
	return tagsString.match(RegExp.TAG).map(tag => {
		//Remove quotes from multiword tags.
		if (tag[0] === "\"" && tag[tag.length-1] === "\"") return tag.substring(1, tag.length-1);

		return tag;
	});
};