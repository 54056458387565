import React from "react";
import DropdownItem from "./DropdownItem/DropdownItem";
import "./DropdownMenu.scss";

/**
 * @typedef Item
 * @type {object}
 * @property text {string}
 * @property value {T}
 */
/**
 * @typedef ItemGroup
 * @type {Item[]}
 */
/**
 * @callback onClick
 * @param clickedValue {T}
 */
/**
 * Menu for the dropdown component.
 *
 * @param narrow {boolean}
 * @param items {ItemGroup[]}
 * @param selectedValue {T}
 * @param onClick {onClick}
 * @param filter {string}
 * @returns {JSX.Element}
 */
const DropdownMenu = ({
	narrow,
	items,
	onClick,
	selectedValue,
	filter
}) => {
	return (
		<div className={"dropdown-v2-menu"}>
			{
				items.map((itemGroup) => {
					const key = itemGroup.reduce((carry, item) => `${carry}${item.value}`, "dropdown-item-group");

					return (
						<div
							key={key}
							className={"dropdownItemGroup"}>
							{
								itemGroup.filter(item => item.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1).map((item) => (
									<DropdownItem
										narrow={narrow}
										key={`dropdown-item-${item.value}`}
										onClick={() => onClick(item.value)}
										shouldScrollTo={selectedValue === item.value}>
										{item.text}
									</DropdownItem>
								))
							}
						</div>
					);
				})
			}
		</div>
	);
};

export default DropdownMenu;