export const ALL_ELEMENTS_FILTER = "ALL_ELEMENTS";
export const CONDITIONAL_ELEMENTS_FILTER = "CONDITIONAL_ELEMENTS";
export const PAGES_FILTER = "PAGES";

export const TOGGLE_LOADER = "SURVEY.CONDITIONS.TOGGLE_RULE_LOADER";
export const SELECT_CATEGORY_FILTER = "SURVEY.CONDITIONS.SELECT_CATEGORY_FILTER";
export const HOVER_CONDITION = "SURVEY.CONDITIONS.HOVER";
export const CLEAR_HOVER = "SURVEY.CONDITIONS.CLEAR_HOVER";
export const SET_INITIAL_DATA = "SURVEY.CONDITIONS.SET_INITIAL_DATA";

export const selectCategoryFilter = (category) => {
	return {
		type: SELECT_CATEGORY_FILTER,
		category
	}
};

export function hoverElement(elementId, add)
{
	return {
		type: HOVER_CONDITION,
		elementId,
		add
	}
}

export function clearHover()
{
	return {
		type: CLEAR_HOVER
	}
}

export function toggleLoader(show)
{
	return {
		type: TOGGLE_LOADER,
		show
	};
}

export function initialDataLoaded(sourceItems, metaItems, languageItems, elementItems, ruleElementItems, conditions, conditionId)
{
	return {
		type: SET_INITIAL_DATA,
		sourceItems,
		metaItems,
		elementItems,
		ruleElementItems,
		languageItems,
		conditions,
		conditionId
	};
}