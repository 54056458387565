import React, {useState, useRef} from "react";
import {connect} from "react-redux";
import Checkbox from "ui/Checkbox/Checkbox";
import {COLUMNS} from "../List";
import {
	toggleColumn
} from "../../../surveysActions";
import "../../../surveysTypes";
import "./ColumnSelection.scss";

/**
 * ColumnSelection lets the user select which columns to display in the survey list table.
 *
 * @param columns {Columns}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const ColumnSelection = ({
	columns,
	dispatch
}) => {
	const [showSelection, setShowSelection] = useState(false);
	const [menuStyle, setMenuStyle] = useState({});
	const iconReference = useRef();

	const toggle = () => {
		if (showSelection)
		{
			setMenuStyle({});
			setShowSelection(false);
			return;
		}

		const referencePosition = iconReference.current.getBoundingClientRect();
		setMenuStyle({
			position: "fixed",
			top: `${referencePosition.y}px`,
			left: `${referencePosition.x}px`
		});
		setShowSelection(true);
	};

	return (
		<>
			<div
				ref={iconReference}
				className={"ColumnSelectionButton"}
				onClick={toggle}>
				<span>{null}</span>
			</div>
			{
				showSelection &&
				<div
					style={menuStyle}
					className={"ColumnSelectionWrapper"}>
					<div
						className={"ColumnSelectionButton"}
						onClick={toggle}>
						<span>{null}</span>
					</div>
					<ul>
						<li>
							<Checkbox
								label={translator.get("common.id")}
								checked={columns[COLUMNS.SURVEY_ID]}
								onSelect={() => dispatch(toggleColumn(COLUMNS.SURVEY_ID))}
							/>
						</li>
						<li>
							<Checkbox
								label={translator.get("home.ownerHeader")}
								checked={columns[COLUMNS.OWNER]}
								onSelect={() => dispatch(toggleColumn(COLUMNS.OWNER))}
							/>
						</li>
						<li>
							<Checkbox
								label={translator.get("home.modifiedHeader")}
								checked={columns[COLUMNS.LAST_MODIFIED]}
								onSelect={() => dispatch(toggleColumn(COLUMNS.LAST_MODIFIED))}
							/>
						</li>
						<li>
							<Checkbox
								label={translator.get("home.answerCountHeader")}
								checked={columns[COLUMNS.RESPONSES]}
								onSelect={() => dispatch(toggleColumn(COLUMNS.RESPONSES))}
							/>
						</li>
						<li>
							<Checkbox
								label={translator.get("home.newResponsesHeader")}
								checked={columns[COLUMNS.NEW_RESPONSES]}
								onSelect={() => dispatch(toggleColumn(COLUMNS.NEW_RESPONSES))}
							/>
						</li>
						<li>
							<Checkbox
								label={translator.get("home.statusHeader")}
								checked={columns[COLUMNS.SURVEY_STATUS]}
								onSelect={() => dispatch(toggleColumn(COLUMNS.SURVEY_STATUS))}
							/>
						</li>
					</ul>
				</div>
			}
		</>
	);
};

export default connect(state => ({
	columns: state.surveys.list.columns
}))(ColumnSelection);