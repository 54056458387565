import React from "react";
import "./StepDivision.scss";

/**
 * <StepDivision/> is a component for a subdivision of one step/page of the wizard.
 *
 * @param className {string}
 * @param title {string}
 * @param segregate {boolean}
 * @param children {JSX.Element}
 * @returns {JSX.Element}
 */
const StepDivision = ({
	className,
	title,
	segregate,
	children
}) => {
	const classes = `StepDivision ${className ? className : ""} ${segregate ? "segregate" : ""}`;

	return (
		<div className={classes}>
			{
				!!title &&
				<div className={"StepDivisionTitle title font-title20"}>{title}</div>
			}
			<div className={"StepDivisionContent"}>
				{children}
			</div>
		</div>
	);
};

export default StepDivision;