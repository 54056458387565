import React from "react";
import {connect} from "react-redux";
import TimeSelection from "ui/TimeSelection/TimeSelection";
import {
	setDayOfMonth,
	setFiringHours,
	setFiringMinutes
} from "../../../automaticReportingActions";
import NumberInput from "ui/NumberInput/NumberInput";
import "../../../automaticReportingTypes";
import "./Monthly.scss";

/**
 * This component is the monthly automatic reporting timing settings.
 *
 * @param firing {FiringTime}
 * @param settings {Monthly}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const Monthly = ({
	firing,
	settings,
	dispatch
}) => {
	return (
		<div className={"monthly"}>
			<div className={"monthlySetting"}>
				<div className={"dayOfMonthSelection"}>
					<label>{translator.get("automaticReporting.selectDayOfMonth")}</label>
					<NumberInput
						className={"dayOfMonthInput"}
						value={settings.dayOfMonth}
						onChange={(newValue) => dispatch(setDayOfMonth(newValue))}
						minimum={1}
						maximum={31}
						increments={[1, 5]}
						decrements={[1, 5]}
					/>
				</div>
				<div className={"timeOfDaySelection"}>
					<label>{translator.get("automaticReporting.at")}</label>
					<TimeSelection
						value={firing}
						onChangeHours={(newHours) => dispatch(setFiringHours(newHours))}
						onChangeMinutes={(newMinutes) => dispatch(setFiringMinutes(newMinutes))}
					/>
				</div>
			</div>
			{
				settings.dayOfMonth > 28 &&
				<div className={"dayOfMonthWarning"}>
					{translator.get("automaticReporting.dayOfMonthWarning").replace("${n}", settings.dayOfMonth)}
				</div>
			}
		</div>
	)
};

export default connect(state => ({
	firing: state.automaticReporting.createNewReport.schedule.firing,
	settings: state.automaticReporting.createNewReport.schedule.monthly
}))(Monthly);