import React from "react";
import LifeSaver from "ui/LifeSaverR";
import "./RadioButton.scss";

/**
 * @callback onChange
 * @param newValue {T}
 */
/**
 * @typedef Option
 * @type {object}
 * @property value {T}
 * @property text {string}
 * @property disabled {boolean}
 * @property tooltip {string}
 */
/**
 * Radio Button react component.
 *
 * @param className {string}
 * @param onChange {onChange}
 * @param selected {T}
 * @param options {Option[]}
 * @returns {JSX.Element}
 */
const RadioButton = ({className, onChange, selected, options}) => {
	return (
		<div className={`RadioButtonGroup ${className}`}>
			{
				options.map((option) => (
					<div
						key={`radio-option-${option.value}`}
						className={`radiobutton-v2 ${option.disabled ? "disabled" : ""} ${selected === option.value ? "selected" : ""}`}
						onClick={() => !option.disabled && onChange(option.value)}>
						<div className={"radio-circle"}>
							<div className={"selection-indicator"}/>
						</div>
						<div className={"radio-label"}>{option.text}</div>
						{
							option.tooltip &&
							<LifeSaver text={option.tooltip}/>
						}
					</div>
				))
			}
		</div>
	);
};

export default RadioButton;