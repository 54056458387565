import ClosePopup from "../../../../ClosePopup/close-popup";
import EVENTS from "conf/ai-events";
import axios from "core/axios-sp";
import {fetchSurveys} from "../../../surveysActions";

/**
 * requestCloseSurvey sends a request to the backend to close a survey, then refetches surveys
 * to update the list.
 *
 * @param surveyId {number}
 */
export const requestCloseSurvey = (surveyId) => {
	Surveypal.applicationInsights.trackEvent(EVENTS.SURVEY_CLOSED);

	axios.put("/app/survey/status", {
		id: surveyId,
		active: false
	}).then((response) => {
		fetchSurveys();
	});
};

/**
 * closeSurvey diplays a popup to confirm if the user wants to close the selected survey.
 *
 * @param surveyId {number}
 */
export const closeSurvey = (surveyId) => {
	new ClosePopup(() => requestCloseSurvey(surveyId));
};