define("core/locale", function(require)
{
	var Class = require("core/class");
	var Environment = require("utils/environment");

	return Class.extend({
		init: function()
		{
			this.data = {};
			this.replacementMap = {
				"capitalDomain": Environment.getCapitalizedDomain(),
				"helpdeskEmail": Environment.getHelpdeskEmail()
			};
		},

		define: function(language)
		{
			jQuery.extend(true, this.data, language);
		},

		get: function(language, group, identifier)
		{
			var locale = " ";
			try
			{
				if(!language)
				{
					// Everything
					locale = this.data;
				}
				else if(!group)
				{
					// Whole language
					locale = this.data[language];
				}
				else if(!identifier)
				{
					// Whole group
					locale = this.data[language][group];
				}
				else if(this.data[language] && this.data[language][group] && this.data[language][group][identifier])
				{
					// Normal text
					locale = this.data[language][group][identifier];
				}
				else if(this.data["en_US"][group][identifier])
				{
					// Try to fetch english translation or empty string
					locale = this.data["en_US"][group][identifier];
				}
			}
			catch(ex)
			{
				//Output error instead of throwing error, because this routine has been written so that it relies on
				//not crashing on an error.
				console.log("!!! The following error is nondisruptive but indicative of something wrong.");
				console.error(ex);
				console.log("!!! Non-disruptive error ends here.");
			}
			return this.replacePlaceholders(locale);
		},

		replacePlaceholders: function(str)
		{
			if (typeof str !== "string")
			{
				return str;
			}
			var newStr = str;
			Object.keys(this.replacementMap).forEach(function(key){
				var value = this.replacementMap[key];
				var newRegex = new RegExp("--" + key + "--", "g");
				newStr = newStr.replace(newRegex, value);
			}.bind(this));

			return newStr;
		}
	});
});