// Usage:
// var regexp = require("utils/regexp");
// var b = regexp.EMAIL.test(str);
// or
// str.split(regexp.TAG);

// Create regexp for email
const chars = "[a-z0-9!#$%&'*+/=?^_`{|}~-]";
const local = '(?:' + chars + '\\.?){0,63}' + chars;
const label = "[a-z0-9!#$%&'*+-/=?^_`{|}~]{2,61}";
const hostname = '(?:' + label + '\\.)+' + label;
const octet = '(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';
const ipv4 = '\\[(?:' + octet + '\\.){3}' + octet + '\\]';
const domain = '(?:' + hostname + '|' + ipv4 + ')';

// Return collection
module.exports = {
	EMAIL: new RegExp('^' + local + '@' + domain + '$', 'i'), // This one has some issues still: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}/gi,
	PASSWORD: /^(?=.{8,})(?:(?!(?:(.)\1{2,}|[0-9]{3,})).)*$/,
	PHONE_NUMBER: /^[\+]?[(]?[0-9]{1,}[)]?[-\s\.]?[0-9]{2,}[-\s\.]?[0-9]{2,}$/im,
	SEPARATOR: /[,;\s](?![\w\s]+}})/gi,
	SUBSTITUTION_VARIABLE: new RegExp('^[{]{2}[^{]+[}]{2}$', 'i'),
	TAG: /\"[^\"]*\"|'[^']*'|\S+/gi,
	WHITESPACE: /\s/gi,
	WEBSITE: /^(?:(http|https)+:\/\/)+[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/m,
	escape: function(s)
	{
		return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
	}
};