define("monitors/monitor", function(require)
{
	var Class = require("core/class");

	return Class.extend(
	{
		init: function()
		{

		},

		// Used for checking a condition ad-hoc
		poll: function(callback)
		{
			return true;
		}
	});
});