import React, {useState} from "react";
import PopupR from "ui/PopupR";
import PopupRichContent from "ui/PopupRichContent";
import "./ReauthPopup.scss";

/**
 * @callback onAccept
 * @param password {string}
 */
/**
 * @callback onClose
 */
/**
 * ReauthPopup displays a password popup before actions that require reauthentication.
 *
 * @param onAccept {onAccept}
 * @param onClose {onClose}
 * @returns {JSX.Element}
 */
const ReauthPopup = ({
	onAccept,
	onClose
}) => {
	const [password, setPassword] = useState("");
	const [incorrect, setIncorrect] = useState(false);

	const handleReauthResponse = (status, json) => {
		if (status === 200)
		{
			onAccept(password);
			return;
		}

		setIncorrect(true);
	};

	const reauth = () => {
		Surveypal.getUser().reauth(handleReauthResponse, password);
	};

	return (
		<PopupR
			classes={"ReauthPopup note"}
			title={translator.get("re-auth-popup.title")}
			cancelCallback={onClose}
			cancelText={translator.get("common.cancel")}
			submitCallback={() => reauth()}
			submitText={translator.get("common.ok")}
			submitSingleUse={false}>
			<PopupRichContent>
				<div className={"title font-title24"}>
					{translator.get("re-auth-popup.title")}
				</div>
				<div className={`desc font-normal ${incorrect ? "invalid" : ""}`}>
					{translator.get("re-auth-popup.password")}
				</div>
				<input
					type={"password"}
					className={`text ${incorrect ? "invalid" : ""}`}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onFocus={() => setIncorrect(false)}
				/>
			</PopupRichContent>
		</PopupR>
	);
};

export default ReauthPopup;