import React, {useState, useEffect, useMemo} from "react";
import {connect} from "react-redux";
import Item from "./Item/Item";
import SortIndicator from "./SortIndicator/SortIndicator";
import ColumnSelection from "./ColumnSelection/ColumnSelection";
import ContextMenuSurvey from "./ContextMenuSurvey/ContextMenuSurvey";
import ContextMenuFolder from "./ContextMenuFolder/ContextMenuFolder";
import {
	sortByName,
	sortById,
	sortByOwner,
	sortByModified,
	sortByResponses,
	sortByNewResponses,
	sortByStatus
} from "../../utils/sorters";
import {
	setSort,
	postSurveysAsRead
} from "../../surveysActions";
import Filters from "../../utils/filters";
import SecondaryFilters from "../../utils/secondaryFilters";
import {folderFilter} from "../../utils/folderFilter";
import {stringFilterSurvey} from "../../utils/stringFilterSurvey";
import {stringFilterFolder} from "../../utils/stringFilterFolder";
import {toggleSelection} from "./utils/toggleSelection";
import "../../surveysTypes";
import "./List.scss";

export const COLUMNS = {
	NAME: "name",
	SURVEY_ID: "survey-id",
	OWNER: "owner",
	LAST_MODIFIED: "last-modified",
	RESPONSES: "responses",
	NEW_RESPONSES: "new-responses",
	PUBLIC_SHARE: "public-share",
	SURVEY_STATUS: "survey-status"
};

export const FILE_TYPES = {
	SURVEY: "SURVEY",
	FOLDER: "FOLDER"
};

/**
 * FileListing lists the given folders and surveys.
 *
 * @param surveys {object[]}
 * @param folders {object[]}
 * @param currentFolderId {number}
 * @param templates {object[]}
 * @param filters {Filters}
 * @param columns {Columns}
 * @param sort {Sort}
 * @param selectedSurveyIds {number[]}
 * @param selectedFolderIds {number[]}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const List = ({
	surveys,
	folders,
	currentFolderId,
	templates,
	filters,
	columns,
	sort,
	selectedSurveyIds,
	selectedFolderIds,
	dispatch
}) => {
	const [showContextMenu, setShowContextMenu] = useState(false);
	const [contextMenuStyle, setContextMenuStyle] = useState({});

	const toggleContextMenu = (x, y) => {
		setShowContextMenu(true);
		setContextMenuStyle({
			left: x,
			top: y
		});
	};

	useEffect(() => {
		const unreadSelectedSurveys = selectedSurveyIds.filter(surveyId => {
			const selectedSurvey = surveys.find(survey => survey.id === surveyId);
			return selectedSurvey.newAnswerCount && selectedSurvey.newAnswerCount > 0;
		});

		if (unreadSelectedSurveys.length > 0) postSurveysAsRead(unreadSelectedSurveys);
	}, [selectedSurveyIds]);

	const toggleSort = (column, sorter) => {
		const newSort = {
			column,
			direction: -sort.direction,
			sorter
		};
		dispatch(setSort(newSort));
	};

	const mainFilter = (() => {
		if (filters.tag !== undefined) return (survey) => {
			return Filters[Filters.FILTER_NAMES.TAGGED_SURVEYS](survey, [filters.tag]);
		};

		if (filters.mainFilter === Filters.FILTER_NAMES.TAGGED_SURVEYS) return Filters[Filters.FILTER_NAMES.ZERO_SURVEYS];

		return Filters[filters.mainFilter];
	})();

	const folderedList =
		filters.mainFilter === Filters.FILTER_NAMES.YOUR_SURVEYS ||
		filters.mainFilter === Filters.FILTER_NAMES.SHARED_SURVEYS ||
		filters.mainFilter === Filters.FILTER_NAMES.ALL_SURVEYS;

	/*
	 * Filtering surveys and folders to choose which items to display in the file list.
	 *
	 * allFilteredSurveys is the list of surveys that match all filters regardless of their folder location.
	 * folderFilteredSurveys is the subset of those surveys that exist in the currently viewed folder.
	 *
	 * This is so that when the user is searching for surveys using the search field, we can first display the items in
	 * the currently viewed folder (primaryDisplayedItems) and then search results from all other folders
	 * (secondaryDisplayedItems).
	 */
	const currentFolder = folders.find(folder => folder.id === currentFolderId);

	const allFilteredSurveys = useMemo(() => surveys
		.filter(mainFilter)
		.filter((survey) => SecondaryFilters.surveyHasNewResponses(survey, filters.newResponsesFilter))
		.filter((survey) => SecondaryFilters.surveyIsShared(survey, filters.sharedFilter))
		.filter((survey) => SecondaryFilters.surveyIsTemplate(survey, templates, filters.templatesFilter))
		.filter((survey) => SecondaryFilters.surveyHasAutomaticReports(survey, filters.automaticReportsFilter))
		.filter((survey) => stringFilterSurvey(survey, filters.stringFilter))
		.map((survey) => ({...survey, _fileType: FILE_TYPES.SURVEY})),
		[surveys, sort, columns, filters]);

	const folderFilteredSurveys = useMemo(() => {
		if (!folderedList) return allFilteredSurveys;

		if (currentFolderId === 0)
		{
			return allFilteredSurveys.filter(survey => folders.findIndex(folder => folder.surveys.indexOf(survey.id) !== -1) === -1);
		}

		return folders.find(folder => folder.id === currentFolderId).surveys.map(surveyId => allFilteredSurveys.find(survey => survey.id === surveyId)).filter(survey => !!survey);
	}, [currentFolderId, allFilteredSurveys]);

	const filteredFolders = useMemo(() => folders
		.filter((folder) => (folderedList ? folderFilter(folder, currentFolder) : false))
		.filter((folder) => stringFilterFolder(folder, filters.stringFilter))
		.map((folder) => ({...folder, _fileType: FILE_TYPES.FOLDER})),
		[folders, currentFolderId, filters]);

	const primaryDisplayedItems = useMemo(() => [...filteredFolders, ...folderFilteredSurveys], [folderFilteredSurveys, filteredFolders]);

	const secondaryDisplayedItems = useMemo(() => {
			if (filters.stringFilter.trim() === "") return [];
			return allFilteredSurveys.filter(survey => folderFilteredSurveys.findIndex(folderSurvey => folderSurvey.id === survey.id) === -1)
		}, [allFilteredSurveys, folderFilteredSurveys]);

	//Two columns are there regardless of column selection: file icon and file name column.
	const columnCount = Object.keys(columns).filter(columnKey => columns[columnKey]).length + 2;

	return (
		<div className={"SurveyList"}>
			<table>
				<thead className={"SurveyFileListingHeader"}>
					<tr>
						<th className={"unsortable columnSelectionColumn"}>
							<ColumnSelection/>
						</th>
						<th className={`SurveyName ${sort.column === COLUMNS.NAME ? "sort" : ""}`} onClick={() => toggleSort(COLUMNS.NAME, sortByName)}>
							{translator.get("home.nameHeader")}
							<SortIndicator column={COLUMNS.NAME}/>
						</th>
						{
							columns[COLUMNS.SURVEY_ID] &&
							<th
								className={sort.column === COLUMNS.SURVEY_ID ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.SURVEY_ID, sortById)}>
								{translator.get("common.id")}
								<SortIndicator column={COLUMNS.SURVEY_ID}/>
							</th>
						}
						{
							columns[COLUMNS.OWNER] &&
							<th
								className={sort.column === COLUMNS.OWNER ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.OWNER, sortByOwner)}>
								{translator.get("home.ownerHeader")}
								<SortIndicator column={COLUMNS.OWNER}/>
							</th>
						}
						{
							columns[COLUMNS.LAST_MODIFIED] &&
							<th
								className={sort.column === COLUMNS.LAST_MODIFIED ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.LAST_MODIFIED, sortByModified)}>
								{translator.get("home.modifiedHeader")}
								<SortIndicator column={COLUMNS.LAST_MODIFIED}/>
							</th>
						}
						{
							columns[COLUMNS.RESPONSES] &&
							<th
								className={sort.column === COLUMNS.RESPONSES ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.RESPONSES, sortByResponses)}>
								{translator.get("home.answerCountHeader")}
								<SortIndicator column={COLUMNS.RESPONSES}/>
							</th>
						}
						{
							columns[COLUMNS.NEW_RESPONSES] &&
							<th
								className={sort.column === COLUMNS.NEW_RESPONSES ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.NEW_RESPONSES, sortByNewResponses)}>
								{translator.get("home.newResponsesHeader")}
								<SortIndicator column={COLUMNS.NEW_RESPONSES}/>
							</th>
						}
						{
							columns[COLUMNS.PUBLIC_SHARE] &&
							<th
								className={sort.column === COLUMNS.PUBLIC_SHARE ? "sort" : ""}
								onClick={() => {}}>
								{translator.get("home.publicShareHeader")}
								<SortIndicator column={COLUMNS.PUBLIC_SHARE}/>
							</th>
						}
						{
							columns[COLUMNS.SURVEY_STATUS] &&
							<th
								className={sort.column === COLUMNS.SURVEY_STATUS ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.SURVEY_STATUS, sortByStatus)}>
								{translator.get("home.statusHeader")}
								<SortIndicator column={COLUMNS.SURVEY_STATUS}/>
							</th>
						}
					</tr>
				</thead>
				<tbody>
				{
					primaryDisplayedItems.length >= 1
					?
					primaryDisplayedItems.map((item, index) => (
						<Item
							onSelect={(e) => toggleSelection(e, index, item._fileType, primaryDisplayedItems, selectedSurveyIds, selectedFolderIds)}
							onContextMenu={(x, y) => toggleContextMenu(x, y)}
							key={`item-1ry-${item._fileType}-${item.id}`}
							fileId={item.id}
							type={item._fileType}
						/>
					))
					:
					<tr>
						<td className={"note"} colSpan={columnCount}>{translator.get("home.nothingFolder")}</td>
					</tr>
				}
				{
					secondaryDisplayedItems.length >= 1 &&
					<>
						<tr>
							<td
								className={"note"}
								colSpan={columnCount}>
								{translator.get("home.otherFolders")}
							</td>
						</tr>
						{
							secondaryDisplayedItems.map((item, index) => (
								<Item
									onSelect={(e) => toggleSelection(e, index, item._fileType, secondaryDisplayedItems, selectedSurveyIds, selectedFolderIds)}
									onContextMenu={(x, y) => toggleContextMenu(x, y)}
									key={`item-2ry-${item._fileType}-${item.id}`}
									fileId={item.id}
									type={item._fileType}
								/>
							))
						}
					</>
				}
				</tbody>
			</table>
			{
				showContextMenu && selectedSurveyIds.length > 0 &&
				<ContextMenuSurvey
					onClose={() => setShowContextMenu(false)}
					style={contextMenuStyle}
				/>
			}
			{
				showContextMenu && selectedFolderIds.length > 0 &&
				<ContextMenuFolder
					onClose={() => setShowContextMenu(false)}
					style={contextMenuStyle}
				/>
			}
		</div>
	);
};

export default connect(state => ({
	surveys: state.surveys.fetchables.surveys.surveys,
	folders: state.surveys.fetchables.folders.folders,
	currentFolderId: state.surveys.list.folder,
	templates: state.surveys.fetchables.templates.templates,
	filters: state.surveys.list.filters,
	selectedSurveyIds: state.surveys.list.selectedSurveyIds,
	selectedFolderIds: state.surveys.list.selectedFolderIds,
	columns: state.surveys.list.columns,
	sort: state.surveys.list.sort
}))(List);