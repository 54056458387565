import clone from "lodash.clonedeep";
import {
	PHASE, CREATE_NEW_CONFIG, SET_PHASE, SET_CONFIGURATIONS, SET_SSO_NOTIFICATION, SET_PASSWORD_NOTIFICATION,
	TOGGLE_MANUAL_MODE, TOGGLE_ERROR, UPDATE_CONFIGURATION, CLEAR_ERRORS, SET_UNDO_CONFIGURATIONS, SET_SELECTED_SERVICE,
	TOGGLE_SINGLE_SIGNON, TOGGLE_PASSWORD_ENABLED, TOGGLE_NEW_CONFIGURATION, SET_PASSWORDLOGINCONF_ID, SET_PASSWORD_CONFIGURATION
} from "./SSOActions";

export const defaultState = {
	configType: "oidc",
	configurations: [],
	errors: [],
	initialConfiguration: null,
	manualParameters: [],
	newConfiguration: false,
	notification: null,
	notificationId: null,
	notificationType: null,
	notificationSection: null,
	passwordEnabled: true,
	passwordLoginConfId: null,
	passwordConfiguration: {},
	phase: PHASE.LOADING_CONFIGURATIONS,
	selectedService: "",
	shownConfiguration: null,
	singleSignOnEnabled: false,
	undoConfigurations: []
};

export default function reducer(state = defaultState, originalAction)
{
	const nextState =  clone(state);
	const action = clone(originalAction);
	switch(action.type)
	{
		case SET_PHASE:
			nextState.phase = action.phase;
			return nextState;
		case SET_CONFIGURATIONS:
			nextState.configurations = action.configurations;
			nextState.shownConfiguration = action.shownConfiguration;
			return nextState;
		case SET_UNDO_CONFIGURATIONS:
			nextState.undoConfigurations = action.configurations;
			return nextState;
		case SET_SSO_NOTIFICATION:
		case SET_PASSWORD_NOTIFICATION:
			nextState.notification = action.notification;
			nextState.notificationType = action.notificationType;
			nextState.notificationId = action.notificationId;
			nextState.notificationSection = (action.type === SET_SSO_NOTIFICATION ? "SSO" : "PASSWORD");
			return nextState;
		case CREATE_NEW_CONFIG:
			nextState.configurations.push(action.configuration);
			return nextState;
		case TOGGLE_MANUAL_MODE:
		{
			const existingIndex = nextState.manualParameters.findIndex(o => o.configurationId === action.configurationId && o.paramKey === action.paramKey);
			//Toggle this parameter to being manually edited
			if (existingIndex === -1 && action.toggle === true)
			{
				nextState.manualParameters.push({
					configurationId: action.configurationId,
					paramKey: action.paramKey
				});
			}
			//Remove specified manual parameter
			else if (existingIndex !== -1 && action.toggle === false)
			{
				nextState.manualParameters.splice(existingIndex, 1);
			}

			return nextState;
		}
		case TOGGLE_ERROR:
		{
			const existingIndex = nextState.errors.findIndex(e => e.configurationId === action.configurationId && e.paramKey === action.paramKey);
			if (action.toggle)
			{
				const errorObj = {
					error: action.error,
					configurationId: action.configurationId,
					paramKey: action.paramKey
				};
				if (existingIndex !== -1)
				{
					nextState.errors[existingIndex] = errorObj;
				}
				else
				{
					nextState.errors.push(errorObj);
				}
			}
			else if (existingIndex !== -1)
			{
				nextState.errors.splice(existingIndex, 1);
			}
			return nextState;
		}
		case CLEAR_ERRORS:
			nextState.errors = [];
			return nextState;
		case UPDATE_CONFIGURATION:
		{
			const existingIndex = nextState.configurations.findIndex(c => c.id === action.configuration.id);

			if (existingIndex !== -1)
			{
				nextState.configurations[existingIndex] = action.configuration;
			}
			return nextState;
		}
		case SET_SELECTED_SERVICE:
		{
			nextState.selectedService = action.selectedService;
			return nextState;
		}
		case TOGGLE_SINGLE_SIGNON:
		{
			nextState.singleSignOnEnabled = action.singleSignOnEnabled;
			return nextState;
		}
		case TOGGLE_PASSWORD_ENABLED:
		{
			nextState.passwordEnabled = action.passwordEnabled;
			return nextState;
		}
		case SET_PASSWORD_CONFIGURATION:
		{
			nextState.passwordConfiguration = action.passwordConfiguration;
			return nextState;
		}
		case TOGGLE_NEW_CONFIGURATION:
		{
			nextState.newConfiguration = action.newConfiguration;
			return nextState;
		}
		default:
			return nextState;
	}
}