define("conf/keycodes", function(require)
{
	return {
		"ENTER": 13,
		"ESC": 27,
		"DELETE": 46,
		"BACKSPACE": 8,
		"TAB": 9,
		"F1": 112,
		"UP": 38,
		"DOWN": 40,
		"SPACE": 32,
		"LEFT": 37,
		"RIGHT": 39,
		"ZERO": 48,
		"NINE": 57,
		"NUMPAD_ZERO": 96,
		"NUMPAD_NINE": 105,
		"DOT": 190,
		"COMMA": 188,
		"HYPHEN": 189,
		"DECIMAL_POINT": 110,
		"MINUS": 109,
		"PLUS": 107,
		"CTRL": 17,
		"S": 83
	};
});