import React from "react";
import "./LoadingIndicator.scss";

/**
 * Loading Indicator displays a text next to a loading/loaded icon.
 *
 * @param className {string}
 * @param loading {boolean}
 * @param text {string}
 * @returns {JSX.Element}
 */
const LoadingIndicator = ({className, loading, text}) => {
	return (
		<div className={`LoadingIndicator ${className ? className: ""}`}>
			<div className={`LoadingIndicatorIcon ${loading ? "loading" : "loaded"}`}>{null}</div>
			<div className={"LoadingIndicatorText"}>{text}</div>
		</div>
	);
};

export default LoadingIndicator;