import axios from "axios";
import axiosServiceRequest from "core/axios-sp-servicereq";
import {AUTOMATIC_REPORTS_DESTINATIONS} from "./AutomaticReporting";
import {SCHEDULING_MODES} from "./CreateNewReport/Schedule/Schedule";
import {REPORTS_PER_PAGE} from "./ExistingReports/ExistingReports";
import BaseDatamap from "conf/base-datamap";
import "./automaticReportingTypes";

export const ACTIONS = {
	//Actions for looking at existing reports
	SET_REPORT_COUNT: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_REPORT_COUNT",
	ADD_EXISTING_REPORTS: "SURVEY.RESULT.AUTOMATIC_REPORTING.ADD_EXISTING_REPORTS",
	REMOVE_EXISTING_REPORT: "SURVEY.RESULT.AUTOMATIC_REPORTING.REMOVE_EXISTING_REPORT",
	MARK_EXISTING_REPORTS_FINISHED: "SURVEY.RESULT.AUTOMATIC_REPORTING.MARK_EXISTING_REPORTS_FINISHED",
	SET_SELECTED_REPORT: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_SELECTED_REPORT",
	REMOVE_REPORT: "SURVEY.RESULT.AUTOMATIC_REPORTING.REMOVE_REPORT",

	//Actions for mass deleting reports
	SET_REPORTS_TO_DELETE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_REPORTS_TO_DELETE",
	SET_CONFIRM_MASS_DELETION: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_CONFIRM_DELETE_SEVERAL",
	ADD_SUCCESSFUL_DELETION: "SURVEY.RESULT.AUTOMATIC_REPORTING.ADD_SUCCESSFUL_DELETION",
	ADD_FAILED_DELETION: "SURVEY.RESULTS.AUTOMATIC_REPORTING.ADD_FAILED_DELETION",
	SET_EXECUTING_MASS_DELETION: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_EXECUTING_MASS_DELETION",
	SET_MASS_DELETION_ENDED: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_MASS_DELETION_ENDED",
	CLEAR_MASS_DELETION_PROGRESS: "SURVEY.RESULT.AUTOMATIC_REPORTING.CLEAR_MASS_DELETION_PROGRESS",

	//Actions for creating a new report
	SET_WIZARD_STEP: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_WIZARD_STEP",
	SET_SCHEDULE_STEP_AVAILABLE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_SCHEDULE_STEP_AVAILABLE",
	SET_DESTINATION_STEP_AVAILABLE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_DESTINATION_STEP_AVAILABLE",
	SET_FINISH_AVAILABLE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FINISH_AVAILABLE",

	//Create new step
	SET_NAME: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_NAME",
	SET_ALL_RESPONSES: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_ALL_RESPONSES",
	SET_SELECTED_FILTERS: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_SELECTED_FILTERS",
	SET_SELECTED_MERGED_DATA: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_SELECTED_MERGED_DATA",
	SET_REPORT_FORMAT: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_REPORT_FORMAT",
	SET_PPTX_THEME: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_PPTX_THEME",
	SET_EXCEL_97_TO_2003: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_EXCEL_97_TO_2003",
	SET_EXCEL_UNIQUE_IDS: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_EXCEL_UNIQUE_IDS",
	SET_CSV_UNIQUE_IDS: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_CSV_UNIQUE_IDS",
	SET_CSV_CHARSET: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_CSV_CHARSET",
	SET_SPSS_UNIQUE_IDS: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_SPSS_UNIQUE_IDS",
	SET_SPSS_STRING_VARIABLE_SIZE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_SPSS_STRING_VARIABLE_SIZE",
	SET_SPSS_STATIC_STRING_VARIABLE_SIZE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_SPSS_STATING_STRING_VARIABLE_SIZE",

	//Schedule step
	SET_SCHEDULING_MODE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_SCHEDULING_MODE",
	SET_WEEKDAY: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_WEEKDAY",
	SET_DAY_OF_MONTH: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_DAY_OF_MONTH",
	SET_CRON_EXPRESSION: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_CRON_EXPRESSION",
	SET_CRON_EXPRESSION_VALIDITY: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_CRON_EXPRESSION_VALIDITY",
	SET_FIRING_HOURS: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FIRING_HOURS",
	SET_FIRING_MINUTES: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FIRING_MINUTES",
	SET_START_DATE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_START_DATE",
	SET_START_HOURS: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_START_HOURS",
	SET_START_MINUTES: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_START_MINUTES",
	SET_END_DATE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_END_DATE",
	SET_END_HOURS: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_END_HOURS",
	SET_END_MINUTES: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_END_MINUTES",
	SET_PERPETUAL: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_PERPETUAL",

	//Destination step
	SET_DESTINATION_TYPE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_DESTINATION_TYPE",
	SET_EMAIL_TO: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_EMAIL_TO",
	SET_EMAIL_SUBJECT: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_EMAIL_SUBJECT",
	SET_FTP_PROTOCOL: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FTP_PROTOCOL",
	SET_FTP_HOST: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FTP_HOST",
	SET_FTP_PORT: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FTP_PORT",
	SET_FTP_USERNAME: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FTP_USERNAME",
	SET_FTP_PASSWORD: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FTP_PASSWORD",
	SET_FTP_PRIVATE_KEY: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FTP_PRIVATE_KEY",
	SET_FTP_PATH: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FTP_PATH",
	SET_FTP_NAME_TEMPLATE: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FTP_NAME_TEMPLATE",

	SET_FETCHED_FILTERS: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FETCHED_FILTERS",
	SET_FETCHED_PPTX_THEMES: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FETCHED_PPTX_THEMES",
	SET_FETCHED_DEFAULT_PPTX_THEME: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FETCHED_DEFAULT_PPTX_THEME",
	SET_FETCHED_MERGED_DATA: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_FETCHED_MERGED_DATA",
	SET_REPORT_CREATED_SUCCESSFULLY: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_REPORT_CREATED_SUCCESSFULLY",
	SET_REPORT_CREATION_ERROR: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_REPORT_CREATION_ERROR",

	//General
	CLEAR_AUTOMATIC_REPORTING_STATE: "SURVEY.RESULT.AUTOMATIC_REPORTING.CLEAR_AUTOMATIC_REPORTING_STATE",
	CLEAR_NEW_REPORT: "SURVEY.RESULT.AUTOMATIC_REPORTING.CLEAR_NEW_REPORT",
	SET_TAB: "SURVEY.RESULT.AUTOMATIC_REPORTING.SET_TAB"
};

/**
 * Actions for looking at existing reports
 */
/**
 * addExistingReports - adds an array of existing reports to the existing array of existing reports.
 *
 * @param reports {Report[]} Array of reports.
 * @returns {{payload: Report[], type: string}}
 */
export const addExistingReports = (reports) => ({
	type: ACTIONS.ADD_EXISTING_REPORTS,
	payload: reports
});

/**
 * removeExistingReport - removes a single existing report from state.
 *
 * @param reportId {number}
 * @returns {{payload: number, type: string}}
 */
export const removeExistingReport = (reportId) => ({
	type: ACTIONS.REMOVE_EXISTING_REPORT,
	payload: reportId
});

/**
 * markExistingReportsFinished - switches the flag indicating that laoding existing reports is finished.
 *
 * @param finished {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const markExistingReportsFinished = (finished) => ({
	type: ACTIONS.MARK_EXISTING_REPORTS_FINISHED,
	payload: finished
});

/**
 * fetchExistingReports - loops through the existing reports and fetches them page by page.
 *
 * @param count {number} the Number of existing reports as returned by /app/survey/export/scheduled/count
 * @param dispatch {function}
 */
export const fetchExistingReports = (count, dispatch) => {
	const fetchNextPage = (offset) => {
		axios.get("/app/survey/export/scheduled/list", {
			params: {
				json: {
					surveyId: Surveypal.getSurvey().getId(),
					offset: offset,
					limit: REPORTS_PER_PAGE
				}
			}
		}).then(response => {
			if (response.status === 200)
			{
				dispatch(addExistingReports(response.data));
			}

			if (offset + REPORTS_PER_PAGE >= count)
			{
				dispatch(markExistingReportsFinished(true));
				return;
			}

			fetchNextPage(offset + REPORTS_PER_PAGE);
		});
	};

	fetchNextPage(0);
};

/**
 * startFetchingExistingReports - starts the process of fetching existing reports.
 *
 * First fetches the number of existing reports, then calls fetchExistingReports() actually fetch them.
 *
 * @param dispatch {function}
 */
export const startFetchingExistingReports = (dispatch) => {
	dispatch(setReportCount({
		loading: true,
		count: undefined
	}));

	axios.get("/app/survey/export/scheduled/count", {
		params: {
			json: {
				surveyId: Surveypal.getSurvey().getId()
			}
		}
	}).then(response => {
		if (response.status === 200)
		{
			dispatch(setReportCount({
				loading: false,
				count: response.data.count
			}));
		}

		if (response.data.count === 0)
		{
			dispatch(markExistingReportsFinished(true));
			return;
		}

		dispatch(markExistingReportsFinished(false));
		fetchExistingReports(response.data.count, dispatch);
	});
};

/**
 * setReportCount - sets the number of existing reports to state.
 *
 * @param count {number}
 * @returns {{payload: number, type: string}}
 */
export const setReportCount = (count) => ({
	type: ACTIONS.SET_REPORT_COUNT,
	payload: count
});

/**
 * setSelectedReport - marks which report the user is viewing in the Existing Reports view.
 *
 * @param reportId {number}
 * @returns {{payload, type: string}}
 */
export const setSelectedReport = (reportId) => ({
	type: ACTIONS.SET_SELECTED_REPORT,
	payload: reportId
});

/**
 * removeReport - sends request to remove report from backend.
 *
 * @param reportId {number}
 * @param dispatch {function}
 */
export const removeReport = (reportId, dispatch) => {
	Surveypal.getLoader().show();
	axios.delete("/app/survey/export/scheduled", {
		params: {
			json: {
				id: reportId
			}
		}
	}).then(response => {
		Surveypal.getLoader().hide();
		if (response.status === 200)
		{
			dispatch(removeExistingReport(reportId));
		}
	});
};

/**
 * Actions for removing several reports.
 */

/**
 * setReportsToDelete - sets an array of reports that are marked as to-be-deleted.
 *
 * @param reports {Report[]}
 * @returns {{payload: Report[], type: string}}
 */
export const setReportsToDelete = (reports) => ({
	type: ACTIONS.SET_REPORTS_TO_DELETE,
	payload: reports
});

/**
 * setConfirmMassDeletion - show or not show the user a confirmation popup for mass deletion.
 *
 * @param showConfirmation {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setConfirmMassDeletion = (showConfirmation) => ({
	type: ACTIONS.SET_CONFIRM_MASS_DELETION,
	payload: showConfirmation
});

/**
 * setExecutingMassDeletion - show or not show the user the mass deletion progress popup.
 *
 * @param showMassDeletionProgress {boolean}
 * @returns {{payload, type: string}}
 */
export const setExecutingMassDeletion = (showMassDeletionProgress) => ({
	type: ACTIONS.SET_EXECUTING_MASS_DELETION,
	payload: showMassDeletionProgress
});

/**
 * addSuccessfulDeletion - takes note of a report that was deleted successfully in a mass deletion.
 *
 * @param reportName {string} Name of the successfully deleted report. May be shown to the user.
 * @returns {{payload: string, type: string}}
 */
export const addSuccessfulDeletion = (reportName) => ({
	type: ACTIONS.ADD_SUCCESSFUL_DELETION,
	payload: reportName
});

/**
 * addFailedDeletion - takes note of a report that couldn't be deleted in a mass deletion.
 *
 * @param reportName {string} Name of the report. May be shown to the user.
 * @returns {{payload: string, type: string}}
 */
export const addFailedDeletion = (reportName) => ({
	type: ACTIONS.ADD_FAILED_DELETION,
	payload: reportName
});

/**
 * executeMassDeletion - performs a loop of removing checked existing reports.
 *
 * @param reportsToDelete {Report[]}
 * @param dispatch {function}
 */
export const executeMassDeletion = (reportsToDelete, dispatch) => {
	const deleteNextReport = (index) => {
		axios.delete("/app/survey/export/scheduled", {
			params: {
				json: {
					id: reportsToDelete[index].id
				}
			}
		}).then(response => {
			if (response.status === 200)
			{
				dispatch(addSuccessfulDeletion(reportsToDelete[index].name));
				dispatch(removeExistingReport(reportsToDelete[index].id));
			}
			else
			{
				dispatch(addFailedDeletion(reportsToDelete[index].name));
			}

			if (index === reportsToDelete.length - 1)
			{
				dispatch(setExecutingMassDeletion(false));
				dispatch(setMassDeletionEnded(true));
				return;
			}

			deleteNextReport(index + 1);
		});
	};

	//Start deletion from index 0.
	dispatch(setExecutingMassDeletion(true));
	deleteNextReport(0);
};

/**
 * setMassDeletionEnded - show mass deletion end popup.
 *
 * @param showMassDeletionEndedPopup {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setMassDeletionEnded = (showMassDeletionEndedPopup) => ({
	type: ACTIONS.SET_MASS_DELETION_ENDED,
	payload: showMassDeletionEndedPopup
});

/**
 * clearMassDeletionProgress - sets the list of both successful and failed deletions to [].
 *
 * @returns {{type: string}}
 */
export const clearMassDeletionProgress = () => ({
	type: ACTIONS.CLEAR_MASS_DELETION_PROGRESS
});

/**
 * Actions for creating a new report.
 */

/**
 * setWizardStep - changes the wizard step to given number.
 *
 * @param newStep {number} The step that the user goes to/is taken to.
 * @returns {{payload: number, type: string}}
 */
export const setWizardStep = (newStep) => ({
	type: ACTIONS.SET_WIZARD_STEP,
	payload: newStep
});

/**
 * setScheduleStepAvailable - Enables/disables the "next" button in the Format&Content step.
 *
 * @param scheduleStepAvailable {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setScheduleStepAvailable = (scheduleStepAvailable) => ({
	type: ACTIONS.SET_SCHEDULE_STEP_AVAILABLE,
	payload: scheduleStepAvailable
});

/**
 * setDestinationStepAvailable - Enables/disables the "next" button in the Schedule step.
 *
 * @param destinationStepAvailable {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setDestinationStepAvailable = (destinationStepAvailable) => ({
	type: ACTIONS.SET_DESTINATION_STEP_AVAILABLE,
	payload: destinationStepAvailable
});

/**
 * setFinishAvailable - Enables/disables the "finish" button in the Destination step.
 *
 * @param finishAvailable {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setFinishAvailable = (finishAvailable) => ({
	type: ACTIONS.SET_FINISH_AVAILABLE,
	payload: finishAvailable
});

/**
 * setName - set name for new report
 *
 * @param newName {string}
 * @returns {{payload: boolean, type: string}}
 */
export const setName = (newName) => ({
	type: ACTIONS.SET_NAME,
	payload: newName
});

/**
 * setAllResponses - set whether the user chooses to include all responses in the content.
 *
 * @param allResponsesIncluded {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setAllResponses = (allResponsesIncluded) => ({
	type: ACTIONS.SET_ALL_RESPONSES,
	payload: allResponsesIncluded
});

/**
 * setSelectedFilters - set which filters the user chooses to include in the content.
 *
 * @param newFilters {string[]}
 * @returns {{payload: string[], type: string}}
 */
export const setSelectedFilters = (newFilters) => ({
	type: ACTIONS.SET_SELECTED_FILTERS,
	payload: newFilters
});

/**
 * setSelectedMergedData - set which merged data the user chooses to include in the content.
 *
 * @param newMergedData {("base"|number)[]}
 * @returns {{payload: ("base"|number)[], type: string}}
 */
export const setSelectedMergedData = (newMergedData) => ({
	type: ACTIONS.SET_SELECTED_MERGED_DATA,
	payload: newMergedData
});

/**
 * setReportFormat - set which format the user chooses for the report.
 *
 * @param newFormat {string}
 * @returns {{payload: string, type: string}}
 */
export const setReportFormat = (newFormat) => ({
	type: ACTIONS.SET_REPORT_FORMAT,
	payload: newFormat
});

/**
 * setPptxTheme - set which Powerpoint theme the user chooses for the Powerpoint report.
 *
 * @param newTheme {string}
 * @returns {{payload: string, type: string}}
 */
export const setPptxTheme = (newTheme) => ({
	type: ACTIONS.SET_PPTX_THEME,
	payload: newTheme
});

/**
 * setExcel97to2003 - set whether the user chooses the older Excel format.
 *
 * @param selected {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setExcel97to2003 = (selected) => ({
	type: ACTIONS.SET_EXCEL_97_TO_2003,
	payload: selected
});

/**
 * setExcelUniqueIds - set whether the user chooses to use unique ids in Excel.
 *
 * @param selected {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setExcelUniqueIds = (selected) => ({
	type: ACTIONS.SET_EXCEL_UNIQUE_IDS,
	payload: selected
});

/**
 * setCsvUniqueIds - set whether the user chooses to use unique ids in CSV.
 *
 * @param selected {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setCsvUniqueIds = (selected) => ({
	type: ACTIONS.SET_CSV_UNIQUE_IDS,
	payload: selected
});

/**
 * setCsvCharset - set which character encoding to use for CSV reports.
 *
 * @param newValue {CharsetOptions}
 * @returns {{payload: CharsetOptions, type: string}}
 */
export const setCsvCharset = (newValue) => ({
	type: ACTIONS.SET_CSV_CHARSET,
	payload: newValue
});

/**
 * setCsvUniqueIds - set whether the user chooses to use unique ids in SPSS.
 *
 * @param selected {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setSpssUniqueIds = (selected) => ({
	type: ACTIONS.SET_SPSS_UNIQUE_IDS,
	payload: selected
});

/**
 * setSpssStringVariableSize - set whether the user chooses to use dynamic or static string variable size in SPSS.
 *
 * @param variableSizeType {"dynamic"|"static"}
 * @returns {{payload: "dynamic"|"static", type: string}}
 */
export const setSpssStringVariableSize = (variableSizeType) => ({
	type: ACTIONS.SET_SPSS_STRING_VARIABLE_SIZE,
	payload: variableSizeType
});

/**
 * setSpssStaticStringVariableSize - set user-selected static string variable size in SPSS.
 *
 * @param newSize {number}
 * @returns {{payload: number, type: string}}
 */
export const setSpssStaticStringVariableSize = (newSize) => ({
	type: ACTIONS.SET_SPSS_STATIC_STRING_VARIABLE_SIZE,
	payload: newSize
});

/**
 * setSchedulingMode - set whether the user wants to create a weekly, monthly or cron-based (advanced) schedule
 *
 * @param newSchedulingMode {"WEEKLY"|"MONTHLY"|"ADVANCED"}
 * @returns {{payload: "WEEKLY"|"MONTHLY"|"ADVANCED", type: string}}
 */
export const setSchedulingMode = (newSchedulingMode) => ({
	type: ACTIONS.SET_SCHEDULING_MODE,
	payload: newSchedulingMode
});

/**
 * setWeekday - set user-selected weekday for weekly schedule
 *
 * @param newWeekday {number}
 * @returns {{payload: {number}, type: string}}
 */
export const setWeekday = (newWeekday) => ({
	type: ACTIONS.SET_WEEKDAY,
	payload: newWeekday
});

/**
 * setDayOfMonth - set user-selected day of month for monthly schedule
 *
 * @param newDayOfMonth {number}
 * @returns {{payload: number, type: string}}
 */
export const setDayOfMonth = (newDayOfMonth) => ({
	type: ACTIONS.SET_DAY_OF_MONTH,
	payload: newDayOfMonth
});

/**
 * setCronExpression - set cron expression for advanced schedule
 *
 * @param newExpression {string}
 * @returns {{payload: string, type: string}}
 */
export const setCronExpression = (newExpression) => ({
	type: ACTIONS.SET_CRON_EXPRESSION,
	payload: newExpression
});

/**
 * setCronExpressionValidity - set whether the user-entered cron expression is valid.
 *
 * @param validity {CronExpressionValidity}
 * @returns {{payload: CronExpressionValidity, type: string}}
 */
export const setCronExpressionValidity = (validity) => ({
	type: ACTIONS.SET_CRON_EXPRESSION_VALIDITY,
	payload: validity
});

/**
 * setFiringHours - set hours at which to run the report
 *
 * @param hours {number}
 * @returns {{payload: number, type: string}}
 */
export const setFiringHours = (hours) => ({
	type: ACTIONS.SET_FIRING_HOURS,
	payload: hours
});

/**
 * setFiringMinutes - set minutes at which to run the report
 *
 * @param minutes {number}
 * @returns {{payload: number, type: string}}
 */
export const setFiringMinutes = (minutes) => ({
	type: ACTIONS.SET_FIRING_MINUTES,
	payload: minutes
});

/**
 * setStartDate - set date on which to start the automation
 *
 * @param date {Date}
 * @returns {{payload: Date, type: string}}
 */
export const setStartDate = (date) => ({
	type: ACTIONS.SET_START_DATE,
	payload: date
});

/**
 * setStartHours - set hours on which to start the automation
 *
 * @param hours {number}
 * @returns {{payload: number, type: string}}
 */
export const setStartHours = (hours) => ({
	type: ACTIONS.SET_START_HOURS,
	payload: hours
});

/**
 * setStartMinutes - set minutes on which to start the automation
 *
 * @param minutes {number}
 * @returns {{payload: number, type: string}}
 */
export const setStartMinutes = (minutes) => ({
	type: ACTIONS.SET_START_MINUTES,
	payload: minutes
});

/**
 * setEndDate - set date on which to end the automation
 *
 * @param date {Date}
 * @returns {{payload: Date, type: string}}
 */
export const setEndDate = (date) => ({
	type: ACTIONS.SET_END_DATE,
	payload: date
});

/**
 * setEndHours - set hours at which to end the automation
 *
 * @param hours {number}
 * @returns {{payload: number, type: string}}
 */
export const setEndHours = (hours) => ({
	type: ACTIONS.SET_END_HOURS,
	payload: hours
});

/**
 * setEndMinutes - set minutes at which to end the automation
 *
 * @param minutes {number}
 * @returns {{payload: number, type: string}}
 */
export const setEndMinutes = (minutes) => ({
	type: ACTIONS.SET_END_MINUTES,
	payload: minutes
});

/**
 * setPerpetual - set whether user chooses to run the automation indefinitely
 *
 * @param perpetual {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setPerpetual = (perpetual) => ({
	type: ACTIONS.SET_PERPETUAL,
	payload: perpetual
});

/**
 * setDestinationType - set whether the user chooses to share report via email, FTP or SFTP.
 *
 * @param newDestinationType {"email"|"FTP"|"SFTP"}
 * @returns {{payload: "email"|"FTP"|"SFTP", type: string}}
 */
export const setDestinationType = (newDestinationType) => ({
	type: ACTIONS.SET_DESTINATION_TYPE,
	payload: newDestinationType
});

/**
 * setEmailTo - set new value for email address
 *
 * @param newAddress {string}
 * @returns {{payload: string, type: string}}
 */
export const setEmailTo = (newAddress) => ({
	type: ACTIONS.SET_EMAIL_TO,
	payload: newAddress
});

/**
 * setEmailSubject - set new value for email subject
 *
 * @param newSubject {string}
 * @returns {{payload: number, type: string}}
 */
export const setEmailSubject = (newSubject) => ({
	type: ACTIONS.SET_EMAIL_SUBJECT,
	payload: newSubject
});

/**
 * setFtpProtocol - set whether chosen FTP protocol is FTP or SFTP
 *
 * @param newProtocol {"FTP"|"SFTP"}
 * @returns {{payload: "FTP"|"SFTP", type: string}}
 */
export const setFtpProtocol = (newProtocol) => ({
	type: ACTIONS.SET_FTP_PROTOCOL,
	payload: newProtocol
});

/**
 * setFtpHost - set new FTP host value
 *
 * @param newHost {string}
 * @returns {{payload: string, type: string}}
 */
export const setFtpHost = (newHost) => ({
	type: ACTIONS.SET_FTP_HOST,
	payload: newHost
});

/**
 * setFtpPort - set new FTP port value
 *
 * @param newPort {number}
 * @returns {{payload: number, type: string}}
 */
export const setFtpPort = (newPort) => ({
	type: ACTIONS.SET_FTP_PORT,
	payload: newPort
});

/**
 * setFtpUsername - set new FTP username value
 *
 * @param newUsername {string}
 * @returns {{payload: string: number, type: string}}
 */
export const setFtpUsername = (newUsername) => ({
	type: ACTIONS.SET_FTP_USERNAME,
	payload: newUsername
});

/**
 * setFtpPassword - set new FTP password value
 *
 * @param newPassword {string}
 * @returns {{payload: string: number, type: string}}
 */
export const setFtpPassword = (newPassword) => ({
	type: ACTIONS.SET_FTP_PASSWORD,
	payload: newPassword
});

/**
 * setFtpPrivateKey - set new FTP private key value
 *
 * @param newPrivateKey {string}
 * @returns {{payload: string: number, type: string}}
 */
export const setFtpPrivateKey = (newPrivateKey) => ({
	type: ACTIONS.SET_FTP_PRIVATE_KEY,
	payload: newPrivateKey
});

/**
 * setFtpPath - set new FTP file path value
 *
 * @param newPath {string}
 * @returns {{payload: string: number, type: string}}
 */
export const setFtpPath = (newPath) => ({
	type: ACTIONS.SET_FTP_PATH,
	payload: newPath
});

/**
 * setFtpNameTemplate - set new FTP filename template value
 *
 * @param newTemplate {string}
 * @returns {{payload: string: number, type: string}}
 */
export const setFtpNameTemplate = (newTemplate) => ({
	type: ACTIONS.SET_FTP_NAME_TEMPLATE,
	payload: newTemplate
});

/**
 * fetchFilters - fetch filters from backend
 *
 * @param dispatch {function}
 */
export const fetchFilters = (dispatch) => {
	dispatch(setFetchedFilters({
		loading: true,
		filters: []
	}));

	axios.get("/app/result/filter/list", {
		params: {
			json: {
				id: Surveypal.getSurvey().getId()
			}
		}
	}).then(response => {
		if (response.status === 200)
		{
			dispatch(setFetchedFilters({
				loading: false,
				filters: [...response.data.filters]
			}));
		}
	});
};

/**
 * setFetchedFilters - set filters and their loading status to state
 *
 * @param filters {Filters}
 * @returns {{payload: Filters, type: string}}
 */
export const setFetchedFilters = (filters) => ({
	type: ACTIONS.SET_FETCHED_FILTERS,
	payload: filters
});

/**
 * fetchPptxThemes - fetch Powerpoint themes from backend
 *
 * @param dispatch {function}
 */
export const fetchPptxThemes = (dispatch) => {
	dispatch(setFetchedPptxThemes({
		loading: true,
		pptxThemes: []
	}));

	const serviceQueries = [{_req: "listPowerPointThemes"}]
	axiosServiceRequest.post("/app/j", serviceQueries).then((response) => {
		if (response.status === 200)
		{
			dispatch(setFetchedPptxThemes({
				loading: false,
				pptxThemes: response.data[0].themes
			}));
		}
	});
};

/**
 * setFetchedPptxThemes - set fetched Powerpoints and their loading status to state
 *
 * @param pptxThemes {PptxThemes}
 * @returns {{payload: PptxThemes, type: string}}
 */
export const setFetchedPptxThemes = (pptxThemes) => ({
	type: ACTIONS.SET_FETCHED_PPTX_THEMES,
	payload: pptxThemes
});

/**
 * fetchDefaultPptxTheme - fetch default Powerpoint theme from backend
 *
 * @param dispatch {function}
 */
export const fetchDefaultPptxTheme = (dispatch) => {
	dispatch(setFetchedDefaultPptxTheme({
		loading: true,
		defaultPptxTheme: {}
	}));

	axios.get("/app/style", {
		params: {
			json: {
				id: Surveypal.getSurvey().getId(),
				type: "powerpoint"
			}
		}
	}).then(response => {
		const resultingDefaultPptxTheme = (() => {
			switch (response.status)
			{
				case 200:
					return response.data;
				case 204:
					return null;
			}
		})();

		dispatch(setFetchedDefaultPptxTheme({
			loading: false,
			defaultPptxTheme: resultingDefaultPptxTheme
		}));
	});
};

/**
 * setFetchedDefaultPptxTheme - set fetched default Powerpoint and its loading status to state
 *
 * @param defaultPptxTheme {DefaultPptxTheme}
 * @returns {{payload: DefaultPptxTheme, type: string}}
 */
export const setFetchedDefaultPptxTheme = (defaultPptxTheme) => ({
	type: ACTIONS.SET_FETCHED_DEFAULT_PPTX_THEME,
	payload: defaultPptxTheme
});

/**
 * fetchMergedData - fetch merged data information from backend
 *
 * @param dispatch {function}
 */
export const fetchMergedData = (dispatch) => {
	dispatch(setFetchedMergedData({
		loading: true,
		mergedData: []
	}));

	axios.get("/app/result/mapping/list", {
		params: {
			json: {
				id: Surveypal.getSurvey().getId()
			}
		}
	}).then(response => {
		if (response.status === 200)
		{
			const survey = Surveypal.getSurvey();
			dispatch(setFetchedMergedData({
				loading: false,
				mergedData: [
					BaseDatamap(survey.get("name"), survey.get("analysisThreshold")),
					...response.data
				]
			}));
		}
	});
};

/**
 * setFetchedMergedData - set fetched merged data and their loading status to state
 *
 * @param mergedData {MergedData}
 * @returns {{payload: mergedData, type: string}}
 */
export const setFetchedMergedData = (mergedData) => ({
	type: ACTIONS.SET_FETCHED_MERGED_DATA,
	payload: mergedData
});

/**
 * sendNewReport - send new created scheduled report to backend for saving and activating
 *
 * @param formatAndContent {FormatAndContentSettings}
 * @param schedule {ScheduleSettings}
 * @param destination {DestinationSettings}
 * @param dispatch {function}
 */
export const sendNewReport = (
	formatAndContent,
	schedule,
	destination,
	dispatch
) => {
	const dateToYYYY_MM_DD = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

	const timeToMMSS = ({hours, minutes}) => `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

	const dataset = Surveypal.getView().getSubview().getWorkspace().getDataset();
	const aggregates = dataset.getDatamaps().slice();

	const cronExpression = (() => {
		switch (schedule.schedulingMode)
		{
			case SCHEDULING_MODES.WEEKLY:
			{
				const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][schedule.weekly.weekday];

				return `${schedule.firing.minutes} ${schedule.firing.hours} ? * ${weekday} *`;
			}
			case SCHEDULING_MODES.MONTHLY:
			{
				return `${schedule.firing.minutes} ${schedule.firing.hours} ${schedule.monthly.dayOfMonth} * ? *`;
			}
			case SCHEDULING_MODES.ADVANCED:
			{
				if (schedule.advanced.cronExpressionValidity.withSeconds.valid)
				{
					return schedule.advanced.cronExpression
						.split(" ")
						.filter((column, index) => index !== 0)
						.join(" ");
				}

				return schedule.advanced.cronExpression;
			}
		}
	})();

	const jsonSchedule = {
		cronExpression: cronExpression,
		date: `${dateToYYYY_MM_DD(schedule.start.date)}T${timeToMMSS(schedule.start)}`
	};

	if (!schedule.perpetual)
	{
		jsonSchedule.endDate = `${dateToYYYY_MM_DD(schedule.end.date)}T${timeToMMSS(schedule.end)}`;
	}

	const writerParams = {
		[formatAndContent.reportFormat]: (() => {
			switch (formatAndContent.reportFormat)
			{
				case "excel":
					return {
						use_unique_ids: formatAndContent.excel.useUniqueIds,
						format: formatAndContent.excel.excel97to2003 ? "97-2003" : null
					};
				case "pptx":
					return {
						theme: formatAndContent.pptx.theme
					};
				case "word":
					return {};
				case "csv":
					return {
						use_unique_ids: formatAndContent.csv.useUniqueIds,
						charset: formatAndContent.csv.charset
					};
				case "spss":
					return {
						dynamicVariableLength: formatAndContent.spss.stringVariableSize === "dynamic",
						variableLength: formatAndContent.spss.stringVariableSize === "dynamic"
						                ?
						                formatAndContent.spss.staticStringVariableSize
						                :
						                null,
						use_unique_ids: formatAndContent.spss.useUniqueIds
					};
				case "xml":
					return {};
			}
		})()
	};

	const destinations = (() => {
		switch (destination.destinationType)
		{
			case "email":
				return destination.email.to.split(" ").map(to => ({
					"@class": AUTOMATIC_REPORTS_DESTINATIONS["EMAIL"],
					subject: destination.email.subject,
					to: to
				}));
			case "ftp":
			case "sftp":
				return [{
					...destination.ftp,
					"@class": AUTOMATIC_REPORTS_DESTINATIONS["FTP"]
				}];
		}
	})();

	const json = {
		name: formatAndContent.reportName,
		ownerId: Surveypal.getUser().getId(),
		languageUuid: Surveypal.getSurvey().getLanguages().getCurrent(),
		type: formatAndContent.reportFormat,
		source: {
			survey: {
				id: Surveypal.getSurvey().getId()
			},
			allFilter: formatAndContent.allResponses,
			filters: formatAndContent.filters.map((filter) => ({id: filter.split("/")[2]})),
			showIndividualAnswerCount: dataset.getStatistics(),
			excludeTarget: !aggregates.some((aggregate) => aggregate.id === "base"),
			aggregates: aggregates.filter((aggregate) => aggregate.id !== "base").map((aggregate) => ({id: aggregate.id})),
			answerStates: Surveypal.store.getState().result.reportingSettings.answerStates
		},
		writerParams: writerParams,
		schedule: jsonSchedule,
		destinations: destinations
	}

	axios.put("/app/survey/export/scheduled", json)
		.then(response => {
			if (response.status === 200 || response.status === 201)
			{
				dispatch(setReportCreatedSuccessfully(true));
				dispatch(addExistingReports([response.data]));
				dispatch(setSelectedReport(response.data.id));
				return;
			}
			dispatch(setReportCreationError(response));
		});

	/*
	 * In case a developer needs to create a bunch of reports at once for developing or testing
	 * purposes, the following code can be used.
	 */

	/*
	const limit = 22;
	const createNextReport = (index) => {
		axios.put("/app/survey/export/scheduled", {...json, name: `${json.name} ${index}`})
			.then(response => {
				if (response.status === 200 || response.status === 201)
				{
					dispatch(addExistingReports([response.data]));
					dispatch(setSelectedReport(response.data.id));
					if (index === limit)
					{
						dispatch(setReportCreatedSuccessfully(true));
						return;
					}
					createNextReport(index + 1);
				}
				dispatch(setReportCreationError(response));
			});
	}

	createNextReport(1);
	*/
};

/**
 * setReportCreatedSuccessfully - show report creation success popup to user
 *
 * @param showSuccess {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setReportCreatedSuccessfully = (showSuccess) => ({
	type: ACTIONS.SET_REPORT_CREATED_SUCCESSFULLY,
	payload: showSuccess
});

/**
 * setReportCreationError - show report creation error popup to user
 *
 * @param showError {boolean}
 * @returns {{payload: boolean, type: string}}
 */
export const setReportCreationError = (showError) => ({
	type: ACTIONS.SET_REPORT_CREATION_ERROR,
	payload: showError
});

/**
 * clearAutomaticReportingState - return state back to default state
 *
 * @returns {{type: string}}
 */
export const clearAutomaticReportingState = () => ({
	type: ACTIONS.CLEAR_AUTOMATIC_REPORTING_STATE
});

/**
 * clearNewReport - return state relating to new report back to default
 *
 * @returns {{type: string}}
 */
export const clearNewReport = () => ({
	type: ACTIONS.CLEAR_NEW_REPORT
});

/**
 * setTab - change the tab within the Automatic Reporting popup
 *
 * @param newTab {string}
 * @returns {{payload: string, type: string}}
 */
export const setTab = (newTab) => ({
	type: ACTIONS.SET_TAB,
	payload: newTab
});