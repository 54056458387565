import React from "react";
import "./NumberInput.scss";

/**
 * @callback onChange
 * @param newValue {number}
 */
/**
 * Number Input creates an input field with plus/minus buttons for inputting just a number.
 *
 * Minimum and maximum values are inclusive.
 *
 * @param className {string}
 * @param value {number}
 * @param onChange {onChange}
 * @param minimum {number}
 * @param maximum {number}
 * @param decrements {number[]}
 * @param increment {number[]}
 * @returns {JSX.Element}
 */
const NumberInput = ({
	className,
	value,
	onChange,
	minimum,
	maximum,
	decrements,
	increments
}) => {
	/**
	 * change() calls the callback only if the new value is within the minimum/maximum boundary.
	 *
	 * @param newValue {number}
	 */
	const change = (newValue) => minimum <= newValue && newValue <= maximum && onChange(newValue);

	/**
	 * manualChange() is called when the user inputs a number manually. This function guards that the user only enters
	 * numbers and doesn't go beyond the minimum/maximum.
	 *
	 * @param newValue {string}
	 */
	const manualChange = (newValue) => {
		if (newValue.trim() === "")
		{
			change(Math.max(0, minimum));
			return;
		}
		if (!isNaN(newValue))
		{
			change(+newValue);
		}
	}

	/**
	 * disabled() determines whether the decrement/increment button should be disabled.
	 *
	 * @param prospectedValue {number}
	 * @returns {boolean}
	 */
	const disabled = (prospectedValue) => prospectedValue < minimum || maximum < prospectedValue;

	return (
		<div className={`NumberInput-v2 ${className ? className : ""}`}>
			{
				decrements.sort((a,b) => b-a).map((decrement) => (
					<div
						key={`NIB-dec-${decrement}`}
						className={`NumberInputButton decrement ${disabled(value - Math.abs(decrement)) ? "disabled" : ""}`}
						onClick={() => change(value - Math.abs(decrement))}>
						{-Math.abs(decrement)}
					</div>
				))
			}
			<input
				type={"text"}
				value={value}
				onChange={(e) => manualChange(e.target.value)}
			/>
			{
				increments.sort((a,b) => a-b).map((increment) => (
					<div
						key={`NIB-inc-${increment}`}
						className={`NumberInputButton increment ${disabled(value + Math.abs(increment)) ? "disabled" : ""}`}
						onClick={() => change(value + Math.abs(increment))}>
						+{Math.abs(increment)}
					</div>
				))
			}
		</div>
	)
};

export default NumberInput;