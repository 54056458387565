import isNil from "lodash.isnil";

export function findRoot(relations)
{
	return relations.find(rel => !rel.parent_object_name);
}

export function shouldRelationBeAdded(parent_object_name, object_name, relationships)
{
	const isNotRoot = !(findRoot(relationships).object_name === object_name);
	const doesNotExistAlready = !(relationships.some(rel => rel.parent_object_name === parent_object_name && rel.object_name === object_name));
	return isNotRoot && doesNotExistAlready;
}

export function buildMapping(provider, dst_object, dst_field_key = null, dst_field_type = null,  dst_field_options = {},  restricted_options = false, src_id = null, src_type = null)
{
	return {
		type: provider,
		dst_object, // Object to which this field belongs
		dst_field_key, // Salesforce field name
		dst_field_type, // Saleforce field type
		dst_field_options, // If salesforce field has some kind of options
		restricted_options,
		src_id, // Source element / meta name
		src_type, // Source type (element, meta, static)
		src_static_value: null // If source is static, this must be specified
	};
}

export function isValidToSave(mappings, relationships)
{
	const cleanMappings = filterSaveableMappings(mappings);
	const cleanRelationships = filterSaveableRelations(relationships, cleanMappings);
	return cleanMappings.length === mappings.length &&
	       mappings.length > 0 &&
	       cleanRelationships.length > 0;
}

export function filterSaveableMappings(mappings)
{
	return mappings.filter(mapping => {
		return (!isNil(mapping.src_id) || !isNil(mapping.src_static_value)) &&
	         !isNil(mapping.src_type) &&
		       !isNil(mapping.dst_object) &&
	         !isNil(mapping.dst_field_key) &&
	         !isNil(mapping.dst_field_type);
	});
}

export function filterSaveableRelations(relations, mappings)
{
	let rootRel = findRoot(relations);
	let collector = [rootRel];
	return getSaveableRelationsRecursive(rootRel.object_name, relations, mappings, collector);
}

function getSaveableRelationsRecursive(object_name, relations, mappings, collector)
{
	getChildRelations(object_name, relations).forEach(childRel => {
		if (branchHasMappingLeaves(childRel.object_name, relations, mappings))
		{
			collector.push(childRel);
			collector = getSaveableRelationsRecursive(childRel.object_name, relations, mappings, collector);
		}
	});
	return collector;
}

function branchHasMappingLeaves(object_name, relations, mappings)
{
	return getChildRelations(object_name, relations).some(child => branchHasMappingLeaves(child.object_name, relations, mappings)) ||
         mappings.some(mapping => mapping.dst_object === object_name)
}

function getChildRelations(object_name, relations)
{
	return relations.filter(rel => rel.parent_object_name === object_name);
}
