import React from "react";
import PopupR from "ui/PopupR";
import PopupRichContent from "ui/PopupRichContent";
import {connect} from "react-redux";
import {TABS} from "../../AutomaticReporting";
import {
	clearNewReport,
	setTab
} from "../../automaticReportingActions";

/**
 * ReportCreationSuccess renders a popup informing of successful report creation.
 *
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const ReportCreationSuccess = ({dispatch}) => {
	const returnToExistingReports = () => {
		dispatch(clearNewReport());
		dispatch(setTab(TABS.EXISTING_REPORTS))
	};

	return (
		<PopupR
			classes={"success"}
			title={translator.get("automaticReporting.automaticReporting")}
			hideCancel={true}
			submitCallback={() => returnToExistingReports()}
			submitText={translator.get("common.ok")}>
			<PopupRichContent title={translator.get("automaticReporting.reportCreatedSuccessfully")}>
				<p>{translator.get("automaticReporting.reportCreatedSuccessfullyText")}</p>
			</PopupRichContent>
		</PopupR>
	);
};

export default connect(state => {})(ReportCreationSuccess);