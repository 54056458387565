/**
 * Module description
 * @module Contains static values related to feature visibility.
 */

export const VISIBILITY_LEVELS = {
	HIDDEN: 0,
	DISABLED: 1,
	ENABLED: 2
};

export const REASON_OBJS = {
	INACCESSIBILITY: {
		key: "INACCESSIBILITY",
		text: "visibility-rules.accessibility"
	},
	LICENSE: {
		key: "LICENSE",
		text: "visibility-rules.license"
	},
	ROLE: {
		key: "ROLE",
		text: "visibility-rules.userRole"
	}
};

// Object containing all feature keys defined in C-1316
export const FEATURES = {
	// Forms
	BASIC_FORM_FUNCTIONS: "BASIC_FORM_FUNCTIONS",
	STATIC_SURVEYS: "STATIC_SURVEYS",
	DYNAMIC_SURVEYS: "DYNAMIC_SURVEYS",

	// Manage surveys
	BASIC_SURVEY_MANAGEMENT_FUNCTIONS: "BASIC_SURVEY_MANAGEMENT_FUNCTIONS",
	SURVEY_KEYWORDS: "SURVEY_KEYWORDS",
	TEAM_SURVEY_SHARES: "TEAM_SURVEY_SHARES",
	PUBLIC_SURVEY_SHARES: "PUBLIC_SURVEY_SHARES",
	SURVEY_TEMPLATES: "SURVEY_TEMPLATES",

	// Build
	BASIC_BUILD_FUNCTIONS: "BASIC_BUILD_FUNCTIONS",
	LANGUAGE_VERSIONS: "LANGUAGE_VERSIONS",
	ANSWER_PATHS: "ANSWER_PATHS",
	ENFORCE_ACCESSIBILITY: "ENFORCE_ACCESSIBILITY",
	JAVASCRIPT_SNIPPETS: "JAVASCRIPT_SNIPPETS",
	PASSWORD_SURVEYS: "PASSWORD_SURVEYS",
	TEXT_ELEMENT: "TEXT_ELEMENT",
	IMAGE_ELEMENT: "IMAGE_ELEMENT",
	VIDEO_ELEMENT: "VIDEO_ELEMENT",
	SINGLE_CHOICE_ELEMENT: "SINGLE_CHOICE_ELEMENT",
	MULTI_CHOICE_ELEMENT: "MULTI_CHOICE_ELEMENT",
	DROPDOWN_ELEMENT: "DROPDOWN_ELEMENT",
	SCALE_ELEMENT: "SCALE_ELEMENT",
	TABLE_ELEMENT: "TABLE_ELEMENT",
	OPEN_FIELD_ELEMENT: "OPEN_FIELD_ELEMENT",
	FILE_UPLOAD_ELEMENT: "FILE_UPLOAD_ELEMENT",
	SLIDER_ELEMENT: "SLIDER_ELEMENT",
	SATISFACTION_ELEMENT: "SATISFACTION_ELEMENT",
	NUMBER_SCALE_ELEMENT: "NUMBER_SCALE_ELEMENT",
	NPS_QUESTION_ELEMENT: "NPS_QUESTION_ELEMENT",
	CES_QUESTION_ELEMENT: "CES_QUESTION_ELEMENT",
	CSAT10_QUESTION_ELEMENT: "CSAT10_QUESTION_ELEMENT",
	CSAT5_QUESTION_ELEMENT: "CSAT5_QUESTION_ELEMENT",
	CSAT2_QUESTION_ELEMENT: "CSAT2_QUESTION_ELEMENT",
	IMAGE_SELECTION_ELEMENT: "IMAGE_SELECTION_ELEMENT",
	RANKORDER_ELEMENT: "RANKORDER_ELEMENT",
	CAPTURE_IMAGE_ELEMENT: "CAPTURE_IMAGE_ELEMENT",
	RECORD_VIDEO_ELEMENT: "RECORD_VIDEO_ELEMENT",
	RECORD_AUDIO_ELEMENT: "RECORD_AUDIO_ELEMENT",
	DATE_PICKER: "DATE_PICKER",

	// Design
	BASIC_DESIGN_FUNCTIONS: "BASIC_DESIGN_FUNCTIONS",
	THEMES: "THEMES",
	CUSTOM_CSS: "CUSTOM_CSS",

	// Preview
	BASIC_PREVIEW_FUNCTIONS: "BASIC_PREVIEW_FUNCTIONS",
	SURVEY_COMMENTS: "SURVEY_COMMENTS",

	// Distribute
	BASIC_DISTRIBUTION_FUNCTIONS: "BASIC_DISTRIBUTION_FUNCTIONS",
	BASIC_EMAIL_INVITATIONS: "BASIC_EMAIL_INVITATIONS",
	PREMIUM_MAIL_INVITATIONS: "PREMIUM_MAIL_INVITATIONS",
	SMS_INVITATIONS: "SMS_INVITATIONS",
	INVITATION_QUARANTINES: "INVITATION_QUARANTINES",
	QR_CODE_LINK: "QR_CODE_LINK",
	EXTERNAL_LINK: "EXTERNAL_LINK",

	// Manage responses
	BASIC_RESPONSE_MANAGEMENT_FUNCTIONS: "BASIC_RESPONSE_MANAGEMENT_FUNCTIONS",
	SEND_EMAIL_MESSAGE_AUTOMATION: "SEND_EMAIL_MESSAGE_AUTOMATION",
	SEND_SUMMARY_VIA_EMAIL_TO_IDENTIFIED_RECIPIENT_AUTOMATICALLY_AUTOMATION: "SEND_SUMMARY_VIA_EMAIL_TO_IDENTIFIED_RECIPIENT_AUTOMATICALLY_AUTOMATION",
	SEND_SUMMARY_VIA_EMAIL_TO_RECIPIENT_IF_REQUESTED_AUTOMATION: "SEND_SUMMARY_VIA_EMAIL_TO_RECIPIENT_IF_REQUESTED_AUTOMATION",
	SEND_SUMMARY_VIA_EMAIL_TO_FOLLOWING_EMAIL_ADDRESS_AUTOMATICALLY_AUTOMATION: "SEND_SUMMARY_VIA_EMAIL_TO_FOLLOWING_EMAIL_ADDRESS_AUTOMATICALLY_AUTOMATION",
	SEND_EMAIL_INVITATION_TO_THE_FOLLOWING_SURVEY_AUTOMATION: "SEND_EMAIL_INVITATION_TO_THE_FOLLOWING_SURVEY_AUTOMATION",
	REDIRECT_RECIPIENT_WITHOUT_SHOWING_THE_THANK_YOU_PAGE_TO_THE_FOLLOWING_ADDRESS_AUTOMATION: "REDIRECT_RECIPIENT_WITHOUT_SHOWING_THE_THANK_YOU_PAGE_TO_THE_FOLLOWING_ADDRESS_AUTOMATION",
	REDIRECT_RECIPIENT_AFTER_THE_THANK_YOU_PAGE_TO_THE_FOLLOWING_ADDRESS_AUTOMATION: "REDIRECT_RECIPIENT_AFTER_THE_THANK_YOU_PAGE_TO_THE_FOLLOWING_ADDRESS_AUTOMATION",
	REDIRECT_RECIPIENT_WITHOUT_SHOWING_THE_THANK_YOU_PAGE_TO_THE_FOLLOWING_SURVEY_AUTOMATION: "REDIRECT_RECIPIENT_WITHOUT_SHOWING_THE_THANK_YOU_PAGE_TO_THE_FOLLOWING_SURVEY_AUTOMATION",
	REDIRECT_RECIPIENT_AFTER_THE_THANK_YOU_PAGE_TO_THE_FOLLOWING_SURVEY_AUTOMATION: "REDIRECT_RECIPIENT_AFTER_THE_THANK_YOU_PAGE_TO_THE_FOLLOWING_SURVEY_AUTOMATION",
	FORWARD_RESPONSE_TO_THE_FOLLOWING_ADDRESS_AUTOMATION: "FORWARD_RESPONSE_TO_THE_FOLLOWING_ADDRESS_AUTOMATION",
	AUTOMATIC_RESPONSE_DELETION: "AUTOMATIC_RESPONSE_DELETION",
	AUTOMATIC_RESPONSE_ANONYMIZATION: "AUTOMATIC_RESPONSE_ANONYMIZATION",

	// Report
	BASIC_REPORTING_FUNCTIONS: "BASIC_REPORTING_FUNCTIONS",
	FILTERS: "FILTERS",
	MERGES: "MERGES",
	SURVEYPAL_SHARE: "SURVEYPAL_SHARE",
	POWERPOINT_EXPORT: "POWERPOINT_EXPORT",
	WORD_EXPORT: "WORD_EXPORT",
	EXCEL_EXPORT: "EXCEL_EXPORT",
	LEGACY_EXCEL_EXPORT: "LEGACY_EXCEL_EXPORT",
	CSV_EXPORT: "CSV_EXPORT",
	SPSS_EXPORT: "SPSS_EXPORT",
	SCHEDULED_EXCEL_EXPORT: "SCHEDULED_EXCEL_EXPORT",
	SCHEDULED_POWERPOINT_EXPORT: "SCHEDULED_POWERPOINT_EXPORT",
	SCHEDULED_WORD_EXPORT: "SCHEDULED_WORD_EXPORT",
	SCHEDULED_CSV_EXPORT: "SCHEDULED_CSV_EXPORT",
	SCHEDULED_SPSS_EXPORT: "SCHEDULED_SPSS_EXPORT",

	// Integrate
	ZAPIER: "ZAPIER",
	SALESFORCE: "SALESFORCE",
	ZENDESK: "ZENDESK",
	GENESYS: "GENESYS",
	FTP_AND_SFTP_INTEGRATIONS: "FTP_AND_SFTP_INTEGRATIONS",

	// EXTRA
	API_ACCESS: "API_ACCESS",
	AZURE_AD_SSO: "AZURE_AD_SSO",
	OKTA_SSO: "OKTA_SSO",
	CUSTOM_SSO: "CUSTOM_SSO",

	// CURRENT LOCAL FEATURES - Add to C-1316?
	SLIDER_HANDLE: "SLIDER_HANDLE",
	SLIDER_DYNAMIC_ICON: "SLIDER_DYNAMIC_ICON",
	IMPORT_AUTOMATIONS: "IMPORT_AUTOMATIONS",
	IMPORT_FILTERS: "IMPORT_FILTERS",

	// Extra feature for unit tests
	UNIT_TEST_FEATURE: "UNIT_TEST_FEATURE",
	INACCESSIBLE_UNIT_TEST_FEATURE: "INACCESSIBLE_UNIT_TEST_FEATURE"
}

export const ALL_FEATURE_KEYS = Object.keys(FEATURES);