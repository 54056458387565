define("conf/countries", function (require)
{
	var Class = require("core/class");
	var Request = require("core/request");

	var countries = [{"AF": {vat: 0, continent: "", name: "Afghanistan"}},
			{"AX": {vat: 24, continent: "Europe", name: "Åland islands"}},
			{"AL": {vat: 24, continent: "Europe", name: "Albania"}},
			{"DZ": {vat: 0, continent: "", name: "Algeria"}},
			{"AS": {vat: 0, continent: "", name: "American samoa"}},
			{"AD": {vat: 24, continent: "Europe", name: "Andorra"}},
			{"AO": {vat: 0, continent: "", name: "Angola"}},
			{"AI": {vat: 0, continent: "", name: "Anguilla"}},
			{"AQ": {vat: 0, continent: "", name: "Antarctica"}},
			{"AG": {vat: 0, continent: "", name: "Antigua and barbuda"}},
			{"AR": {vat: 0, continent: "", name: "Argentina"}},
			{"AM": {vat: 24, continent: "Europe", name: "Armenia"}},
			{"AW": {vat: 0, continent: "", name: "Aruba"}},
			{"AU": {vat: 0, continent: "", name: "Australia"}},
			{"AT": {vat: 24, continent: "Europe", name: "Austria"}},
			{"AZ": {vat: 24, continent: "Europe", name: "Azerbaijan"}},
			{"BS": {vat: 0, continent: "", name: "Bahamas"}},
			{"BH": {vat: 0, continent: "", name: "Bahrain"}},
			{"BD": {vat: 0, continent: "", name: "Bangladesh"}},
			{"BB": {vat: 0, continent: "", name: "Barbados"}},
			{"BY": {vat: 24, continent: "Europe", name: "Belarus"}},
			{"BE": {vat: 24, continent: "Europe", name: "Belgium"}},
			{"BZ": {vat: 0, continent: "", name: "Belize"}},
			{"BJ": {vat: 0, continent: "", name: "Benin"}},
			{"BM": {vat: 0, continent: "", name: "Bermuda"}},
			{"BT": {vat: 0, continent: "", name: "Bhutan"}},
			{"BO": {vat: 0, continent: "", name: "Bolivia"}},
			{"BA": {vat: 24, continent: "Europe", name: "Bosnia and herzegovina"}},
			{"BW": {vat: 0, continent: "", name: "Botswana"}},
			{"BV": {vat: 0, continent: "", name: "Bouvet island"}},
			{"BR": {vat: 0, continent: "", name: "Brazil"}},
			{"IO": {vat: 0, continent: "", name: "British indian ocean territory"}},
			{"BN": {vat: 0, continent: "", name: "Brunei darussalam"}},
			{"BG": {vat: 24, continent: "Europe", name: "Bulgaria"}},
			{"BF": {vat: 0, continent: "", name: "Burkina faso"}},
			{"BI": {vat: 0, continent: "", name: "Burundi"}},
			{"KH": {vat: 0, continent: "", name: "Cambodia"}},
			{"CM": {vat: 0, continent: "", name: "Cameroon"}},
			{"CA": {vat: 0, continent: "", name: "Canada"}},
			{"CV": {vat: 0, continent: "", name: "Cape verde"}},
			{"KY": {vat: 0, continent: "", name: "Cayman islands"}},
			{"CF": {vat: 0, continent: "", name: "Central african republic"}},
			{"TD": {vat: 0, continent: "", name: "Chad"}},
			{"CL": {vat: 0, continent: "", name: "Chile"}},
			{"CN": {vat: 0, continent: "", name: "China"}},
			{"CX": {vat: 0, continent: "", name: "Christmas island"}},
			{"CC": {vat: 0, continent: "", name: "Cocos (keeling) islands"}},
			{"CO": {vat: 0, continent: "", name: "Colombia"}},
			{"KM": {vat: 0, continent: "", name: "Comoros"}},
			{"CG": {vat: 0, continent: "", name: "Congo"}},
			{"CD": {vat: 0, continent: "", name: "Congo, the democratic republic of the"}},
			{"CK": {vat: 0, continent: "", name: "Cook islands"}},
			{"CR": {vat: 0, continent: "", name: "Costa rica"}},
			{"CI": {vat: 0, continent: "", name: "Cote d'ivoire"}},
			{"HR": {vat: 24, continent: "Europe", name: "Croatia"}},
			{"CU": {vat: 0, continent: "", name: "Cuba"}},
			{"CY": {vat: 24, continent: "Europe", name: "Cyprus"}},
			{"CZ": {vat: 24, continent: "Europe", name: "Czech republic"}},
			{"DK": {vat: 24, continent: "Europe", name: "Denmark"}},
			{"DJ": {vat: 0, continent: "", name: "Djibouti"}},
			{"DM": {vat: 0, continent: "", name: "Dominica"}},
			{"DO": {vat: 0, continent: "", name: "Dominican republic"}},
			{"EC": {vat: 0, continent: "", name: "Ecuador"}},
			{"EG": {vat: 0, continent: "", name: "Egypt"}},
			{"SV": {vat: 0, continent: "", name: "El salvador"}},
			{"GQ": {vat: 0, continent: "", name: "Equatorial guinea"}},
			{"ER": {vat: 0, continent: "", name: "Eritrea"}},
			{"EE": {vat: 24, continent: "Europe", name: "Estonia"}},
			{"ET": {vat: 0, continent: "", name: "Ethiopia"}},
			{"FK": {vat: 0, continent: "", name: "Falkland islands (malvinas)"}},
			{"FO": {vat: 24, continent: "Europe", name: "Faroe islands"}},
			{"FJ": {vat: 0, continent: "", name: "Fiji"}},
			{"FI": {vat: 24, continent: "Europe", name: "Finland"}},
			{"FR": {vat: 24, continent: "Europe", name: "France"}},
			{"GF": {vat: 0, continent: "", name: "French guiana"}},
			{"PF": {vat: 0, continent: "", name: "French polynesia"}},
			{"TF": {vat: 0, continent: "", name: "French southern territories"}},
			{"GA": {vat: 0, continent: "", name: "Gabon"}},
			{"GM": {vat: 0, continent: "", name: "Gambia"}},
			{"GE": {vat: 24, continent: "Europe", name: "Georgia"}},
			{"DE": {vat: 24, continent: "Europe", name: "Germany"}},
			{"GH": {vat: 0, continent: "", name: "Ghana"}},
			{"GI": {vat: 24, continent: "Europe", name: "Gibraltar"}},
			{"GR": {vat: 24, continent: "Europe", name: "Greece"}},
			{"GL": {vat: 0, continent: "", name: "Greenland"}},
			{"GD": {vat: 0, continent: "", name: "Grenada"}},
			{"GP": {vat: 0, continent: "", name: "Guadeloupe"}},
			{"GU": {vat: 0, continent: "", name: "Guam"}},
			{"GT": {vat: 0, continent: "", name: "Guatemala"}},
			{"GN": {vat: 0, continent: "", name: "Guinea"}},
			{"GW": {vat: 0, continent: "", name: "Guinea-bissau"}},
			{"GY": {vat: 0, continent: "", name: "Guyana"}},
			{"HT": {vat: 0, continent: "", name: "Haiti"}},
			{"HM": {vat: 0, continent: "", name: "Heard island and mcdonald islands"}},
			{"VA": {vat: 0, continent: "", name: "Holy see (vatican city state)"}},
			{"HN": {vat: 0, continent: "", name: "Honduras"}},
			{"HK": {vat: 0, continent: "", name: "Hong kong"}},
			{"HU": {vat: 24, continent: "Europe", name: "Hungary"}},
			{"IS": {vat: 24, continent: "Europe", name: "Iceland"}},
			{"IN": {vat: 0, continent: "", name: "India"}},
			{"ID": {vat: 0, continent: "", name: "Indonesia"}},
			{"IR": {vat: 0, continent: "", name: "Iran, islamic republic of"}},
			{"IQ": {vat: 0, continent: "", name: "Iraq"}},
			{"IE": {vat: 24, continent: "Europe", name: "Ireland"}},
			{"IL": {vat: 0, continent: "", name: "Israel"}},
			{"IT": {vat: 24, continent: "Europe", name: "Italy"}},
			{"JM": {vat: 0, continent: "", name: "Jamaica"}},
			{"JP": {vat: 0, continent: "", name: "Japan"}},
			{"JO": {vat: 0, continent: "", name: "Jordan"}},
			{"KZ": {vat: 24, continent: "Europe", name: "Kazakhstan"}},
			{"KE": {vat: 0, continent: "", name: "Kenya"}},
			{"KI": {vat: 0, continent: "", name: "Kiribati"}},
			{"KP": {vat: 0, continent: "", name: "Korea, democratic people's republic of"}},
			{"KR": {vat: 0, continent: "", name: "Korea, republic of"}},
			{"KW": {vat: 0, continent: "", name: "Kuwait"}},
			{"KG": {vat: 0, continent: "", name: "Kyrgyzstan"}},
			{"LA": {vat: 0, continent: "", name: "Lao people's democratic republic"}},
			{"LV": {vat: 24, continent: "Europe", name: "Latvia"}},
			{"LB": {vat: 0, continent: "", name: "Lebanon"}},
			{"LS": {vat: 0, continent: "", name: "Lesotho"}},
			{"LR": {vat: 0, continent: "", name: "Liberia"}},
			{"LY": {vat: 0, continent: "", name: "Libyan arab jamahiriya"}},
			{"LI": {vat: 24, continent: "Europe", name: "Liechtenstein"}},
			{"LT": {vat: 24, continent: "Europe", name: "Lithuania"}},
			{"LU": {vat: 24, continent: "Europe", name: "Luxembourg"}},
			{"MO": {vat: 0, continent: "", name: "Macao"}},
			{"MK": {vat: 24, continent: "Europe", name: "Macedonia, the former yugoslav republic of"}},
			{"MG": {vat: 0, continent: "", name: "Madagascar"}},
			{"MW": {vat: 0, continent: "", name: "Malawi"}},
			{"MY": {vat: 0, continent: "", name: "Malaysia"}},
			{"MV": {vat: 0, continent: "", name: "Maldives"}},
			{"ML": {vat: 0, continent: "", name: "Mali"}},
			{"MT": {vat: 24, continent: "Europe", name: "Malta"}},
			{"MH": {vat: 0, continent: "", name: "Marshall islands"}},
			{"MQ": {vat: 0, continent: "", name: "Martinique"}},
			{"MR": {vat: 0, continent: "", name: "Mauritania"}},
			{"MU": {vat: 0, continent: "", name: "Mauritius"}},
			{"YT": {vat: 0, continent: "", name: "Mayotte"}},
			{"MX": {vat: 0, continent: "", name: "Mexico"}},
			{"FM": {vat: 0, continent: "", name: "Micronesia, federated states of"}},
			{"MD": {vat: 24, continent: "Europe", name: "Moldova, republic of"}},
			{"MC": {vat: 24, continent: "Europe", name: "Monaco"}},
			{"MN": {vat: 0, continent: "", name: "Mongolia"}},
			{"ME": {vat: 24, continent: "Europe", name: "Montenegro"}},
			{"MS": {vat: 0, continent: "", name: "Montserrat"}},
			{"MA": {vat: 0, continent: "", name: "Morocco"}},
			{"MZ": {vat: 0, continent: "", name: "Mozambique"}},
			{"MM": {vat: 0, continent: "", name: "Myanmar"}},
			{"NA": {vat: 0, continent: "", name: "Namibia"}},
			{"NR": {vat: 0, continent: "", name: "Nauru"}},
			{"NP": {vat: 0, continent: "", name: "Nepal"}},
			{"NL": {vat: 24, continent: "Europe", name: "Netherlands"}},
			{"AN": {vat: 0, continent: "", name: "Netherlands antilles"}},
			{"NC": {vat: 0, continent: "", name: "New caledonia"}},
			{"NZ": {vat: 0, continent: "", name: "New zealand"}},
			{"NI": {vat: 0, continent: "", name: "Nicaragua"}},
			{"NE": {vat: 0, continent: "", name: "Niger"}},
			{"NG": {vat: 0, continent: "", name: "Nigeria"}},
			{"NU": {vat: 0, continent: "", name: "Niue"}},
			{"NF": {vat: 0, continent: "", name: "Norfolk island"}},
			{"MP": {vat: 0, continent: "", name: "Northern mariana islands"}},
			{"NO": {vat: 24, continent: "Europe", name: "Norway"}},
			{"OM": {vat: 0, continent: "", name: "Oman"}},
			{"PK": {vat: 0, continent: "", name: "Pakistan"}},
			{"PW": {vat: 0, continent: "", name: "Palau"}},
			{"PS": {vat: 0, continent: "", name: "Palestinian territory, occupied"}},
			{"PA": {vat: 0, continent: "", name: "Panama"}},
			{"PG": {vat: 0, continent: "", name: "Papua new guinea"}},
			{"PY": {vat: 0, continent: "", name: "Paraguay"}},
			{"PE": {vat: 0, continent: "", name: "Peru"}},
			{"PH": {vat: 0, continent: "", name: "Philippines"}},
			{"PN": {vat: 0, continent: "", name: "Pitcairn"}},
			{"PL": {vat: 24, continent: "Europe", name: "Poland"}},
			{"PT": {vat: 24, continent: "Europe", name: "Portugal"}},
			{"PR": {vat: 0, continent: "", name: "Puerto rico"}},
			{"QA": {vat: 0, continent: "", name: "Qatar"}},
			{"RE": {vat: 0, continent: "", name: "Reunion"}},
			{"RO": {vat: 24, continent: "Europe", name: "Romania"}},
			{"RU": {vat: 24, continent: "Europe", name: "Russian federation"}},
			{"RW": {vat: 0, continent: "", name: "Rwanda"}},
			{"SH": {vat: 0, continent: "", name: "Saint helena"}},
			{"KN": {vat: 0, continent: "", name: "Saint kitts and nevis"}},
			{"LC": {vat: 0, continent: "", name: "Saint lucia"}},
			{"PM": {vat: 0, continent: "", name: "Saint pierre and miquelon"}},
			{"VC": {vat: 0, continent: "", name: "Saint vincent and the grenadines"}},
			{"WS": {vat: 0, continent: "", name: "Samoa"}},
			{"SM": {vat: 24, continent: "Europe", name: "San marino"}},
			{"ST": {vat: 0, continent: "", name: "Sao tome and principe"}},
			{"SA": {vat: 0, continent: "", name: "Saudi arabia"}},
			{"SN": {vat: 0, continent: "", name: "Senegal"}},
			{"RS": {vat: 24, continent: "Europe", name: "Serbia"}},
			{"SC": {vat: 0, continent: "", name: "Seychelles"}},
			{"SL": {vat: 0, continent: "", name: "Sierra leone"}},
			{"SG": {vat: 0, continent: "", name: "Singapore"}},
			{"SK": {vat: 24, continent: "Europe", name: "Slovakia"}},
			{"SI": {vat: 24, continent: "Europe", name: "Slovenia"}},
			{"SB": {vat: 0, continent: "", name: "Solomon islands"}},
			{"SO": {vat: 0, continent: "", name: "Somalia"}},
			{"ZA": {vat: 0, continent: "", name: "South africa"}},
			{"GS": {vat: 0, continent: "", name: "South georgia and the south sandwich islands"}},
			{"ES": {vat: 24, continent: "Europe", name: "Spain"}},
			{"LK": {vat: 0, continent: "", name: "Sri lanka"}},
			{"SD": {vat: 0, continent: "", name: "Sudan"}},
			{"SR": {vat: 0, continent: "", name: "Suriname"}},
			{"SJ": {vat: 0, continent: "", name: "Svalbard and jan mayen"}},
			{"SZ": {vat: 0, continent: "", name: "Swaziland"}},
			{"SE": {vat: 24, continent: "Europe", name: "Sweden"}},
			{"CH": {vat: 24, continent: "Europe", name: "Switzerland"}},
			{"SY": {vat: 0, continent: "", name: "Syrian arab republic"}},
			{"TW": {vat: 0, continent: "", name: "Taiwan, province of china"}},
			{"TJ": {vat: 0, continent: "", name: "Tajikistan"}},
			{"TZ": {vat: 0, continent: "", name: "Tanzania, united republic of"}},
			{"TH": {vat: 0, continent: "", name: "Thailand"}},
			{"TL": {vat: 0, continent: "", name: "Timor-leste"}},
			{"TG": {vat: 0, continent: "", name: "Togo"}},
			{"TK": {vat: 0, continent: "", name: "Tokelau"}},
			{"TO": {vat: 0, continent: "", name: "Tonga"}},
			{"TT": {vat: 0, continent: "", name: "Trinidad and tobago"}},
			{"TN": {vat: 0, continent: "", name: "Tunisia"}},
			{"TR": {vat: 24, continent: "Europe", name: "Turkey"}},
			{"TM": {vat: 0, continent: "", name: "Turkmenistan"}},
			{"TC": {vat: 0, continent: "", name: "Turks and caicos islands"}},
			{"TV": {vat: 0, continent: "", name: "Tuvalu"}},
			{"UG": {vat: 0, continent: "", name: "Uganda"}},
			{"UA": {vat: 24, continent: "Europe", name: "Ukraine"}},
			{"AE": {vat: 0, continent: "", name: "United arab emirates"}},
			{"GB": {vat: 24, continent: "Europe", name: "United kingdom"}},
			{"US": {vat: 0, continent: "", name: "United states"}},
			{"UM": {vat: 0, continent: "", name: "United states minor outlying islands"}},
			{"UY": {vat: 0, continent: "", name: "Uruguay"}},
			{"UZ": {vat: 0, continent: "", name: "Uzbekistan"}},
			{"VU": {vat: 0, continent: "", name: "Vanuatu"}},
			{"VE": {vat: 0, continent: "", name: "Venezuela"}},
			{"VN": {vat: 0, continent: "", name: "Viet nam"}},
			{"VG": {vat: 0, continent: "", name: "Virgin islands, british"}},
			{"VI": {vat: 0, continent: "", name: "Virgin islands, u.s."}},
			{"WF": {vat: 0, continent: "", name: "Wallis and futuna"}},
			{"EH": {vat: 0, continent: "", name: "Western sahara"}},
			{"YE": {vat: 0, continent: "", name: "Yemen"}},
			{"ZM": {vat: 0, continent: "", name: "Zambia"}},
			{"ZW": {vat: 0, continent: "", name: "Zimbabwe"}}];
	return Class.extend(
	{
		init: function(offline) //call with offline as TRUE if you want to use the list above without requesting the countries from server
		{
			this.countries = countries;
			if(offline === undefined || offline === false)
			{
				var request = new Request("/app/account/billing/countries");
				var callback = function(status, json)
				{
					if(status == 200)
					{
						this.countries = json.countries;
					}
				}.bind(this);

				request.setCallback(callback);
				request.post();
			}
		},

		list: function()
		{
			return this.countries;
		},

		get: function(id)
		{
			var list = this.list();
			for( var i = 0; i < list.length; i++ )
			{
				var country = list[i];
				if(country[id])
				{
					return country[id];
				}
			}
		}
	});
});