/**
 * getPath returns the path of a folder, including the folder itself. The return value is an array of folderIds,
 * starting with the root folder and ending in the queried folder.
 *
 * @param folderId {number}
 * @returns {number[]}
 */
export const getPath = (folderId) => {
	if (folderId === 0 || folderId === undefined) return [0];

	const folders = Surveypal.store.getState().surveys.fetchables.folders.folders;
	const path = [folderId];

	do
	{
		const nextFolder = folders.find(folder => folder.subFolders.indexOf(path[path.length - 1]) !== -1);
		path.push(nextFolder === undefined ? 0 : nextFolder.id);
	} while (path[path.length-1] !== 0)

	return path.reverse();
};