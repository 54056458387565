define("ui/dropdown-menu", function (require)
{
	var Menu = require("ui/menu");
	return Menu.extend(
		{
			init: function (handle, dropdown)
			{
				this.supreme(handle);

				this.getDropdown = function ()
				{
					return dropdown;
				}
			},

			open: function (items, width)
			{
				var data = [];

				// Iterate trough data
				for (var i = 0; i < items.length; i++)
				{
					var block = [];

					for (var j = 0; j < items[i].length; j++)
					{
						var item = items[i][j];
						block.push(item);
					}

					data.push(block);
				}

				// If dropdown menu is opened, the old kind of placeholder text (if value = -2)
				// must not be seen as one choice. At the moment value -1 is used at some places, and
				// if not wanted to see that placeholder text at the dropdown menu, value can be
				// updated to the value -2
				// This can't be done now because this component is used very many ways at different components.
				//const dataWithoutPlaceHolder = data[0].filter(oneItem => oneItem.value !== -2);

				// Open the menu
				var handle = this.getHandle();
				handle.addClass("open");

				var position = handle.offset();
				var handleWidth = handle.width();

				// Preset width not used if dropdown is rule type selector in conditions-view
				if(this.find(".drop").hasClass("rule-type-selector"))
				{
					this.find(".drop").css("min-width", handleWidth);
				}
				else
				{
					this.width(width || handleWidth);
				}

				this.position(position.left + 2, position.top + 35);

				this.supreme(data);

				// If no suitable questions for email at invitations popup, making question text greyed
				if(jQuery.find(".drop .block .item .label[title = '" + translator.get('invitations.questionNotFound') + "']"))
				{
					jQuery(".drop .block .item .label[title = '" + translator.get('invitations.questionNotFound') + "']").addClass("disabled");
				}
			},

			select: function (item)
			{
				// If no suitable questions for email at invitations popup, not allowing to select that dropdown item
				if(item.text !== translator.get('invitations.questionNotFound'))
				{
					this.getDropdown().select(item);
				}
			},

			close: function ()
			{
				this.getHandle().removeClass("menu-open");
				this.getHandle().removeClass("open");
				this.detach();
				this.find(".drop").html("");
				this.getHandle().find('.dropdown-search').val('');
			}
		});
});
