import clone from "lodash.clonedeep";
import {
  ASSET_CREATED, ASSET_UPDATED,
  ASSETS_LOADED, LICENSE_CREATED, LICENSE_UPDATED,
  LICENSES_LOADED, PACKAGE_CREATED, PACKAGE_SET_CREATED, PACKAGE_SET_UPDATED,
  PACKAGE_SETS_LOADED, PACKAGE_UPDATED,
  PACKAGES_LOADED,
  SET_PERSISTING
} from "./licensesActions";

export const defaultState = {
  assets: [],
  assetsLoaded: false,
  licenses: [],
  licensesLoaded: false,
  persisting: false,
  packageSets: [],
  packageSetsLoaded: false,
  packages: [],
  packagesLoaded: false
};

export default function reducer(previousState = defaultState, action)
{
  const state = clone(previousState)
  switch (action.type)
  {
    // List loaded actions
    case ASSETS_LOADED:
    {
      state.assets = action.assets;
      state.assetsLoaded = true;
      break;
    }
    case LICENSES_LOADED:
    {
      state.licenses = action.licenses.map(prepareLicense);
      state.licensesLoaded = true;
      break;
    }
    case PACKAGE_SETS_LOADED:
    {
      state.packageSets = action.packageSets.map(preparePackageSet);
      state.packageSetsLoaded = true;
      break;
    }
    case PACKAGES_LOADED:
    {
      state.packages = action.packages.map(preparePackage);
      state.packagesLoaded = true;
      break;
    }
    case SET_PERSISTING:
    {
      state.persisting = action.persisting;
      break;
    }
    // Updated and created objects
    case ASSET_CREATED:
    {
      state.assets.push(action.asset);
      state.persisting = false;
      break;
    }
    case ASSET_UPDATED:
    {
      state.assets = replaceIn(state.assets, action.asset);
      state.persisting = false;
      break;
    }
    case LICENSE_CREATED:
    {
      state.licenses.push(prepareLicense(action.license));
      state.persisting = false;
      break;
    }
    case LICENSE_UPDATED:
    {
      state.licenses = replaceIn(state.licenses, prepareLicense(action.license));
      state.persisting = false;
      break;
    }
    case PACKAGE_CREATED:
    {
      state.packages.push(preparePackage(action.pkg));
      state.persisting = false;
      break;
    }
    case PACKAGE_UPDATED:
    {
      state.packages = replaceIn(state.packages, preparePackage(action.pkg));
      state.persisting = false;
      break;
    }
    case PACKAGE_SET_CREATED:
    {
      state.packageSets.push(preparePackageSet(action.packageSet));
      state.persisting = false;
      break;
    }
    case PACKAGE_SET_UPDATED:
    {
      state.packageSets = replaceIn(state.packageSets, preparePackageSet(action.packageSet));
      state.persisting = false;
      break;
    }
  }
  return state;
}

function replaceIn(list, newItem)
{
  const index = list.findIndex(item => item.id === newItem.id);
  if (index !== -1)
  {
    list[index] = newItem;
  }
  return list;
}

// Change packageSets to be just a list of ids
function prepareLicense(license)
{
  const packageSetIds = license.packageSets.map(p => p.id);
  delete license.packageSets;
  license.packageSetIds = packageSetIds;
  return license;
}

// Change packages to a list of package ids
function preparePackageSet(packageSet)
{
  const packageIds = packageSet.packages.map(p => p.id);
  delete packageSet.packages;
  packageSet.packageIds = packageIds;
  return packageSet;
}

function preparePackage(pkg)
{
  pkg.feature = pkg.features && pkg.features.length > 0 ?
    pkg.features[0] :
    null;
  delete pkg.features;
  pkg.assetId = pkg.assets && pkg.assets.length > 0 ?
    pkg.assets[0].id :
    null;
  delete pkg.assets;
  return pkg;
}