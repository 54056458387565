export default {
	getDisplayName: function()
	{
		if (this.displayName && this.displayName.trim() !== "")
		{
			return this.displayName;
		}
		if (this.name)
		{
			return this.name;
		}

		return "";
	}
};