import clone from "lodash.clonedeep";
import { prepareAction } from "./action-conf";
import { createNewRuleFromTemplate } from "common/rules/rule-utils";
import * as RuleConf from "common/rules/rule-conf";
import * as ActionConf from "./action-conf";
import assign from "lodash.assign";
import { IMPORTED_STATUS_CODES } from "../../result/libraries/filterOrAutomationImportingUtils";

export const ACTION_SELECTED = "SURVEY.MANAGE.AUTOMATIONS.ACTION_SELECTED";
export const PARAM_CHANGED = "SURVEY.MANAGE.AUTOMATIONS.PARAM_CHANGED";
export const CHANGE_TRIGGER_GROUP = "SURVEY.MANAGE.AUTOMATIONS.CHANGE_TRIGGER_GROUP";
export const CHANGE_ROOT_OPERATOR = "SURVEY.MANAGE.AUTOMATIONS.CHANGE_ROOT_OPERATOR";
export const CHANGE_GROUP_OPERATOR = "SURVEY.MANAGE.AUTOMATIONS.CHANGE_GROUP_OPERATOR";
export const CHANGE_RULE_TYPE = "SURVEY.MANAGE.AUTOMATIONS.CHANGE_RULE_TYPE";
export const CHANGE_RULE_OPERATOR = "SURVEY.MANAGE.AUTOMATIONS.CHANGE_RULE_OPERATOR";
export const CHANGE_RULE_VALUE = "SURVEY.MANAGE.AUTOMATIONS.CHANGE_RULE_VALUE";
export const DELETE_RULE = "SURVEY.MANAGE.AUTOMATIONS.DELETE_RULE";
export const DUPLICATE_RULE = "SURVEY.MANAGE.AUTOMATIONS.DUPLICATE_RULE";
export const CREATE_RULE = "SURVEY.MANAGE.AUTOMATIONS.CREATE_RULE";
export const ADD_NEW_CONDITION_GROUP = "SURVEY.MANAGE.AUTOMATIONS.ADD_NEW_CONDITION_GROUP";
export const REMOVE_CONDITION_GROUP = "SURVEY.MANAGE.AUTOMATIONS.REMOVE_CONDITION_GROUP";
export const CREATE_AUTOMATION = "SURVEY.MANAGE.AUTOMATIONS.CREATE_NEW_AUTOMATION";
export const DELETE_AUTOMATION = "SURVEY.MANAGE.AUTOMATIONS.DELETE_AUTOMATION";
export const SELECT_AUTOMATION = "SURVEY.MANAGE.AUTOMATIONS.SELECT_AUTOMATION";
export const EDIT_AUTOMATION = "SURVEY.MANAGE.AUTOMATIONS.EDIT_AUTOMATION";
export const RENAME_AUTOMATION = "SURVEY.MANAGE.AUTOMATIONS.RENAME_AUTOMATION";
export const SURVEYS_WITH_AUTOMATIONS_LOADING = "SURVEY.MANAGE.AUTOMATIONS.SURVEYS_WITH_AUTOMATIONS_LOADING";
export const SURVEYS_WITH_AUTOMATIONS_LOADED = "SURVEY.MANAGE.AUTOMATIONS.SURVEYS_WITH_AUTOMATIONS_LOADED";
export const HIDE_SURVEY_SELECTION_POPUP = "SURVEY.MANAGE.AUTOMATIONS.HIDE_SURVEY_SELECTION_POPUP";
export const LOAD_AUTOMATIONS_FROM_SURVEY_LOADING = "SURVEY.MANAGE.AUTOMATIONS.LOAD_AUTOMATIONS_FROM_SURVEY_LOADING";
export const LOAD_AUTOMATIONS_FROM_SURVEY_LOADED = "SURVEY.MANAGE.AUTOMATIONS.LOAD_AUTOMATIONS_FROM_SURVEY_LOADED";
export const COPY_LOADED_AUTOMATIONS_TO_SURVEY = "SURVEY.MANAGE.AUTOMATIONS.COPY_LOADED_COPY_LOADED_AUTOMATIONS_TO_SURVEY";
export const HIDE_AUTOMATION_SELECTION_POPUP = "SURVEY.MANAGE.AUTOMATIONS.HIDE_AUTOMATION_SELECTION_POPUP";
export const HIDE_AUTOMATIONS_IMPORTED_POPUP = "SURVEY.MANAGE.AUTOMATIONS.HIDE_AUTOMATIONS_IMPORTED_POPUP";

export function hideAutomationsImportedPopup()
{
	return {
		type: HIDE_AUTOMATIONS_IMPORTED_POPUP
	}
}

export function hideAutomationSelectionPopupAction()
{
	return {
		type: HIDE_AUTOMATION_SELECTION_POPUP
	}
}

export function surveysWithAutomationsLoading()
{
	return {
		type: SURVEYS_WITH_AUTOMATIONS_LOADING
	}
}

export function surveysWithAutomationsLoaded(surveys)
{
	return {
		type: SURVEYS_WITH_AUTOMATIONS_LOADED,
		surveys: surveys
	}
}

export function hideSurveySelectionPopup()
{
	return {
		type: HIDE_SURVEY_SELECTION_POPUP,
		showSurveySelectionPopup: false
	}
}

export function loadAutomationsFromSurveyLoading()
{
	return {
		type: LOAD_AUTOMATIONS_FROM_SURVEY_LOADING
	}
}

export function loadAutomationsFromSurveyLoaded(status, automations)
{
	return {
		type: LOAD_AUTOMATIONS_FROM_SURVEY_LOADED,
		automationsFetchedStatus: status,
		loadedAutomations: automations,
		showAutomationsImportedPopup: status !== IMPORTED_STATUS_CODES.OK
	}
}

export function copyLoadedAutomationsToSurvey(existingAutomations, selectedLoadedAutomations, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	const concatenatedAutomations = existingAutomations.concat(selectedLoadedAutomations);
	return {
		type: COPY_LOADED_AUTOMATIONS_TO_SURVEY,
		automations: concatenatedAutomations,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	}
}

export function getNewDummyAutomation(id)
{
	const actionTemplate = ActionConf.ACTIONS[0];
	const actions = [createNewActionFromTemplate(actionTemplate)];
	const group = actionTemplate.groups[0].value;
	const dummyGroup = getNewDummyGroup();

	return {
		name: translator.get("automations.newAutomation"),
		id: id,
		actions: actions,
		filter: {
			match: RuleConf.ROOT_OPERATORS.FILTERS_ALL.value,
			groups: dummyGroup
		},
		triggerGroup: group,
		isNew: true,
		selected: true
	};
}

export function getNewDummyId(id = "")
{
	return "new-automation-" + Date.now() + id;
}

export function getNewDummyGroup()
{
	return [{
		match: RuleConf.ROOT_OPERATORS.FILTERS_ALL.value,
		rules: [createNewRuleFromTemplate(RuleConf.RULES.NO_CONDITION)]
	}];
}

export function createNewActionFromTemplate(actionTemplate)
{
	const actionBase = clone(actionTemplate);
	const paramsMap = {};
	prepareAction(actionBase).params.forEach(function(paramBase){
		paramsMap[paramBase.name] = paramBase.value;
	});
	return {
		action: actionBase.action,
		params: paramsMap
	};
}

export function actionSelectedAction(automation, actionTemplate)
{
	const action = createNewActionFromTemplate(actionTemplate);
	const group = actionTemplate.groups[actionTemplate.groups.length - 1].value;
	return {
		type: ACTION_SELECTED,
		automation: automation,
		action: action,
		group: group
	};
}

export function changeParameterAction(automationId, actionIndex, paramName, value)
{
	return {
		type: PARAM_CHANGED,
		automationId: automationId,
		actionIndex: actionIndex,
		paramName: paramName,
		value: value
	};
}

export function changeTriggerGroupAction(automationId, triggerGroupValue)
{
	return {
		type: CHANGE_TRIGGER_GROUP,
		automationId: automationId,
		triggerGroupValue: triggerGroupValue
	};
}

export function selectAutomationAction(automationId)
{
	return {
		type: SELECT_AUTOMATION,
		automationId: automationId
	};
}

export function startEditingAutomationAction(automationId)
{
	return {
		type: EDIT_AUTOMATION,
		automationId: automationId
	};
}

export function renameAutomationAction(automationId, automationName)
{
	return {
		type: RENAME_AUTOMATION,
		automationId: automationId,
		automationName: automationName
	};
}

// ID can be specified to make testing easier
export function createAutomationAction(id = getNewDummyId())
{
	return {
		type: CREATE_AUTOMATION,
		automation: getNewDummyAutomation(id)
	};
}

export function duplicateAutomationAction(automationId)
{
	return {
		type: DUPLICATE_AUTOMATION,
		automationId: automationId
	};
}

export function deleteAutomationAction(automationId)
{
	return {
		type: DELETE_AUTOMATION,
		automationId: automationId
	};
}

export function copyAutomationAction(automation)
{
	const newAutomation = assign(clone(automation), {id: getNewDummyId(), isNew: true});
	return {
		type: CREATE_AUTOMATION,
		automation: newAutomation
	};
}

export function changeRootOperatorAction(automationId, rootOperatorValue)
{
	return {
		type: CHANGE_ROOT_OPERATOR,
		automationId: automationId,
		rootOperatorValue: rootOperatorValue
	};
}

export function changeGroupOperatorAction(automationId, groupIndex, operatorValue)
{
	return {
		type: CHANGE_GROUP_OPERATOR,
		automationId: automationId,
		groupIndex: groupIndex,
		operatorValue: operatorValue
	};
}

export function changeRuleTypeAction(automationId, groupIndex, ruleIndex, ruleKey, operator, value, ruleType, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	return {
		type: CHANGE_RULE_TYPE,
		automationId: automationId,
		groupIndex: groupIndex,
		ruleIndex: ruleIndex,
		ruleKey: ruleKey,
		ruleType: ruleType,
		operator: operator,
		value: value,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	};
}

export function changeRuleOperatorAction(automationId, groupIndex, ruleIndex, operator, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	return {
		type: CHANGE_RULE_OPERATOR,
		automationId: automationId,
		groupIndex: groupIndex,
		ruleIndex: ruleIndex,
		operator: operator,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	};
}

export function changeRuleValueAction(automationId, groupIndex, ruleIndex, value, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	return {
		type: CHANGE_RULE_VALUE,
		automationId: automationId,
		groupIndex: groupIndex,
		ruleIndex: ruleIndex,
		value: value,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	};
}

export function deleteRuleAction(automationId, groupIndex, ruleIndex, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	return {
		type: DELETE_RULE,
		automationId: automationId,
		groupIndex: groupIndex,
		ruleIndex: ruleIndex,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	};
}

export function duplicateRuleAction(automationId, groupIndex, ruleIndex)
{
	return {
		type: DUPLICATE_RULE,
		automationId: automationId,
		groupIndex: groupIndex,
		ruleIndex: ruleIndex
	};
}

export function createRuleAction(automationId, groupIndex, ruleTemplate)
{
	return {
		type: CREATE_RULE,
		automationId: automationId,
		groupIndex: groupIndex,
		ruleTemplate: ruleTemplate
	};
}

export function addNewConditionGroup(automationId, ruleTemplate)
{
	return {
		type: ADD_NEW_CONDITION_GROUP,
		automationId: automationId,
		ruleTemplate: ruleTemplate
	}
}

export function removeConditionGroup(automationId, groupIndex, applicableRules, elementItems, metaItems, languageItems, sourceItems, useSliderIndexValues)
{
	return {
		type: REMOVE_CONDITION_GROUP,
		automationId: automationId,
		groupIndex: groupIndex,
		applicableRules: applicableRules,
		elementItems: elementItems,
		metaItems: metaItems,
		languageItems: languageItems,
		sourceItems: sourceItems,
		useSliderIndexValues: useSliderIndexValues
	}
}