import clone from "lodash.clonedeep";
import isNil from "lodash.isnil";
import {
  ADD_META_COLUMN,
  COLUMN_DELETED,
  COLUMNS_LOADED,
  COLUMN_RENAMED,
  COLUMN_SELECTED,
  METAS_LOADED,
  PHASE,
  ROW_SELECTED,
  SET_PHASE,
  SORT_KEYS, META_EDITED
} from "./metaActions";
import { SORT_VAL } from "../responseActions";

export const defaultState = {
  columns: [],
  keyword: "",
  metas: [],
  metaEdits: {},
  offset: 0,
  orderBy: SORT_KEYS.ANSWER_SOURCE,
  phase: PHASE.STARTING,
  responseCount: 0,
  selectedColumn: null,
  selectedRow: null,
  sort: SORT_VAL.ASC
};

export default function(state = defaultState, action)
{
  const nextState = clone(state);
  switch(action.type)
  {
    case ADD_META_COLUMN:
      nextState.columns.push({fresh: true, key: action.key});
      return nextState;
    case COLUMN_DELETED:
      const col = nextState.columns.find(c => c.key === action.key);
      if (col)
      {
        col.deleted = true;
      }
      nextState.selectedColumn = nextState.selectedColumn === action.key ?
        null :
        nextState.selectedColumn;
      nextState.orderBy = nextState.orderBy === action.key ?
        null :
        nextState.orderBy;
      return nextState;
    case COLUMNS_LOADED:
      nextState.columns = action.columns
        .sort((a, b) => a.localeCompare(b))
        .map(colKey => {
          return {
            deleted: false,
            fresh: false,
            key: colKey,
            newKey: null,
            renamed: false
          };
      });
      nextState.selectedColumn = null;
      nextState.orderBy = SORT_KEYS.ANSWER_SOURCE;
      return nextState;
    case COLUMN_SELECTED:
      nextState.selectedColumn = action.key;
      return nextState;
    case COLUMN_RENAMED:
      const nameIndex = nextState.columns.findIndex(c => c.key === action.oldKey);
      if (nameIndex !== -1)
      {
        const col = nextState.columns[nameIndex];
        col.renamed = true;
        col.newKey = action.newKey;
        nextState.columns[nameIndex].newKey = action.newKey;
      }
      return nextState;
    case META_EDITED:
      editMetaValue(nextState, action);
      return nextState;
    case METAS_LOADED:
      nextState.metas = action.metas;
      nextState.offset = action.offset;
      nextState.orderBy = action.orderBy;
      nextState.sort = action.sort;
      nextState.keyword = action.keyword;
      nextState.responseCount = action.responseCount;
      nextState.selectedColumn = null;
      nextState.selectedRow = null;
      return nextState;
    case ROW_SELECTED:
      nextState.selectedRow = action.id;
      return nextState;
    case SET_PHASE:
      const cleanedState = action.reset ?
        clone(defaultState) :
        nextState;
      cleanedState.phase = action.phase;
      return cleanedState;
    default:
      return nextState;
  }
};

function editMetaValue(state, action)
{
  if (!state.metaEdits[action.answerId])
  {
    state.metaEdits[action.answerId] = {};
  }
  state.metaEdits[action.answerId][action.key] = action.value;
}