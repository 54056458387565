import React, {useEffect} from "react";
import {connect} from "react-redux";
/*import {isFeatureEnabled} from "utils/organizationFeatures";*/
import Dropdown from "ui/Dropdown/Dropdown";
import EmailSettings from "./EmailSettings/EmailSettings";
import SFTPSettings from "./SFTPSettings/SFTPSettings";
import {
	setDestinationType,
	setFtpProtocol,
	setFinishAvailable
} from "../../automaticReportingActions";
import regexp from "utils/regexp";
import "./Destination.scss";
import "../../automaticReportingTypes";

/**
 * Destination step of the automatic report creation wizard.
 *
 * @param settings {DestinationSettings}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const Destination = ({
	settings,
	dispatch
}) => {
	//Mark finish as available when everything is OK.
	const finishAvailable =
		settings.destinationType === "email"
		?
		settings.email.to.trim().split(" ").every(recipient => regexp.EMAIL.test(recipient)) &&
		settings.email.subject.trim() !== ""
		:
		settings.ftp.host.trim() !== "" &&
		(
			(settings.ftp.username.trim() !== "" && settings.ftp.password.trim() !== "") ||
			settings.ftp.privateKey.trim() !== ""
		) &&
		(!isNaN(settings.ftp.port) && settings.ftp.port >= 0 && settings.ftp.port <= 65535) &&
		settings.ftp.nameTemplate.trim() !== "";

	useEffect(() => {
		dispatch(setFinishAvailable(finishAvailable))
	}, [finishAvailable]);

	const destinationTypeOptions = [[
		{text: translator.get("automaticReporting.emailDestination"), value: "email"},
		{text: translator.get("automaticReporting.ftpDestination"), value: "FTP"},
		{text: translator.get("automaticReporting.sftpDestination"), value: "SFTP"}
	]];

	//If the user switches to FTP or SFTP, update the FTP protocol in the FTP settings and not just
	// the generic protocol settings.
	const switchProtocol = (newValue) => {
		if (newValue === "FTP" || newValue === "SFTP")
		{
			dispatch(setFtpProtocol(newValue));
		}
		dispatch(setDestinationType(newValue));
	};

	return (
		<div className={"destinationContent"}>
			<div className={"destinationTypeSelection"}>
				<label htmlFor={"destinationTypeDropdown"}>{translator.get("automaticReporting.shareVia")}</label>
				<Dropdown
					id={"destinationTypeDropdown"}
					items={destinationTypeOptions}
					selectedValue={settings.destinationType}
					onChange={(newValue) => switchProtocol(newValue)}
				/>
			</div>
			{
				settings.destinationType === "email" &&
				<EmailSettings/>
			}
			{
				(settings.destinationType === "FTP" || settings.destinationType === "SFTP") &&
				<SFTPSettings/>
			}
		</div>
	);
};

export default connect(state => ({
	reportName: state.automaticReporting.createNewReport.formatAndContent.reportName,
	settings: state.automaticReporting.createNewReport.destination
}))(Destination);