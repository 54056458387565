export function createAnalyticsRanks(elementOptions) {
  const names = createRankNames(elementOptions);
  return names.map(name => {
    return {
      name: name,
      real: 0,
      y: 0
    };
  });
}

export function createRankNames(elementOptions)
{
  return elementOptions.map((_opt, i) => {
    return i === 0 ?
      (i + 1) + " " + translator.get("filters.rankHighest") :
      (
        i === (elementOptions.length - 1) ?
          (i + 1) + " " + translator.get("filters.rankLowest") :
          String(i + 1)
      );
  });
}
