import axios from "core/axios-sp";
import clone from "lodash.clonedeep";
import isNil from "lodash.isnil";
import assign from "lodash.assign";
import uniqby from "lodash.uniqby";

const PERSONAL_DATA_SEARCH_MODE = {
	ALL: "ALL",
	META: "META",
	ANSWER_DATA: "ANSWER_DATA"
};

// Personal data results can be cached because they change slowly and searches are usually done on old results.
// This class takes care of data results and caching them for each set of search parameters.
export default class PersonalDataFetcher {
	constructor()
	{
		this.cache = {};
	}

	fetch(survey, searchDate, rawSearchStr, searchResponses, searchMeta)
	{
		const searchMode = searchResponses && searchMeta ?
		                   PERSONAL_DATA_SEARCH_MODE.ALL :
		                   (
		                   	searchMeta ?
		                    PERSONAL_DATA_SEARCH_MODE.META :
		                    PERSONAL_DATA_SEARCH_MODE.ANSWER_DATA
		                   );
		const cacheKey = `${survey.id}-${rawSearchStr}-${searchDate}-${searchMode}`;
		// Use cache
		if (!isNil(this.cache[cacheKey]))
		{
			return Promise.resolve(clone(this.cache[cacheKey]));
		}
		// Fetch new results from back-end
		else
		{
			return PersonalDataFetcher.fetchPersonalData(survey, searchDate, rawSearchStr, searchMode).then(processedMatches => {
				this.cache[cacheKey] = processedMatches;
				return clone(processedMatches);
			});
		}
	}

	// Fetch survey timeline in an array that has survey timeline in shape:
	// [{date: 100}, {date: 200} ...]
	static fetchPersonalData(survey, searchDate, rawSearchStr, searchMode)
	{
		const searchString = rawSearchStr.replace(/[*]/g, "%");
		return axios.get("/app/team/personaldata/survey", {
			params: {
				json: {
					searchMode,
					surveyId: survey.id,
					searchDate,
					searchString
				}
			}
		}).then(response => {
			if (response.status === 200)
			{
				// It's possible to get same answer match twice if there's meta and answer match for the same response.
				// Show only one of them to avoid problems with react keys and delete requests.
				const uniqMatches = uniqby(response.data, "hash");
				return uniqMatches.map(PersonalDataFetcher.prepareMatchForLocalUse.bind(null, searchString, survey.name));
			}
			// "No content" status
			else if (response.status === 204)
			{
				return [];
			}
			else
			{
				return Promise.reject("Unexpected status from GET /app/team/personaldata/survey !");
			}
		});
	}

	static prepareMatchForLocalUse(searchStr, surveyName, item)
	{
		const hasSourceMatch = !!item.recipient && (item.recipient === searchStr);
		return assign(clone(item), {
			surveyName,
			matches: PersonalDataFetcher.parseAllMatches(item, hasSourceMatch),
			recipient: item.recipient ?
			           item.recipient :
			           translator.get("manage.sourceAnonymous")
		});
	}

	static parseAllMatches(item, hasSourceMatch)
	{
		const sourceMatches = hasSourceMatch ? [translator.get("profile-app-data.labelRecipient")] : [];
		const metaMatches = item.meta ? PersonalDataFetcher.parseMatchesFromObject(true, item.meta) : [];
		const answerMatches = item.answerData ? PersonalDataFetcher.parseMatchesFromObject(false, item.answerData) : [];
		return sourceMatches.concat(metaMatches.concat(answerMatches));
	}

	static parseMatchesFromObject(isMeta, matchObject)
	{
		const prefix = isMeta ? translator.get("profile-app-data.metaPrefix") : "";
		const keys = Object.keys(matchObject);
		return keys.map(key => {
			const value = matchObject[key];
			return `${prefix}${key}: ${value}`;
		});
	}
};

