define("utils/session-storage", function(require)
{
	var Storage = require("utils/storage");
	return Storage.extend(
	{
		init: function()
		{
			this.supreme(sessionStorage);
		}
	})
});