const Geolocator = require("geolocator");

export function sendConsent(user)
{
	require("./drip"); // This will make _dcq as globally available object

	detectGeolocation()
		.then(geoLocation => {
			_dcq.push([
				"identify",
				{
					email: user.getUsername(),
					first_name: user.getFirstName(),
					last_name: user.getLastName(),
					privacyaccept: "yes",
					receiveupdates: "yes",
					country: geoLocation.address.country,
					region: getSalesRegion(geoLocation),
					tags: ["Subscribed a blog"]
				}
			]);
		})
		.catch(error => {
			// Sent the consent but with less parameters
			_dcq.push([
				"identify",
				{
					email: user.getUsername(),
					first_name: user.getFirstName(),
					last_name: user.getLastName(),
					privacyaccept: "yes",
					receiveupdates: "yes",
					tags: ["Subscribed a blog"]
				}
			]);
		});
}

function detectGeolocation()
{
	return new Promise((resolve, reject) => {
		Geolocator.setGeoIPSource({
			provider: 'ipstack',
			// https requires premium api key
			url: 'https://api.ipstack.com/check?access_key=ce4ce59fef5bd0d26f000f6841e9360a',
			callbackParam: 'callback',
			schema: {
				ip: 'ip',
				coords: {
					latitude: 'latitude',
					longitude: 'longitude'
				},
				address: {
					city: 'city',
					state: 'region_name',
					stateCode: 'region_code',
					postalCode: 'zip',
					countryCode: 'country_code',
					country: 'country_name',
					region: 'region_name'
				}
			}
		});
		Geolocator.locateByIP({}, (error, location) => {
			if(error)
			{
				console.warn("Location detection failed: " + error);
				reject(error);
			}
			else
			{
				console.info("Location detected.");
				console.info(`Country detected as ${location.address.country}`);
				resolve(location);
			}
		});
	});
}

function getSalesRegion(geoLocation)
{
	switch (geoLocation.address.country)
	{
		case "Finland":
			return "Finland";
		case "United States":
		case "Canada":
		case "Mexico":
		case "Cuba":
		case "Puerto Rico":
		case "Guatemala":
		case "Nicaragua":
		case "Venezuela":
		case "Colombia":
			return "NA";
		default:
			return "International";
	}
}