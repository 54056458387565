define("notifications/premium-limit-notification", function(require)
{
	var NotificationBar = require("ui/notification-bar");

	return NotificationBar.extend(
		{
			init: function ()
			{
				this.supreme();
			},

			setText: function(answerCount)
			{
				var text = translator.get("premium-answer-limit.barText1000");
				this.text(text);
			}
		});
});