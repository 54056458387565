import axios from "core/axios-sp";
import { TEMPLATE_PARAMS } from "./template-params";

export const PATHS = {
	GLOBAL: {
		LIST: "/app/survey/template/list",
		MAIN: "/app/survey/template",
		USE: "/app/survey/template/use",
		PREVIEW: "/app/survey/template/preview"
	},
	ORGANIZATION: {
		LIST: "/app/org/survey/template/list",
		MAIN: "/app/org/survey/template",
		USE: "/app/org/survey/template/use",
		PREVIEW: "/app/org/survey/template/preview"
	}
};

export function fetchOrganizationTemplates() {
	return new Promise(resolve => {
		axios.get(PATHS.ORGANIZATION.LIST)
			.then(res => resolve(res.data))
			.catch(_e => resolve([]));
	})
}

export function fetchGlobalTemplates()
{
	return new Promise(resolve => {
		axios.get(PATHS.GLOBAL.LIST)
			.then(res => resolve(res.data))
			.catch(_e => resolve([]));
	});
}

export function fetchAllTemplates()
{
	return Promise.all([fetchOrganizationTemplates(), fetchGlobalTemplates()])
		.then(resArray => {
			return resArray[0].concat(resArray[1]);
		})
		.catch(_e => {
			console.warn(e);
			return [];
		});
}

export function deleteTemplate(template)
{
	return axios.delete(PATHS[template.type].MAIN, {params: {json: {id: template.id}}});
}

export function updateTemplate(template, filterLocale)
{
	return axios.post(PATHS[template.type].MAIN, {
		id: template.id,
		title: template.title,
		description: template.description,
		language: template.language ? template.language : template.localeFilter,
	});
}

export function createTemplate(template)
{
	return axios.put(PATHS[template.type].MAIN, {
		title: template.title,
		description: template.description,
		language: template.language,
		surveyId: template.survey_id
	});
}
export function updateTemplateWithType(oldTemplate, newTemplate)
{
	return deleteTemplate(oldTemplate).then(() => createTemplate(newTemplate));
}


export function validateTemplate(template)
{
	return TEMPLATE_PARAMS.reduce((collector, param) => {
		if (typeof param.validate === "function")
		{
			const validationResult = param.validate(template[param.key]);
			if (!validationResult.valid)
			{
				collector.push({
					configurationId: template.id,
					paramKey: param.key,
					error: validationResult.error
				});
			}
		}
		return collector;
	}, []);
}