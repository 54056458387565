import { ALL_VISIBILITY_RULES } from "conf/visibility-rules";
import { VISIBILITY_LEVELS, REASON_OBJS } from "conf/visibility-constants";
import * as Roles from "conf/roles";
import clone from "lodash.clonedeep";

/**
 * Shortcut function for checking if user has VISIBILITY_LEVEL.ENABLED for a single feature
 *
 * @param {string} feature - Feature key to be checked
 * @param {object} parameters - Parameter object of shape {
 *   accessibilityRequired: true|false,
 *   environment: server environment
 * }
 * @return {boolean} returns true if user has access level VISIBILITY_LEVEL.ENABLED
 */
export function isFeatureEnabled(feature, parameters)
{
	const resultObj = checkAccess([feature], parameters);
	return resultObj[feature].result === VISIBILITY_LEVELS.ENABLED;
}

/**
 * Checks user access for several features and returns an object of results for each feature. Even when
 * checking just one feature, the checking process goes through this function, so you still need to pass the queried
 * feature as a single-item array.
 *
 * @param {array} features - Keys for features to be checked
 * @param {object} parameters - Parameter object of shape {
 *   accessibilityRequired: true|false,
 *   environment: server environment
 * }
 * @returns {object} object of shape {
 *   feature1: {
 *     humanReadable: "Text about why feature is disabled"
 *     reasons: Array of "INACCESSIBILITY"|"LICENSE"|"ROLE"
 *     result: One of 0 (VISIBILITY_LEVEL.HIDDEN), 1 (VISIBILITY_LEVEL.DISABLED), 2 (VISIBILITY_LEVEL.ENABLED)
 *   },
 *   feature2: {...}
 * }
 */
export function checkAccess(features, parameters)
{
	// Access global functions and do side effects here to keep checkIndividualFeature as pure as possible.
	const user = Surveypal.getUser();
	const licensedFeatures = user.getLicensedFeatures();
	const role = user.getRole();

	// Todo: If there is no accessibility parameter passed to this function, find out the user's general accessibility setting.
	const accessibilityRequired = !!parameters.accessibilityRequired;
	const resultObjs = features.map(feature => checkIndividualFeature(feature, licensedFeatures, role, accessibilityRequired, translator.get.bind(translator)));
	return features.reduce((collector, feature, i) => {
		return {...collector, [feature]: resultObjs[i]};
	}, {});
}

export const DEFAULT_RESULT = {
	humanReadable: "",
	reasons: [REASON_OBJS.LICENSE.key],
	result: VISIBILITY_LEVELS.ENABLED
};

/**
 * This function checks user access for an individual feature.
 * The function is as pure as possible for easy unit testing.
 *
 * @param {string} feature - Feature key to be checked
 * @param {array} licensedFeatures - Array of features keys that represent features currently enabled by license
 * @param {string} role - User's role
 * @param {boolean} accessibilityRequired - Defines if accessibility rules are to be checked or not
 * @param {function} translatorGet - Reference to the global translator's get function
 *
 * @returns {object} Result item of shape {
 *   result: number - LEVELS.HIDDEN|LEVELS.DISABLED|LEVELS.USABLE,
 *   humanReadable: string - human readable explanation why the feature is disabled or hidden
 *   reason: string - REASONS.ACCESSIBILITY|REASONS.LICENSE|REASONS.ROLE
 * }
 */

export function checkIndividualFeature(feature, licensedFeatures, role, accessibilityRequired, translatorGet)
{
	// Validate the key being checked
	if (!ALL_VISIBILITY_RULES[feature])
	{
		console.error("Feature key '" + feature + "' provided to user-access.js@checkIndividualFeature is not a valid feature key!");
		return DEFAULT_RESULT;
	}

	// Do all checks that the feature's rules require
	const rules = ALL_VISIBILITY_RULES[feature];
	const positiveAdditions = {result: VISIBILITY_LEVELS.ENABLED, text: ""};
	const resultObjs = rules.map(ruleOriginal =>
	{
		const rule = clone(ruleOriginal);
		switch (rule.reason)
		{
			case REASON_OBJS.LICENSE.key:
				return licensedFeatures.includes(feature) ?
					{...rule, ...positiveAdditions} :
					clone(rule);

			case REASON_OBJS.INACCESSIBILITY.key:
				return !accessibilityRequired ?
					{...rule, ...positiveAdditions}  :
					clone(rule);

			case REASON_OBJS.ROLE.key:
				return Roles.isAtLeast(role, rule.value) ?
					{...rule, ...positiveAdditions} :
					clone(rule);

			default:
				return clone(DEFAULT_RESULT);
		}
	});

	// 1) Filter objects with minimal visibility result
	// 2) From those: Filter objects with maximal priority
	const minResult = Math.min(...resultObjs.map(res => res.result));
	const minResultObjs = resultObjs.filter(res => res.result === minResult);
	const maxPrio = Math.max(...minResultObjs.map(res => res.priority));
	const maxPrioObjs = minResultObjs.filter(res => res.priority === maxPrio);
	return {
		humanReadable: minResult === VISIBILITY_LEVELS.DISABLED ?
			translateReasonText(maxPrioObjs, translatorGet) :
			"",
		reasons: maxPrioObjs.map(res => res.reason),
		result: minResult
	};
}

/**
 * This function transforms the array of reasons into a human-readable text. The array contains translation keys
 * for the translation key group "visibility-rules", i.e. the group should not be included in the array items.
 *
 * The resulting text is an HTML-formatted string, so it needs to be displayed in a context where HTML can be
 * displayed (for example the LifeSaverR component), or tags need to be stripped out.
 *
 * @author Pauli Marttinen
 * @param {array} results - result objects from which the reason string is built from
 * @param {function} translatorGet - reference to translator's get function. Passed as a parameter for unit testing.
 * @returns {string} - human readable explanation why the feature is disabled
 */
function translateReasonText(results, translatorGet)
{
	if (results.length === 1)
	{
		return "<p>" + getLinkedText(results[0], translatorGet) + "</p>";
	}
	else
	{
		let humanReadableText = "<p>" + translatorGet("visibility-rules.disabledBecause") + "</p><ul>";
		for (let item = 0; item < results.length; item++)
		{
			humanReadableText += "<li>" + getLinkedText(results[item], translatorGet) + "</li>";
		}
		humanReadableText += "</ul>";

		return humanReadableText;
	}
}

const LINK_REGEX = /{{[\w|\s]+}}/;
function getLinkedText(result, translatorGet)
{
	let base = translatorGet(result.text);
	if (result.link)
	{
		const found = base.match(LINK_REGEX);
		if (found && found[0])
		{
			const trimmedFound = found[0].replace(/[{|}]/gi, "");
			const aElem = "<a href='" + result.link + "'>" + trimmedFound + "</a>";
			base = base.replace(LINK_REGEX, aElem);
		}
	}
	return base;
}