const SurveyAccess = {
	ACCESS_TYPE: {
		OWN: 0,
		SHARED: 1,
		LIMITED: 2,
		TEAM: 3,
		TEAM_SHARE: 4,
		TEAM_LIMITED: 5
	},
	PERMISSION: {
		READ: 0,
		WRITE: 1
	},
	isTeamShared: function(item) {
		return item.access === this.ACCESS_TYPE.TEAM ||
		       item.access === this.ACCESS_TYPE.TEAM_SHARE ||
		       item.access === this.ACCESS_TYPE.TEAM_LIMITED;
	}
};

export default SurveyAccess;