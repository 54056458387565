import React from "react";
import {connect} from "react-redux";
import {isFeatureEnabled} from "utils/organizationFeatures";
import {StepDivision} from "ui/Wizard/Wizard";
import {
	setFtpHost,
	setFtpPort,
	setFtpUsername,
	setFtpPassword,
	setFtpPrivateKey,
	setFtpPath,
	setFtpNameTemplate
} from "../../../automaticReportingActions";
import "./SFTPSettings.scss";

/**
 * (S)FTP settings for Destination step.
 *
 * @param destinationType {"email"|"FTP"|"SFTP"}
 * @param protocol {"FTP"|"SFTP"}
 * @param host {string}
 * @param port {number}
 * @param username {string}
 * @param password {string}
 * @param privateKey {string}
 * @param path {string}
 * @param nameTemplate {string}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const SFTPSettings = ({
	destinationType,
	host,
	port,
	username,
	password,
	privateKey,
	path,
	nameTemplate,
	dispatch
}) => {
	if (!isFeatureEnabled("SCHEDULED_EXPORTS_SFTP"))
	{
		return (
			<StepDivision
				className={"settings"}
				title={translator.get("automaticReporting.shareViaDisabledTitle")}>
				<p>{translator.get("automaticReporting.shareViaDisabledText")}</p>
			</StepDivision>
		);
	}

	return (
		<StepDivision
			className={"settings"}
			title={translator.get(
				destinationType === "FTP"
				?
				"automaticReporting.ftpDetails"
				:
				"automaticReporting.sftpDetails")}>
			<div>
				<label htmlFor={"ftpHost"}>{translator.get("automaticReporting.ftpHost")}</label>
				<input
					type={"text"}
					id={"ftpHost"}
					className={"text"}
					value={host}
					onChange={(e) => dispatch(setFtpHost(e.target.value))}
				/>
				<label htmlFor={"ftpPort"}>{translator.get("automaticReporting.ftpPort")}</label>
				<input
					type={"text"}
					id={"ftpPort"}
					className={"text"}
					value={port}
					onChange={(e) => dispatch(setFtpPort(parseInt(e.target.value)))}
				/>
				<label htmlFor={"ftpUsername"}>{translator.get("automaticReporting.ftpUsername")}</label>
				<input
					type={"text"}
					id={"ftpUsername"}
					className={"text"}
					value={username}
					onChange={(e) => dispatch(setFtpUsername(e.target.value))}
				/>
				<label htmlFor={"ftpPassword"}>{translator.get("automaticReporting.ftpPassword")}</label>
				<input
					type={"text"}
					id={"ftpPassword"}
					className={"text"}
					value={password}
					onChange={(e) => dispatch(setFtpPassword(e.target.value))}
				/>
			</div>
			<div>
				{/*<label htmlFor={"ftpPrivateKey"}>{translator.get("automaticReporting.ftpPrivateKey")}</label>
						<input
							type={"text"}
							id={"ftpPrivateKey"}
							className={"text"}
							value={privateKey}
							onChange={(e) => dispatch(setFtpPrivateKey(e.target.value))}
						/>*/}
				<label htmlFor={"ftpPath"}>{translator.get("automaticReporting.ftpPath")}</label>
				<input
					type={"text"}
					id={"ftpPath"}
					className={"text"}
					value={path}
					onChange={(e) => dispatch(setFtpPath(e.target.value))}
				/>
				<label htmlFor={"ftpNameTemplate"}>{translator.get("automaticReporting.ftpNameTemplate")}</label>
				<input
					type={"text"}
					id={"ftpNameTemplate"}
					className={"text"}
					value={nameTemplate}
					onChange={(e) => dispatch(setFtpNameTemplate(e.target.value))}
				/>
				<div className={"nameTemplateHelp"}>
					<p>{translator.get("automaticReporting.ftpNameTemplateText")}</p>
					<table>
						<tbody>
						<tr>
							<td>{"{0,number,0}"}</td>
							<td>{translator.get("automaticReporting.unixTimestamp")}</td>
						</tr>
						<tr>
							<td>{"{1,date,yyyyMMdd}"}</td>
							<td>{translator.get("automaticReporting.humanReadableDate")}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</StepDivision>
	)
};

export default connect(state => ({
	destinationType: state.automaticReporting.createNewReport.destination.destinationType,
	protocol: state.automaticReporting.createNewReport.destination.ftp.protocol,
	host: state.automaticReporting.createNewReport.destination.ftp.host,
	port: state.automaticReporting.createNewReport.destination.ftp.port,
	username: state.automaticReporting.createNewReport.destination.ftp.username,
	password: state.automaticReporting.createNewReport.destination.ftp.password,
	privateKey: state.automaticReporting.createNewReport.destination.ftp.privateKey,
	path: state.automaticReporting.createNewReport.destination.ftp.path,
	nameTemplate: state.automaticReporting.createNewReport.destination.ftp.nameTemplate
}))(SFTPSettings);