import React, {useState} from "react";
import ButtonR from "ui/ButtonR";
import ConfirmationPopup from "./ConfirmationPopup/ConfirmationPopup";

/**
 * @callback onClick
 * @param id {number}
 */
/**
 * <RemoveReport/> is the button for deleting a scheduled report.
 *
 * @param reportName {string}
 * @param onClick {onClick}
 * @param reportId {number}
 * @returns {JSX.Element}
 */
const RemoveReport = ({reportName, onClick, reportId}) => {
	const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

	const confirmDeletion = () => {
		setShowConfirmationPopup(false);
		onClick(reportId);
	};

	return (
		<>
			<ButtonR
				text={translator.get("common.delete")}
				callback={() => setShowConfirmationPopup(true)}
				isRound={true}
				singleUse={false}
				isDangerous={true}
			/>
			{
				showConfirmationPopup &&
				<ConfirmationPopup
					reportName={reportName}
					onCancel={() => setShowConfirmationPopup(false)}
					onConfirm={() => confirmDeletion()}
				/>
			}
		</>
	);
};

export default RemoveReport;