import React from "react";
import {connect} from "react-redux";
import Dropdown from "ui/Dropdown/Dropdown";
import {
	setNewResponsesFilter,
	setTemplatesFilter,
	setSharedFilter,
	setAutomaticReportsFilter
} from "../../surveysActions";
import {isFeatureEnabled} from "utils/organizationFeatures";
import "../../surveysTypes";
import "./Filters.scss";

export const FILTER_VALUES = {
	ALL: "ALL",
	YES: "YES",
	NO: "NO"
};

/**
 * Filters displays a selection of ways to filter surveys in the survey listing.
 *
 * @param newResponsesFilter {SecondaryFilter}
 * @param templatesFilter {SecondaryFilter}
 * @param sharedFilter {SecondaryFilter}
 * @param automaticReportsFilter {SecondaryFilter}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const Filters = ({
	newResponsesFilter,
	templatesFilter,
	sharedFilter,
	automaticReportsFilter,
	dispatch
}) => {
	const newResponsesOptions = [[
		{
			text: translator.get("home.allSurveys"),
			value: FILTER_VALUES.ALL
		},
		{
			text: translator.get("home.newResponsesOnly"),
			value: FILTER_VALUES.YES
		},
		{
			text: translator.get("home.newResponsesOmitted"),
			value: FILTER_VALUES.NO
		}
	]];

	const templatesOptions = [[
		{
			text: translator.get("home.allSurveys"),
			value: FILTER_VALUES.ALL
		},
		{
			text: translator.get("home.usedAsTemplateOnly"),
			value: FILTER_VALUES.YES,
		},
		{
			text: translator.get("home.usedAsTemplateOmitted"),
			value: FILTER_VALUES.NO
		}
	]];

	const sharedOptions = [[
		{
			text: translator.get("home.allSurveys"),
			value: FILTER_VALUES.ALL
		},
		{
			text: translator.get("home.surveySharedOnly"),
			value: FILTER_VALUES.YES
		},
		{
			text: translator.get("home.surveySharedOmitted"),
			value: FILTER_VALUES.NO
		}
	]];

	const automaticReportsOptions = [[
		{
			text: translator.get("home.allSurveys"),
			value: FILTER_VALUES.ALL
		},
		{
			text: translator.get("home.automaticReportsOnly"),
			value: FILTER_VALUES.YES
		},
		{
			text: translator.get("home.automaticReportsOmitted"),
			value: FILTER_VALUES.NO
		}
	]];

	return (
		<div className={"SurveyListFilters"}>
			<div className={"Label"}>
				{translator.get("home.filters")}
			</div>
			<div className={"Filter NewResponses"}>
				<Dropdown
					id={"newResponsesFilterDropdown"}
					items={newResponsesOptions}
					selectedValue={newResponsesFilter}
					onChange={(newValue) => dispatch(setNewResponsesFilter(newValue))}
					prefix={translator.get("home.newResponses")}
				/>
			</div>
			<div className={"Filter Templates"}>
				<Dropdown
					id={"templatesFilterDropdown"}
					items={templatesOptions}
					selectedValue={templatesFilter}
					onChange={(newValue) => dispatch(setTemplatesFilter(newValue))}
					prefix={translator.get("home.usedAsTemplate")}
				/>
			</div>
			<div className={"Filter Shared"}>
				<Dropdown
					id={"sharedFilterDropdown"}
					items={sharedOptions}
					selectedValue={sharedFilter}
					onChange={(newValue) => dispatch(setSharedFilter(newValue))}
					prefix={translator.get("home.surveyShared")}
				/>
			</div>
			{
				(isFeatureEnabled("SCHEDULED_EXPORTS") || isFeatureEnabled("SCHEDULED_EXPORTS_SFTP")) &&
				<div className={"Filter AutomaticReports"}>
					<Dropdown
						id={"automaticReportsFilterDropdown"}
						items={automaticReportsOptions}
						selectedValue={automaticReportsFilter}
						onChange={(newValue) => dispatch(setAutomaticReportsFilter(newValue))}
						prefix={translator.get("home.automaticReports")}
					/>
				</div>
			}
		</div>
	);
};

export default connect(state => ({
	newResponsesFilter: state.surveys.list.filters.newResponsesFilter,
	templatesFilter: state.surveys.list.filters.templatesFilter,
	sharedFilter: state.surveys.list.filters.sharedFilter,
	automaticReportsFilter: state.surveys.list.filters.automaticReportsFilter,
	nameFilter: state.surveys.list.filters.nameFilter
}))(Filters);