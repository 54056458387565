module.exports = {
	Public: 'public',
	Visitor: 'visitor',
	User: 'user',
	Admin: 'admin',
	Sales: 'sales',
	Support: 'support',
	Super_admin: 'super_admin',

	isOrganizationRestrictedRole: function(role)
	{
		return role === this.Sales ||
		       role === this.Support ||
		       role === this.Super_admin ;
	},

	isAtLeast: function(userRole, targetRole)
	{
		const rankedRoles = [this.Public, this.Visitor, this.User, this.Admin, this.Sales, this.Support, this.Super_admin];
		const userRoleIndex = rankedRoles.findIndex(someRole => someRole === userRole);
		const targetRoleIndex = rankedRoles.findIndex(someRole => someRole === targetRole);
		return userRoleIndex >= targetRoleIndex;
	}
};