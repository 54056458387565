import React, {useEffect} from "react";
import {connect} from "react-redux";
import {StepDivision} from "ui/Wizard/Wizard";
import Dropdown from "ui/Dropdown/Dropdown";
import DateSelection from "ui/DateSelection/DateSelection";
import TimeSelection from "ui/TimeSelection/TimeSelection";
import Weekly from "./Weekly/Weekly";
import Monthly from "./Monthly/Monthly";
import Advanced from "./Advanced/Advanced";
import {
	setStartDate,
	setStartHours,
	setStartMinutes,
	setPerpetual,
	setEndDate,
	setEndHours,
	setEndMinutes,
	setSchedulingMode,
	setDestinationStepAvailable
} from "../../automaticReportingActions";
import "./Schedule.scss";
import * as Roles from "conf/roles";
import "../../automaticReportingTypes";
import InlineWarning from "ui/InlineWarning/InlineWarning";

export const SCHEDULING_MODES = {
	WEEKLY: "WEEKLY",
	MONTHLY: "MONTHLY",
	ADVANCED: "ADVANCED"
};

/**
 * The scheduling step for the automated reports wizard.
 *
 * @param settings {ScheduleSettings}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const Schedule = ({settings, dispatch}) => {
	const endDateOk = settings.start.date < settings.end.date;

	const destinationStepAvailable =
		(
			settings.schedulingMode === SCHEDULING_MODES.WEEKLY ||
			settings.schedulingMode === SCHEDULING_MODES.MONTHLY ||
			(settings.schedulingMode === SCHEDULING_MODES.ADVANCED && ( //If scheduling mode is advanced, the given Cron expression must be valid
				settings.advanced.cronExpressionValidity.withSeconds ||   //either with seconds included
				settings.advanced.cronExpressionValidity.withoutSeconds   //or with seconds excluded
			))
		) &&
		(settings.perpetual || !settings.perpetual && endDateOk);

	//Mark destination step available when everything is OK.
	useEffect(() => {
		dispatch(setDestinationStepAvailable(destinationStepAvailable));
	}, [destinationStepAvailable]);

	const perpetualityOptions = [[
		{ text: translator.get("automaticReporting.noEndDate"), value: true },
		{ text: translator.get("automaticReporting.endOnDate"), value: false}
	]];

	//Show advanced timing options to support+ users only.
	const schedulingModeOptions = Roles.isAtLeast(Surveypal.getUser().getRole(), "support")
		?
		[[
			{ text: translator.get("automaticReporting.weekly"), value: SCHEDULING_MODES.WEEKLY},
			{ text: translator.get("automaticReporting.monthly"), value: SCHEDULING_MODES.MONTHLY},
			{ text: translator.get("common.advanced"), value: SCHEDULING_MODES.ADVANCED},
		]]
		:
		[[
			{ text: translator.get("automaticReporting.weekly"), value: SCHEDULING_MODES.WEEKLY},
			{ text: translator.get("automaticReporting.monthly"), value: SCHEDULING_MODES.MONTHLY}
		]];

	return (
		<div className={"scheduleStep"}>
			<StepDivision
				className={"limitDates"}>
				<StepDivision
					className={"startDate"}
					title={translator.get("automaticReporting.defineStartDate")}>
					<div className={"date"}>
						<label>{translator.get("automaticReporting.date")}</label>
						<DateSelection
							value={settings.start.date}
							onChangeDate={(newDate) => dispatch(setStartDate(newDate))}
						/>
					</div>
					<div className={"time"}>
						<label>{translator.get("automaticReporting.time")}</label>
						<TimeSelection
							value={settings.start}
							onChangeHours={(newHours) => dispatch(setStartHours(newHours))}
							onChangeMinutes={(newMinutes) => dispatch(setStartMinutes(newMinutes))}
						/>
					</div>
				</StepDivision>
				<StepDivision
					className={"endDate"}
					title={translator.get("automaticReporting.defineEndDate")}>
						<Dropdown
							className={"perpetuality"}
							selectedValue={settings.perpetual}
							items={perpetualityOptions}
							onChange={(newValue) => dispatch(setPerpetual(newValue))}
						/>
						{
							!settings.perpetual &&
							<div className={"endDate"}>
								<div className={"date"}>
									<label>{translator.get("automaticReporting.date")}</label>
									<DateSelection
										value={settings.end.date}
										onChangeDate={(newDate) => dispatch(setEndDate(newDate))}
									/>
								</div>
								<div className={"time"}>
									<label>{translator.get("automaticReporting.time")}</label>
									<TimeSelection
										value={settings.end}
										onChangeHours={(newHours) => dispatch(setEndHours(newHours))}
										onChangeMinutes={(newMinutes) => dispatch(setEndMinutes(newMinutes))}
									/>
								</div>
							</div>
						}
					{
						!settings.perpetual && !endDateOk &&
						<div className={"endDateError"}>
							<InlineWarning>{translator.get("automaticReporting.endDateWarning")}</InlineWarning>
						</div>
					}
				</StepDivision>
			</StepDivision>
			<StepDivision
				className={"frequency"}
				title={translator.get("automaticReporting.defineFrequency")}>
				<div className={"schedulingMode"}>
					<label>{translator.get("automaticReporting.runAutomation")}</label>
					<Dropdown
						className={"schedulingModeDropdown"}
						selectedValue={settings.schedulingMode}
						items={schedulingModeOptions}
						onChange={(newValue) => dispatch(setSchedulingMode(newValue))}
					/>
				</div>
				<div className={"schedulingSettings"}>
					{
						settings.schedulingMode === SCHEDULING_MODES.WEEKLY &&
						<Weekly/>
					}
					{
						settings.schedulingMode === SCHEDULING_MODES.MONTHLY &&
						<Monthly/>
					}
					{
						settings.schedulingMode === SCHEDULING_MODES.ADVANCED &&
						<Advanced/>
					}
				</div>
			</StepDivision>
		</div>
	);
};

export default connect(state => ({
	settings: state.automaticReporting.createNewReport.schedule
}))(Schedule);