define("monitors/poll-monitor", function(require)
{
	var Monitor = require("monitors/monitor");

	return Monitor.extend(
	{
		init: function(delay)
		{
			this.supreme();
			this.DELAY = delay || 60000;
		},

		start: function()
		{
			this.stop();
			this.timer = setInterval(this.poll.bind(this), this.DELAY);
		},

		stop: function()
		{
			clearInterval(this.timer);
		},

		// Used to check condition ad-hoc or by the timer.
		// Does not affect the timer, so stop and start timer independently.
		poll: function()
		{
		},

		canPoll: function()
		{
			return !Surveypal.getUser().needToChangePassword();
		}
	});
});