define("common/terms-and-privacy", function(require)
{
	var Class = require("core/class");
	var Locales = require("conf/locales");
	var Tab = require("utils/tab");

	return Class.extend({
		init: function(locale)
		{
			this._locale = locale;
		},

		terms: function ()
		{
			this.open("terms");
		},

		privacy: function ()
		{
			this.open("privacy");
		},

		open: function(name)
		{
			var LocaleMap = Locales.map;
			var url = LocaleMap[this._locale][name] ? LocaleMap[this._locale][name] : LocaleMap["en_US"][name];
			new Tab(url);
		}
	});
});