import AccessConf from "conf/survey-access";
import {SUBVIEW_PATHS} from "../subviews";
import {parseTags} from "../../utils/parseTags";

/**
 * yourSurveys filters surveys owned by the user.
 *
 * @param survey {object}
 * @returns {boolean}
 */
export const yourSurveys = (survey) => {
	const isYourSurvey = survey.owner === Surveypal.getUser().getId();
	const isOwnSurvey = survey.access === AccessConf.ACCESS_TYPE.OWN;
	const isNotDeleted = !survey.deleted;

	return isYourSurvey && isOwnSurvey && isNotDeleted;
};

/**
 * teamSurveys filters surveys owned by someone else and shared to the user's team.
 *
 * @param survey {object}
 * @returns {false}
 */
export const teamSurveys = (survey) => {
	const isSomeoneElsesSurvey = survey.owner !== Surveypal.getUser().getId();
	const isTeamShared = AccessConf.isTeamShared(survey);
	const isNotDeleted = !survey.deleted;

	return isSomeoneElsesSurvey && isTeamShared && isNotDeleted;
};

/**
 * sharedSurveys filters surveys that are shared to the user personally.
 *
 * @param survey {object}
 * @returns {boolean}
 */
export const sharedSurveys = (survey) => {
	const isSomeoneElsesSurvey = survey.owner !== Surveypal.getUser().getId();
	const isNotSharedToTeam = !AccessConf.isTeamShared(survey);
	const isNotOwnSurvey = survey.access !== AccessConf.ACCESS_TYPE.OWN;
	const isNotDeleted = !survey.deleted;

	return isSomeoneElsesSurvey && isNotSharedToTeam && isNotOwnSurvey && isNotDeleted;
};

/**
 * allSurveys filters all surveys.
 *
 * @param survey {object}
 * @returns {boolean}
 */
export const allSurveys = (survey) => {
	return !survey.deleted;
};

/**
 * deletedSurveys filters surveys that are deleted.
 *
 * @param survey {object}
 * @returns {boolean}
 */
export const deletedSurveys = (survey) => {
	const isDeleted = survey.deleted;
	const isNotTeamShared = !AccessConf.isTeamShared(survey);

	return isDeleted && isNotTeamShared;
};

/**
 * taggedSurveys filters surveys tagged with any of the given tags.
 *
 * @param survey {object}
 * @param tags {string[]}
 * @returns {boolean}
 */
export const taggedSurveys = (survey, tags) => {
	if (survey.deleted) return false;

	const surveyTags = parseTags(survey);
	return tags.some(tag => surveyTags.indexOf(tag) !== -1);
};

/**
 * zeroSurveys filters out all surveys.
 *
 * @returns {boolean}
 */
export const zeroSurveys = () => {
	return false;
};

export const FILTER_NAMES = {
	YOUR_SURVEYS: SUBVIEW_PATHS.YOUR_SURVEYS,
	TEAM_SURVEYS: SUBVIEW_PATHS.TEAM_SURVEYS,
	ALL_SURVEYS: SUBVIEW_PATHS.ALL_SURVEYS,
	SHARED_SURVEYS: SUBVIEW_PATHS.SHARED_SURVEYS,
	DELETED_SURVEYS: SUBVIEW_PATHS.DELETED_SURVEYS,
	TAGGED_SURVEYS: SUBVIEW_PATHS.KEYWORDS,
	ZERO_SURVEYS: SUBVIEW_PATHS.ZERO_SURVEYS
};

export default {
	FILTER_NAMES,
	[FILTER_NAMES.YOUR_SURVEYS]: yourSurveys,
	[FILTER_NAMES.TEAM_SURVEYS]: teamSurveys,
	[FILTER_NAMES.ALL_SURVEYS]: allSurveys,
	[FILTER_NAMES.SHARED_SURVEYS]: sharedSurveys,
	[FILTER_NAMES.DELETED_SURVEYS]: deletedSurveys,
	[FILTER_NAMES.TAGGED_SURVEYS]: taggedSurveys,
	[FILTER_NAMES.ZERO_SURVEYS]: zeroSurveys
};