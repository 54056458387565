import cloneDeep from "lodash.clonedeep";
import * as actions from "./crmStatisticsActions";

export const defaultState = {
	searchParameters: {
		offset: 0
	},
	organizations: [],
	loadingStatistics: false,
	loadingOrganizations: false,
	dataGranularity: actions.DATA_GRANULARITY.WEEK,
	trendLineVisibility: true,
	dailyCounts: [],
	fromDate: new Date(2009, 0, 1),
	toDate: new Date()
};

export default function crmStatisticsReducer(state = defaultState, action)
{
	const nextState = cloneDeep(state);
	switch(action.type)
	{
		case actions.CHANGE_PARAMETER:
			nextState.searchParameters = action.searchParameters;
			return nextState;

		case actions.SET_ORGANIZATIONS:
			nextState.organizations = action.organizations;
			return nextState;

		case actions.TOGGLE_LOADING_STATISTICS:
			nextState.loadingStatistics = action.loading;
			return nextState;

		case actions.TOGGLE_LOADING_ORGANIZATIONS:
			nextState.loadingOrganizations = action.loading;
			return nextState;

		case actions.SET_DATA_GRANULARITY:
			nextState.dataGranularity = action.granularity;
			return nextState;

		case actions.TOGGLE_TRENDLINE:
			nextState.trendLineVisibility = action.trendLineVisibility;
			return nextState;

		case actions.SET_TIMERANGE:
			nextState.fromDate = action.fromDate;
			nextState.toDate = action.toDate;
			return nextState;

		case actions.SELECT_ORGANIZATION:
			nextState.organizations = nextState.organizations.map(org => {
				org.selected = org.id === action.id;
				return org;
			});
			return nextState;

		case actions.SET_DAILY_COUNTS:
			nextState.dailyCounts = action.dailyCounts;
			return nextState;

		default:
			return nextState;
	}
}