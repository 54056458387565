define("utils/focus", function(require)
{
	return function(field)
	{
		if(field.get)
		{
			field.get(0).focus();
			field.get(0).select();
		}
		else
		{
			field.focus();
			field.select();
		}
	};
});