import React from "react";
import ButtonR from "ui/ButtonR";

/**
 * @callback onClick
 */
/**
 * <FinishButton/> creates a button that will finish up the wizard and runs the onFinish function
 * passed from above.
 *
 * @param onClick {onClick}
 * @param disabled {boolean}
 * @returns {JSX.Element}
 */
const FinishButton = ({onClick, disabled}) => {
	return (
		<ButtonR
			text={translator.get("wizard.finish")}
			callback={onClick}
			isDisabled={disabled}
			isDefault={true}
			singleUse={false}
		/>
	);
};

export default FinishButton;