import React from "react";

/**
 * <Footer/> is the bottom part of the wizard.
 *
 * @param children {JSX.Element[]}
 * @returns {JSX.Element}
 */
const Footer = ({children}) => {
	return (
		<div className={"footer"}>
			{children}
		</div>
	);
};

Footer.displayName = "Footer";

export default Footer;