define("utils/add-css", function () {
	return function (css)
	{
		if(css === undefined)
		{
			return;
		}
		// Find a reference to the existing style dom element
		var head = jQuery(document.head);
		var style = jQuery(head.find("style#surveypal"));
		if (style.length == 0)
		{
			style = document.createElement("style");
			style.type = "text/css";
			style.id = "surveypal";
			head.append(style);
		}
		else
		{
			style = style[0];
		}

		// Load css file
		if (style.styleSheet)
		{
			//style.styleSheet.cssText = style.styleSheet.cssText + css;
			document.styleSheets[0].cssText = document.styleSheets[0].cssText + css;
		}
		else
		{
			style.appendChild(document.createTextNode(css));
		}
	};
});