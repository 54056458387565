import React from "react";
import {connect} from "react-redux";
import isCronExpressionValid from "./cronValidator";
import InlineWarning from "ui/InlineWarning/InlineWarning";
import InlineNote from "ui/InlineNote/InlineNote";
import InlineOk from "ui/InlineOk/InlineOk";
import CronAnalysisLifeSaver from "./CronAnalysisLifeSaver/CronAnalysisLifeSaver";
import {
	setCronExpression,
	setCronExpressionValidity
} from "../../../automaticReportingActions";
import "../../../automaticReportingTypes";
import "./Advanced.scss";

/**
 * This component is the advanced automatic reporting timing settings.
 *
 * @param settings {Advanced}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const Advanced = ({settings, dispatch}) => {
	const change = (newValue) => {
		dispatch(setCronExpression(newValue));
		dispatch(setCronExpressionValidity(isCronExpressionValid(newValue)));
	};

	const completelyInvalid =
		!settings.cronExpressionValidity.withSeconds.valid &&
		!settings.cronExpressionValidity.withoutSeconds.valid;

	const ambiguous =
		settings.cronExpressionValidity.withSeconds.valid &&
		settings.cronExpressionValidity.withoutSeconds.valid;

	const validWithSecondsOnly =
		settings.cronExpressionValidity.withSeconds.valid &&
		!settings.cronExpressionValidity.withoutSeconds.valid;

	const validWithoutSecondsOnly =
		!settings.cronExpressionValidity.withSeconds.valid &&
		settings.cronExpressionValidity.withoutSeconds.valid;

	return (
		<div className={"advanced"}>
			<div className={"cronExpression"}>
				<label htmlFor={"cronExpressionInput"}>{translator.get("automaticReporting.cronExpression")}</label>
				<input
					name={"cronExpressionInput"}
					id={"cronExpressionInput"}
					type={"text"}
					className={"text"}
					value={settings.cronExpression}
					onChange={(e) => change(e.target.value)}
				/>
			</div>
			<div className={"formatNote"}>
				{
					completelyInvalid &&
					<>
						<InlineWarning icon={true}>
							{translator.get("automaticReporting.cronExpressionInvalid")}
						</InlineWarning>
						<CronAnalysisLifeSaver
							validity={settings.cronExpressionValidity.withoutSeconds}
						/>
					</>
				}
				{
					ambiguous &&
					<InlineNote icon={true}>
						{translator.get("automaticReporting.cronExpressionAmbiguous")}
					</InlineNote>
				}
				{
					validWithSecondsOnly &&
					<InlineNote icon={true}>
						{translator.get("automaticReporting.cronExpressionHasSeconds")}
					</InlineNote>
				}
				{
					validWithoutSecondsOnly &&
					<InlineOk icon={true}>
						{translator.get("automaticReporting.cronExpressionValid")}
					</InlineOk>
				}
			</div>
			<div className={"formatNote"}>
				<p>
					{translator.get("automaticReporting.formatNote")}
				</p>
			</div>
			<ul>
				<li>
					<a
						href={"http://www.quartz-scheduler.org/documentation/quartz-2.3.0/tutorials/crontrigger.html"}
						target={"_blank"}>
						{translator.get("automaticReporting.cronHelp")} ({translator.get("common.inEnglish")})
					</a>
				</li>
				<li>
					<a
						href={"http://www.cronmaker.com/?0"}
						target={"_blank"}>
						CronMaker
					</a>
				</li>
			</ul>
		</div>
	);
};

export default connect(state => ({
	settings: state.automaticReporting.createNewReport.schedule.advanced
}))(Advanced);