// Meta management related API actions
import axios from "core/axios-sp";

const META_ENDPOINT = "/app/survey/meta/list";
export function getMeta(surveyId, limit, offset, keyword, orderByType, sort, metaKey)
{
  return axios.get(META_ENDPOINT, {
    params: {
      json: {
        surveyId: surveyId,
        keyword: keyword,
        orderBy: orderByType,
        sort: sort,
        metaKey: metaKey,
        limit: limit,
        offset: offset
      }
    }
  });
}

const META_UPDATE_ENDPOINT = "/app/survey/meta";
export async function updateMeta(surveyId, fields, answerIds)
{
  return axios.post(META_UPDATE_ENDPOINT, {
    surveyId: surveyId,
    fields: fields,
    answerIds: answerIds
  });
}

const META_RESPONSE_COUNT_ENDPOINT = "/app/survey/meta/response/count";
export async function getResponseCount(surveyId, keyword)
{
  return axios.get(META_RESPONSE_COUNT_ENDPOINT, {
    params: {
      json: {
        surveyId: surveyId,
        keyword: keyword
      }
    }
  });
}

const META_COLUMN_LIST_ENDPOINT = "/app/survey/meta/column/list";
export async function getColumns(surveyId)
{
  return axios.get(META_COLUMN_LIST_ENDPOINT, {
    params: {
      json: {
        surveyId: surveyId
      }
    }
  });
}

const META_COUNT_ENDPOINT = "/app/survey/meta/response/count";
export async function getMetaCount(surveyId, keyword)
{
  return axios.get(META_COUNT_ENDPOINT, {
    params: {
      json: {
        surveyId: surveyId,
        keyword: keyword
      }
    }
  });
}