import { ApplicationInsights } from '@microsoft/applicationinsights-web'
const Environment = require("utils/environment");

let previousPageViews = [];
const MIN_PAGE_VIEW_BREAK = 2000;

// Requests to these paths are not logged into AI
const REQUEST_BLACKLIST = [
  {
    // Frontend version polling
    path: /\/version.json$/,
    responseCode: "ANY"
  },
  {
    // Server maintenance information
    path: /\/active.json$/,
    responseCode: "ANY"
  },
  {
    // Server maintenance information
    path: /\/upcoming.json$/,
    responseCode: "ANY"
  },
  {
    // Requests to get locale files
    path: /\/locale\/.*\.json/,
    responseCode: 200
  },
  {
    // Session polling
    path: /\/app\/session/,
    responseCode: 401
  },
  {
    // Front page survey list polling
    path: /\/app\/home\/survey\/list/,
    responseCode: 304 // Not modified
  },
  {
    // Lock polling in surveys
    path: /\/app\/design\/lock/,
    responseCode: "ANY"
  },
  {
    // Upload polling in image library
    path: /\/app\/upload/,
    responseCode: "ANY"
  },
  {
    // Export status polling in analyze view
    path: /\/app\/export\/status/,
    responseCode: "ANY"
  },
  {
    // Theme previews in style view
    path: /\/app\/theme\/preview/,
    responseCode: "ANY"
  },
  {
    // Image upload polling
    path: /\/app\/upload/,
    responseCode: 206 // Partial content
  }
];

const BASE_TYPE = {
  PAGE_VIEW_DATA: "PageviewData",
  REMOTE_DEPENDENCY_DATA: "RemoteDependencyData"
};

export default function initApplicationInsights()
{
  // Settings documentation: https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript

  // disableAjaxTracking 	false <- this could be changed
  const appInsights = new ApplicationInsights({ config: {
      disableCorrelationHeaders: true, // not needed unless back-end agent is installed
      enableCorsCorrelation: true, // not needed unless back-end agent is installed
      enableAutoRouteTracking: true, // changes in hash are considered page views
      instrumentationKey: Environment.getAIInstrumentationKey(),
      maxBatchInterval: 120000
    }
  });
  appInsights.loadAppInsights();

  // Filter out some ajax requests
  function monitorRequestFilter(envelope) {
    if
    (
      envelope.baseType === "RemoteDependencyData" &&
      envelope.baseData?.target
    )
    {
      const targetStr = envelope.baseData.target;
      const responseCode = envelope.baseData?.responseCode;
      for (let i = 0; i < REQUEST_BLACKLIST.length; i++)
      {
        const blackListObj = REQUEST_BLACKLIST[i];
        if
        (
          targetStr.match(blackListObj.path) &&
          (
            blackListObj.responseCode === "ANY" ||
            responseCode === blackListObj.responseCode
          )
        )
        {
          return false;
        }
      }
    }
  }

  // Improved page view name parsing
  function parsePageViewNames(envelope)
  {
    // Only modify page views that are not triggered manually from code
    if (envelope.baseType === BASE_TYPE.PAGE_VIEW_DATA && !envelope.baseData.pageType)
    {
      const splitByHash = envelope.baseData.uri.split("#");
      const safeHash = splitByHash[1] ? splitByHash[1] : "";
      const splitByAmp = safeHash.split("&");
      let view = "";
      splitByAmp.forEach(parameter => {
        if (parameter.startsWith("view="))
        {
          const splitByEquals = parameter.split("=");
          view = splitByEquals[1] ? splitByEquals[1] : "";
        }
      });
      envelope.baseData.name = view;
    }
  }

  // Remove page view entires with same names that are chronologically near each other
  function filterDuplicatePageViews(envelope)
  {
    if (envelope.baseType === BASE_TYPE.PAGE_VIEW_DATA)
    {
      const now = Date.now();
      previousPageViews = previousPageViews.filter(pageView => now - pageView.time < MIN_PAGE_VIEW_BREAK);
      if (previousPageViews.map(pv => pv.name).includes(envelope.baseData.name))
      {
        return false;
      }
      else
      {
        previousPageViews.push({
          name: envelope.baseData.name,
          time: new Date(envelope.time).valueOf()
        });
      }
    }
  }

  appInsights.addTelemetryInitializer(monitorRequestFilter);
  appInsights.addTelemetryInitializer(parsePageViewNames);
  appInsights.addTelemetryInitializer(filterDuplicatePageViews);
  appInsights.trackPageView();
  return appInsights;
}