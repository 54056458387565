import React from "react";
import "./RibbonButtonR.scss";

export const RibbonIcon = {
	GEARS: "gears",
	LIST: "list"
};

/**
 * @callback onClick
 */
/**
 * <RibbonButton/> is a button to be used as part of <PopupRibbon/>. These buttons can be used for tabs or functions.
 *
 * @param icon {string}
 * @param onClick {onClick}
 * @param disabled {boolean}
 * @param label {string}
 * @param active {boolean}
 * @returns {JSX.Element}
 */
const RibbonButtonR = ({icon, onClick, disabled, label, active}) => {
	const buttonClasses = `RibbonButtonR ${disabled ? "disabled" : ""} ${active ? "active" : ""}`;
	const iconClasses = `icon sprite ${icon}`;

	const callback = !disabled ? onClick : () => {};

	return (
		<div
			className={buttonClasses}
			onClick={callback}>
			<div className={iconClasses}>{null}</div>
			<div className={"label"}>
				{label}
			</div>
		</div>
	);
};

export default RibbonButtonR;