import axios from "core/axios-sp";

export const CHANGE_PARAMETER = "CRM.STATISTICS.CHANGE_PARAMETER";
export const SET_ORGANIZATIONS = "CRM.STATISTICS.SET_ORGANIZATIONS";
export const TOGGLE_LOADING_STATISTICS = "CRM.STATISTICS.TOGGLE_LOADING_STATICS";
export const TOGGLE_LOADING_ORGANIZATIONS = "CRM.STATISTICS.TOGGLE_LOADING_ORGANIZATION";
export const SET_DATA_GRANULARITY = "CRM.STATISTICS.SET_DATA_GRANULARITY";
export const TOGGLE_TRENDLINE = "CRM.STATISTICS.TOGGLE_TRENDLINE";
export const SET_TIMERANGE = "CRM.STATISTICS.SET_TIME_RANGE";
export const SELECT_ORGANIZATION = "CRM.STATISTICS.SELECT_ORGANIZATION";
export const SET_DAILY_COUNTS = "CRM.STATISTICS.SET_DAILY_COUNTS";

export const DATA_GRANULARITY = {
	// Keep these values compatible with groupbytime.js
	DAY: "day",
	WEEK: "week",
	MONTH: "month"
};

export function changeParameter(searchParameters, key, value)
{
	if (key)
	{
		if (validateValue(value))
		{
			searchParameters[key] = value;
		}
		// Delete the key-value pair from search parameters if the value is undefined, empty or otherwise invalid
		else
		{
			delete searchParameters[key];
		}
	}
	return {
		type: CHANGE_PARAMETER,
		searchParameters
	};
}

function validateValue(value)
{
	if (typeof value === "string")
	{
		// String numbers must be more than zero
		if (!isNaN(parseInt(value)))
		{
			const numericVal = parseInt(value);
			return numericVal >= 0;
		}
		// String must be non-empty
		else
		{
			return value.length > 0;
		}
	}
	// Numbers must be at least 0
	else if (typeof value === "number")
	{
		return value >= 0;
	}
	// Dates are accepted
	else if(value instanceof Date)
	{
		return true;
	}
}

export function setOrganizations(organizations)
{
	return {
		type: SET_ORGANIZATIONS,
		organizations
	}
}

export function toggleLoadingOrganizations(loading)
{
	return {
		type: TOGGLE_LOADING_ORGANIZATIONS,
		loading
	}
}

export function toggleLoadingStatistics(loading)
{
	return {
		type: TOGGLE_LOADING_STATISTICS,
		loading
	}
}

export function setGranularity(granularity)
{
	return {
		type: SET_DATA_GRANULARITY,
		granularity
	}
}

export function toggleTrendLine(trendLineVisibility)
{
	return {
		type: TOGGLE_TRENDLINE,
		trendLineVisibility
	}
}
export function setTimeRange(fromDate, toDate = Date.now())
{
	return {
		type: SET_TIMERANGE,
		fromDate,
		toDate
	}
}

export function selectOrganization(id)
{
	return {
		type: SELECT_ORGANIZATION,
		id
	}
}

export function setDailyCounts(dailyCounts)
{
	return {
		type: SET_DAILY_COUNTS,
		dailyCounts
	}
}

export function fetchOrganizations(searchParams, nextOffset)
{
	return dispatch => {
		const paramAction = changeParameter(searchParams, "offset", nextOffset);
		const newParams = paramAction.searchParameters;
		dispatch(setOrganizations([]));
		dispatch(toggleLoadingOrganizations(true));

		return axios.get("/app/crm/statistics/organization/search", {
			params: {
				json: newParams
			}
		}).then(response => {
			dispatch(paramAction);
			dispatch(setOrganizations(response.data));
			dispatch(toggleLoadingOrganizations(false));
		}).catch(error => {
			dispatch(toggleLoadingOrganizations(false));
			throw error;
		});
	};
}

// toDate can be passed as parameter for testability
export function fetchStatistics(id, toDate = new Date())
{
	return dispatch => {
		dispatch(toggleLoadingStatistics(true));

		return axios.get("/app/crm/statistics/organization", {
			params: {
				json: {id}
			}
		}).then(response => {
			const fromDate = parseFirstDate(response.data);
			dispatch(setTimeRange(fromDate, toDate));
			dispatch(setDailyCounts(response.data));
			dispatch(toggleLoadingStatistics(false));
		}).catch(error => {
			dispatch(toggleLoadingStatistics(false));
			throw error;
		});
	};
}

//Find the first date when there's activity, i.e. the lowest date with a non-zero total count.
export function parseFirstDate(dailyCounts)
{
	const firstDate = dailyCounts.reduce((currentFirstDate, currentObject) => {
		let currentDate = currentObject.date;
		let totalCount = currentObject.answerCount + currentObject.emailCount + currentObject.loginCount + currentObject.smsCount + currentObject.surveyCount;

		if (totalCount !== 0 && currentDate < currentFirstDate)
		{
			return currentDate;
		}
		else
		{
			return currentFirstDate;
		}
	}, Date.now());
	return new Date(firstDate);
}