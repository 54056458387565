window._dcq = [];
window._dcs = {};
window._dcs.account = '4207229';

(function() {
	var dc = document.createElement('script');
	dc.type = 'text/javascript'; dc.async = true;
	dc.src = '//tag.getdrip.com/4207229.js';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(dc, s);
})();