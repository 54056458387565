define("monitors/browser-checker", function(require)
{
	var BrowserNotification = require("notifications/browser-notification");
	var SessionStorage = require("utils/session-storage");
	var Browser = require("utils/browser");

	return function()
	{
		// Do not show twice in a session
		var storage = new SessionStorage();
		if(storage.get("browser-check") == true)
		{
			return;
		}

		var browser = new Browser();
		var name = browser.getName();
		var version = browser.getMajor();
		var support = true;
		var browserLabel = name + " " + version;

		console.log("Identified browser as " + browserLabel);

		if (navigator.userAgent.indexOf('Mac') > 0)
		{
			jQuery(document.body).addClass('mac-os');
		}

		if((name == "IE" && version < 9) || (name == "Firefox" && version < 32) || (name == "Safari" && version < 7) || (name == "Chrome" && version < 31) || (name == "Opera" && version < 26))
		{
			support = false;
		}

		if(!support)
		{
			storage.set("browser-check", true);
			new BrowserNotification(browserLabel);
		}
	}
});