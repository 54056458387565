import Tab from "utils/tab";

/**
 * openSurvey opens the given survey on a new tab. An unpublished survey is opened in the survey
 * build view and a published survey is opened in the analysis view.
 *
 * @param survey {object}
 */
export const openSurvey = (survey) => {
	if (survey.deleted) return;

	new Tab(`#view=survey/${survey.published ? "result" : "design"}&id=${survey.id}`);
};