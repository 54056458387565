define("utils/storage", ["core/class"], function (Class)
{
	var typevar = function(key)
	{
		return ":typeOf " + key;
	};

	return Class.extend(
	{
		init: function (storage)
		{
			this.set = function(key, value)
			{
				// Set type
				var type = typeof value;
				storage.setItem(typevar(key), type);

				// Convert type
				var val = value;

				switch(type)
				{
					case "object":
						val = JSON.stringify(value);
						break;
					case "boolean":
						val = value.toString();
						break;
					case "number":
						val = value.toString();
						break;
					case "string":
						val = val;  // Nothing really...
						break;
					default:
						val = val.toString();
						break;
				}
				storage.setItem(key, val);
				return val;
			};

			this.get = function(key)
			{
				// First make sure the item is there
				if(!storage.getItem(key))
				{
					return null;
				}

				// Get type and do conversion
				var type = storage.getItem(typevar(key));
				var value = storage.getItem(key);
				switch(type)
				{
					case "object":
						return JSON.parse(value);
						break;
					case "number":
						var number = new Number(value);
						return number.valueOf();
					case "boolean":
						return value && value.toLowerCase() == "true" ? true : false;
					case "string":
						return value;
					default:
						return value;
				}
			};

			this.has = function(key)
			{
				return !!storage.getItem(key);
			};

			this.remove = function(key)
			{
				storage.removeItem(typevar(key));
				storage.removeItem(key);
			};
		}
	});
});