import React from "react";
import {connect} from "react-redux";
import PopupR from "ui/PopupR";
import PopupRichContent from "ui/PopupRichContent";
import {setReportCreationError} from "../../automaticReportingActions";

/**
 * ReportCreationError renders a popup informing the user of an error when creating a report.
 *
 * @param reportCreationError {Error|null}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const ReportCreationError = ({reportCreationError, dispatch}) => {
	return (
		<PopupR
			title={translator.get("automaticReporting.reportCreationError")}
			hideCancel={true}
			submitCallback={() => dispatch(setReportCreationError(null))}
			submitText={translator.get("common.ok")}>
			<PopupRichContent title={translator.get("automaticReporting.reportCreationError")}>
				<p>{translator.get("automaticReporting.reportCreationErrorText")}</p>
				<p>{reportCreationError}</p>
			</PopupRichContent>
		</PopupR>
	)
};

export default connect(state => ({
	reportCreationError: state.automaticReporting.createNewReport.reportCreationError
}))(ReportCreationError);