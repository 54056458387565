define("conf/locales", function(require)
{
	return {
		"order": ["en_US", "es_ES", "de_DE", "fi_FI", "sv_SE", "nl_NL", "ru_RU", "ja_JP"],
		"map":   {
			fi_FI: {
				name:              "Suomi",
				terms:             "https://www.surveypal.fi/fi/kayttoehdot/",
				help:              "https://support.surveypal.com/hc/fi",
				supportedbrowsers: "http://support.surveypal.com/fi/tuetut-selaimet/",
				privacy:           "https://www.surveypal.fi/fi/rekisteriseloste/",
				feedback:          "https://my.surveypal.com/app/form?_d=0&_sid=241524303&_k=TNFUT_3uZspC3lBQtqrDopeb4KX7tZ2UfLxrxdjdIAcSO5YDLm-rTvtJ5EPo8DF2",
				integrations:      "https://my.surveypal.com/app/form?_d=0&_sid=289903520&_k=PfEbVNoJEQkTSABGsIu0w18HjFES2v0buV6N8HE8Mz-lfFTR-1ZC4jL5l3cOxvb9",
				automaticReportingTest: "https://mytest.surveypal.com/app/form/ext?sid=49962909&sh=-y5wK7MpVU7JVhv4cU4uEnEsWyZophgnDcwjPdtGa2y5hIX-i8pXLcIRWcnpHmoW&data={'p0e2':'{{email}}'}",
				automaticReportingProduction: "https://my.surveypal.com/app/form/ext?sid=2200961554&sh=YYhkjCZtywWxRLKxmKGvMtwcA5_K2Y3_nM5qkIyEbQPKGXCr6PVxZfZ3E6DxSSxd&data={'p0e2':'{{organizationName}}','p0e3':'{{email}}'}",
				uniquelinks:       "https://my.surveypal.com/app/form?_d=0&_sid=288114556&_k=Rr9633_mS8-Z544UoxADRDJuBPfobANrBOZNhAQKXCyTceIgDY00roqttgkwFcY9"
			},
			en_US: {
				name:              "English (US)",
				terms:             "https://www.surveypal.com/terms-of-service/",
				help:              "https://support.surveypal.com/hc/en-us",
				supportedbrowsers: "http://support.surveypal.com/en/browser-support/",
				privacy:           "http://www.surveypal.com/privacy-policy/",
				feedback:          "https://my.surveypal.com/app/form?_d=0&_sid=241524305&_k=5gVfWKLbLgNK_28Zb3qNWS0vO71_7V8q0afN39qchQT4Xw2RjDWGdAdrB5lpQGPL",
				integrations:      "https://my.surveypal.com/app/form?_d=0&_sid=288625169&_k=PyqcRzJMrU3pX0wWTFqFmPThoRgWfLbpczmhuMtU5sqENHDOaCWy-IArKJKIaG0C",
				automaticReportingTest: "https://mytest.surveypal.com/app/form/ext?sid=49962909&sh=-y5wK7MpVU7JVhv4cU4uEnEsWyZophgnDcwjPdtGa2y5hIX-i8pXLcIRWcnpHmoW&data={'p0e2':'{{email}}'}",
				automaticReportingProduction: "https://my.surveypal.com/app/form/ext?sid=2200961554&sh=YYhkjCZtywWxRLKxmKGvMtwcA5_K2Y3_nM5qkIyEbQPKGXCr6PVxZfZ3E6DxSSxd&data={'p0e2':'{{organizationName}}','p0e3':'{{email}}'}",
				uniquelinks:       "https://my.surveypal.com/app/form?_d=0&_sid=288625153&_k=LXCWNttr-eOrCcRzFZg17Uooo2MgbHUX4CjJgV4Sf111MguiwyVlyzXwhCiyW7YJ"
			},
			es_ES: {
				name:              "Español",
				terms:             "https://www.surveypal.com/terms-of-service/",
				help:              "https://support.surveypal.com/hc/en-us",
				supportedbrowsers: "http://support.surveypal.com/fi/browsers",
				privacy:           "http://www.surveypal.com/privacy-policy/",
			},
			sv_SE: {
				name:      "Svenska",
				terms:     "https://www.surveypal.com/terms-of-service/",
				help:      "https://support.surveypal.com/hc/en-us",
				privacy:   "http://www.surveypal.com/privacy-policy/"
			},
			nl_NL: {
				name:      "Nederlands",
				terms:     "https://www.surveypal.com/terms-of-service/",
				help:      "https://support.surveypal.com/hc/en-us",
				privacy:   "http://www.surveypal.com/privacy-policy/"
			},
			ru_RU: {
				name:      "Pусский",
				terms:     "https://www.surveypal.com/terms-of-service/",
				help:      "https://support.surveypal.com/hc/en-us",
				privacy:   "http://www.surveypal.com/privacy-policy/"
			},
			de_DE: {
				name:      "Deutsch",
				terms:     "https://www.surveypal.com/terms-of-service/",
				help:      "https://support.surveypal.com/hc/en-us",
				privacy:   "http://www.surveypal.com/privacy-policy/"
			},
			ja_JP: {
				name:      "日本語",
				terms:     "https://www.surveypal.com/terms-of-service/",
				help:      "https://support.surveypal.com/hc/en-us",
				privacy:   "http://www.surveypal.com/privacy-policy/"
			}
		},

		getName: function(locale)
		{
			return this.get(locale, "name");
		},

		get: function(locale, field)
		{
			return this.map[locale][field] ? this.map[locale][field] : this.map["en_US"][field];
		}
	};
});