const ISOLanguages = require("@cospired/i18n-iso-languages");
ISOLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/de.json"));
ISOLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));
ISOLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/es.json"));
ISOLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/lv.json"));
ISOLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/nl.json"));
ISOLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/fi.json"));
ISOLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/ru.json"));
ISOLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/sv.json"));
ISOLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/ja.json"));

// ISO-639-1 locale is a locale code of two lowercase characters, e.g. "en" or "fi"
export default function getLanguageItems(userIS06391Locale)
{
	const keyNameObjs = ISOLanguages.getNames(userIS06391Locale);
	return Object.keys(keyNameObjs).map(key => {
		return {
			value: key,
			text: keyNameObjs[key],
			fixed: true
		};
	}).sort((a, b) => a.text.localeCompare(b.text));
}