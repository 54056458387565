import * as RulesConf from "common/rules/rule-conf";
import * as actions from "./automation-actions";
import * as asyncActions from "./automation-async-actions";
import * as RedUtils from "utils/reducerutils";
import { createNewRuleFromTemplate } from "common/rules/rule-utils";
import clone from "lodash.clonedeep";
import assign from "lodash.assign";
import { validateCopiedFiltersOrAutomations } from "../../result/libraries/filterOrAutomationImportingUtils";

export const defaultState = {
	loading: true,
	automations: [],
	originalAutomations: [],
	deletedAutomations: [],
	sourceItems: [],
	surveyItems: [],
	metaItems: [],
	languageItems: [],
	elementItems: [],
	surveys: [],
	loadedAutomations: {},
	copiedAutomations: [],
	automationsFetchedStatus: "ok",
	showSurveySelectionPopup: false,
	showAutomationSelectionPopup: false,
	showAutomationsImportedPopup: false
};

export default function(previousState = defaultState, action)
{
	const nextState = clone(previousState);
	switch (action.type)
	{
		case asyncActions.INITIAL_DATA_LOADING:
			return assign(nextState, {loading: true});

		case asyncActions.INITIAL_DATA_LOADED:
			return assign(nextState, {
				loading: false,
				sourceItems: action.sourceItems,
				automations: action.automations,
				originalAutomations: action.originalAutomations,
				surveyItems: action.surveyItems,
				metaItems: action.metaItems,
				elementItems: action.elementItems,
				languageItems: action.languageItems
			});

		case asyncActions.STOP_LOADING:
			return assign(nextState, {loading: false});

		case actions.SURVEYS_WITH_AUTOMATIONS_LOADING:
			return assign(nextState, {loading: true});

		case actions.SURVEYS_WITH_AUTOMATIONS_LOADED:
			return assign(nextState, {
				loading: false,
				surveys: action.surveys,
				showSurveySelectionPopup: true
			});

		case actions.HIDE_SURVEY_SELECTION_POPUP:
			return assign(nextState, {
				showSurveySelectionPopup: action.showSurveySelectionPopup
			});

		case actions.LOAD_AUTOMATIONS_FROM_SURVEY_LOADING:
			return assign(nextState, {loading: true});

		case actions.LOAD_AUTOMATIONS_FROM_SURVEY_LOADED:
			return assign(nextState, {
				loading: false,
				automationsFetchedStatus: action.automationsFetchedStatus,
				loadedAutomations: action.loadedAutomations,
				showAutomationsImportedPopup: action.showAutomationsImportedPopup
			});

		case actions.COPY_LOADED_AUTOMATIONS_TO_SURVEY:
			const automations = validateCopiedFiltersOrAutomations(RedUtils.deselectAllItems(action.automations), action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues, true);
			automations[0].selected = true;

			return assign(nextState, {
				automations: automations,
				showAutomationsImportedPopup: true
			});

		case actions.HIDE_AUTOMATION_SELECTION_POPUP:
			return assign(nextState, {
				loadedAutomations: {}
			});

		case actions.HIDE_AUTOMATIONS_IMPORTED_POPUP:
			return assign(nextState, {
				showAutomationsImportedPopup: false
			});

		case actions.SELECT_AUTOMATION:
		{
			const newAutomations = RedUtils.deselectAllItems(nextState.automations);
			RedUtils.findById(newAutomations, action.automationId).selected = true;
			return assign(nextState, {automations: newAutomations});
		}

		case actions.EDIT_AUTOMATION:
		{
			RedUtils.findById(nextState.automations, action.automationId).editing = true;
			return nextState;
		}

		case actions.RENAME_AUTOMATION:
		{
			const automation = RedUtils.findById(nextState.automations, action.automationId);
			automation.editing = false;
			automation.name = action.automationName;
			return nextState;
		}

		case actions.CREATE_AUTOMATION:
		{
			const newAutomations = RedUtils.deselectAllItems(nextState.automations);
			newAutomations.push(action.automation);
			return assign(nextState, {automations: newAutomations});
		}

		case actions.DELETE_AUTOMATION:
		{
			const deletedAutomation = RedUtils.findById(nextState.automations, action.automationId);
			const newAutomations = RedUtils.deleteItemAndSelectNext(nextState.automations, action.automationId);
			nextState.deletedAutomations.push(deletedAutomation);
			return assign(nextState, {automations: newAutomations});
		}

		case actions.ACTION_SELECTED:
		{
			nextState.automations[RedUtils.findIndexById(nextState.automations, action.automation.id)] = assign(action.automation, {
				actions: [action.action],
				triggerGroup: action.group
			});
			return nextState;
		}

		case actions.PARAM_CHANGED:
		{
			const automation = RedUtils.findById(nextState.automations, action.automationId);
			automation.actions[action.actionIndex].params[action.paramName] = action.value;
			return nextState;
		}

		case actions.CHANGE_TRIGGER_GROUP:
		{
			const automation = RedUtils.findById(nextState.automations, action.automationId);
			if (action.triggerGroupValue !== automation.triggerGroup)
			{
				automation.filter.groups = filterGroupRulesByTriggerGroup(automation.filter.groups, action.triggerGroupValue);
			}
			automation.triggerGroup = action.triggerGroupValue;
			return nextState;
		}

		case actions.CHANGE_ROOT_OPERATOR:
		{
			const automation = RedUtils.findById(nextState.automations, action.automationId);
			automation.filter.match = action.rootOperatorValue;
			return nextState;
		}

		case actions.CHANGE_GROUP_OPERATOR:
		{
			const automation = RedUtils.findById(nextState.automations, action.automationId);
			automation.filter.groups[action.groupIndex].match = action.operatorValue;
			return nextState;
		}

		case actions.CHANGE_RULE_TYPE:
		{
			let automation = RedUtils.findById(nextState.automations, action.automationId);
			const newRule = {
				key: action.ruleKey,
				value: action.value,
				op: action.operator,
				type: action.ruleType
			};
			automation.filter.groups[action.groupIndex].rules[action.ruleIndex] = newRule;
			automation = validateCopiedFiltersOrAutomations([automation], action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues)[0];
			return nextState;
		}

		case actions.CHANGE_RULE_OPERATOR:
		{
			let automation = RedUtils.findById(nextState.automations, action.automationId);
			automation.filter.groups[action.groupIndex].rules[action.ruleIndex].op = action.operator;
			automation = validateCopiedFiltersOrAutomations([automation], action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues)[0];
			return nextState;
		}

		case actions.DELETE_RULE:
		{
			let automation = RedUtils.findById(nextState.automations, action.automationId);
			const rules = automation.filter.groups[action.groupIndex].rules;
			rules.splice(action.ruleIndex, 1);
			if (rules.length === 0)
			{
				automation.filter.groups.splice(action.groupIndex, 1);
			}
			automation = validateCopiedFiltersOrAutomations([automation], action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues)[0];
			return nextState;
		}

		case actions.DUPLICATE_RULE:
			const ruleIndex = action.ruleIndex;
			const groupIndex = action.groupIndex;
			const automation = RedUtils.findById(nextState.automations, action.automationId);
			const rule = RedUtils.prepareRuleForDuplicating(clone(automation.filter.groups[groupIndex].rules[ruleIndex]));
			automation.filter.groups[groupIndex].rules.splice((ruleIndex + 1), 0, rule);
			return nextState;

		case actions.CREATE_RULE:
		{
			const automation = RedUtils.findById(nextState.automations, action.automationId);
			const newRule = createNewRuleFromTemplate(action.ruleTemplate);
			automation.filter.groups[action.groupIndex].rules.push(newRule);
			return nextState;
		}

		case actions.CHANGE_RULE_VALUE:
		{
			let automation = RedUtils.findById(nextState.automations, action.automationId);
			automation.filter.groups[action.groupIndex].rules[action.ruleIndex].value = action.value;
			automation = validateCopiedFiltersOrAutomations([automation], action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues)[0];
			return nextState;
		}

		case actions.ADD_NEW_CONDITION_GROUP:
		{
			const automation = RedUtils.findById(nextState.automations, action.automationId);
			const newRule = createNewRuleFromTemplate(action.ruleTemplate);
			const newGroup = {
				match: RulesConf.ROOT_OPERATORS.FILTERS_ALL.value,
				rules: [newRule]
			};

			automation.filter.groups.push(newGroup);
			return nextState;
		}

		case actions.REMOVE_CONDITION_GROUP:
		{
			let automation = RedUtils.findById(nextState.automations, action.automationId);
			automation.filter.groups.splice(action.groupIndex, 1);
			automation = validateCopiedFiltersOrAutomations([automation], action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues)[0];
			return nextState;
		}

		default:
			return nextState;
	}
};

function filterGroupRulesByTriggerGroup(groups, triggerGroupValue)
{
	const triggerGroupRules = RulesConf.TRIG_GROUPS[triggerGroupValue].applicableRules;

	groups.forEach(group => {
		group.rules = group.rules.filter(function(rule) {
			return triggerGroupRules.some(function (trigGroupRule) {
				return trigGroupRule.key === rule.key;
			});
		});
	});

	groups = groups.filter(group => group.rules.length !== 0);

	return groups;
}