define("utils/location", function(require)
{
	var Class = require("core/class");
	
	return Class.extend(
	{
		init: function()
		{
			var obj = {};
			obj.protocol = location.protocol;
			obj.host = location.host;
			obj.pathname = location.pathname;
			obj.search = location.search;
			obj.hostname = location.hostname;
			obj.port = location.port;
			obj.origin = obj.protocol + '//' + obj.host;
			this.obj = obj;
		},

		get: function(key)
		{
			return this.obj[key];
		},

		set: function(key, value)
		{
			this.obj[key] = value;
		},

		href: function()
		{
			return this.get("protocol") + '//' + this.get("host") + this.get("pathname") + this.get("search");
		}
	});
});