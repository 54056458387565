import axios from "core/axios-sp";
import PublishPopup from "views/survey/publish-popup";
import EVENTS from "conf/ai-events";
import FailurePopup from "common/failure-popup";
import SuccessPopup from "common/success-popup";
import {fetchSurveys} from "../../../surveysActions";

/**
 * requestSurveyLaunch sends a request to backend for launching the selected survey, then displays
 * a success or failure popup. In the case of success, it also refetches the survey list from the
 * backend to refresh it.
 *
 * @param surveyId {number}
 */
export const requestSurveyLaunch = (surveyId) => {
	Surveypal.applicationInsights.trackEvent(EVENTS.SURVEY_LAUNCHED);

	axios.put("/app/home/survey/publish", [{surveyId}])
		.then((response) => {
			if (response.status === 200)
			{
				new SuccessPopup(
					translator.get("success-popup.launchSuccessTitle"),
					translator.get("home.surveyLaunched")
				);

				fetchSurveys();
				return;
			}

			if (response.status === 206)
			{
				new FailurePopup(translator.get("home.notSurveyLaunched"));
			}
		})
		.catch((response) => {
			console.error(response);

			new FailurePopup(translator.get("home.notSurveyLaunched"));
		});
};

/**
 * launchSurvey opens survey launch popup.
 *
 * @param surveyId {number}
 */
export const launchSurvey = (surveyId) => {
	new PublishPopup(() => requestSurveyLaunch(surveyId));
};