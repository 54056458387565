import axios from "core/axios-sp";
import PublicLinkPopup from "common/share/public-share-popup";
import {
	addPublicShare,
	removePublicShare
} from "../../../surveysActions";

/**
 * toggleSharedAccess sends a request to either enable or disable the shared access of a survey.
 * After completing the request, public share information is updated in the state.
 *
 * @param survey {object}
 */
export const toggleSharedAccess = (survey) => {
	Surveypal.getLoader().show();

	if (survey.publicShare)
	{
		axios.delete("/app/home/share/public", {
			params: {
				json: {
					id: survey.id
				}
			}
		}).then((response) => {
			Surveypal.getLoader().hide();
			Surveypal.store.dispatch(removePublicShare(survey.id));
			new PublicLinkPopup(null);
		});
		return;
	}

	axios.put("/app/home/share/public", {
		id: survey.id
	}).then((response) => {
		Surveypal.getLoader().hide();
		Surveypal.store.dispatch(addPublicShare(survey.id, response.data.link));
		new PublicLinkPopup(response.data.link);
	});
};