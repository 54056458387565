import {formatStatus} from "../components/List/Item/utils/formatStatus";

/**
 * stringFilterSurvey filters surveys based on the user's input in the filter string field.
 *
 * @param survey {object}
 * @param string {string}
 * @returns {boolean}
 */
export const stringFilterSurvey = (survey, string) => {
	if (typeof string !== "string") throw new Error("stringFilterSurvey(): argument 'string' must be of string type")

	const lowerCaseString = string.toLowerCase();

	//Empty string means no filtering whatsoever
	if (lowerCaseString.trim() === "") return true;

	//Match survey ID
	if (!isNaN(+string) && survey.id && survey.id.toString().indexOf(lowerCaseString.toLowerCase()) !== -1) return true;

	//Match survey name
	if (survey.name && survey.name.toLowerCase().indexOf(lowerCaseString) !== -1) return true;

	//Match survey owner
	if (survey.firstName && survey.firstName.toLowerCase().indexOf(lowerCaseString) !== -1) return true;
	if (survey.lastName && survey.lastName.toLowerCase().indexOf(lowerCaseString) !== -1) return true;

	//Match localized "you" for survey owner
	if (survey.owner === Surveypal.getUser().getId() && translator.get("common.you").toLowerCase().indexOf(lowerCaseString) !== -1)
		return true;

	//Match tags
	if (survey.tags && survey.tags.toLowerCase().indexOf(lowerCaseString) !== -1) return true;

	//Match status
	if (formatStatus(survey).toLowerCase().indexOf(lowerCaseString) !== -1) return true;

	//If no match so far, search doesn't match.
	return false;
};