/**
 * stringFilterFolder filters folders based on the user's input in the filter string field.
 *
 * @param folder {object}
 * @param string {string}
 * @returns {boolean}
 */
export const stringFilterFolder = (folder, string) => {
	if (typeof string !== "string") throw new Error("stringFilterFolder(): argument 'string' must be of string type")

	const lowerCaseString = string.toLowerCase();

	//Empty string means no filtering whatsoever
	if (lowerCaseString.trim() === "") return true;

	//Match folder ID
	if (!isNaN(+string) && folder.id && folder.id.toString().indexOf(lowerCaseString.toLowerCase()) !== -1) return true;

	//Match folder name
	if (folder.name && folder.name.toLowerCase().indexOf(lowerCaseString) !== -1) return true;

	//If no match so far, search doesn't match.
	return false;
};