import React from "react";
import RadioButton from "ui/RadioButton/RadioButton";
import {connect} from "react-redux";
import WeekDays from "conf/weekdays";
import TimeSelection from "ui/TimeSelection/TimeSelection";
import {
	setFiringHours,
	setFiringMinutes,
	setWeekday
} from "../../../automaticReportingActions";
import "../../../automaticReportingTypes";
import "./Weekly.scss";

/**
 * This component is the weekly automatic reporting timing settings.
 *
 * @param firing {FiringTime}
 * @param settings {Weekly}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const Weekly = ({
	firing,
	settings,
	dispatch
}) => {
	//Prepare weekday options
	const weekdayOptions = (() => {
		const optionsSundayFirst = translator.get("Date.days").map((weekday, index) => ({
			text: weekday,
			value: index,
			disabled: false
		}));

		//Options are by default Sunday first. Move Sunday to last if user's first day is Monday.
		if (Surveypal.getUser().getFirstDayOfWeek() === WeekDays.MONDAY)
			return [...optionsSundayFirst.splice(1, optionsSundayFirst.length), optionsSundayFirst[0]];

		return optionsSundayFirst;
	})();

	return (
		<div className={"weekly"}>
			<div className={"weekdaySelection"}>
				<label>{translator.get("automaticReporting.onWeekday")}</label>
				<RadioButton
					options={weekdayOptions}
					selected={settings.weekday}
					onChange={(newValue) => dispatch(setWeekday(newValue))}
				/>
			</div>
			<div className={"weeklyTimeSelection"}>
				<label>{translator.get("automaticReporting.at")}</label>
				<TimeSelection
					value={firing}
					onChangeHours={(newHours) => dispatch(setFiringHours(newHours))}
					onChangeMinutes={(newMinutes) => dispatch(setFiringMinutes(newMinutes))}
				/>
			</div>
		</div>
	);
};

export default connect(state => ({
	firing: state.automaticReporting.createNewReport.schedule.firing,
	settings: state.automaticReporting.createNewReport.schedule.weekly
}))(Weekly);