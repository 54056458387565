const EnvConfig = require("environment.json");

// Module for getting different urls, email addresses etc. that change based on top-level domain
define("utils/environment", function(require){
	return {
		getDomain: function()
		{
			return EnvConfig.domain;
		},

		getCapitalizedDomain: function()
		{
			return this.capitalizeFirstLetter(this.getDomain());
		},

		getToolDomain: function()
		{
			return EnvConfig.toolDomain;
		},

		getFullToolUrl: function()
		{
			return this.getProtocol() + this.getToolDomain();
		},

		getHomeDomain: function()
		{
			return EnvConfig.homeDomain;
		},

		getFormDomain: function()
		{
			return EnvConfig.formDomain;
		},

		getFormPath: function()
		{
			return EnvConfig.formPath;
		},

		getPreviewPath: function()
		{
			return EnvConfig.previewPath;
		},

		getFullPreviewUrl: function()
		{
			return this.getFullToolUrl() + this.getPreviewPath(); // TODO: fix localhost (form vs tool port)
		},

		getPaperDomain: function()
		{
			return EnvConfig.paperDomain;
		},

		getFullFormUrl: function()
		{
			return this.getProtocol() + this.getFormDomain();
		},

		getFullFormUrlWithPath: function()
		{
			return this.getFullFormUrl() + this.getFormPath();
		},

		getAliasDomain: function()
		{
			return EnvConfig.aliasDomain;
		},

		getFullAliasUrl: function()
		{
			return this.getProtocol() + this.getAliasDomain();
		},

		getFullPaperUrl: function()
		{
			return this.getProtocol() + this.getPaperDomain();
		},

		getFormHostR4: function()
		{
			return EnvConfig.formHostR4;
		},

		getFullFormR4Url: function()
		{
			return this.getProtocol() + this.getFormHostR4();
		},

		getHelpdeskEmail: function(locale)
		{
			return (locale && locale === "fi_FI" ? EnvConfig.helpdeskEmailFi : EnvConfig.helpdeskEmail);
		},

		getNoReplyEmail: function()
		{
			return EnvConfig.noReplyEmail;
		},

		getStatusPage: function()
		{
			return EnvConfig.statusPage;
		},

		getIntercomIdentifier: function()
		{
			return EnvConfig.intercomIdentifier;
		},

		getAIInstrumentationKey: function()
		{
			return EnvConfig.toolAIInstrumentationKey;
		},

		getMediaCdn: function()
		{
			return EnvConfig.cdn.media;
		},

		getIconContainer: function()
		{
			return EnvConfig.cdn.iconContainer;
		},

		getIconCdn: function()
		{
			return EnvConfig.cdn.iconCdn;
		},

		getTemplatePreviewCdn: function()
		{
			return EnvConfig.cdn.templatePreview;
		},

		getStaticCdn: function()
		{
			return EnvConfig.cdn.static;
		},

		isProductionEnv: function()
		{
			return EnvConfig.isProductionEnv;
		},

		isLocalhost: function()
		{
			return EnvConfig.domain.indexOf("localhost") !== -1 || 
				     EnvConfig.domain.indexOf("127.0.0.1") !== -1;
		},

		getProtocol: function()
		{
			return this.isLocalhost() ? "http://" : "https://";
		},

		isStaging: function()
		{
			return this.getToolDomain().indexOf("mystaging") !== -1;
		},

		isTest: function()
		{
			return this.getToolDomain().indexOf("mytest") !== -1;
		},

		capitalizeFirstLetter: function(string)
		{
			return string.charAt(0).toUpperCase() + string.slice(1);
		}
	};
});