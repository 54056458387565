/**
 * folderFilter filters files based on whether they are in the folder that the user is currently viewing.
 *
 * @param file {object}
 * @param currentFolder {object}
 * @returns {boolean}
 */
export const folderFilter = (file, currentFolder) => {
	if (currentFolder === undefined) return file.parentFolder === undefined || file.parentFolder === 0;

	return currentFolder.surveys.indexOf(file.id) !== -1 || currentFolder.subFolders.indexOf(file.id) !== -1;
};