const { DateTime } = require("luxon");
require('date-time-format-timezone');

export const RESTRICTED_DATES = {
	NONE: "NONE",
	CUSTOM: "CUSTOM",
	WEEKDAYS: "WEEKDAYS",
	WEEKENDS: "WEEKENDS"
};

export function dateToHelsinkiTimezone(timestamp)
{
	const date = new Date(timestamp);
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const utcDate = DateTime.utc(year, month, day);
	const utcToHelsinkiOffset = utcDate.offset + utcDate.setZone("Europe/Helsinki").offset;
	return (utcDate.toMillis() + (utcToHelsinkiOffset * 60 * 1000));
}

export function timestampToLocalDate(date)
{
	const utcDate = new Date(date);
	const utcLocalOffset = utcDate.getTimezoneOffset();
	return new Date(utcDate.getTime() + (utcLocalOffset * 60 * 1000));
}

export function getDefaultDate(setMinutes, defaultDate)
{
	const date = defaultDate ? new Date(defaultDate) : new Date();
	if (setMinutes)
	{
		date.setMinutes(5 * Math.round(date.getMinutes() / 5));
	}
	return date.getTime();
}

export function getDifferenceBetweenLocalAndAccountTimezone()
{
	const accountTimezone = Surveypal.getUser().getTimezone();
	const localDateOffset = DateTime.local().offset;
	const accountDateOffset = DateTime.utc().setZone(accountTimezone).offset;
	return localDateOffset - accountDateOffset;
}

export function getDifferenceBetweenHelsinkiAndAccountTimezone()
{
	const accountTimezone = Surveypal.getUser().getTimezone();
	const accountDateOffset = DateTime.utc().setZone(accountTimezone).offset;
	const helsinkiTimezoneOffset = DateTime.utc().setZone("Europe/Helsinki").offset;
	return helsinkiTimezoneOffset - accountDateOffset;
}