import { SOURCE } from "conf/sources";
import orderBy from "lodash.orderby";

export function onlyEmails(responses, selectedIds)
{
	return selectedIds.every(selectedId => {
		const response = responses.find(response => response.id === selectedId);
		return response &&
		       response.source === SOURCE.EMAIL;
	});
}

export function handleSortedResponsesNulls(sortedResponses, sortKey)
{
	const nulls = sortedResponses.filter(response => response[sortKey] === null);
	const nullsRemoved = sortedResponses.filter(response => response[sortKey] !== null);
	return nullsRemoved.concat(nulls);
}

export function dropAnonymizedResponsesToBottom(sorted)
{
	return orderBy(sorted, "dataAnonymized", "desc");
}
