define("utils/browser", function(require)
{
	var Class = require("core/class");
	var UAParser = require('ua-parser-js');

	return Class.extend(
	{
		init: function() {
			var parser = new UAParser();
			this._browser = parser.getResult().browser;
		},

		getName: function()
		{
			return this._browser.name;
		},

		getMajor: function()
		{
			return parseInt(this._browser.version);
		}
	});
});