export function selectItem(items, id)
{
	var clearedItems = this.deselectAllItems(items);
	var selectIndex = this.findIndexById(clearedItems, id);
	if (selectIndex !== -1)
	{
		clearedItems[selectIndex].selected = true;
	}
	return clearedItems;
}

export function deselectAllItems (items)
{
	return items.map(
		function (item)
		{
			item.selected = false;
			return item;
		}
	);
}

export function deleteItemAndSelectNext(items, delId)
{
	var index = items.findIndex(
		function (item)
		{
			return item.id === delId;
		}
	);
	items.splice(index, 1);
	if (items[index])
	{
		items[index].selected = true;
	}
	else if (items[index - 1])
	{
		items[index - 1].selected = true;
	}
	return items;
}

export function findById(array, id)
{
	var index = array.findIndex(
		function (object)
		{
			return object.id === id;
		}
	);
	return index === -1 ? null : array[index];
}

export function findIndexById(array, id)
{
	return array.findIndex(
		function (object)
		{
			return object.id === id;
		}
	);
}

export function filterByIds(array, ids)
{
	return array.filter(item => ids.includes(item.id));
}

export function deleteItemById(array, id)
{
	array.splice(findIndexById(array, id), 1);
	return array;
}

export function replaceById(array, id, replacement)
{
	array.splice(findIndexById(array, id), 1, replacement);
	return array;
}

export function prepareRuleForDuplicating(rule)
{
	if (rule.id)
	{
		delete rule.id;
	}
	return rule;
}