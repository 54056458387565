import {ACTIONS} from "./surveysActions";
import {COLUMNS} from "./components/List/List";
import {sortByModified, sortByName} from "./utils/sorters";
import Filters from "./utils/filters";
import {FILTER_VALUES} from "./components/Filters/Filters";
import "./surveysTypes";

/**
 * @type SurveysState
 */
const initialState = {
	fetchables: {
		surveys: {
			fetched: false,
			fetching: false,
			surveys: [],
			checksum: undefined,
			lastRefresh: undefined
		},
		templates: {
			fetched: false,
			fetching: false,
			templates: [],
			lastRefresh: undefined
		},
		folders: {
			fetched: false,
			fetching: false,
			folders: [],
			lastRefresh: undefined
		}
	},
	list: {
		selectedSurveyIds: [],
		selectedFolderIds: [],
		folder: 0,
		refresher: -1,
		sort: {
			column: COLUMNS.LAST_MODIFIED,
			direction: -1,
			sorter: (a, b) => sortByModified(a, b) * -1
		},
		filters: {
			mainFilter: Filters.FILTER_NAMES.YOUR_SURVEYS,
			tag: undefined,
			newResponsesFilter: FILTER_VALUES.ALL,
			templatesFilter: FILTER_VALUES.ALL,
			sharedFilter: FILTER_VALUES.ALL,
			automaticReportsFilter: FILTER_VALUES.ALL,
			stringFilter: ""
		},
		columns: {
			[COLUMNS.SURVEY_ID]: false,
			[COLUMNS.OWNER]: true,
			[COLUMNS.LAST_MODIFIED]: true,
			[COLUMNS.RESPONSES]: true,
			[COLUMNS.NEW_RESPONSES]: false,
			[COLUMNS.PUBLIC_SHARE]: false,
			[COLUMNS.SURVEY_STATUS]: true
		}
	},
	informationPanel: {
		surveyInformation: {
			fetched: false,
			fetching: false,
			surveyInformation: undefined
		},
		responseTimeline: {
			fetched: false,
			fetching: false,
			responseTimeline: undefined
		},
		preview: {
			fetched: false,
			fetching: false,
			preview: undefined
		}
	},
	popups: {
		surveyTemplate: {
			show: false,
			surveyId: undefined
		},
		folderDeletion: {
			show: false
		},
		surveysAndFoldersDeletionPopup: {
			show: false
		},
		moveToFolderPopup: {
			show: false
		},
		surveyDeletionErrorPopup: {
			show: false,
			error: undefined
		}
	}
};

const surveysReducer = (previousState = initialState, action) => {
	switch (action.type)
	{
		case ACTIONS.CLEAR_STATE:
			return initialState;
		case ACTIONS.SET_SURVEYS:
		{
			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...action.surveys,
						surveys: action.surveys.surveys
					}
				}
			};
		}
		case ACTIONS.DELETE_SURVEY:
		{
			const deleter = (survey) => {
				if (survey.id === action.surveyId) return {
					...survey,
					deleted: true
				};

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(deleter)
					}
				}
			};
		}
		case ACTIONS.UNDELETE_SURVEY:
		{
			const undeleter = (survey) => {
				if (survey.id === action.surveyId) return {
					...survey,
					deleted: false
				};

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(undeleter)
					}
				}
			};
		}
		case ACTIONS.REMOVE_SURVEY:
		{
			const surveyRemover = (survey) => survey.id !== action.surveyId;
			const selectionRemover = (selectedSurveyId) => selectedSurveyId !== action.surveyId;

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.filter(surveyRemover)
					}
				},
				list: {
					...previousState.list,
					selected: previousState.list.selectedSurveyIds.filter(selectionRemover)
				}
			};
		}
		case ACTIONS.ADD_SURVEY_DELETION_TASK:
		{
			const deletionTaskAdder = (survey) => {
				if (survey.id === action.surveyId)
				{
					delete survey.deleting;
					return {
						...survey,
						deleteAt: action.deleteAt
					}
				}

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(deletionTaskAdder)
					}
				}
			};
		}
		case ACTIONS.REMOVE_SURVEY_DELETION_TASK:
		{
			const deletionTaskRemover = (survey) => {
				if (survey.id === action.surveyId)
				{
					delete survey.deleteAt
				}

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(deletionTaskRemover)
					}
				}
			};
		}
		case ACTIONS.MARK_AS_BEING_DELETED:
		{
			const marker = (survey) => {
				if (survey.id === action.surveyId)
				{
					return {
						...survey,
						deleting: true
					};
				}

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(marker)
					}
				}
			};
		}
		case ACTIONS.UNMARK_AS_BEING_DELETED:
		{
			const unmarker = (survey) => {
				if (survey.id === action.surveyId)
				{
					delete survey.deleting;
				}

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(unmarker)
					}
				}
			};
		}
		case ACTIONS.SET_NEW_TAGS:
		{
			const retagger = (survey) => {
				if (survey.id === action.surveyId)
				{
					return {
						...survey,
						tags: action.newTags
					};
				}

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(retagger)
					}
				}
			};
		}
		case ACTIONS.MARK_SURVEYS_AS_READ:
		{
			const marker = (survey) => {
				if (action.surveyIds.indexOf(survey.id) !== -1) survey.newAnswerCount = 0;

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(marker)
					}
				}
			};
		}
		case ACTIONS.MOVE_SURVEY_TO_FOLDER:
		{
			const mover = (survey) => {
				if (survey.id === action.surveyId) return {
					...survey,
					parentFolder: action.targetFolderId
				};

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(mover)
					}
				}
			};
		}
		case ACTIONS.GO_TO_FOLDER:
		{
			return {
				...previousState,
				list: {
					...previousState.list,
					folder: action.id
				}
			};
		}
		case ACTIONS.SET_FOLDERS:
		{
			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					folders: action.folders
				}
			};
		}
		case ACTIONS.MOVE_FOLDER_TO_FOLDER:
		{
			const mover = (folder) => {
				if (folder.id === action.id) return {
					...folder,
					parentFolder: action.targetFolderId
				};

				return folder;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					folders: {
						...previousState.fetchables.folders,
						folders: previousState.fetchables.folders.folders.map(mover)
					}
				}
			};
		}
		case ACTIONS.RENAME_FOLDER:
		{
			const renamer = (folder) => {
				if (folder.id === action.folderId) return {
					...folder,
					name: action.newName
				};

				return folder;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					folders: {
						...previousState.fetchables.folders,
						folders: previousState.fetchables.folders.folders.map(renamer)
					}
				}
			};
		}
		case ACTIONS.DELETE_FOLDER:
		{
			const deleter = (folder) => folder.id !== action.id;

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					folders: {
						...previousState.fetchables.folders,
						folders: previousState.fetchables.folders.folders.filter(deleter)
					}
				}
			};
		}
		case ACTIONS.RENAME_SURVEY:
		{
			const renamer = (survey) => {
				if (survey.id !== action.surveyId) return survey;

				return {
					...survey,
					name: action.newName
				};
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(renamer)
					}
				}
			}
		}
		case ACTIONS.SET_TEMPLATES:
			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					templates: action.templates
				}
			};
		case ACTIONS.UPDATE_TEMPLATE:
		{
			const updater = (template) => {
				if (template.id === action.newTemplate.id) return action.newTemplate;

				return template;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					templates: {
						...previousState.fetchables.templates,
						templates: previousState.fetchables.templates.templates.map(updater)
					}
				}
			};
		}
		case ACTIONS.DELETE_TEMPLATE:
		{
			const deleter = (template) => template.id !== action.deletedTemplate.id;

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					templates: {
						...previousState.fetchables.templates,
						templates: previousState.fetchables.templates.templates.filter(deleter)
					}
				}
			};
		}
		case ACTIONS.CREATE_TEMPLATE:
			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					templates: {
						...previousState.fetchables.templates,
						templates: [...previousState.fetchables.templates.templates, action.newTemplate]
					}
				}
			};
		case ACTIONS.ADD_PUBLIC_SHARE:
			const publicShareAdder = (survey) => {
				if (survey.id !== action.surveyId) return survey;

				return {
					...survey,
					publicShare: true,
					publicSurveyUrl: action.link
				};
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(publicShareAdder)
					}
				}
			};
		case ACTIONS.REMOVE_PUBLIC_SHARE:
		{
			const publicShareRemover = (survey) => {
				if (survey.id !== action.surveyId)
				{
					return survey;
				}

				return {
					...survey,
					publicShare: false,
					publicSurveyUrl: null
				};
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(publicShareRemover)
					}
				}
			};
		}
		case ACTIONS.SET_CHECKSUM:
			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						checksum: action.checksum
					}
				}
			};
		case ACTIONS.SET_SELECTED_SURVEYS:
			return {
				...previousState,
				list: {
					...previousState.list,
					selectedSurveyIds: action.surveyIds
				}
			};
		case ACTIONS.SET_SELECTED_FOLDERS:
			return {
				...previousState,
				list: {
					...previousState.list,
					selectedFolderIds: action.folderIds
				}
			};
		case ACTIONS.SET_SORT:
		{
			const sorter = action.sort.sorter;
			const direction = action.sort.direction;
			const sortWithDirection = (a, b) => sorter(a, b) * direction;
			return {
				...previousState,
				list: {
					...previousState.list,
					sort: {
						...action.sort,
						sorter: sortWithDirection
					}
				}
			};
		}
		case ACTIONS.TOGGLE_COLUMN:
			return {
				...previousState,
				list: {
					...previousState.list,
					columns: {
						...previousState.list.columns,
						[action.column]: !previousState.list.columns[action.column]
					}
				}
			};
		case ACTIONS.SET_REFRESHER:
			return {
				...previousState,
				list: {
					...previousState.list,
					refresher: action.refresher
				}
			};
		case ACTIONS.CHANGE_MAIN_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						mainFilter: action.mainFilter,
						tag: undefined
					}
				}
			}
		case ACTIONS.FILTER_BY_TAG:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						mainFilter: Filters.FILTER_NAMES.TAGGED_SURVEYS,
						tag: action.tag
					}
				}
			};
		case ACTIONS.SET_NEW_RESPONSES_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						newResponsesFilter: action.newResponsesFilter
					}
				}
			};
		case ACTIONS.SET_TEMPLATES_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						templatesFilter: action.templatesFilter
					}
				}
			};
		case ACTIONS.SET_SHARED_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						sharedFilter: action.sharedFilter
					}
				}
			};
		case ACTIONS.SET_AUTOMATIC_REPORTS_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						automaticReportsFilter: action.automaticReportsFilter
					}
				}
			};
		case ACTIONS.SET_STRING_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						stringFilter: action.stringFilter
					}
				}
			};
		case ACTIONS.SET_RESPONSE_TIMELINE:
			return {
				...previousState,
				informationPanel: {
					...previousState.informationPanel,
					responseTimeline: action.responseTimeline
				}
			};
		case ACTIONS.SET_PREVIEW:
			return {
				...previousState,
				informationPanel: {
					...previousState.informationPanel,
					preview: action.preview
				}
			};
		case ACTIONS.SET_SURVEY_INFORMATION:
			return {
				...previousState,
				informationPanel: {
					...previousState.informationPanel,
					surveyInformation: action.surveyInformation
				}
			}
		case ACTIONS.TOGGLE_SURVEY_TEMPLATE_POPUP:
			return {
				...previousState,
				popups: {
					...previousState.popups,
					surveyTemplate: {
						...previousState.popups.surveyTemplate,
						show: action.newState
					}
				}
			};
		case ACTIONS.TOGGLE_FOLDER_DELETION_POPUP:
			return {
				...previousState,
				popups: {
					...previousState.popups,
					folderDeletion: {
						...previousState.popups.folderDeletion,
						show: action.newState
					}
				}
			};
		case ACTIONS.TOGGLE_SURVEYS_AND_FOLDERS_DELETION_POPUP:
			return {
				...previousState,
				popups: {
					...previousState.popups,
					surveysAndFoldersDeletionPopup: {
						...previousState.popups.surveysAndFoldersDeletionPopup,
						show: action.newState
					}
				}
			};
		case ACTIONS.TOGGLE_MOVE_TO_FOLDER_POPUP:
			return {
				...previousState,
				popups: {
					...previousState.popups,
					moveToFolderPopup: {
						...previousState.popups.moveToFolderPopup,
						show: action.newState
					}
				}
			};
		case ACTIONS.TOGGLE_SURVEY_DELETION_ERROR_POPUP:
			return {
				...previousState,
				popups: {
					...previousState.popups,
					surveyDeletionErrorPopup: {
						...previousState.popups.surveyDeletionErrorPopup,
						show: action.newState,
						error: action.error
					}
				}
			};
		default:
			return previousState;
	}
};

export default surveysReducer;