import React, {useRef, useEffect} from "react";
import "./DropdownItem.scss";

/**
 * @callback onClick
 */
/**
 * Individual item in Dropdown menu.
 *
 * @param narrow {boolean}
 * @param shouldScrollTo {boolean}
 * @param onClick {onClick}
 * @param children {JSX.Element[]}
 * @returns {JSX.Element}
 */
const DropdownItem = ({
	narrow,
	shouldScrollTo,
	onClick,
	children
}) => {
	const reference = useRef();

	useEffect(() => {
		shouldScrollTo && reference.current.scrollIntoView();
	}, []);

	return (
		<div
			ref={reference}
			className={`dropdownItem ${narrow ? "narrow" : ""}`}
			onClick={onClick}>
			{children}
		</div>
	);
};

export default DropdownItem;