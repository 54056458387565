import "../../../automaticReportingTypes";

/**
 * isWithinRange() check if the given number is within an allowed range.
 *
 * @param value {number}
 * @param minimum {number}
 * @param maximum {number}
 * @returns {boolean}
 */
const isWithinRange = (value, minimum, maximum) => {
	if (typeof value !== "number")
	{
		return false;
	}

	return minimum <= value && value <= maximum;
};

/**
 * isAllValues() checks whether the given value is just an asterisk.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isAllValues = (value) => {
	if (typeof value !== "string")
	{
		return false;
	}

	return value === "*";
};

/**
 * isNoSpecificValue() checks whether the given value is just a question mark.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isNoSpecificValue = (value) => {
	if (typeof value !== "string")
	{
		return false;
	}

	return value === "?";
};

/**
 * isValidIncrement() checks whether the given value is a valid incremental value. The first half of the value must be
 * a number within the correct range, and the second half must be a number.
 *
 * @param value {string}
 * @param minimum {number}
 * @param maximum {number}
 * @returns {boolean}
 */
const isValidIncrement = (value, minimum, maximum) => {
	//Must be string
	if (typeof value !== "string")
	{
		return false;
	}

	const halves = value.split("/");

	//Must have two halves
	if (halves.length !== 2)
	{
		return false;
	}

	//Both halves must be numbers
	if (isNaN(+halves[0]) || isNaN(+halves[1]))
	{
		return false;
	}

	//First half must be within range
	if (!isWithinRange(+halves[0], minimum, maximum))
	{
		return false;
	}

	return true;
};

/**
 * isValidRange() checks if the given value is a valid range value.
 *
 * @param value {string}
 * @param minimum {number}
 * @param maximum {number}
 * @returns {boolean}
 */
const isValidRange = (value, minimum, maximum) => {
	//Must be string
	if (typeof value !== "string")
	{
		return false;
	}

	const halves = value.split("-");

	//Must have two halves
	if (halves.length !== 2)
	{
		return false;
	}

	//Both must be numbers
	if (isNaN(+halves[0]) || isNaN(+halves[1]))
	{
		return false;
	}

	//Both must be within range
	if (!isWithinRange(+halves[0], minimum, maximum) || !isWithinRange(+halves[1], minimum, maximum))
	{
		return false;
	}

	//Latter half must be higher than the first half.
	if (+halves[1] <= +halves[0])
	{
		return false;
	}

	return true;
};

/**
 * isValidNthDayOfWeek() checks the validity of the use of # in the day-of-week column.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isValidNthDayOfWeek = (value) => {
	//Value must be string
	if (typeof value !== "string")
	{
		return false;
	}

	const halves = value.split("#");

	//Both halves must be numeric
	if (isNaN(+halves[0]) || isNaN(+halves[1]))
	{
		return false;
	}

	//The first half must be a valid weekday number
	return isWithinRange(+halves[0], 1, 7);
};

/**
 * isValidNearestWeekday() validates the use of "W" in the day-of-month column.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isValidNearestWeekday = (value) => {
	if (typeof value !== "string")
	{
		return false;
	}

	return /^([0-9]{1,2}|L)W$/.test(value);
};

/**
 * isValidLastDayOfMonth() validates the use of "L" in the day-of-month column.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isValidLastDayOfMonth = (value) => {
	if (typeof value !== "string")
	{
		return false;
	}

	return /^L(\-[[0-9]{0,2})?$/.test(value);
};

/**
 * isValidLastDayOfWeek() validates the use of "L" in the day-of-week column.
 *
 * @param value
 * @returns {boolean}
 */
const isValidLastDayOfWeek = (value) => {
	if (typeof value !== "string")
	{
		return false;
	}

	return /^[1-7]L$/.test(value);
};

/**
 * isValidGroupValue() checks if the given value is a valid set of several individual or range values. A set of one
 * value is also a set.
 *
 * @param value {string}
 * @param minimum {number}
 * @param maximum {number}
 * @returns {boolean}
 */
const isValidGroupValue = (value, minimum, maximum) => {
	if (typeof value !== "string")
	{
		return false;
	}

	return value.split(",").every((subValue) => (
		isWithinRange(+subValue, minimum, maximum) ||
		isValidRange(subValue, minimum, maximum) ||
		isValidIncrement(subValue, minimum, maximum)
	));
};

/**
 * isValidTimeColumn() validates seconds, minutes and hours columns.
 *
 * @param value {string}
 * @param minimum {number}
 * @param maximum {number}
 * @returns {boolean}
 */
const isValidTimeColumn = (value, minimum, maximum) => {
	return (
		isAllValues(value) ||
		isValidGroupValue(value, minimum, maximum)
	);
};

/**
 * isSecondsColumnValid() checks the validity of the seconds column.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isSecondsColumnValid = (value) => {
	if (value === undefined)
	{
		return false;
	}

	if (typeof value !== "string")
	{
		return false;
	}

	if (value.trim() === "")
	{
		return false;
	}

	return isValidTimeColumn(value, 0, 59);
};

/**
 * isMinutesColumnValid() checks the validity of the minutes column.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isMinutesColumnValid = (value) => {
	if (value === undefined)
	{
		return false;
	}

	if (typeof value !== "string")
	{
		return false;
	}

	if (value.trim() === "")
	{
		return false;
	}

	return isValidTimeColumn(value, 0, 59);
};

/**
 * isHoursColumnValid() checks the validity of the hours column.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isHoursColumnValid = (value) => {
	if (value === undefined)
	{
		return false;
	}

	if (typeof value !== "string")
	{
		return false;
	}

	if (value.trim() === "")
	{
		return false;
	}

	return isValidTimeColumn(value, 0, 23);
};

/**
 * isDaysOfMonthColumnValid() checks the validity of the day of month column.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isDaysOfMonthColumnValid = (value) => {
	if (value === undefined)
	{
		return false;
	}

	if (typeof value !== "string")
	{
		return false;
	}

	if (value.trim() === "")
	{
		return false;
	}

	return (
		isValidTimeColumn(value, 1, 31) ||
		isValidNearestWeekday(value) ||
		isValidLastDayOfMonth(value) ||
		isNoSpecificValue(value)
	);
};

/**
 * isMonthsColumnValid() checks the validity of the month column. Month names can be used to stand in for month numbers.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isMonthsColumnValid = (value) => {
	if (value === undefined)
	{
		return false;
	}

	if (typeof value !== "string")
	{
		return false;
	}

	if (value.trim() === "")
	{
		return false;
	}

	const numericVersion = value
		.replace("JAN", "1")
		.replace("FEB", "2")
		.replace("MAR", "3")
		.replace("APR", "4")
		.replace("MAY", "5")
		.replace("JUN", "6")
		.replace("JUL", "7")
		.replace("AUG", "8")
		.replace("SEP", "9")
		.replace("OCT", "10")
		.replace("NOV", "11")
		.replace("DEC", "12");

	return (
		isValidTimeColumn(numericVersion, 1, 12)
	);
}

/**
 * isDaysOfWeekColumnValid() checks the validity of the day-of-week column. Day names can be used to stand in for day
 * numbers. "L" can be used to stand in for Saturday.
 *
 * @param value {string}
 * @returns {boolean}
 */
const isDaysOfWeekColumnValid = (value) => {
	if (value === undefined)
	{
		return false;
	}

	if (typeof value !== "string")
	{
		return false;
	}

	if (value.trim() === "")
	{
		return false;
	}

	const numericVersion = value
		.replace("SUN", "1")
		.replace("MON", "2")
		.replace("TUE", "3")
		.replace("WED", "4")
		.replace("THU", "5")
		.replace("FRI", "6")
		.replace("SAT", "7")
		.replace("L", "7");

	return (
		isValidTimeColumn(numericVersion, 1, 7) ||
		isNoSpecificValue(value) ||
		isValidNthDayOfWeek(value) ||
		isValidLastDayOfWeek(value)
	);
};

/**
 * isYearsColumnValid() checks the validity of the years column.
 *
 * The years column may be absent, therefore undefined is accepted.
 *
 * @param value {string|undefined}
 * @returns {boolean}
 */
const isYearsColumnValid = (value) => value === undefined || isValidTimeColumn(value, 1970, 2099);

/**
 * isCronExpressionValid() takes a cron expression string and returns whether it's a valid expression or not.
 *
 * Uses the Quartz cron format.
 *
 * @param cronExpression {string}
 * @returns {CronExpressionValidity}
 */
const isCronExpressionValid = (cronExpression) => {
	//Expression must be a string.
	if (typeof cronExpression !== "string")
	{
		throw new Error("isCronExpressionValid: Cron expression must be a string.");
	}

	const columns = cronExpression.trim().toUpperCase().split(" ");

	//In this validator, we make the seconds column optional, therefore the columns must be checked twice, once as if
	//the first column is a seconds column, and again as if the first column is a minutes column.
	const analysisWithSeconds = [
		isSecondsColumnValid(columns[0]),
		isMinutesColumnValid(columns[1]),
		isHoursColumnValid(columns[2]),
		isDaysOfMonthColumnValid(columns[3]),
		isMonthsColumnValid(columns[4]),
		isDaysOfWeekColumnValid(columns[5]),
		isYearsColumnValid(columns[6])
	];
	const analysisWithoutSeconds = [
		isMinutesColumnValid(columns[0]),
		isHoursColumnValid(columns[1]),
		isDaysOfMonthColumnValid(columns[2]),
		isMonthsColumnValid(columns[3]),
		isDaysOfWeekColumnValid(columns[4]),
		isYearsColumnValid(columns[5])
	];

	const validWithSeconds =
		!analysisWithSeconds.some(columnValid => !columnValid) &&
		(columns.length === 6 || columns.length === 7);

	const validWithoutSeconds =
		!analysisWithoutSeconds.some(columnValid => !columnValid) &&
		(columns.length === 5 || columns.length === 6);

	return {
		withSeconds: {
			valid: validWithSeconds,
			seconds: analysisWithSeconds[0],
			minutes: analysisWithSeconds[1],
			hours: analysisWithSeconds[2],
			daysOfMonth: analysisWithSeconds[3],
			months: analysisWithSeconds[4],
			daysOfWeek: analysisWithSeconds[5],
			years: analysisWithSeconds[6]
		},
		withoutSeconds: {
			valid: validWithoutSeconds,
			minutes: analysisWithoutSeconds[0],
			hours: analysisWithoutSeconds[1],
			daysOfMonth: analysisWithoutSeconds[2],
			months: analysisWithoutSeconds[3],
			daysOfWeek: analysisWithoutSeconds[4],
			years: analysisWithoutSeconds[5]
		}
	};
};

export default isCronExpressionValid;