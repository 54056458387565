import cloneDeep from "lodash.clonedeep";
import {
	SET_SURVEY_INFO,
	SET_SURVEY_OWNER_INFO,
	SET_SEARCH_STATUS,
	SHOW_NOTIFICATION,
	SET_SEARCH_TERM,
	NOT_SEARCHED_YET
} from "./crmUtilitiesActions";

export const defaultState = {
	survey: null,
	searchTerm: "",
	searchStatus: NOT_SEARCHED_YET,
	surveyOwner: null,
	notification: null
};

export default function(previousState = defaultState, originalAction)
{
	// Clone to avoid manipulation of original values
	var nextState = cloneDeep(previousState);
	var action = cloneDeep(originalAction);
	switch (action.type)
	{
		case SET_SEARCH_TERM:
			nextState.notification = null;
			nextState.searchTerm = action.searchTerm;
			return nextState;

		case SET_SEARCH_STATUS:
			nextState.searchStatus = action.searchStatus;
			return nextState;

		case SHOW_NOTIFICATION:
			nextState.notification = action.notification;
			return nextState;

		case SET_SURVEY_INFO:
			nextState.survey = action.survey;
			return nextState;

		case SET_SURVEY_OWNER_INFO:
			nextState.surveyOwner = action.surveyOwner;
			return nextState;

		default:
			return nextState;
	}
}
