import React from "react";
import {connect} from "react-redux";
import ContextMenu from "ui/ContextMenu/ContextMenu";
import {newSurvey} from "../../Toolbar/functions/newSurvey";
import {moveToFolder} from "../../Toolbar/functions/moveToFolder";
import {openSurvey} from "../../Toolbar/functions/openSurvey";
import {duplicateSurvey} from "../../Toolbar/functions/duplicateSurvey";
import {renameSurvey} from "../../Toolbar/functions/renameSurvey";
import {shareSurvey} from "../../Toolbar/functions/shareSurvey";
import {transferSurvey} from "../../Toolbar/functions/transferSurvey";
import {toggleSharedAccess} from "../../Toolbar/functions/toggleSharedAccess";
import {surveyTemplate} from "../../Toolbar/functions/surveyTemplate";
import {launchSurvey} from "../../Toolbar/functions/launchSurvey";
import {closeSurvey} from "../../Toolbar/functions/closeSurvey";
import {reopenSurvey} from "../../Toolbar/functions/reopenSurvey";
import {deleteSurvey} from "../../Toolbar/functions/deleteSurvey";
import {restoreSurvey} from "../../Toolbar/functions/restoreSurvey";
import {cancelDeletion} from "../../Toolbar/functions/cancelDeletion";
import FILTERS from "../../../utils/filters";
import SURVEY_ACCESS from "conf/survey-access";
import Roles from "conf/roles";

/**
 * @callback OnClose
 */
/**
 * ContextMenu appears when the user clicks on a survey in the survey listing using the second mouse
 * button. ContextMenu should contain the same items as the toolbar.
 *
 * Note: When modifying items in the context menu, remember to make the same modifications in the toolbar.
 *
 * @param onClose {OnClose}
 * @param style {object}
 * @param surveys {object[]}
 * @param folders {object[]}
 * @param selectedSurveyIds {number[]}
 * @param mainFilter {string}
 * @returns {JSX.Element}
 */
const ContextMenuSurvey = ({
	onClose,
	style,
	surveys,
	folders,
	selectedSurveyIds,
	mainFilter
}) => {
	const firstSelectedSurvey = surveys.find(survey => survey.id === selectedSurveyIds[0]);
	const selectedSurveys = surveys.filter(survey => selectedSurveyIds.indexOf(survey.id) !== -1);

	const isAdmin = Roles.isAtLeast(Surveypal.getUser().getRole(), Roles.Admin)

	const sharedAccessText =
		selectedSurveyIds.length === 1 && firstSelectedSurvey.publicShare
		?
		translator.get("share-popup.disablePublicLink")
		:
		translator.get("share-popup.enablePublicLink");

	/**
	 * Conditions for deciding whether to show conditional items in the menu.
	 */
	const hasWriteAccess = firstSelectedSurvey.owner === Surveypal.getUser().getId() || firstSelectedSurvey.permission !== SURVEY_ACCESS.PERMISSION.READ;

	const showRenameOption = hasWriteAccess;
	const showSharingGroup = hasWriteAccess;
	const showTemplateGroup = isAdmin && hasWriteAccess;
	const showLaunchOption = selectedSurveyIds.length === 0 || (firstSelectedSurvey.active && !firstSelectedSurvey.published);
	const showCloseOption = selectedSurveyIds.length === 1 && firstSelectedSurvey.active && firstSelectedSurvey.published;
	const showReopenOption = selectedSurveyIds.length === 1 && !firstSelectedSurvey.active && firstSelectedSurvey.published;
	const showRestoreOption = mainFilter === FILTERS.FILTER_NAMES.DELETED_SURVEYS;
	const showPermanentDeleteOption = selectedSurveyIds.length === 1 && mainFilter === FILTERS.FILTER_NAMES.DELETED_SURVEYS && (firstSelectedSurvey.deleteAt === undefined || firstSelectedSurvey.deleteAt === 0);
	const showCancelDeletionOption = selectedSurveyIds.length === 1 && mainFilter === FILTERS.FILTER_NAMES.DELETED_SURVEYS && firstSelectedSurvey.deleteAt !== undefined && firstSelectedSurvey.deleteAt !== 0;
	const showDeleteOption = mainFilter !== FILTERS.FILTER_NAMES.DELETED_SURVEYS;

	/**
	 * Item groups for the menu
	 */
	const newItemGroup = [
		{
			text: translator.get("surveymodel.newSurvey"),
			disabled: selectedSurveyIds.length === 0,
			onClick: newSurvey
		},
		{
			text: translator.get("moveToFolder.moveToFolder"),
			disabled: selectedSurveyIds.length === 0 || folders.length === 0,
			onClick: moveToFolder
		}
	];

	const manipulateItemGroup = [
		{
			text: translator.get("home.open"),
			disabled: selectedSurveyIds.length === 0,
			onClick: () => openSurvey(firstSelectedSurvey)
		},
		{
			text: translator.get("home.duplicate"),
			disabled: selectedSurveyIds.length !== 1,
			onClick: () => duplicateSurvey(firstSelectedSurvey)
		},
		...(showRenameOption ? [{
			text: translator.get("home.rename"),
			disabled: selectedSurveyIds.length !== 1,
			onClick: () => renameSurvey(firstSelectedSurvey)
		}] : [])
	];

	const sharingGroup = [
		{
			text: translator.get("home.shareExt"),
			disabled: selectedSurveyIds.length !== 1,
			onClick: () => shareSurvey(firstSelectedSurvey)
		},
		{
			text: translator.get("home.ownership"),
			disabled: selectedSurveyIds.length !== 1,
			onClick: () => transferSurvey(selectedSurveyIds, "")
		},
		{
			text: sharedAccessText,
			disabled: selectedSurveyIds.length !== 1,
			onClick: () => toggleSharedAccess(firstSelectedSurvey)
		}
	];

	const templateGroup = [{
		text: translator.get("home.shareTemplate"),
		disabled: selectedSurveyIds.length !== 1,
		onClick: () => surveyTemplate()
	}];

	const launchCloseDeleteGroup = [
		...(showLaunchOption ? [{
			text: translator.get("home.launchSelected"),
			disabled: selectedSurveyIds.length !== 1,
			onClick: () => launchSurvey(selectedSurveyIds[0])
		}] : []),
		...(showCloseOption ? [{
			text: translator.get("close-popup.close"),
			disabled: selectedSurveyIds.length !== 1,
			onClick: () => closeSurvey(selectedSurveyIds[0])
		}] : []),
		...(showReopenOption ? [{
			text: translator.get("reOpen-popup.open"),
			disabled: selectedSurveyIds.length !== 1,
			onClick: () => reopenSurvey(selectedSurveyIds[0])
		}] : []),
		...(showRestoreOption ? [{
			text: translator.get("home.restore"),
			disabled: selectedSurveyIds.length === 0,
			onClick: () => restoreSurvey(selectedSurveys)
		}] : []),
		...(showPermanentDeleteOption ? [{
			text: translator.get("delete-permanent-popup.delete"),
			disabled: selectedSurveyIds.length === 0,
			onClick: () => deleteSurvey(selectedSurveys)
		}] : []),
		...(showCancelDeletionOption ? [{
			text: translator.get("home.cancelPermanentDeletion"),
			disabled: selectedSurveyIds.length !== 1,
			onClick: () => cancelDeletion(selectedSurveyIds[0])
		}] : []),
		...(showDeleteOption ? [{
			text: translator.get("home.delete"),
			disabled: selectedSurveyIds.length === 0,
			onClick: () => deleteSurvey(selectedSurveys)
		}] : [])
	];

	/**
	 * Menu items
	 */
	const items = [
		newItemGroup,
		manipulateItemGroup,
		...(showSharingGroup ? [sharingGroup] : []),
		...(showTemplateGroup ? [templateGroup] : []),
		launchCloseDeleteGroup
	];

	return (
		<ContextMenu
			items={items}
			onClose={onClose}
			style={style}
		/>
	);
};

export default connect(state => ({
	surveys: state.surveys.fetchables.surveys.surveys,
	folders: state.surveys.fetchables.folders.folders,
	selectedSurveyIds: state.surveys.list.selectedSurveyIds,
	mainFilter: state.surveys.list.filters.mainFilter
}))(ContextMenuSurvey);