import React from "react";
import Step from "./Step/Step.js";
import StepDivision from "./StepDivision/StepDivision";
import Footer from "./Footer/Footer";
import PreviousButton from "./PreviousButton/PreviousButton";
import NextButton from "./NextButton/NextButton";
import FinishButton from "./FinishButton/FinishButton";
import Stepper from "./Stepper/Stepper";
import "./Wizard.scss";

const WIZARD_CHILD_TYPES = {
	STEP: "Step",
	FOOTER: "Footer"
};

/**
 * @callback onClose
 */
/**
 * @callback onFinish
 */
/**
 * @callback onStepChange
 * @param newStep {number}
 */
/**
 * <Wizard/> is the big main generic UI component for wizards.
 *
 * @param className {string}
 * @param title {string}
 * @param finishAvailable {boolean}
 * @param step {number}
 * @param embedded {boolean}
 * @param onClose {onClose}
 * @param onFinish {onFinish}
 * @param onStepChange {onStepChange}
 * @param children {JSX.Element[]}
 * @returns {JSX.Element}
 */
const Wizard = ({
	className,
	title,
	finishAvailable,
	step,
	embedded,
	onClose,
	onFinish,
	onStepChange,
	children
}) => {
	const steps = React.Children.toArray(children)
		.filter(child => child.type.displayName === WIZARD_CHILD_TYPES.STEP)
		.map(child => ({
			className: child.props.className,
			content: child.props.children,
			available: child.props.available,
			title: child.props.title
		}));

	const footer = React.Children.toArray(children)
		.filter(child => child.type.displayName === WIZARD_CHILD_TYPES.FOOTER)
		.map(child => ({
			content: child.props.children
		}));

	//Throw error if there are no step elements within the wizard.
	if (steps.length === 0) throw new Error("Wizard needs at least one step.");

	const goToPreviousStep = () => onStepChange(step-1);

	const goToNextStep = () => onStepChange(step+1);

	const onFirstStep = step === 0;
	const previousStepAvailable = !onFirstStep && steps[step - 1].available;
	const onLastStep = step === steps.length - 1;
	const nextStepAvailable = !onLastStep && steps[step + 1].available;

	const wrapperClassName = `wizard ${className} ${embedded ? "embedded" : "standAlone"}`;

	return (
		<div className={wrapperClassName}>
			<div className={"header"}>
				{
					!!title &&
					<h1 className={"title font-title32"}>{title}</h1>
				}
				{
					!embedded &&
					<button
						className={"close-button"}
						onClick={onClose}>
						X
					</button>
				}
				<Stepper
					embedded={embedded}
					currentStep={step}
					steps={steps}
				/>
			</div>
			<div className={"content"}>
				<Step
					className={steps[step].className}>
					{steps[step].content}
				</Step>
			</div>
			<div className={"footer"}>
				{
					footer.length > 0 &&
					<div className={"custom-footer"}>
						{footer[0].content}
					</div>
				}
				<div className={"control-buttons"}>
					<PreviousButton
						onClick={() => goToPreviousStep()}
						disabled={!previousStepAvailable}
					/>
					{
						onLastStep
						?
						<FinishButton
							onClick={() => onFinish()}
							disabled={!finishAvailable}
						/>
						:
						<NextButton
							onClick={() => goToNextStep()}
							disabled={!nextStepAvailable}
						/>
					}
				</div>
			</div>
		</div>
	);
};

export {Wizard, Step, StepDivision, Footer};