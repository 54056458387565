import React from "react";
import "./SplitButtonItem.scss";

/**
 * SplitButtonItem is a single item in a split button menu.
 *
 * @param onClick {false}
 * @param disabled {boolean}
 * @param children {JSX.Element[]}
 * @returns {JSX.Element}
 */
const SplitButtonItem = ({onClick, disabled, children}) => {return (
		<div
			className={`SplitButtonItem ${disabled ? "disabled" : ""}`}
			onClick={!disabled ? onClick : (e) => e.stopPropagation()}>
			{children}
		</div>
	)
};

export default SplitButtonItem;