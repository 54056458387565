/* Promise chain is a generic way to handle a bunch of asynchronous actions in sequence.
 *
 * Parameters:
 * - items - an array of items that get fed one by one to the handler
 * - handler - a function that gets parameters:
 *   prevResults - already collected results or result in the chain
 *   item - the current item to be handled (e.g. data for a request)
 *   i - index of the current item
 *   handler must return a new promise that gets resolved with the updated results
 *
 * See usage examples in the related tests
 */

export default function promiseChain(items, initialCollector, handler)
{
	return items.map((item, i) => {
		return prevResults => handler(prevResults, item, i);
	}).reduce((prevPromise, nextTask) => prevPromise.then(nextTask), Promise.resolve(initialCollector));
}