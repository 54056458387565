import Hash from "utils/hash";
import {goToFolder} from "../../../surveysActions";

/**
 * enterFolder changes the current folder in the state and in the URL.
 *
 * @param folderId {number}
 */
export const enterFolder = (folderId) => {
	const currentSubView = Surveypal.store.getState().surveys.list.filters.mainFilter;

	const hash = new Hash();
	hash.set("view", `home/surveys/${currentSubView}/${folderId}`);
	hash.apply();
	Surveypal.store.dispatch(goToFolder(folderId));
};