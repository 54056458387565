import ConfirmationPopup from "common/confirmation-popup";
import EVENTS from "conf/ai-events";
import axios from "core/axios-sp";
import {fetchSurveys} from "../../../surveysActions";

/**
 * requestSurveyReopen sends a request to backend to reopen a closed survey, then refetches
 * the list of surveys.
 *
 * @param surveyId {number}
 */
export const requestSurveyReopen = (surveyId) => {
	Surveypal.applicationInsights.trackEvent(EVENTS.SURVEY_OPENED);

	axios.put("/app/survey/status", {
		id: surveyId,
		active: true
	}).then((response) => {
		fetchSurveys();
	});
};

/**
 * reopenSurvey displays a confirmation popup for reopening a closed survey.
 *
 * @param surveyId {number}
 */
export const reopenSurvey = (surveyId) => {
	const title = translator.get("home.openReport");
	const desc = translator.get("reOpen-popup.desc");
	const buttonText = translator.get("reOpen-popup.open");

	new ConfirmationPopup(() => requestSurveyReopen(surveyId), title, desc, buttonText);
};