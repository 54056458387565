define("notifications/free-limit-notification", function(require)
{
	var NotificationBar = require("ui/notification-bar");
	var RoundButton = require("ui/round-button");

	return NotificationBar.extend(
		{
			init: function (upgrade_callback)
			{
				this.supreme();

				var button = new RoundButton(translator.get("free-answer-limit.button"), upgrade_callback);
				this.find().click(upgrade_callback);
				button.makeDefault();
				button.attach(this.find());
			},

			setText: function(answerCount)
			{
				var text = '';

				if(answerCount < 100)
				{
					text = translator.get("free-answer-limit.barText50_1").replace("%1", answerCount);
					text += " " + translator.get("free-answer-limit.barText50_2");
				}
				else
				{
					text = translator.get("free-answer-limit.barText100");
				}

				this.text(text);
			}
		});
});