const SURVEY_STATUSES = {
	deleted: 0,
	markedAsToBeDeleted: 1,
	unpublished: 2,
	startInFuture: 3,
	endInPast: 4,
	endInFuture: 5,
	active: 6,
	inactive: 7
};

/**
 * Returns a number-encoded version of a survey's status.
 *
 * @param item {object} Survey object
 * @returns {number}
 */
const getStatusAsNumber = (item) => {
	if (item.deleted)
	{
		return SURVEY_STATUSES.deleted;
	}

	if (!item.published)
	{
		return SURVEY_STATUSES.unpublished;
	}

	if (item.active && item.startDate && item.startDate > Date.now())
	{
		// Active and time framed, but start data in future
		return SURVEY_STATUSES.startInFuture;
	}

	if (item.active && item.endDate && item.endDate <= Date.now())
	{
		// Active and time framed, but end date in past
		return SURVEY_STATUSES.endInPast;
	}

	if (item.active && item.endDate && item.startDate < Date.now() < item.endDate)
	{
		// Active and time framed, but end date in future (and start date in past).
		return SURVEY_STATUSES.endInFuture;
	}

	if (item.active)
	{
		// Active without time frame
		return SURVEY_STATUSES.active;
	}

	if (!item.active)
	{
		// Inactive without time frame
		return SURVEY_STATUSES.inactive
	}

	return SURVEY_STATUSES.deleted;
};

export {getStatusAsNumber, SURVEY_STATUSES};