const FilterActions = require("views/survey/result/filter-actions");
import * as RedUtils from "utils/reducerutils";
import { validateCopiedFiltersOrAutomations } from "./libraries/filterOrAutomationImportingUtils";
import clone from "lodash.clonedeep";
import assign from "lodash.assign";

export const defaultState = {
	loading: true,
	filters: [],
	deletedFilters: [],
	originalFilters: [],
	sourceItems: [],
	metaItems: [],
	elementItems: [],
	languageItems: [],
	surveys: [],
	loadedFilters: {},
	copiedFilters: [],
	filtersFetchedStatus: "ok",
	showSurveySelectionPopup: false,
	showFilterSelectionPopup: false,
	showFiltersImportedPopup: false
};

export default function (previousState = defaultState, action)
{
	// Clone to avoid manipulation of previous values
	const nextState = clone(previousState);
	switch (action.type)
	{
		case FilterActions.INITIAL_DATA_LOADING:
			return assign(nextState, {loading: true});

		case FilterActions.INITIAL_DATA_LOADED:
			return assign(nextState, {
				loading: false,
				filters: action.filters,
				originalFilters: clone(action.filters),
				sourceItems: action.sourceItems,
				metaItems: action.metaItems,
				elementItems: action.elementItems,
				languageItems: action.languageItems
			});

		case FilterActions.SURVEYS_WITH_FILTERS_LOADING:
			return assign(nextState, {loading: true});

		case FilterActions.SURVEYS_WITH_FILTERS_LOADED:
			return assign(nextState, {
				loading: false,
				surveys: action.surveys,
				showSurveySelectionPopup: true
			});

		case FilterActions.HIDE_SURVEY_SELECTION_POPUP:
			return assign(nextState, {
				showSurveySelectionPopup: action.showSurveySelectionPopup
			});

		case FilterActions.LOAD_FILTERS_FROM_SURVEY_LOADING:
			return assign(nextState, {loading: true});

		case FilterActions.LOAD_FILTERS_FROM_SURVEY_LOADED:
			return assign(nextState, {
				loading: false,
				filtersFetchedStatus: action.filtersFetchedStatus,
				loadedFilters: action.loadedFilters,
				showFiltersImportedPopup: action.showFiltersImportedPopup
			});

		case FilterActions.COPY_LOADED_FILTERS_TO_SURVEY:
			const filters = validateCopiedFiltersOrAutomations(RedUtils.deselectAllItems(action.filters), action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues, true);
			filters[0].selected = true;

			return assign(nextState, {
				filters: filters,
				showFiltersImportedPopup: true
			});

		case FilterActions.HIDE_FILTER_SELECTION_POPUP:
			return assign(nextState, {
				loadedFilters: {}
			});

		case FilterActions.HIDE_FILTERS_IMPORTED_POPUP:
			return assign(nextState, {
				showFiltersImportedPopup: false
			});

		case FilterActions.CREATE_FILTER:
		{
			let deselectedFilters = RedUtils.deselectAllItems(nextState.filters);
			deselectedFilters.push(action.filter);
			return assign(nextState, {
				filters: deselectedFilters
			});
		}

		case FilterActions.COPY_FILTER:
		{
			const deselectedFilters = RedUtils.deselectAllItems(nextState.filters);
			const originalIndex = RedUtils.findIndexById(deselectedFilters, action.originalFilter.id);
			const firstHalf = nextState.filters.slice(0, originalIndex + 1);
			const lastHalf = nextState.filters.slice(originalIndex + 1);
			const newFilters = [...firstHalf, action.newFilter, ...lastHalf].map((filter, index) => ({...filter, order: index}));

			return assign(nextState, {
				filters: newFilters
			});
		}

		case FilterActions.DELETE_FILTER:
		{
			const delIndex = RedUtils.findIndexById(nextState.filters, action.filterId);
			nextState.deletedFilters.push(nextState.filters[delIndex]);
			nextState.filters.splice(delIndex, 1);
			if (nextState.filters[delIndex])
			{
				nextState.filters[delIndex].selected = true;
			}
			else if (nextState.filters[delIndex - 1])
			{
				nextState.filters[delIndex - 1].selected = true;
			}
			return nextState;
		}

		case FilterActions.SELECT_FILTER:
		{
			const deselectedFilters = RedUtils.deselectAllItems(nextState.filters);
			RedUtils.findById(deselectedFilters, action.filterId).selected = true;
			return nextState;
		}

		case FilterActions.EDIT_FILTER:
		{
			RedUtils.findById(nextState.filters, action.filterId).editing = true;
			return nextState;
		}

		case FilterActions.RENAME_FILTER:
		{
			const index = RedUtils.findIndexById(nextState.filters, action.filterId);
			nextState.filters[index] = assign(nextState.filters[index], {
				editing: false,
				name: action.filterName
			});
			return nextState;
		}

		case FilterActions.CHANGE_ROOT_OPERATOR:
		{
			RedUtils.findById(nextState.filters, action.filterId).match = action.rootOperatorValue;
			return nextState;
		}

		case FilterActions.CHANGE_GROUP_OPERATOR:
		{
			const filter = RedUtils.findById(nextState.filters, action.filterId);
			filter.groups[action.groupIndex].match = action.operatorValue;
			return nextState;
		}

		case FilterActions.CHANGE_RULE_TYPE:
		{
			let filter = RedUtils.findById(nextState.filters, action.filterId);
			filter.groups[action.groupIndex].rules[action.ruleIndex] = {
				key: action.ruleKey,
				value: action.value,
				op: action.operator,
				type: action.ruleType
			};
			filter = validateCopiedFiltersOrAutomations([filter], action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues)[0];
			return nextState;
		}

		case FilterActions.CHANGE_RULE_OPERATOR:
		{
			let filter = RedUtils.findById(nextState.filters, action.filterId);
			filter.groups[action.groupIndex].rules[action.ruleIndex].op = action.operator;
			filter = validateCopiedFiltersOrAutomations([filter], action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues)[0];
			return nextState;
		}

		case FilterActions.DELETE_RULE:
		{
			let filter = RedUtils.findById(nextState.filters, action.filterId);
			filter.groups[action.groupIndex].rules.splice(action.ruleIndex, 1);
			filter = validateCopiedFiltersOrAutomations([filter], action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues)[0];
			return nextState;
		}

		case FilterActions.DUPLICATE_RULE:
		{
			const groupIndex = action.groupIndex;
			const ruleIndex = action.ruleIndex;
			const filter = RedUtils.findById(nextState.filters, action.filterId);
			const rule = RedUtils.prepareRuleForDuplicating(clone(filter.groups[groupIndex].rules[ruleIndex]));
			filter.groups[groupIndex].rules.splice((ruleIndex + 1), 0, rule);
			return nextState;
		}

		case FilterActions.CREATE_NEW_RULE:
		{
			const filter = RedUtils.findById(nextState.filters, action.filterId);
			filter.groups[action.groupIndex].rules.push(action.newRule);
			return nextState;
		}

		case FilterActions.CHANGE_RULE_VALUE:
		{
			let filter = RedUtils.findById(nextState.filters, action.filterId);
			filter.groups[action.groupIndex].rules[action.ruleIndex].value = action.value;
			filter = validateCopiedFiltersOrAutomations([filter], action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues)[0];
			return nextState;
		}

		case FilterActions.ADD_NEW_CONDITION_GROUP:
		{
			const filter = RedUtils.findById(nextState.filters, action.filterId);
			filter.groups.push(action.newGroup);
			return nextState;
		}

		case FilterActions.REMOVE_CONDITION_GROUP:
		{
			let filter = RedUtils.findById(nextState.filters, action.filterId);
			filter.groups.splice(action.groupIndex, 1);
			filter = validateCopiedFiltersOrAutomations([filter], action.applicableRules, action.elementItems, action.metaItems, action.languageItems, action.sourceItems, action.useSliderIndexValues)[0];
			return nextState;
		}

		default:
			return nextState;
	}
}