import React from "react";

/**
 * <Step/> is a component for a step/page of the wizard.
 *
 * The parameters "title" and "available" are not directly used byt the Step component. Instead they are used
 * by the parenting <Wizard/> to organize the steps.
 *
 * @param className {string}
 * @param title {string}
 * @param available {boolean}
 * @param children {JSX.Element[]}
 * @returns {JSX.Element}
 */
const Step = ({className, title, available, children}) => {
	const stepClassName = `step ${className ? className : ""}`;

	return (
		<div className={stepClassName}>
			{children}
		</div>
	);
};

Step.displayName = "Step";

export default Step;