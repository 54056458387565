define("utils/console", function(require)
{
	var Environment = require("utils/environment");

	return function()
	{
		function init()
		{
			if (Environment.isProductionEnv())
			{
				// Disable all console messages for production
				window.console.log = function() {};
				window.console.info = function() {};
				window.console.warn = function() {};
				window.console.error = function() {};
			}
			else if (Environment.isStaging())
			{
				logSpider();
			}
			else
			{
				logBug();
			}
		}

		function logBug()
		{
			console.log("");
			console.log("                 .-\"-.");
			console.log("   .-\"-.      @@/     \\");
			console.log("  /     \\@@   Y '-<<<-'");
			console.log("  '->>>-' Y       '''");
			console.log("    ```");
			console.log("                      .-\"-.");
			console.log("      .-\"-.        @@/     \\");
			console.log("     /     \\@@     Y '-<<<-'");
			console.log("     '->>>-' Y         '''");
			console.log("       ```");
			console.log("");
			console.log("Don't mind us. We are just chilling here.");
			console.log("");
		}

		function logSpider()
		{
			console.log("");
			console.log("        ^^         |         ^^");
			console.log("        ::         |         ::");
			console.log(" ^^     ::         |         ::     ^^");
			console.log(" ::     ::         |         ::     ::");
			console.log("  ::     ::        |        ::     ::");
			console.log("    ::    ::       |       ::    ::");
			console.log("      ::    ::   _/~\\_   ::    ::");
			console.log("        ::   :::/     \\:::   ::");
			console.log("          :::::(       ):::::");
			console.log("                \\ ___ /");
			console.log("           :::::/`   `\\:::::");
			console.log("         ::    ::\\o o/::    ::");
			console.log("       ::     ::  :\":  ::     ::");
			console.log("     ::      ::   ` `   ::      ::");
			console.log("    ::      ::           ::      ::");
			console.log("   ::      ::             ::      ::");
			console.log("   ^^      ::             ::      ^^");
			console.log("           ::             ::");
			console.log("           ^^             ^^");
			console.log("");
			console.log("Got any bugs to spare? I'm hungry.");
			console.log("");
		}

		init();
	}
});