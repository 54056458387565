import React, {useEffect} from "react";
import PopupR from "ui/PopupR";
import {PopupRibbonR, RibbonButtonR, RibbonIcon} from "ui/PopupRibbonR/PopupRibbonR";
import ExistingReports from "./ExistingReports/ExistingReports";
import CreateNewReport from "./CreateNewReport/CreateNewReport";
import {connect} from "react-redux";
import {
	startFetchingExistingReports,
	clearAutomaticReportingState,
	setTab,
	fetchFilters,
	fetchPptxThemes,
	fetchDefaultPptxTheme,
	fetchMergedData
} from "./automaticReportingActions";
import "./AutomaticReporting.scss";

export const AUTOMATIC_REPORTS_DESTINATIONS = {
	EMAIL: "com.surveypal.survey.result.export.scheduled.EmailDestination",
	FTP: "com.surveypal.survey.result.export.scheduled.FtpDestination"
};

export const TABS = {
	EXISTING_REPORTS: "EXISTING_REPORTS",
	CREATE_NEW_REPORT: "CREATE_NEW_REPORT"
};

/**
 * @callback onClose
 */
/**
 * <AutomaticReporting/> opens a popup for managing and creating automated reports.
 *
 * @param loadingExistingReports {boolean}
 * @param tab {string}
 * @param onClose {onClose}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const AutomaticReporting = ({
	tab,
	onClose,
	dispatch
}) => {
	useEffect(() => {
		startFetchingExistingReports(dispatch);
		fetchFilters(dispatch);
		fetchPptxThemes(dispatch);
		fetchDefaultPptxTheme(dispatch);
		fetchMergedData(dispatch);

		return () => {
			dispatch(clearAutomaticReportingState());
		};
	}, []);

	return (
		<PopupR
			width={870}
			title={translator.get("automaticReporting.automaticReporting")}
			hideButtons={tab === TABS.CREATE_NEW_REPORT}
			cancelCallback={onClose}
			cancelText={translator.get("common.close")}
			hideSubmit={true}>
			<PopupRibbonR>
				<RibbonButtonR
					icon={RibbonIcon.LIST}
					onClick={() => dispatch(setTab(TABS.EXISTING_REPORTS))}
					label={translator.get("automaticReporting.existingReports")}
					active={tab === TABS.EXISTING_REPORTS}
				/>
				<RibbonButtonR
					icon={RibbonIcon.GEARS}
					onClick={() => dispatch(setTab(TABS.CREATE_NEW_REPORT))}
					label={translator.get("automaticReporting.createNewReport")}
					active={tab === TABS.CREATE_NEW_REPORT}
				/>
			</PopupRibbonR>
			<div className={"automaticReportingContent"}>
				{
					tab === TABS.EXISTING_REPORTS &&
					<ExistingReports/>
				}
				{
					tab === TABS.CREATE_NEW_REPORT &&
					<CreateNewReport
						onClose={onClose}
					/>
				}
			</div>
		</PopupR>
	);
};

export default connect(state => ({
	tab: state.automaticReporting.tab,
	loadingExistingReports: state.automaticReporting.existingReports.fetchables.existingReports.loading,
}))(AutomaticReporting);